/**
 * @generated SignedSource<<90ec3b1d12402a43b4169794b6a6b9f9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CheckoutAssistance_viewer$data = {
  readonly checkoutAssistanceGetHelpTopics: {
    readonly " $fragmentSpreads": FragmentRefs<"PopularArticles_getHelpTopics">;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"OtherHelp_viewer">;
  readonly " $fragmentType": "CheckoutAssistance_viewer";
};
export type CheckoutAssistance_viewer$key = {
  readonly " $data"?: CheckoutAssistance_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"CheckoutAssistance_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CheckoutAssistance_viewer",
  "selections": [
    {
      "alias": "checkoutAssistanceGetHelpTopics",
      "args": [
        {
          "kind": "Literal",
          "name": "helpType",
          "value": "checkoutAssistance"
        }
      ],
      "concreteType": "GetHelpTopics",
      "kind": "LinkedField",
      "name": "getHelpTopics",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PopularArticles_getHelpTopics"
        }
      ],
      "storageKey": "getHelpTopics(helpType:\"checkoutAssistance\")"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OtherHelp_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "19ced939b7d1a24e2b44583551a3e792";

export default node;
