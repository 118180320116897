import dibsCss from 'dibs-css';
import classnames from 'classnames';
import { FunctionComponent } from 'react';

type EnvelopeProps = {
    className?: string;
};

const Envelope: FunctionComponent<EnvelopeProps> = props => {
    return (
        <svg
            className={classnames(dibsCss.dibsIcon, props.className)}
            data-tn={'envelope'}
            viewBox="0 0 250 250"
        >
            <path d="M1.9 27.8v193.7h246.6V27.8H1.9zM228 36.7L124.6 144.9 22.6 36.7H228zm11.6 175.9H10.8V36.7l113.8 121.9 115-121.9v175.9z" />
        </svg>
    );
};
export default Envelope;
