export const DENIED_PAYMENT_AUTHORIZATION_ERRORS = {
    FAILURE_BAD_PAYMENT_METHOD: 'FAILURE_BAD_PAYMENT_METHOD',
    FAILURE_BANK_SOFT_DECLINE: 'FAILURE_BANK_SOFT_DECLINE',
    FAILURE_CARD_ERROR: 'FAILURE_CARD_ERROR',
    FAILURE_CC_EXPIRATION: 'FAILURE_CC_EXPIRATION',
    FAILURE_LIMIT_EXCEEDED: 'FAILURE_LIMIT_EXCEEDED',
    FAILURE_PAYPAL_ACCOUNT: 'FAILURE_PAYPAL_ACCOUNT',
    FAILURE_PAYPAL_DECLINE: 'FAILURE_PAYPAL_DECLINE',
    FAILURE_PAYPAL_SUPPORT: 'FAILURE_PAYPAL_SUPPORT',
    FAILURE_RETRY: 'FAILURE_RETRY',
    PROCESSOR_ERROR: 'PROCESSOR_ERROR',
    SERVICE_ERROR: 'SERVICE_ERROR',
    THREE_DS_REQUIRED: 'THREE_DS_REQUIRED',
} as const;
export type DeniedPaymentAuthorizationError = keyof typeof DENIED_PAYMENT_AUTHORIZATION_ERRORS;

export const SEND_MESSAGE_ERRORS = {
    MESSAGE_RATE_LIMIT: 'MESSAGE_RATE_LIMIT',
} as const;
export type SendMessageError = keyof typeof SEND_MESSAGE_ERRORS;
