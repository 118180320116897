import { FC } from 'react';
import { useFragment, graphql } from 'react-relay';
import { FormattedMessage } from 'dibs-react-intl';

import GetHelpHeader from './GetHelpHeader';
import PopularArticles from './PopularArticles';
import OtherTopics from './OtherTopics';

import { OtherAssistance_viewer$key } from './__generated__/OtherAssistance_viewer.graphql';

const OtherAssistance: FC<{ viewer: OtherAssistance_viewer$key }> = ({ viewer: viewerRef }) => {
    const viewer = useFragment(
        graphql`
            fragment OtherAssistance_viewer on Viewer {
                otherAssistanceGetHelpTopics: getHelpTopics(
                    helpType: $helpType
                    isSeller: $isSeller
                ) {
                    ...PopularArticles_getHelpTopics
                    ...OtherTopics_getHelpTopics
                }
            }
        `,
        viewerRef
    );

    const getHelpTopics = viewer.otherAssistanceGetHelpTopics;

    return (
        <div>
            <GetHelpHeader
                title={
                    <FormattedMessage
                        id="getHelp.OtherAssistance.greeting"
                        defaultMessage="Other Assistance"
                    />
                }
            />
            <PopularArticles getHelpTopics={getHelpTopics} />
            <OtherTopics getHelpTopics={getHelpTopics} />
        </div>
    );
};

export default OtherAssistance;
