import { FC, useContext } from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'dibs-react-intl';

import GetHelpContext from './GetHelpContext';

import styles from './styles/Error.scss';

const Error: FC = () => {
    const {
        state: { isEmbedded },
    } = useContext(GetHelpContext);

    return (
        <div className={classnames(styles.container, { [styles.isEmbedded]: isEmbedded })}>
            <div>
                <div className={styles.header}>
                    <FormattedMessage
                        id="getHelp.Error.header"
                        defaultMessage="Sorry, we're currently experiencing technical difficulties."
                    />
                </div>
                <div className={styles.info}>
                    <FormattedMessage
                        id="getHelp.Error.info"
                        defaultMessage="We know your time is valuable. Give us a few seconds and try loading this page again."
                    />
                </div>
            </div>
        </div>
    );
};

export default Error;
