import { FC } from 'react';
import classnames from 'classnames';

import dibsCss from 'dibs-css';
import styles from './GetHelpZendeskContentDivider.scss';

const GetHelpZendeskContentDivider: FC = () => {
    return (
        <div className={classnames(styles.line, dibsCss.h1px, dibsCss.wFull, dibsCss.bgDolphin)} />
    );
};

export { GetHelpZendeskContentDivider };
