import { Component, ReactNode, ChangeEvent, MouseEvent, FocusEvent } from 'react';
import classnames from 'classnames';

import { ErrorMessage } from '../ErrorMessage';
import { Field } from '../Common/Field/Field';
import { FormLabel } from '../Common/FormLabel/FormLabel';
import { IdConsumer } from 'dibs-uid';

import styles from './main.scss';

type TextAreaProps = {
    autoFocus?: boolean;
    className?: string;
    dataTn: string;
    disabled?: boolean;
    showErrorAsNote?: boolean;
    errorDataTn?: string;
    errorMessage?: ReactNode | { __html: string };
    errorMessageAlignment?: 'left' | 'right';
    hideBorder?: boolean;
    label?: ReactNode;
    maskForPrivacy?: boolean;
    maxLength?: number;
    onBlur?: (e: FocusEvent<HTMLTextAreaElement>) => void;
    onClick?: (e: MouseEvent<HTMLTextAreaElement>) => void;
    onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
    placeholder?: string;
    name?: string;
    rows?: number;
    readOnly?: boolean;
    value: string;
    ariaLabel?: string | null;
    /** must be universally unique among HTML element ids */
    id?: string;
};

export type TextAreaState = {
    value: string;
    prevPropsValue: string;
};

export class TextArea extends Component<TextAreaProps, TextAreaState> {
    static defaultProps = {
        autoFocus: false,
        errorMessageAlignment: 'left',
        hideBorder: false,
        value: '',
    };

    constructor(props: TextAreaProps) {
        super(props);

        this.state = {
            value: props.value || '',
            prevPropsValue: props.value || '',
        };
    }

    static getDerivedStateFromProps(
        props: TextAreaProps,
        state: TextAreaState
    ): { value: string; prevPropsValue: string } {
        let value: string = state.value;
        let prevPropsValue: string = state.prevPropsValue;

        if (props.value !== state.prevPropsValue) {
            value = props.value;
            prevPropsValue = props.value;
        }

        return {
            value,
            prevPropsValue,
        };
    }

    onChange = (e: ChangeEvent<HTMLTextAreaElement>): void => {
        this.setState({ value: e.currentTarget.value });

        if (this.props.onChange) {
            this.props.onChange(e);
        }
    };

    render(): ReactNode {
        const {
            autoFocus,
            className,
            dataTn,
            errorMessageAlignment,
            hideBorder,
            label,
            maskForPrivacy,
            name,
            maxLength,
            placeholder,
            disabled = false,
            showErrorAsNote = false,
            errorMessage,
            errorDataTn,
            readOnly,
            rows,
            onClick,
            onBlur,
            ariaLabel,
            id: propId,
        } = this.props;
        const errorDataTnString = errorDataTn ? errorDataTn : `${dataTn}-error`;

        const textareaClasses = classnames(styles.message, className, {
            [styles.error]: !!errorMessage && !showErrorAsNote,
            'fs-block': maskForPrivacy,
            [styles.noBorder]: hideBorder,
        });
        // allow null ariaLabel
        const derivedAriaLabel =
            ariaLabel !== null && !label ? ariaLabel || placeholder : undefined;
        return (
            <IdConsumer>
                {htmlId => {
                    const internalId = htmlId ? `input-${htmlId}` : undefined;
                    const inputId = propId || internalId;
                    // only render hidden placeholder if it can be related via
                    // id, has truthy value, will not be used in place of label
                    // or ariaLabel, and is not redundant with label or
                    // ariaLabel
                    const renderHiddenPlaceholder =
                        !!(htmlId && placeholder) &&
                        (label || ariaLabel) &&
                        placeholder !== label &&
                        placeholder !== ariaLabel;
                    const placeholderId = renderHiddenPlaceholder
                        ? `input-placeholder-${htmlId}`
                        : undefined;
                    return (
                        <Field>
                            {renderHiddenPlaceholder && (
                                <span id={placeholderId} className={styles.hiddenPlaceholder}>
                                    {placeholder}
                                </span>
                            )}
                            <FormLabel htmlFor={inputId}>{label}</FormLabel>
                            <textarea
                                id={inputId}
                                aria-label={derivedAriaLabel}
                                aria-describedby={placeholderId}
                                autoFocus={autoFocus}
                                className={textareaClasses}
                                data-tn={dataTn}
                                maxLength={maxLength || undefined}
                                onBlur={onBlur}
                                onChange={this.onChange}
                                onClick={onClick}
                                placeholder={placeholder}
                                rows={rows || 6}
                                name={name}
                                value={this.state.value || ''}
                                disabled={disabled}
                                readOnly={readOnly}
                            />
                            <ErrorMessage
                                showErrorAsNote={showErrorAsNote}
                                message={errorMessage}
                                dataTn={errorDataTnString}
                                alignRight={errorMessageAlignment === 'right'}
                            />
                        </Field>
                    );
                }}
            </IdConsumer>
        );
    }
}
