import { FC, useContext } from 'react';
import { useFragment, graphql } from 'react-relay';
import { FormattedMessage, FormattedDate, useIntl } from 'dibs-react-intl';

import ArrowRight from 'dibs-icons/exports/legacy/ArrowRight';
import { Link } from 'dibs-elements/exports/Link';

import { getOrderStatusLabel } from './getOrderStatusLabel';
import GetHelpContext from './GetHelpContext';
import { ACTION_SET_ORDER_ID, ACTION_SET_STEP, STEP_SELECTED_ORDER } from './constants';
import { trackOrderSelected } from './tracking';

import { OrdersListOrder_transaction$key } from './__generated__/OrdersListOrder_transaction.graphql';

import styles from './styles/SharedListItem.scss';

type Props = {
    transaction: OrdersListOrder_transaction$key | null | undefined;
};

const OrdersListOrder: FC<Props> = ({ transaction: transctionRef }) => {
    const intl = useIntl();

    const {
        dispatch,
        state: { isEmbedded, isSeller },
    } = useContext(GetHelpContext);

    const transaction = useFragment(
        graphql`
            fragment OrdersListOrder_transaction on Transaction {
                serviceId
                checkoutDate
                item {
                    title
                    firstPhotoWebPath(size: small)
                }
                ...getOrderStatusLabel_transaction
            }
        `,
        transctionRef
    );

    const { checkoutDate, item, serviceId } = transaction || {};
    const { title, firstPhotoWebPath } = item || {};
    const statusLabel = getOrderStatusLabel({ transaction, isSeller, intl });

    return (
        <Link
            dataTn="get-help-modal-orders-list-order"
            className={styles.container}
            onClick={() => {
                trackOrderSelected();

                if (isEmbedded) {
                    window.scrollTo(0, 0);
                }
                dispatch({ type: ACTION_SET_ORDER_ID, orderId: serviceId || '' });
                dispatch({ type: ACTION_SET_STEP, step: STEP_SELECTED_ORDER });
            }}
            underline="none"
        >
            <div className={styles.left}>
                <div className={styles.imageWrapper}>
                    <img
                        src={firstPhotoWebPath || ''}
                        alt={title || ''}
                        className={styles.image}
                        data-tn="get-help-orders-list-order-item-image"
                    />
                </div>
                <div className={styles.itemInfo}>
                    <div className={styles.title} data-tn="get-help-orders-list-order-item-title">
                        {title}
                    </div>
                    <div className={styles.details}>
                        <div className={styles.orderNumber} data-tn="get-help-orders-list-order-id">
                            <FormattedMessage
                                id="contact1stdibs.OrdersListOrder.orderNumber"
                                defaultMessage="Order {orderId}"
                                values={{ orderId: serviceId }}
                            />
                        </div>
                        <div
                            className={styles.orderStatus}
                            data-tn="get-help-orders-list-order-status"
                        >
                            {statusLabel && (
                                <>
                                    {statusLabel}
                                    {statusLabel && checkoutDate && (
                                        <span className={styles.dotDivider}>.</span>
                                    )}
                                </>
                            )}
                        </div>
                        {checkoutDate && (
                            <div
                                className={styles.checkoutDate}
                                data-tn="get-help-orders-list-order-checkout-date"
                            >
                                <FormattedMessage
                                    id="contact1stdibs.OrdersListOrder.checkoutDate"
                                    defaultMessage="Purchased on {checkoutDate}"
                                    values={{
                                        checkoutDate: (
                                            <FormattedDate
                                                year="numeric"
                                                month="short"
                                                day="numeric"
                                                value={checkoutDate || ''}
                                            />
                                        ),
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <ArrowRight className={styles.arrow} />
        </Link>
    );
};

export default OrdersListOrder;
