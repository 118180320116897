import { getAbTestV2, trackAbTestV2Variant } from 'dibs-ab-tests/exports/clientAbTestV2';

const SELLER_FACILITATED_SUPPORT_TEST_NAME = 'sellerFacilitatedSupport';
const BOMT_TIMELINE_OPTIMIZATION = 'bomt-timeline-optimization' as const;

export const isSellerFacilitatedSupportTestVariant = (): boolean =>
    getAbTestV2(SELLER_FACILITATED_SUPPORT_TEST_NAME)?.variant === 'variant';

export const trackSellerFacilitatedSupportTestVariant = (): void => {
    trackAbTestV2Variant(SELLER_FACILITATED_SUPPORT_TEST_NAME);
};

export const isBomtTimelineOptimizationVariant = (): boolean =>
    getAbTestV2(BOMT_TIMELINE_OPTIMIZATION)?.variant === 'variant';
