import { FC, ReactNode, useContext, useState } from 'react';
import { useMutation, graphql, useFragment } from 'react-relay';

import { FormattedMessage } from 'dibs-react-intl';

import ContactFormContent from './ContactFormContent';

import GetHelpContext from './GetHelpContext';

import { ContactBuyerFormMutation } from './__generated__/ContactBuyerFormMutation.graphql';
import { ContactBuyerForm_transaction$key } from './__generated__/ContactBuyerForm_transaction.graphql';

const ContactBuyerForm: FC<{
    transaction: ContactBuyerForm_transaction$key | null | undefined;
}> = ({ transaction: transactionRef }) => {
    const {
        state: { sellerId },
    } = useContext(GetHelpContext);
    const [messageContent, setMessageContent] = useState('');
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState<ReactNode>(null);
    const [conversationId, setConversationId] = useState('');

    const [contactDealer, isLoading] = useMutation<ContactBuyerFormMutation>(
        graphql`
            mutation ContactBuyerFormMutation($input: SaveMessageInput!) {
                saveMessage(input: $input) {
                    conversation {
                        serviceId
                    }
                }
            }
        `
    );

    const transaction = useFragment(
        graphql`
            fragment ContactBuyerForm_transaction on Transaction {
                item {
                    serviceId
                }
                buyer {
                    serviceId
                }
            }
        `,
        transactionRef
    );

    const { item, buyer } = transaction || {};
    const { serviceId: itemId } = item || {};
    const { serviceId: buyerServiceId } = buyer || {};

    const sendMessage = (): void => {
        setErrorMessage(null);
        contactDealer({
            variables: {
                input: {
                    textBody: messageContent,
                    senderId: sellerId,
                    isFromSeller: true,
                    itemId,
                    recipientId: buyerServiceId,
                },
            },
            onCompleted(response) {
                setShowSuccessMessage(true);
                setConversationId(response.saveMessage?.conversation?.serviceId || '');
            },
            onError() {
                setErrorMessage(
                    <FormattedMessage
                        id="getHelp.ContactBuyerForm.error"
                        defaultMessage="Something went wrong."
                    />
                );
            },
        });
    };

    return (
        <ContactFormContent
            showSuccessMessage={showSuccessMessage}
            sendMessage={sendMessage}
            conversationId={conversationId}
            setMessageContent={setMessageContent}
            messageContent={messageContent}
            isLoading={isLoading}
            errorMessage={errorMessage}
        />
    );
};

export default ContactBuyerForm;
