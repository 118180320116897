import { FC } from 'react';
import { useFragment, graphql } from 'react-relay';
import classnames from 'classnames';

import { FormattedMessage, FormattedDate } from 'dibs-react-intl';
import {
    isPostConfDeliveryTestVariant,
    trackPostConfDeliveryAbTest,
} from 'dibs-shipment-tracking/exports/postConfDeliveryAbTest';
import CarrierTrackingDetails from 'dibs-shipment-tracking/exports/CarrierTrackingDetails';
import EstimatedDeliveryDateDisplay from 'dibs-shipment-tracking/exports/EstimatedDeliveryDateDisplay';
import EstimatedShippingDateDisplay from 'dibs-shipment-tracking/exports/EstimatedShippingDateDisplay';
import { Button } from 'dibs-elements/exports/Button';
import {
    trackEvent,
    eventNameConstants,
    interactionTypeConstants,
    stepInteractionConstants,
    triggerConstants,
} from 'dibs-tracking';

import { TrackingDetails_viewer$key } from './__generated__/TrackingDetails_viewer.graphql';

import dibsCss from 'dibs-css';
import styles from './styles/TrackingDetails.scss';

const { EVENT_CONTACT_1STDIBS } = eventNameConstants;
const { INTERACTION_TYPE_BUYER_GET_HELP_MODAL } = interactionTypeConstants;
const {
    STEP_INTERACTION_TRACKING_DETAILS_CLICKED,
    STEP_INTERACTION_TRACKING_NUMBER_LINK_CLICKED,
    STEP_INTERACTION_CARRIER_TRACKING_CTA_CLICKED,
} = stepInteractionConstants;
const { TRIGGER_PROJECT_44_TRACKED, TRIGGER_NON_PROJECT_44_TRACKED } = triggerConstants;

const viewerFragment = graphql`
    fragment TrackingDetails_viewer on Viewer {
        ...EstimatedShippingDateDisplay_viewer
        ...EstimatedDeliveryDateDisplay_viewer
        transaction(transactionId: $transactionId) @include(if: $hasOrderId) {
            ...EstimatedDeliveryDateDisplay_transaction
            ...EstimatedShippingDateDisplay_transaction
            serviceId
            isCanceled
            shipment {
                ...CarrierTrackingDetails_shipment
                deliveryDateRangeDisplay
                estimatedShippingDate
                isBuyerShipping
                shipmentTracking {
                    lateByDate
                    trackingUrl
                    trackingUrlType
                    estimatedDeliveryDate
                }
            }
            buyerTimelineStatuses {
                shipped
                completed
            }
        }
    }
`;

const TrackingDetails: FC<{
    viewer: TrackingDetails_viewer$key | null | undefined;
    isSeller: boolean;
}> = ({ viewer: viewerRef, isSeller }) => {
    const viewer = useFragment(viewerFragment, viewerRef);

    const { transaction } = viewer || {};
    const {
        serviceId: transactionId,
        shipment,
        isCanceled,
        buyerTimelineStatuses,
    } = transaction || {};
    const { estimatedShippingDate, deliveryDateRangeDisplay, isBuyerShipping, shipmentTracking } =
        shipment || {};
    const { shipped, completed } = buyerTimelineStatuses || {};
    const { lateByDate, trackingUrl, trackingUrlType, estimatedDeliveryDate } =
        shipmentTracking || {};

    let isLate = false;

    if (lateByDate) {
        const today = new Date();
        const lateBy = new Date(lateByDate);
        isLate = today.setHours(0, 0, 0, 0) > lateBy.setHours(0, 0, 0, 0);
    }

    const showShippingDelayedBanner = !isSeller && isLate && !isBuyerShipping && !isCanceled;

    if (!completed) {
        trackPostConfDeliveryAbTest();
        const isProject44Tracking = trackingUrlType === 'PROJECT44';
        const isPostConfDeliveryVariant = isPostConfDeliveryTestVariant();
        const estimatedDeliveryDisplay =
            isProject44Tracking && estimatedDeliveryDate ? (
                <FormattedDate value={estimatedDeliveryDate} month="long" day="numeric" />
            ) : (
                deliveryDateRangeDisplay
            );

        if (isPostConfDeliveryVariant && estimatedDeliveryDisplay) {
            trackEvent({
                eventName: eventNameConstants.EVENT_CONTACT_1STDIBS,
                interaction_type: INTERACTION_TYPE_BUYER_GET_HELP_MODAL,
                step_interaction_name:
                    stepInteractionConstants.STEP_INTERACTION_ESTIMATED_DATE_DISPLAYED,
                trigger:
                    triggerConstants[
                        isProject44Tracking
                            ? 'TRIGGER_PROJECT_44_TRACKED'
                            : 'TRIGGER_NON_PROJECT_44_TRACKED'
                    ],
                orderId: transactionId || undefined,
            });

            return (
                <div
                    className={classnames(
                        dibsCss.sassyFontBodyHeaderLarge,
                        dibsCss.textNoir,
                        dibsCss.pyXsmall
                    )}
                >
                    <FormattedMessage
                        id="getHelp.TrackingDetails.estimatedDelivery"
                        defaultMessage="Estimated delivery: {estimatedDeliveryDate}"
                        values={{
                            estimatedDeliveryDate: (
                                <span
                                    className={classnames(
                                        styles.deliveryDate,
                                        dibsCss.sassyFontBodyHeaderLargeHeavy,
                                        {
                                            [dibsCss.textCharcoal]: !shipped,
                                            [dibsCss.textCharcoal]: shipped && isLate,
                                            [isPostConfDeliveryVariant
                                                ? dibsCss.textSuccesssecondary
                                                : dibsCss.textCharcoal]: shipped && !isLate,
                                        }
                                    )}
                                >
                                    {estimatedDeliveryDisplay}
                                </span>
                            ),
                        }}
                    />
                    {shipped && (
                        <div className={dibsCss.mtMedium}>
                            {isProject44Tracking && trackingUrl ? (
                                <Button
                                    href={trackingUrl}
                                    target="_blank"
                                    onClick={() =>
                                        trackEvent({
                                            eventName: EVENT_CONTACT_1STDIBS,
                                            interaction_type: INTERACTION_TYPE_BUYER_GET_HELP_MODAL,
                                            step_interaction_name:
                                                STEP_INTERACTION_TRACKING_DETAILS_CLICKED,
                                            trigger: TRIGGER_PROJECT_44_TRACKED,
                                            orderId: transactionId || undefined,
                                        })
                                    }
                                >
                                    <FormattedMessage
                                        id="getHelp.TrackingDetails.trackingCta"
                                        defaultMessage="Tracking Details"
                                    />
                                </Button>
                            ) : (
                                <div
                                    className={classnames(
                                        dibsCss.sassyFontBodyMedium,
                                        dibsCss.borderT,
                                        dibsCss.borderTDolphin,
                                        dibsCss.borderTSolid,
                                        dibsCss.ptMedium
                                    )}
                                >
                                    <CarrierTrackingDetails
                                        shipment={shipment || null}
                                        onTrackingNumberLinkClick={() =>
                                            trackEvent({
                                                eventName: EVENT_CONTACT_1STDIBS,
                                                interaction_type:
                                                    INTERACTION_TYPE_BUYER_GET_HELP_MODAL,
                                                step_interaction_name:
                                                    STEP_INTERACTION_TRACKING_NUMBER_LINK_CLICKED,
                                                trigger: TRIGGER_NON_PROJECT_44_TRACKED,
                                                orderId: transactionId || undefined,
                                            })
                                        }
                                        onTrackingCtaClick={() =>
                                            trackEvent({
                                                eventName: EVENT_CONTACT_1STDIBS,
                                                interaction_type:
                                                    INTERACTION_TYPE_BUYER_GET_HELP_MODAL,
                                                step_interaction_name:
                                                    STEP_INTERACTION_CARRIER_TRACKING_CTA_CLICKED,
                                                trigger: TRIGGER_NON_PROJECT_44_TRACKED,
                                                orderId: transactionId || undefined,
                                            })
                                        }
                                    />
                                </div>
                            )}
                        </div>
                    )}
                </div>
            );
        }
    }

    return (
        <div>
            {showShippingDelayedBanner && (
                <div className={styles.shippingDelayed}>
                    <FormattedMessage
                        id="getHelp.ShippingDelayedBanner.info"
                        defaultMessage="<b>It appears that this item has not yet been shipped.</b> We recommend contacting the seller for details regarding the delay."
                        values={{
                            b: content => <b>{content}</b>,
                        }}
                    />
                </div>
            )}
            <div className={styles.datesWrapper}>
                {!shipped && estimatedShippingDate && (
                    <div>
                        <EstimatedShippingDateDisplay
                            viewer={viewer}
                            transaction={transaction || null}
                        />
                    </div>
                )}
                {deliveryDateRangeDisplay && !completed && (
                    <div>
                        <EstimatedDeliveryDateDisplay
                            transaction={transaction || null}
                            viewer={viewer}
                        />
                    </div>
                )}
            </div>
            {shipped && (
                <div className={styles.trackingInfo}>
                    <CarrierTrackingDetails shipment={shipment || null} />
                </div>
            )}
        </div>
    );
};

export default TrackingDetails;
