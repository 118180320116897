import { FC, useState, useContext, ElementType } from 'react';
import classnames from 'classnames';

import { ExpandingArea } from 'dibs-elements/exports/ExpandingArea';
import { Link } from 'dibs-elements/exports/Link';
import AccountOutlined from 'dibs-icons/exports/legacy/AccountOutlined';
import Shipping from 'dibs-icons/exports/legacy/Shipping';
import ShoppingBagCheckmark from 'dibs-icons/exports/legacy/ShoppingBagCheckmark';
import ShoppingBagCheckmarkCircle from 'dibs-icons/exports/legacy/ShoppingBagCheckmarkCircle';
import ReturnArrow from 'dibs-icons/exports/legacy/ReturnArrow';
import CreditCard from 'dibs-icons/exports/legacy/CreditCard';
import MagnifyingGlass from 'dibs-icons/exports/legacy/MagnifyingGlass';
import Storefront from 'dibs-icons/exports/legacy/Storefront';
import MakeOffer from 'dibs-icons/exports/legacy/MakeOffer';
import PlaceholderArt from 'dibs-icons/exports/legacy/PlaceholderArt';
import Copy from 'dibs-icons/exports/legacy/Copy';
import RecommendItem from 'dibs-icons/exports/legacy/RecommendItem';
import Invoice from 'dibs-icons/exports/legacy/Invoice';
import PaddleOutlined from 'dibs-icons/exports/legacy/PaddleOutlined';

import GetHelpContext from './GetHelpContext';
import { ACTION_SET_TOPIC, ACTION_SET_STEP, STEP_TOPIC } from './constants';
import { trackCategorySelect, trackTopicSelect } from './tracking';

import { OtherTopics_getHelpTopics$data } from './__generated__/OtherTopics_getHelpTopics.graphql';
import { GetHelpTopicsCategoryType } from './__generated__/OtherTopics_getHelpTopics.graphql';

import styles from './styles/OtherTopicsCategory.scss';

type Category = NonNullable<OtherTopics_getHelpTopics$data['otherIssues']>[0];

const CategoryIconsMap: Partial<Record<GetHelpTopicsCategoryType, ElementType>> = {
    myOrders: ShoppingBagCheckmark,
    shippingAndTracking: Shipping,
    paymentAndCheckout: CreditCard,
    returnsAndRefunds: ReturnArrow,
    findingPiece: MagnifyingGlass,
    placingAnOrder: ShoppingBagCheckmarkCircle,
    myAccount: AccountOutlined,
    sellingOn1stDibs: Storefront,
    // seller specific
    offers: MakeOffer,
    orders: ShoppingBagCheckmark,
    shippingAndLogistics: Shipping,
    payments: Invoice,
    imaging: PlaceholderArt,
    itemDetails: Copy,
    listing: RecommendItem,
    auction: PaddleOutlined,
    account: AccountOutlined,
    billingAndPayment: Invoice,
    // end seller specific
};

const OtherTopicsCategory: FC<{ category: Category }> = ({ category }) => {
    const { dispatch } = useContext(GetHelpContext);

    const [isExpanded, setIsExpanded] = useState(false);

    const { categoryKey, categoryLabel, topics } = category || {};
    const IconComponent = categoryKey ? CategoryIconsMap[categoryKey] : null;

    return (
        <ExpandingArea
            expanded={isExpanded}
            labelClass={classnames(styles.cateogry, { [styles.isExpanded]: isExpanded })}
            label={
                <div className={styles.cateogryLabel}>
                    {IconComponent && <IconComponent className={styles.cateogryIcon} />}
                    {categoryLabel}
                </div>
            }
            showArrow
            onClick={() => {
                setIsExpanded(!isExpanded);
                if (!isExpanded) {
                    trackCategorySelect(categoryKey || '');
                }
            }}
            showGradient={false}
            expandingAreaClass={isExpanded ? styles.expandingAreaClass : ''}
        >
            {(topics || []).map(topic => (
                <Link
                    dataTn="get-help-modal-topic-link"
                    className={styles.topic}
                    key={topic?.topicKey}
                    underline="none"
                    onClick={() => {
                        dispatch({ type: ACTION_SET_TOPIC, topic: topic?.topicKey || '' });
                        dispatch({ type: ACTION_SET_STEP, step: STEP_TOPIC });
                        trackTopicSelect(topic?.topicKey || '');
                    }}
                >
                    {topic?.label}
                </Link>
            ))}
        </ExpandingArea>
    );
};

export default OtherTopicsCategory;
