/**
 * @generated SignedSource<<c882deb85764f0333715aef86f86f1e3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type GetHelpTopicsCategoryType = "account" | "auction" | "billingAndPayment" | "findingPiece" | "imaging" | "itemDetails" | "listing" | "myAccount" | "myOrders" | "offers" | "orders" | "paymentAndCheckout" | "payments" | "placingAnOrder" | "returnsAndRefunds" | "sellingOn1stDibs" | "shippingAndLogistics" | "shippingAndTracking" | "topIssues" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type OtherTopics_getHelpTopics$data = {
  readonly otherIssues: ReadonlyArray<{
    readonly categoryKey: GetHelpTopicsCategoryType | null;
    readonly categoryLabel: string | null;
    readonly topics: ReadonlyArray<{
      readonly label: string | null;
      readonly topicKey: string | null;
    } | null> | null;
  } | null> | null;
  readonly " $fragmentType": "OtherTopics_getHelpTopics";
};
export type OtherTopics_getHelpTopics$key = {
  readonly " $data"?: OtherTopics_getHelpTopics$data;
  readonly " $fragmentSpreads": FragmentRefs<"OtherTopics_getHelpTopics">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OtherTopics_getHelpTopics",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "GetHelpOtherIssues",
      "kind": "LinkedField",
      "name": "otherIssues",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "categoryKey",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "categoryLabel",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "GetHelpTopic",
          "kind": "LinkedField",
          "name": "topics",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "topicKey",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "label",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "GetHelpTopics",
  "abstractKey": null
};

(node as any).hash = "b56dd4c626fe228c18b5c985f1eade1a";

export default node;
