import dibsCss from 'dibs-css';
import classnames from 'classnames';
import { FunctionComponent } from 'react';

type PersonBubbleProps = {
    className?: string;
};

const PersonBubble: FunctionComponent<PersonBubbleProps> = props => {
    return (
        <svg
            className={classnames(dibsCss.dibsIcon, props.className)}
            data-tn={'person-bubble'}
            viewBox="0 0 250 250"
        >
            <path d="M224.1 0H119.2C105 0 93.5 11.6 93.5 25.8v29.6c-1.6-.1-3.2-.2-4.9-.1-17.8.2-34.5 7.4-46.9 20.1-12.4 12.8-19.2 29.6-19 47.4.4 28.6 18.7 52.8 44.2 62-18.7 3.1-38.6 11.1-52.7 28.9C2.1 229 .2 244.3.1 245c-.2 1.3.3 2.6 1.1 3.5s2.1 1.5 3.4 1.5h171.9c1.3 0 2.6-.6 3.5-1.6.9-1 1.2-2.4 1-3.7-5.1-27.8-22.7-47.3-51-56.4-5.8-1.9-11.6-3.1-16.8-3.9 25.4-9.7 43.3-34.4 43-63.2 0-3.7-.4-7.3-1-10.8h31.1l.8 24.7c.1 1.9 1.3 3.5 3 4.1.5.2 1 .3 1.5.3 1.3 0 2.5-.5 3.4-1.6l23.9-27.5h5.3c14.2 0 25.8-11.6 25.8-25.8V25.8C249.9 11.6 238.3 0 224.1 0zm-53.2 241H10.2c1.5-5.2 4.7-13.7 11.3-22 14.4-17.9 37.1-27 67.7-27h.3c.2 0 18.5-1.3 37.8 5 23.4 7.5 38 22.3 43.6 44zm-40.2-78.7c-10.8 11-25.2 17.2-40.6 17.4h-.7c-31.5 0-57.3-25.4-57.7-57-.2-15.4 5.6-30 16.4-41 10.8-11 25.2-17.2 40.6-17.4h.7c1.9 0 3.8.1 5.7.3.7.6 1.5.9 2.4 1 22.1 2 40.8 18.3 47.6 41.5v.1c1.2 4.5 1.8 9.3 1.9 14.1.3 15.4-5.6 29.9-16.3 41zm110.2-77.7c0 9.2-7.5 16.7-16.7 16.7h-7.4c-1.3 0-2.6.6-3.4 1.6l-17.7 20.4-.6-17.5c-.1-2.4-2.1-4.4-4.5-4.4h-37.7c-3.3-10.2-9-19.4-16.9-27.1-9.4-9.1-20.9-15.2-33.5-17.7V25.8c0-9.2 7.5-16.7 16.7-16.7h104.9c9.2 0 16.7 7.5 16.7 16.7v58.8z" />
        </svg>
    );
};
export default PersonBubble;
