import { FC, Suspense, useEffect, useContext } from 'react';
import classnames from 'classnames';
import { graphql, useFragment } from 'react-relay';

import { FormattedMessage } from 'dibs-react-intl';
import { Link } from 'dibs-elements/exports/Link';
import { GetHelpZendeskLatestOrder } from './GetHelpZendeskLatestOrder';
import { GetHelpZendeskLatestOrderSkeleton } from './GetHelpZendeskLatestOrderSkeleton';

import { GetHelpZendeskContext } from './GetHelpZendeskContext';
import { trackGetHelpZendeskEvent } from './getHelpZendeskTracking';

import { GetHelpZendeskArticle_viewer$key } from './__generated__/GetHelpZendeskArticle_viewer.graphql';

import dibsCss from 'dibs-css';
import './GetHelpZendeskArticle.scss';

const GetHelpZendeskArticle: FC<{ viewer: GetHelpZendeskArticle_viewer$key }> = ({
    viewer: viewerRef,
}) => {
    const {
        state: { isCheckout },
    } = useContext(GetHelpZendeskContext);

    const viewer = useFragment(
        graphql`
            fragment GetHelpZendeskArticle_viewer on Viewer {
                zendeskArticles {
                    article(articleId: $articleId) @include(if: $hasArticleId) {
                        body
                        title
                        url
                    }
                }
            }
        `,
        viewerRef
    );

    const { zendeskArticles } = viewer;
    const { body, title, url } = zendeskArticles?.article || {};

    useEffect(() => {
        trackGetHelpZendeskEvent({
            stepInteractionName: 'Support article viewed',
            trigger: title || '',
        });
    }, [title]);

    return (
        <div className={dibsCss.mbMedium}>
            <div className="zendesk-article-styles">
                <div className={classnames(dibsCss.sassyFontHeaderLarge, dibsCss.mbMedium)}>
                    {title}
                </div>
                <div
                    className={classnames('content', dibsCss.mbSmall)}
                    dangerouslySetInnerHTML={{
                        __html: body || '',
                    }}
                />
                <Link
                    href={url || ''}
                    target="_blank"
                    onClick={() => {
                        trackGetHelpZendeskEvent({
                            stepInteractionName: 'Navigate to support center',
                        });
                    }}
                >
                    <FormattedMessage
                        id="getHelp.GetHelpZendeskArticle.viewSupportCenterLink"
                        defaultMessage="View in Support Center"
                    />
                </Link>
            </div>
            <Suspense fallback={<GetHelpZendeskLatestOrderSkeleton />}>
                {!isCheckout && <GetHelpZendeskLatestOrder />}
            </Suspense>
        </div>
    );
};

export { GetHelpZendeskArticle };
