import { ReactNode } from 'react';
import { graphql, readInlineData } from 'react-relay';

import { Link } from 'dibs-elements/exports/Link';
import { FormattedMessage, IntlShape, defineMessages } from 'dibs-react-intl';
import { ReturnsFormattedPrice } from './ReturnsFormattedPrice';

import { getCurrency } from 'dibs-intl/exports/units';
import getBuyerSupportLink from 'dibs-support-link/exports/getBuyerSupportLink';

import { getReturnStatusCopy_transaction$key } from './__generated__/getReturnStatusCopy_transaction.graphql';

import dibsCss from 'dibs-css';

const messages = defineMessages({
    acceptQuoteHeader: {
        id: 'getReturnStatusCopy.acceptQuoteHeader',
        defaultMessage: 'Action Required: Accept Return Quote',
    },
    downloadShippingLabelHeader: {
        id: 'getReturnStatusCopy.downloadShippingLabelHeader',
        defaultMessage: 'Your return is in progress.',
    },
    returnInitiated: {
        id: 'getReturnStatusCopy.returnInitiated',
        defaultMessage: 'Return Request Submitted',
    },
    shippedHeader: {
        id: 'getReturnStatusCopy.shippedHeader',
        defaultMessage: 'Your item has been shipped.',
    },
    quoteAcceptedHeader: {
        id: 'getReturnStatusCopy.quoteAcceptedHeader',
        defaultMessage: 'Return in Progress',
    },
    returnApproved: {
        id: 'getReturnStatusCopy.returnApproved',
        defaultMessage: 'Return Approved',
    },
    returnSellerReceived: {
        id: 'getReturnStatusCopy.returnSellerReceived',
        defaultMessage: 'Your item has been received and your refund is pending.',
    },
    returnCancelled: {
        id: 'getReturnStatusCopy.returnCancelled',
        defaultMessage: 'Return Request Declined',
    },
    returnCompleted: {
        id: 'getReturnStatusCopy.returnCompleted',
        defaultMessage: 'Refund Pending',
    },
});

export const getReturnStatusCopy = ({
    transaction: transactionRef,
    intl,
}: {
    transaction: getReturnStatusCopy_transaction$key | null | undefined;
    intl: IntlShape;
}): { header: string | null; label?: ReactNode; note?: ReactNode; icon: string } => {
    const transaction = readInlineData(
        graphql`
            fragment getReturnStatusCopy_transaction on Transaction @inline {
                acceptQuote: hasAction(actionCode: "BUYER_APPROVE_RETURN_QUOTE")
                buyerCurrency
                returns {
                    status
                    showReturnDownloadShippingLabel
                    shipmentQuote {
                        isBuyerAccepted
                        isDealerAccepted
                        subTotalAmount {
                            ...ReturnsFormattedPrice_financialAmount
                        }
                    }
                    shipment {
                        hasUnknownShipmentTracking
                        isPreTransit
                        shipmentTracking {
                            status
                            labelUrl
                            carrier {
                                name
                            }
                        }
                    }
                }
            }
        `,
        transactionRef
    );

    const defaultCurrency = getCurrency(intl.locale);
    const { acceptQuote = false, returns, buyerCurrency } = transaction || {};
    const returnObj = returns?.[0];
    const currency = buyerCurrency || defaultCurrency;

    let header = null;
    let note = null;
    let icon = 'Returns';

    const { status, showReturnDownloadShippingLabel, shipment, shipmentQuote } = returnObj || {};
    const { hasUnknownShipmentTracking, isPreTransit, shipmentTracking } = shipment || {};

    if (acceptQuote) {
        const carrierName = shipmentTracking?.carrier?.name;
        const shipmentQuoteTotal = (
            <ReturnsFormattedPrice
                currency={currency}
                financialAmount={shipmentQuote?.subTotalAmount || null}
            />
        );

        return {
            header: intl.formatMessage(messages.acceptQuoteHeader),
            label: (
                <FormattedMessage
                    id="getReturnStatusCopy.acceptQuoteLabel"
                    defaultMessage="You received a return shipping quote for {shipmentQuoteTotal}"
                    values={{ shipmentQuoteTotal }}
                />
            ),
            note: (
                <FormattedMessage
                    id="getReturnStatusCopy.acceptQuoteNote"
                    defaultMessage="1stDibs must be involved in the return shipping process. Please accept the provided shipping quote within 7 days or the return will be cancelled.{hasCarrierName, select, true {<carrierInfo>Fulfilled by: 1stDibs  |  Carrier: {carrierName}</carrierInfo>} other {}} <supportCenterLink>Visit our support center for more details.</supportCenterLink>"
                    values={{
                        carrierName,
                        hasCarrierName: !!carrierName,
                        carrierInfo: content => <div className={dibsCss.mtXsmall}>{content}</div>,
                        supportCenterLink: content => (
                            <Link href={getBuyerSupportLink('')} target="_blank">
                                {content}
                            </Link>
                        ),
                    }}
                />
            ),
            icon: 'Exclamation',
        };
    }

    if (
        status === 'APPROVED' &&
        (hasUnknownShipmentTracking || isPreTransit) &&
        !!shipmentTracking?.labelUrl
    ) {
        return {
            header: intl.formatMessage(messages.downloadShippingLabelHeader),
            note: showReturnDownloadShippingLabel ? (
                <FormattedMessage
                    id="getReturnStatusCopy.downloadShippingLabelNote"
                    defaultMessage="1stDibs must be involved in the return shipping process. Please accept the provided shipping quote within 7 days or the return will be cancelled. <link>Visit our support center for more details.</link>"
                    values={{
                        link: (content: ReactNode[]) => (
                            <Link href={getBuyerSupportLink('')} target="_blank">
                                {content}
                            </Link>
                        ),
                    }}
                />
            ) : null,
            icon,
        };
    }

    switch (status) {
        case 'INITIATED': {
            header = intl.formatMessage(messages.returnInitiated);
            note = (
                <FormattedMessage
                    id="getReturnStatusCopy.returnInitiatedNote"
                    defaultMessage="Your return request has been sent to the seller."
                />
            );
            break;
        }
        case 'APPROVED': {
            const shipmentStatus = shipmentTracking?.status;
            const { isBuyerAccepted, isDealerAccepted } = shipmentQuote || {};

            if (shipmentStatus === 'SHIPPED') {
                header = intl.formatMessage(messages.shippedHeader);
                break;
            }

            if (isBuyerAccepted || isDealerAccepted) {
                header = intl.formatMessage(messages.quoteAcceptedHeader);

                note = (
                    <FormattedMessage
                        id="getReturnStatusCopy.quoteAcceptedNote"
                        defaultMessage="Your return delivery is on its way to the seller."
                    />
                );

                break;
            }

            header = intl.formatMessage(messages.returnApproved);

            note = (
                <FormattedMessage
                    id="getReturnStatusCopy.returnApprovedNote"
                    defaultMessage="Your return request has been accepted. 1stDibs will reach out to you to coordinate return shipping. Your refund will be processed once the seller has received the item in acceptable condition."
                />
            );

            break;
        }
        case 'SELLER_RECEIVED': {
            header = intl.formatMessage(messages.returnSellerReceived);

            break;
        }
        case 'CANCELED': {
            header = intl.formatMessage(messages.returnCancelled);

            note = (
                <FormattedMessage
                    id="getReturnStatusCopy.returnCancelledNote"
                    defaultMessage="Your return request has been declined."
                />
            );
            icon = 'Close';

            break;
        }
        case 'COMPLETED': {
            header = intl.formatMessage(messages.returnCompleted);

            note = (
                <FormattedMessage
                    id="getReturnStatusCopy.returnCompletedNote"
                    defaultMessage="Our team is reviewing your refund request. You’ll be notified via email once the refund is issued."
                />
            );

            break;
        }
    }

    return { header, note, icon };
};
