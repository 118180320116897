import dibsCss from 'dibs-css';
import classnames from 'classnames';
import { FunctionComponent } from 'react';

type MessageProps = {
    className?: string;
};

const Message: FunctionComponent<MessageProps> = props => {
    return (
        <svg
            className={classnames(dibsCss.dibsIcon, props.className)}
            data-tn={'message'}
            viewBox="0 0 250 250"
        >
            <path d="M235.5 8.9h-221C6.5 8.9 0 15.4 0 23.3v151.6c0 8 6.5 14.5 14.5 14.5h111.8l60.3 51c.8.7 1.9 1.1 2.9 1.1.6 0 1.3-.1 1.9-.4 1.6-.7 2.6-2.3 2.6-4.1v-47.6h41.6c8 0 14.5-6.5 14.5-14.5V23.3c-.1-7.9-6.6-14.4-14.6-14.4zm5.5 166c0 3-2.5 5.5-5.5 5.5H185v46.9l-55.5-47-.1.2-.1-.1H14.5c-3 0-5.5-2.5-5.5-5.5V23.3c0-3 2.5-5.5 5.5-5.5h221c3 0 5.5 2.5 5.5 5.5v151.6z" />
        </svg>
    );
};
export default Message;
