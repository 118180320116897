import dibsCss from 'dibs-css';
import classnames from 'classnames';
import { FC } from 'react';

type ShoppingBagCheckmarkProps = {
    className?: string;
    role?: 'img';
    ariaLabel?: string;
    ariaLabelledby?: string;
};

const ShoppingBagCheckmark: FC<ShoppingBagCheckmarkProps> = props => {
    return (
        <svg
            className={classnames(dibsCss.dibsIcon, props.className)}
            data-tn={'shopping-bag-checkmark'}
            role={props.role}
            aria-label={props.ariaLabel}
            aria-labelledby={props.ariaLabelledby}
            viewBox="0 0 250 250"
        >
            <path d="M214 231H28L9 64h231l-26 167zm-179-9h172l24-150H18l17 150z" />
            <path d="M175 72H73l1-6c2.645-9.845 7.177-19.088 12-28 10.472-19.08 23.226-29 38-29 14.774 0 28.518 9.898 39 29 4.817 8.914 8.356 18.157 11 28l1 6zm-92-9h82c-4.271-13.15-17.448-45-41-45S87.281 49.85 83 63z" />
            <path d="M175 118l-62 57-39-35 5-7 34 30 57-52 5 7z" fillRule="evenodd" />
        </svg>
    );
};

export default ShoppingBagCheckmark;
