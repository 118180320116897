/**
 * @generated SignedSource<<bb57942b68c176a9cb53620332a0174e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type getBuyerEventHeader_transaction$data = {
  readonly didCheckoutProcessingFail: boolean | null;
  readonly isCheckoutProcessing: boolean | null;
  readonly returns: ReadonlyArray<{
    readonly serviceId: string | null;
  } | null> | null;
  readonly transactionHistory: {
    readonly historicalEvents: ReadonlyArray<{
      readonly buyerCopy: string | null;
      readonly buyerStatusCopy: {
        readonly header: string | null;
      } | null;
      readonly name: string | null;
    } | null> | null;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"getDeniedPaymentAuthorizationStatus_transaction" | "getLastBuyerEvent_transaction" | "getReturnStatusCopy_transaction" | "isMissingRefundEventInProgress_transaction">;
  readonly " $fragmentType": "getBuyerEventHeader_transaction";
};
export type getBuyerEventHeader_transaction$key = {
  readonly " $data"?: getBuyerEventHeader_transaction$data;
  readonly " $fragmentSpreads": FragmentRefs<"getBuyerEventHeader_transaction">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "getBuyerEventHeader_transaction"
};

(node as any).hash = "22724c3fd42cb23d095e6d741fc9e428";

export default node;
