import { FC, useState } from 'react';
import { FormattedMessage } from 'dibs-react-intl';
import classnames from 'classnames';

import Envelope from 'dibs-icons/exports/legacy/Envelope';
import { ExpandingArea } from 'dibs-elements/exports/ExpandingArea';
import SendMessageForm from './SendMessageForm';

import styles from './styles/SendMessageButton.scss';

const SendMessageButton: FC = () => {
    const [isExpanded, setIsExpanded] = useState(false);

    return (
        <div className={classnames(styles.container, { [styles.isExpanded]: isExpanded })}>
            <ExpandingArea
                dataTn="contact-send-email"
                label={
                    <div className={styles.labelWrapper}>
                        <Envelope className={styles.icon} />
                        <div>
                            <div className={styles.label}>
                                <FormattedMessage
                                    id="getHelp.SendMessageButton.label"
                                    defaultMessage="Send Email"
                                />
                            </div>
                            <div className={styles.info}>
                                <FormattedMessage
                                    id="getHelp.SendMessageButton.info"
                                    defaultMessage="Typically respond within 24 hours"
                                />
                            </div>
                        </div>
                    </div>
                }
                expanded={isExpanded}
                onClick={() => setIsExpanded(!isExpanded)}
                showArrow
                labelClass={styles.labelContainer}
            >
                <div className={styles.formContainer}>
                    <SendMessageForm />
                </div>
            </ExpandingArea>
        </div>
    );
};

export default SendMessageButton;
