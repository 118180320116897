import { FC, useState } from 'react';
import classnames from 'classnames';

import { Button } from 'dibs-elements/exports/Button';
import RecaptchaContainer, { MFAErrorCodes, MFAActionType } from './RecaptchaContainer';
import dibsCss from 'dibs-css';
import { FormattedMessage } from 'dibs-react-intl';

export const RecaptchaForm: FC<{
    onSubmit: (mfaVerificationCode?: string) => Promise<void>;
    email?: string;
    errorCode: MFAErrorCodes | null;
    mfaActionType: MFAActionType;
    // add tracking props and pass RecaptchaContainer in
    // https://1stdibs.atlassian.net/browse/INTOOLS-2971
}> = ({ onSubmit, email, errorCode, ...recaptchaProps }) => {
    const [mfaVerificationCode, setMFAVerificationCode] = useState<string>();
    const [isDisabled, setIsDisabled] = useState(false);
    return (
        <form
            className={classnames(dibsCss.flex, dibsCss.flexCol, dibsCss.mXsmall)}
            onSubmit={async event => {
                event.preventDefault();
                if (isDisabled) {
                    return;
                }
                setIsDisabled(true);
                await onSubmit(mfaVerificationCode);
                setMFAVerificationCode(undefined);
                setIsDisabled(false);
            }}
        >
            <RecaptchaContainer
                {...recaptchaProps}
                email={email || ''}
                errorCode={errorCode}
                readOnly={isDisabled}
                mfaVerificationCode={mfaVerificationCode || null}
                setMFAVerificationCode={setMFAVerificationCode}
            />
            {errorCode !== 'SUSPICIOUS_ACTIVITY' && (
                <Button htmlType="submit" fullWidth size="medium" disabled={isDisabled}>
                    <FormattedMessage id="dibs.recaptchaForm.submit" defaultMessage="Submit" />
                </Button>
            )}
        </form>
    );
};
