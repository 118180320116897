/**
 * @generated SignedSource<<fd1dad1e01d70ac3ae3964f5668d6bfc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EstimatedDeliveryDateDisplay_transaction$data = {
  readonly " $fragmentSpreads": FragmentRefs<"getPostConfDeliveryDateDisplay_transaction" | "shippingTrackingDetails_transaction">;
  readonly " $fragmentType": "EstimatedDeliveryDateDisplay_transaction";
};
export type EstimatedDeliveryDateDisplay_transaction$key = {
  readonly " $data"?: EstimatedDeliveryDateDisplay_transaction$data;
  readonly " $fragmentSpreads": FragmentRefs<"EstimatedDeliveryDateDisplay_transaction">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "displayValue",
    "storageKey": null
  }
],
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "Period",
  "kind": "LinkedField",
  "name": "handlingTime",
  "plural": false,
  "selections": (v0/*: any*/),
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "Period",
  "kind": "LinkedField",
  "name": "collectionTime",
  "plural": false,
  "selections": (v0/*: any*/),
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "ShipmentServiceMethod",
  "kind": "LinkedField",
  "name": "serviceMethod",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "describeRange",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = [
  (v3/*: any*/),
  (v1/*: any*/)
],
v5 = {
  "kind": "InlineDataFragmentSpread",
  "name": "shippingTrackingDetails_transaction",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ItemSkuType",
      "kind": "LinkedField",
      "name": "sku",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "leadTime",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isAvailableNow",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TransactionLeadTime",
      "kind": "LinkedField",
      "name": "leadTime",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "value",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hasLeadTime",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Shipment",
      "kind": "LinkedField",
      "name": "shipment",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "deliveryDateRangeDisplay",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "estimatedShippingDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "leadTimeMin",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "leadTimeMax",
          "storageKey": null
        },
        (v1/*: any*/),
        (v2/*: any*/),
        (v3/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TransactionShipmentChangeReview",
      "kind": "LinkedField",
      "name": "pendingShipmentChangeReview",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Shipment",
          "kind": "LinkedField",
          "name": "shipment",
          "plural": false,
          "selections": [
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "shipmentType",
              "storageKey": null
            },
            (v1/*: any*/),
            (v2/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ShipmentQuote",
          "kind": "LinkedField",
          "name": "shipmentQuote",
          "plural": false,
          "selections": (v4/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ShipmentQuote",
      "kind": "LinkedField",
      "name": "currentShipmentQuote",
      "plural": false,
      "selections": (v4/*: any*/),
      "storageKey": null
    }
  ],
  "args": null,
  "argumentDefinitions": ([]/*: any*/)
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EstimatedDeliveryDateDisplay_transaction",
  "selections": [
    (v5/*: any*/),
    {
      "kind": "InlineDataFragmentSpread",
      "name": "getPostConfDeliveryDateDisplay_transaction",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Shipment",
          "kind": "LinkedField",
          "name": "shipment",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ShipmentTracking",
              "kind": "LinkedField",
              "name": "shipmentTracking",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "trackingUrlType",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "estimatedDeliveryDate",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        (v5/*: any*/)
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "Transaction",
  "abstractKey": null
};
})();

(node as any).hash = "c14283458541b6907bd3687fe478fc1d";

export default node;
