import { FC, ReactNode, Suspense, useContext, useCallback, useEffect } from 'react';
import { graphql, useLazyLoadQuery, useRelayEnvironment } from 'react-relay';

import { Spinner } from 'dibs-elements/exports/Spinner';
import Error from './Error';
import MessageSent from './MessageSent';
import ContactSellerMessageSent from './ContactSellerMessageSent';
import IntroStep from './IntroStep';
import OrdersList from './OrdersList';
import OtherAssistance from './OtherAssistance';
import SelectedOrder from './SelectedOrder';
import Topic from './Topic';
import CheckoutAssistance from './CheckoutAssistance';
import IntroStepSeller from './IntroStepSeller';
import ItemsList from './ItemsList';
import SelectedListing from './SelectedListing';

import { initChat } from './liveChat/initSalesforceChat';
import { adjustPdpSellerChatPosition } from './liveChat/helpers/adjustPdpSellerChatPosition';
import { CHAT_TYPE_MAP } from './liveChat/constants';
import GetHelpContext from './GetHelpContext';
import {
    STEP_INTRO,
    STEP_OTHER_ASSISTANCE,
    STEP_ORDERS_LIST,
    STEP_SELECTED_ORDER,
    STEP_TOPIC,
    STEP_MESSAGE_SENT,
    STEP_CONTACT_SELLER_MESSAGE_SENT,
    STEP_ERROR,
    STEP_CHECKOUT_ASSISTANCE,
    STEP_ITEMS_LIST,
    STEP_SELECTED_LISTING,
} from './constants';

import { GetHelpRendererQuery } from './__generated__/GetHelpRendererQuery.graphql';

import styles from './styles/GetHelpRenderer.scss';

const GetHelpRenderer: FC = () => {
    const environment = useRelayEnvironment();
    const {
        state: {
            step,
            orderId,
            helpType,
            topicValue,
            userId,
            isSeller,
            sellerId,
            itemPk,
            onChatEnd,
        },
    } = useContext(GetHelpContext);

    const { viewer } = useLazyLoadQuery<GetHelpRendererQuery>(
        graphql`
            query GetHelpRendererQuery(
                $orderId: String
                $helpType: GetHelpTopicsHelpType
                $transactionId: ID!
                $hasOrderId: Boolean!
                $topicKey: String!
                $userId: String!
                $hasUserId: Boolean!
                $sellerId: String!
                $hasSellerId: Boolean!
                $isSeller: Boolean!
                $itemId: String!
                $hasItemId: Boolean!
                $userCountryCode: String = ""
            ) {
                viewer {
                    contact1stdibsConfig(isSeller: false) {
                        isAgentAvailable
                        showChannelChat
                    }
                    ...IntroStep_viewer
                    ...IntroStepSeller_viewer
                    ...SelectedOrder_viewer @include(if: $hasOrderId)
                    ...OtherAssistance_viewer
                    ...Topic_viewer
                    ...CheckoutAssistance_viewer @skip(if: $isSeller)
                    ...SelectedListing_viewer @include(if: $hasItemId)
                }
            }
        `,
        {
            orderId,
            helpType,
            transactionId: orderId || '',
            hasOrderId: !!orderId,
            topicKey: topicValue || '',
            userId: userId || '',
            hasUserId: !!userId,
            sellerId: sellerId || '',
            hasSellerId: !!sellerId,
            isSeller,
            itemId: itemPk || '',
            hasItemId: !!itemPk,
        }
    );

    const Intro = isSeller ? IntroStepSeller : IntroStep;

    const getContent = useCallback((): ReactNode => {
        switch (step) {
            case STEP_INTRO:
                return <Intro viewer={viewer} />;
            case STEP_ORDERS_LIST:
                return <OrdersList />;
            case STEP_ITEMS_LIST:
                return <ItemsList />;
            case STEP_SELECTED_LISTING:
                return <SelectedListing viewer={viewer} />;
            case STEP_OTHER_ASSISTANCE:
                return <OtherAssistance viewer={viewer} />;
            case STEP_SELECTED_ORDER:
                return <SelectedOrder viewer={viewer} />;
            case STEP_TOPIC:
                return <Topic viewer={viewer} />;
            case STEP_MESSAGE_SENT:
                return <MessageSent />;
            case STEP_CONTACT_SELLER_MESSAGE_SENT:
                return <ContactSellerMessageSent />;
            case STEP_CHECKOUT_ASSISTANCE:
                return <CheckoutAssistance viewer={viewer} />;
            case STEP_ERROR:
                return <Error />;
            default:
                return <Intro viewer={viewer} />;
        }
    }, [step, viewer]);

    const { contact1stdibsConfig } = viewer;
    const { isAgentAvailable, showChannelChat } = contact1stdibsConfig || {};

    useEffect(() => {
        const chatShouldBeInitialized = showChannelChat && (isAgentAvailable || isSeller);

        // initiating chat in initial view to reduce wait time for chat button to be enabled in topic view
        if (chatShouldBeInitialized) {
            initChat({
                chatType: isSeller ? CHAT_TYPE_MAP.seller : CHAT_TYPE_MAP.buyer,
                environment,
                initiated: true,
                afterDestroy: () => {
                    if (typeof onChatEnd === 'function') {
                        onChatEnd();
                    }
                    if (!isSeller) {
                        adjustPdpSellerChatPosition({ reset: true });
                    }
                },
            });
        }
    }, [environment, isAgentAvailable, showChannelChat, isSeller, onChatEnd]);

    return (
        <Suspense
            fallback={
                <div className={styles.spinnerWrapper}>
                    <Spinner />
                </div>
            }
        >
            {getContent()}
        </Suspense>
    );
};

export default GetHelpRenderer;
