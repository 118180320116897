import { FC } from 'react';
import { useFragment, graphql } from 'react-relay';
import { FormattedMessage } from 'dibs-react-intl';

import ReturnPolicyEmbed from 'dibs-return-policy/exports/ReturnPolicyEmbed';

import { ItemReturnPolicy_viewer$key } from './__generated__/ItemReturnPolicy_viewer.graphql';

import styles from './styles/ItemReturnPolicy.scss';

const ItemReturnPolicy: FC<{ viewer: ItemReturnPolicy_viewer$key | null | undefined }> = ({
    viewer: viewerRef,
}) => {
    const viewer = useFragment(
        graphql`
            fragment ItemReturnPolicy_viewer on Viewer {
                transaction(transactionId: $transactionId) @include(if: $hasOrderId) {
                    returnPolicy {
                        ...ReturnPolicyEmbed_returnPolicy
                    }
                }
            }
        `,
        viewerRef
    );

    const returnPolicy = viewer?.transaction?.returnPolicy;

    return (
        <div className={styles.returnsPolicy}>
            <div className={styles.title}>
                <FormattedMessage
                    id="getHelp.TopicInfo.returnPolicyHeader"
                    defaultMessage="Return Policy"
                />
            </div>
            <ReturnPolicyEmbed
                returnPolicy={returnPolicy || null}
                showReturnPolicyName={false}
                hideDetails
                bgTransparent
            />
        </div>
    );
};

export default ItemReturnPolicy;
