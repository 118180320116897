/**
 * @generated SignedSource<<1c59632fdce702ef278d15448761b1aa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GetHelpZendeskContactSupport_viewer$data = {
  readonly contact1stdibsConfig: {
    readonly isAgentAvailable: boolean | null;
    readonly showChannelChat: boolean | null;
  } | null;
  readonly seller?: {
    readonly sellerProfile: {
      readonly email: string | null;
      readonly firstName: string | null;
      readonly lastName: string | null;
    } | null;
  } | null;
  readonly user?: {
    readonly profile: {
      readonly email: string | null;
      readonly firstName: string | null;
      readonly lastName: string | null;
    } | null;
  } | null;
  readonly zendeskArticles: {
    readonly topicKeyForArticle: string | null;
  } | null;
  readonly " $fragmentType": "GetHelpZendeskContactSupport_viewer";
};
export type GetHelpZendeskContactSupport_viewer$key = {
  readonly " $data"?: GetHelpZendeskContactSupport_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"GetHelpZendeskContactSupport_viewer">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "email",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "firstName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "lastName",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "articleId"
    },
    {
      "kind": "RootArgument",
      "name": "hasSellerId"
    },
    {
      "kind": "RootArgument",
      "name": "hasUserId"
    },
    {
      "kind": "RootArgument",
      "name": "isSeller"
    },
    {
      "kind": "RootArgument",
      "name": "sellerId"
    },
    {
      "kind": "RootArgument",
      "name": "userId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "GetHelpZendeskContactSupport_viewer",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "isSeller",
          "variableName": "isSeller"
        }
      ],
      "concreteType": "Contact1stdibsConfig",
      "kind": "LinkedField",
      "name": "contact1stdibsConfig",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isAgentAvailable",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "showChannelChat",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "condition": "hasUserId",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "userId",
              "variableName": "userId"
            }
          ],
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Profile",
              "kind": "LinkedField",
              "name": "profile",
              "plural": false,
              "selections": (v0/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ]
    },
    {
      "condition": "hasSellerId",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "sellerId",
              "variableName": "sellerId"
            }
          ],
          "concreteType": "Seller",
          "kind": "LinkedField",
          "name": "seller",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "SellerProfile",
              "kind": "LinkedField",
              "name": "sellerProfile",
              "plural": false,
              "selections": (v0/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ]
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Contact1stdibsZendeskArticles",
      "kind": "LinkedField",
      "name": "zendeskArticles",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "articleId",
              "variableName": "articleId"
            }
          ],
          "kind": "ScalarField",
          "name": "topicKeyForArticle",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
})();

(node as any).hash = "31951eac476e83fb919192b68bb5c0ae";

export default node;
