import { FC, useContext } from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'dibs-react-intl';

import { Link } from 'dibs-elements/exports/Link';
import ArrowLeft from 'dibs-icons/exports/legacy/ArrowLeft';

import GetHelpContext from './GetHelpContext';
import { ACTION_GO_BACK } from './constants';
import { trackGoBackNav } from './tracking';

import styles from './styles/GetHelpGoBack.scss';

const GoBack: FC = () => {
    const {
        dispatch,
        state: { step },
    } = useContext(GetHelpContext);

    return (
        <Link
            className={classnames(styles.backButtonContainer, styles.container)}
            onClick={() => {
                trackGoBackNav(step);
                dispatch({ type: ACTION_GO_BACK });
            }}
            dataTn="get-help-go-back"
        >
            <ArrowLeft className={styles.backArrow} />
            <FormattedMessage id="getHelp.GoBack.title" defaultMessage="Go Back" />
        </Link>
    );
};

export default GoBack;
