import { FC, useContext, useEffect, useState } from 'react';
import { FormattedMessage, defineMessages, useIntl, IntlShape } from 'dibs-react-intl';
import { useFragment, graphql } from 'react-relay';
import classnames from 'classnames';

import PersonBubble from 'dibs-icons/exports/legacy/PersonBubble';
import Message from 'dibs-icons/exports/legacy/Message';
import WhatsApp from 'dibs-icons/exports/legacy/WhatsApp';
import InfoCircle from 'dibs-icons/exports/legacy/InfoCircle';
import { Tooltip } from 'dibs-elements/exports/Tooltip';
import SupportChannelLink from './SupportChannelLink';
import SendMessageButton from './SendMessageButton';

import GetHelpContext from './GetHelpContext';
import {
    CHANNEL_LIVE_CHAT,
    CHANNEL_WHATSAPP,
    CHANNEL_MESSAGE,
    RENDER_AS_LOGGED_OUT,
    HELP_TYPE_LISTINGS,
    HELP_TYPE_ORDER_ISSUES,
} from './constants';
import { HelpType } from './types';
import { startChat } from './liveChat/startLiveChat';
import { adjustPdpSellerChatPosition } from './liveChat/helpers/adjustPdpSellerChatPosition';
import { DEFAULT_CHAT_LOAD_DELAY_MS } from './liveChat/constants';
import { trackChatInitiated, trackWhatsAppClick, trackSMSClick } from './tracking';

import { OtherHelp_viewer$key } from './__generated__/OtherHelp_viewer.graphql';

import dibsCss from 'dibs-css';
import styles from './styles/OtherHelp.scss';

const DIBS_SUPPORT_PHONE_NUMBER = '18777213427';

const messages = defineMessages({
    liveChatTitle: {
        id: 'getHelp.OtherHelp.liveChatTitle',
        defaultMessage: 'Live Chat',
    },
    virtualAssistantTitle: {
        id: 'getHelp.OtherHelp.virtualAssistantTitle',
        defaultMessage: 'Virtual Assistant',
    },
    sendMessageAvailability: {
        id: 'getHelp.OtherHelp.sendMessageAvailability',
        defaultMessage: 'Available 24/7',
    },
    liveChatAvailabilityOnline: {
        id: 'getHelp.OtherHelp.liveChatAvailabilityOnline',
        defaultMessage: 'Online',
    },
    whatsApp: {
        id: 'getHelp.OtherHelp.whatsApp',
        defaultMessage: 'WhatsApp',
    },
    textMessage: {
        id: 'getHelp.OtherHelp.textMessage',
        defaultMessage: 'Text Message',
    },
    waitUntilOnline: {
        id: 'getHelp.OtherHelp.waitUntilOnline',
        defaultMessage: 'Back online in {hours, plural, one {# hour} other {# hours}}',
    },
    waitTime: {
        id: 'getHelp.OtherHelp.waitTime',
        defaultMessage: 'Wait time {hours, plural, one {# hour} other {# hours}}',
    },
    defaultMesssageText: {
        id: 'getHelp.OtherHelp.defaultMesssageText',
        defaultMessage: 'Hello, I need help with Order {orderId}',
    },
    defaultMesssageTextForItem: {
        id: 'getHelp.OtherHelp.defaultMesssageTextForItem',
        defaultMessage: 'Hello, I need help with Item {itemId}',
    },
});

export const getAvailability = ({
    isAgentAvailable,
    isChat = false,
    isSeller = false,
    intl,
}: {
    isAgentAvailable: boolean;
    isChat?: boolean;
    isSeller?: boolean;
    intl: IntlShape;
}): string | null => {
    // everything is counted in UTC format because Support Reps are working in GMT time. (Monday - Friday, 8:00 - 22:00 GMT)

    const WORK_DAY_START_HOURS = 8;
    const WORK_DAY_END_HOURS = 22;
    const WORK_WEEK_START_DAY = 1;
    const WORK_WEEK_END_DAY = 5;
    const DEFAULT_WAITING_HOURS = '2';

    const day = new Date().getUTCDay();
    const hours = new Date().getUTCHours();

    const isWorkWeek = day >= WORK_WEEK_START_DAY && day <= WORK_WEEK_END_DAY;
    const isWorkingHours = hours >= WORK_DAY_START_HOURS && hours <= WORK_DAY_END_HOURS;
    const isLastWorkDay = day === WORK_WEEK_END_DAY;

    if (isAgentAvailable || isSeller) {
        return intl.formatMessage(messages.liveChatAvailabilityOnline);
    }

    if (isWorkWeek && !isWorkingHours) {
        if (isLastWorkDay && hours > WORK_DAY_END_HOURS) {
            return null;
        }

        const now = new Date();

        const startOfWorkingDay = Date.UTC(
            now.getUTCFullYear(),
            now.getUTCMonth(),
            now.getUTCDate() + (hours >= WORK_DAY_END_HOURS ? 1 : 0),
            WORK_DAY_START_HOURS,
            0,
            0
        );

        // to get time difference in hours milliseconds needs to be converted in hours by dividing it by 60*60*1000 = 3600000
        const difference = Math.ceil(
            Math.abs(new Date(startOfWorkingDay).getTime() - new Date().getTime()) / 3600000
        ).toString();

        if (isChat) {
            return intl.formatMessage(messages.waitUntilOnline, { hours: difference });
        } else {
            return intl.formatMessage(messages.waitTime, { hours: difference });
        }
    } else if (isWorkWeek && isWorkingHours) {
        if (isChat) {
            return intl.formatMessage(messages.waitUntilOnline, { hours: DEFAULT_WAITING_HOURS });
        } else {
            return intl.formatMessage(messages.waitTime, { hours: DEFAULT_WAITING_HOURS });
        }
    }

    return null;
};

const getDefaultMessageText = ({
    orderId,
    itemId,
    helpType,
    intl,
}: {
    orderId?: string;
    itemId?: string;
    helpType?: HelpType | null;
    intl: IntlShape;
}): string => {
    let message = '';

    if (helpType === HELP_TYPE_LISTINGS && !!itemId) {
        message = intl.formatMessage(messages.defaultMesssageTextForItem, { itemId });
    }

    if (helpType === HELP_TYPE_ORDER_ISSUES && !!orderId) {
        message = intl.formatMessage(messages.defaultMesssageText, { orderId });
    }

    return encodeURI(message);
};

const TextMessageInfo = (
    <FormattedMessage
        id="getHelp.OtherHelp.tooltip"
        defaultMessage="Message and data rates may apply."
    />
);

const OtherHelp: FC<{
    viewer: OtherHelp_viewer$key;
    scrollToOtherHelp?: boolean;
    setScrollToOtherHelp?: (scroll: boolean) => void;
}> = ({ viewer: viewerRef, scrollToOtherHelp, setScrollToOtherHelp }) => {
    const intl = useIntl();
    const [isTooltipVisible, setIsTooltipVisible] = useState(false);

    const viewer = useFragment(
        graphql`
            fragment OtherHelp_viewer on Viewer {
                contact1stdibsConfig(isSeller: false) {
                    isAgentAvailable
                    showChannelChat
                }
                user(userId: $userId) @include(if: $hasUserId) {
                    profile {
                        email
                        firstName
                        lastName
                    }
                }
                seller(sellerId: $sellerId) @include(if: $hasSellerId) {
                    sellerProfile {
                        email
                        firstName
                        lastName
                    }
                }
                getHelpTopic(topicKey: $topicKey, orderId: $orderId, isSeller: $isSeller) {
                    showChannelLiveChat
                    showChannelWhatsApp
                    showChannelMessage
                    showChannelEmail
                    isOtherIssue
                }
            }
        `,
        viewerRef
    );

    const {
        state: {
            showAuthModal,
            renderAs,
            topicValue,
            userId,
            orderId,
            itemPk,
            isEmbedded,
            onClose,
            helpType,
            sellerId,
            isSeller,
            onChatStart,
        },
    } = useContext(GetHelpContext);

    useEffect(() => {
        if (scrollToOtherHelp) {
            const modalElement = document.querySelectorAll("[data-tn='get-help-modal']")[0];

            if (modalElement) {
                modalElement.scrollTop = modalElement.scrollHeight;
            }

            if (setScrollToOtherHelp) {
                setScrollToOtherHelp(false);
            }
        }
    }, [scrollToOtherHelp, setScrollToOtherHelp]);

    const { contact1stdibsConfig, user, seller, getHelpTopic } = viewer;
    const profile = isSeller ? seller?.sellerProfile : user?.profile;
    const { firstName = '', lastName = '', email = '' } = profile || {};
    const { isAgentAvailable: configIsAgentAvailable, showChannelChat } =
        contact1stdibsConfig || {};
    const {
        showChannelLiveChat,
        showChannelMessage,
        showChannelWhatsApp,
        showChannelEmail,
        isOtherIssue,
    } = getHelpTopic || {};
    const isAgentAvailable = configIsAgentAvailable || false;
    const [isEmbeddedSvcLoading, setIsEmbeddedSvcLoading] = useState(false);
    const chatShouldBeInitialized = showChannelChat && isAgentAvailable && showChannelLiveChat;

    // don't render component if all channels are disabled
    if (!(showChannelLiveChat || showChannelMessage || showChannelWhatsApp || showChannelEmail)) {
        return null;
    }

    if (chatShouldBeInitialized) {
        if (
            typeof window.embedded_svc?.liveAgentAPI?.startChat !== 'function' &&
            !isEmbeddedSvcLoading
        ) {
            setIsEmbeddedSvcLoading(true);
            const interval = setInterval(() => {
                if (window.embedded_svc && window.embedded_svc.liveAgentAPI) {
                    setIsEmbeddedSvcLoading(false);
                    clearInterval(interval);
                }
            }, DEFAULT_CHAT_LOAD_DELAY_MS);
        }
    }

    const availabilityCopy = getAvailability({ isAgentAvailable, intl });

    return (
        <div className={classnames(styles.container, dibsCss.pLarge, dibsCss.pbLarger)}>
            {!isOtherIssue && (
                <div className={styles.title}>
                    <FormattedMessage
                        id="getHelp.OtherHelp.title"
                        defaultMessage="Still need help?"
                    />
                </div>
            )}
            {availabilityCopy && (
                <div className={styles.supportLinks}>
                    {showChannelLiveChat && (
                        <SupportChannelLink
                            title={
                                isSeller && !isAgentAvailable
                                    ? intl.formatMessage(messages.virtualAssistantTitle)
                                    : intl.formatMessage(messages.liveChatTitle)
                            }
                            Icon={PersonBubble}
                            onClick={() => {
                                if (renderAs === RENDER_AS_LOGGED_OUT) {
                                    if (showAuthModal) {
                                        showAuthModal({
                                            topicValue: topicValue || '',
                                            channel: CHANNEL_LIVE_CHAT,
                                            isChatFlow: true,
                                            helpType,
                                        });
                                    }
                                } else {
                                    if (typeof onChatStart === 'function') {
                                        onChatStart();
                                    }
                                    if (!isSeller) {
                                        adjustPdpSellerChatPosition({});
                                    }
                                    startChat({
                                        userId,
                                        orderId: orderId || '',
                                        firstName: firstName || '',
                                        lastName: lastName || '',
                                        email: email || '',
                                        selectedTopic: topicValue || '',
                                        itemPk: itemPk || '',
                                        sellerId,
                                    });
                                    trackChatInitiated(topicValue || '');
                                    if (!isEmbedded) {
                                        onClose();
                                    }
                                }
                            }}
                            availability={
                                getAvailability({
                                    isAgentAvailable,
                                    isChat: true,
                                    isSeller,
                                    intl,
                                }) || ''
                            }
                            showOnline={isSeller || isAgentAvailable}
                            disabled={(!isSeller && !isAgentAvailable) || isEmbeddedSvcLoading}
                            channel={CHANNEL_LIVE_CHAT}
                        />
                    )}
                    {showChannelMessage && (
                        <SupportChannelLink
                            title={
                                <div className={styles.linkTitle}>
                                    {intl.formatMessage(messages.textMessage)}
                                    <span className={styles.asterisk}>*</span>
                                    <span
                                        onMouseEnter={() => setIsTooltipVisible(true)}
                                        onMouseLeave={() => setIsTooltipVisible(false)}
                                        className={styles.tooltipWrapper}
                                    >
                                        <InfoCircle className={styles.tooltipIcon} />
                                        <Tooltip
                                            isVisible={isTooltipVisible}
                                            align="center"
                                            direction="top"
                                            onClose={() => setIsTooltipVisible(false)}
                                        >
                                            {TextMessageInfo}
                                        </Tooltip>
                                    </span>
                                </div>
                            }
                            Icon={Message}
                            channel={CHANNEL_MESSAGE}
                            availability={availabilityCopy}
                            showOnline={isAgentAvailable}
                            href={`sms:+${DIBS_SUPPORT_PHONE_NUMBER}?&body=${getDefaultMessageText({
                                orderId,
                                itemId: itemPk,
                                helpType,
                                intl,
                            })}`}
                            onClick={() => trackSMSClick(topicValue || '')}
                        />
                    )}
                    {showChannelWhatsApp && (
                        <SupportChannelLink
                            title={intl.formatMessage(messages.whatsApp)}
                            Icon={WhatsApp}
                            channel={CHANNEL_WHATSAPP}
                            availability={availabilityCopy}
                            showOnline={isAgentAvailable}
                            href={`https://wa.me/${DIBS_SUPPORT_PHONE_NUMBER}?text=${getDefaultMessageText(
                                { orderId, itemId: itemPk, helpType, intl }
                            )}`}
                            onClick={() => trackWhatsAppClick(topicValue || '')}
                        />
                    )}
                </div>
            )}
            {showChannelEmail && <SendMessageButton />}
            {showChannelMessage && <div className={styles.textMessageInfo}>*{TextMessageInfo}</div>}
        </div>
    );
};

export default OtherHelp;
