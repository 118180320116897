import { FC, Suspense } from 'react';
import classnames from 'classnames';

import { ErrorBoundary } from 'dibs-error-tools/ErrorBoundary';
import { Spinner } from 'dibs-elements/exports/Spinner';

import GetHelpRenderer from './GetHelpRenderer';
import Error from './Error';

import dibsCss from 'dibs-css';

const GetHelpEmbedded: FC = () => {
    return (
        // note: this data-tn is also used in packages/dibs-contact-1stdibs/src/OtherHelp.tsx to modify scroll position
        <Suspense
            fallback={
                <div className={classnames(dibsCss.flex, dibsCss.justifyCenter)}>
                    <Spinner />
                </div>
            }
        >
            <div data-tn="get-help-modal">
                <ErrorBoundary fallback={() => <Error />}>
                    <GetHelpRenderer />
                </ErrorBoundary>
            </div>
        </Suspense>
    );
};

export default GetHelpEmbedded;
