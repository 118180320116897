import { FC, useContext } from 'react';
import { useFragment, graphql } from 'react-relay';
import { FormattedMessage, useIntl } from 'dibs-react-intl';

import TransactionTimeline from 'dibs-transaction-timeline/exports/TransactionTimeline';
import { Link } from 'dibs-elements/exports/Link';

import { isBomtTimelineOptimizationVariant } from 'dibs-transaction-timeline/exports/abTestHelpers';
import { getOrderStatusLabel } from './getOrderStatusLabel';
import GetHelpContext from './GetHelpContext';
import { STEP_TOPIC } from './constants';

import { OrderInfo_viewer$key } from './__generated__/OrderInfo_viewer.graphql';

import styles from './styles/OrderInfo.scss';

const OrderInfo: FC<{ viewer: OrderInfo_viewer$key }> = ({ viewer: viewerRef }) => {
    const intl = useIntl();

    const {
        state: { step, isSeller },
    } = useContext(GetHelpContext);

    const viewer = useFragment(
        graphql`
            fragment OrderInfo_viewer on Viewer {
                transaction(transactionId: $transactionId) @include(if: $hasOrderId) {
                    serviceId
                    item {
                        firstPhotoWebPath(size: thumb)
                        title
                    }
                    ...TransactionTimeline_transaction
                    ...getOrderStatusLabel_transaction
                }
                getHelpTopic(topicKey: $topicKey, orderId: $orderId, isSeller: $isSeller) {
                    showOrderTimeline
                }
            }
        `,
        viewerRef
    );

    const { transaction = null, getHelpTopic } = viewer;
    const { item, serviceId: transactionId } = transaction || {};
    const { title, firstPhotoWebPath } = item || {};

    const statusLabel = getOrderStatusLabel({ transaction, isSeller, intl });
    const showTimeline =
        step === STEP_TOPIC &&
        getHelpTopic?.showOrderTimeline &&
        !isBomtTimelineOptimizationVariant();

    return (
        <div className={styles.container}>
            <div className={styles.orderInfo}>
                <div className={styles.imageWrapper}>
                    <img
                        alt={title || ''}
                        src={firstPhotoWebPath || ''}
                        className={styles.image}
                        data-tn="get-help-order-info-item-image"
                    />
                </div>
                <div className={styles.title} data-tn="get-help-order-info-item-title">
                    {title}
                </div>
                <div className={styles.infoText}>
                    {isSeller && (
                        <>
                            <FormattedMessage
                                id="getHelp.OrderInfo.orderNumber"
                                defaultMessage="Order {transactionId}"
                                values={{ transactionId }}
                            />
                            <span className={styles.dotDivider}>.</span>
                        </>
                    )}
                    {statusLabel && (
                        <span
                            className={styles.currentStatus}
                            data-tn="get-help-order-info-order-status"
                        >
                            {statusLabel}
                        </span>
                    )}{' '}
                    {!isSeller && (
                        <Link
                            dataTn="get-help-modal-view-order-details-link"
                            href={`/orders/order/${transactionId}/`}
                        >
                            <FormattedMessage
                                id="getHelp.OrderInfo.viewOrder"
                                defaultMessage="View order details"
                            />
                        </Link>
                    )}
                </div>
            </div>
            {showTimeline && (
                <div
                    className={styles.timelinewrapper}
                    data-tn="get-help-modal-order-info-timeline"
                >
                    <TransactionTimeline
                        transaction={transaction}
                        type={isSeller ? 'seller' : 'buyer'}
                    />
                </div>
            )}
        </div>
    );
};

export default OrderInfo;
