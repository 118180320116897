// buyer-initiated message - needs captcha
import { FC, ReactNode, useContext, useState } from 'react';
import { useMutation, graphql, useFragment } from 'react-relay';

import ContactFormContent from './ContactFormContent';

import GetHelpContext from './GetHelpContext';

import { ContactSellerFormMutation } from './__generated__/ContactSellerFormMutation.graphql';
import { ContactSellerForm_transaction$key } from './__generated__/ContactSellerForm_transaction.graphql';

import { FormattedMessage, useIntl } from 'dibs-react-intl';
import { recaptchaV3 } from 'dibs-recaptcha/exports/recaptcha';
import RecaptchaForm, {
    MFAErrorCodes,
    isValidErrorCode,
} from 'dibs-recaptcha/exports/RecaptchaForm';
import {
    getSendMessageErrorMessage,
    isSendMessageError,
} from 'dibs-error-tools/exports/ErrorMessages/getSendMessageErrorMessage';

const ContactSellerForm: FC<{
    transaction: ContactSellerForm_transaction$key | null | undefined;
}> = ({ transaction: transactionRef }) => {
    const intl = useIntl();
    const {
        state: { userId },
    } = useContext(GetHelpContext);
    const [messageContent, setMessageContent] = useState('');
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState<ReactNode>(null);
    const [conversationId, setConversationId] = useState('');
    const [mfaErrorCode, setMFAErrorCode] = useState<MFAErrorCodes | null>(null);

    const [contactDealer, isLoading] = useMutation<ContactSellerFormMutation>(
        graphql`
            mutation ContactSellerFormMutation($input: ContactDealerInput!) {
                contactDealer(input: $input) {
                    conversation {
                        serviceId
                    }
                }
            }
        `
    );

    const transaction = useFragment(
        graphql`
            fragment ContactSellerForm_transaction on Transaction {
                buyer {
                    profile {
                        displayName
                        email
                    }
                }
                item {
                    serviceId
                    seller {
                        serviceId
                    }
                }
            }
        `,
        transactionRef
    );

    const { item, buyer } = transaction || {};
    const { serviceId: itemId, seller } = item || {};
    const sellerId = seller?.serviceId || '';
    const displayName = buyer?.profile?.displayName;

    const sendMessage = async (mfaVerificationCode?: string): Promise<void> => {
        setErrorMessage(null);
        const captchaResponse = await recaptchaV3({
            action: 'contactDealer',
            userName: displayName,
            mfaVerificationCode,
            supportedMFATypes: ['CODE'],
        });
        await new Promise<void>(resolve =>
            contactDealer({
                variables: {
                    input: {
                        buyerId: userId,
                        messageContent,
                        sellerPk: sellerId,
                        displayName,
                        itemPk: itemId,
                        mfaVerificationCode,
                        ...captchaResponse,
                    },
                },
                onCompleted(response) {
                    setShowSuccessMessage(true);
                    setConversationId(response.contactDealer?.conversation?.serviceId || '');
                    setMFAErrorCode(null);
                    resolve();
                },
                onError(error) {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    const sourceError = (error as any)?.source?.errors?.[0]?.message || '';
                    if (isValidErrorCode(sourceError)) {
                        setMFAErrorCode(sourceError);
                    } else {
                        const sendMessageErrorMessage = isSendMessageError(sourceError) ? (
                            getSendMessageErrorMessage({ code: sourceError, intl })
                        ) : (
                            <FormattedMessage
                                id="getHelp.ContactSellerForm.error"
                                defaultMessage="Something went wrong."
                            />
                        );
                        setErrorMessage(sendMessageErrorMessage);
                    }
                    resolve();
                },
            })
        );
    };

    return mfaErrorCode ? (
        <RecaptchaForm
            onSubmit={mfaVerificationCode => sendMessage(mfaVerificationCode)}
            errorCode={mfaErrorCode}
            email={buyer?.profile?.email || ''}
            mfaActionType="displayed"
        />
    ) : (
        <ContactFormContent
            showSuccessMessage={showSuccessMessage}
            sendMessage={sendMessage}
            conversationId={conversationId}
            setMessageContent={setMessageContent}
            messageContent={messageContent}
            isLoading={isLoading}
            errorMessage={errorMessage}
        />
    );
};

export default ContactSellerForm;
