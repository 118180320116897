/**
 * @generated SignedSource<<f850109851b70abbe1d987de35e9a376>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type PaymentMethodType = "ACH" | "AFFIRM_CREDIT_CARD" | "APPLE_PAY" | "APPLIED_SALE" | "CASH" | "CHECK" | "CREDIT_CARD" | "CREDIT_TO_USER_ACCOUNT" | "DIGITAL_WALLET" | "DIRECT_PAYMENT" | "DISCOUNT" | "HYPERWALLET" | "KLARNA" | "ON_PLATFORM_WIRE_TRANSFER" | "OTHER" | "PAYPAL_ACCOUNT" | "PAY_LATER" | "SEPA" | "UNKNOWN" | "WIRE_TRANSFER" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type getLastBuyerEvent_transaction$data = {
  readonly summary: {
    readonly balanceDueAmount: {
      readonly amount: number | null;
    } | null;
    readonly checkoutPaymentAuthorization: {
      readonly paymentMethod: {
        readonly paymentMethodType: PaymentMethodType | null;
      } | null;
    } | null;
  } | null;
  readonly transactionHistory: {
    readonly historicalEvents: ReadonlyArray<{
      readonly buyerCopy: string | null;
      readonly name: string | null;
    } | null> | null;
  } | null;
  readonly " $fragmentType": "getLastBuyerEvent_transaction";
};
export type getLastBuyerEvent_transaction$key = {
  readonly " $data"?: getLastBuyerEvent_transaction$data;
  readonly " $fragmentSpreads": FragmentRefs<"getLastBuyerEvent_transaction">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "getLastBuyerEvent_transaction"
};

(node as any).hash = "9884c46ceca4687dbe167fa762922cc5";

export default node;
