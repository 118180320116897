import dibsCss from 'dibs-css';
import classnames from 'classnames';
import { FunctionComponent } from 'react';

type CircleCheckProps = {
    className?: string;
};

const CircleCheck: FunctionComponent<CircleCheckProps> = props => {
    return (
        <svg
            className={classnames(dibsCss.dibsIcon, props.className)}
            data-tn={'circle-check'}
            viewBox="0 0 250 250"
        >
            <path d="M125.3-.8C56.3-.8.2 55.3.2 124.3s56.2 125.2 125.2 125.2 125.2-56.2 125.2-125.2S194.4-.8 125.3-.8zm0 241.3c-64.1 0-116.2-52.1-116.2-116.2S61.3 8.2 125.3 8.2s116.2 52.1 116.2 116.2-52.1 116.1-116.2 116.1z" />
            <polygon points="106.2,145.1 74.6,113.8 62,126.6 105.8,169.9 195.1,88.1 182.9,74.9" />
        </svg>
    );
};
export default CircleCheck;
