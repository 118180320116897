import { FC, useContext } from 'react';
import classnames from 'classnames';

import { FormattedMessage } from 'dibs-react-intl';
import InfoCircle from 'dibs-icons/exports/InfoCircle';
import { ModalCloseButton } from 'dibs-elements/exports/ModalCloseButton';

import { GetHelpZendeskContext } from './GetHelpZendeskContext';

import dibsCss from 'dibs-css';
import styles from './GetHelpZendeskError.scss';

const GetHelpZendeskError: FC<{ renderOutsideContent?: boolean }> = ({ renderOutsideContent }) => {
    const {
        state: { onClose },
    } = useContext(GetHelpZendeskContext);

    return (
        <div className={classnames({ [dibsCss.pxMedium]: renderOutsideContent })}>
            {renderOutsideContent && (
                <ModalCloseButton onClick={() => onClose()} dataTn="get-help-modal-close-button" />
            )}
            <div
                className={classnames(
                    dibsCss.flex,
                    dibsCss.justifyCenter,
                    dibsCss.itemsCenter,
                    dibsCss.minH300Px,
                    { [styles.container]: !renderOutsideContent }
                )}
            >
                <div
                    className={classnames(
                        dibsCss.flex,
                        dibsCss.flexCol,
                        dibsCss.itemsCenter,
                        dibsCss.textCenter
                    )}
                >
                    <div className={dibsCss.fillDolphin}>
                        <InfoCircle variation="40Filled" />
                    </div>
                    <h2 className={dibsCss.sassyFontBodyMediumHeavy}>
                        <FormattedMessage
                            id="getHelp.GetHelpZendeskError.title"
                            defaultMessage="Sorry, we're currently experiencing technical difficulties."
                        />
                    </h2>
                    <div className={dibsCss.sassyFontBodyMedium}>
                        <FormattedMessage
                            id="getHelp.GetHelpZendeskError.info"
                            defaultMessage="We know your time is valuable. Give us a few seconds and try loading this page again."
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export { GetHelpZendeskError };
