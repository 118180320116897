import { getAbTestV2, trackAbTestV2Variant } from 'dibs-ab-tests/exports/clientAbTestV2';

const BOMT_TIMELINE_OPTIMIZATION = 'bomt-timeline-optimization' as const;

export const isBomtTimelineOptimizationVariant = (): boolean =>
    getAbTestV2(BOMT_TIMELINE_OPTIMIZATION)?.variant === 'variant';

let isTracked = false;

export const trackBomtTimelineOptimizationTest = (): void => {
    if (!isTracked) {
        isTracked = true;
        trackAbTestV2Variant(BOMT_TIMELINE_OPTIMIZATION);
    }
};
