import type { FC, ComponentProps } from 'react';
import { graphql, useFragment } from 'react-relay';

import { FormattedMessage } from 'dibs-react-intl';
import { Timeline, Step } from 'dibs-elements/exports/Timeline';

import type { TransactionTimeline_transaction$key } from './__generated__/TransactionTimeline_transaction.graphql';

const transactionFragment = graphql`
    fragment TransactionTimeline_transaction on Transaction {
        isCanceled
        buyerTimelineSteps {
            isCompleted
            label
        }
        sellerTimelineSteps {
            isCompleted
            label
        }
    }
`;

type Props = {
    transaction: TransactionTimeline_transaction$key | null | undefined;
    type: 'buyer' | 'seller';
} & Omit<ComponentProps<typeof Timeline>, 'steps'>;

const TransactionTimeline: FC<Props> = ({ transaction: transactionRef, type, ...rest }) => {
    const transaction = useFragment(transactionFragment, transactionRef);
    const { buyerTimelineSteps, sellerTimelineSteps, isCanceled } = transaction || {};

    const timelineSteps = type === 'seller' ? sellerTimelineSteps : buyerTimelineSteps;

    const steps = (timelineSteps || []).reduce<Step[]>((acc, val, index) => {
        const { label, isCompleted } = val || {};
        let timelineStep: Step;

        /**
         * For cancelled transactions first timeline step is shown
         * as canceled and all other steps as incomplete
         */
        if (isCanceled) {
            const isFirstStep = index === 0;
            timelineStep = {
                label: isFirstStep ? (
                    <FormattedMessage id="transactionTimeline.canceled" defaultMessage="Canceled" />
                ) : (
                    label
                ),
                isCompleted: isFirstStep ? true : false,
                bubbleType: isFirstStep ? 'suspended' : undefined,
            };
        } else {
            timelineStep = { label, isCompleted: !!isCompleted };
        }

        /**
         * Only include steps with label
         */
        if (timelineStep?.label) {
            acc.push(timelineStep);
        }

        return acc;
    }, []);

    if (!steps.length) {
        return null;
    }

    return <Timeline steps={steps} {...rest} />;
};

export default TransactionTimeline;
