import { FC, ReactNode } from 'react';

import GoBack from './GoBack';

import styles from './styles/GetHelpHeader.scss';

type Props = {
    title?: ReactNode;
    showBackButton?: boolean;
};

const GetHelpHeader: FC<Props> = ({ title, showBackButton = true }) => {
    return (
        <div>
            {showBackButton && <GoBack />}
            {title && <div className={styles.title}>{title}</div>}
        </div>
    );
};
export default GetHelpHeader;
