import { FC, Suspense, useContext } from 'react';
import classnames from 'classnames';
import { graphql, useFragment } from 'react-relay';

import { FormattedMessage } from 'dibs-react-intl';
import { GetHelpZendeskArticlesSearch } from './GetHelpZendeskArticlesSearch';
import { GetHelpZendeskPromotedArticles } from './GetHelpZendeskPromotedArticles';
import { GetHelpZendeskLatestOrder } from './GetHelpZendeskLatestOrder';
import { GetHelpZendeskLatestOrderSkeleton } from './GetHelpZendeskLatestOrderSkeleton';

import { GetHelpZendeskContext } from './GetHelpZendeskContext';

import { GetHelpZendeskModalIntro_viewer$key } from './__generated__/GetHelpZendeskModalIntro_viewer.graphql';

import dibsCss from 'dibs-css';

const GetHelpZendeskModalIntro: FC<{ viewer: GetHelpZendeskModalIntro_viewer$key }> = ({
    viewer: viewerRef,
}) => {
    const {
        state: { isCheckout },
    } = useContext(GetHelpZendeskContext);

    const viewer = useFragment(
        graphql`
            fragment GetHelpZendeskModalIntro_viewer on Viewer {
                user(userId: $userId) @include(if: $hasUserId) {
                    profile {
                        firstName
                    }
                }
                ...GetHelpZendeskPromotedArticles_viewer
                ...GetHelpZendeskArticlesSearch_viewer
            }
        `,
        viewerRef
    );

    const firstName = viewer?.user?.profile?.firstName || '';

    return (
        <div className={dibsCss.mbLarge}>
            <div
                className={classnames(
                    dibsCss.sassyFontHeaderMedium,
                    dibsCss.mbMedium,
                    dibsCss.pb0,
                    dibsCss.textNoir,
                    dibsCss.textCenter
                )}
            >
                {firstName ? (
                    <FormattedMessage
                        id="getHelp.IntroStep.introHeaderLoggedIn"
                        defaultMessage="Hi {firstName}, what can we help you with?"
                        values={{
                            firstName: (
                                <span className={dibsCss.sassyFontHeaderMediumItalic}>
                                    {firstName}
                                </span>
                            ),
                        }}
                    />
                ) : (
                    <FormattedMessage
                        id="getHelp.IntroStep.introHeader"
                        defaultMessage="What can we help you with?"
                    />
                )}
            </div>
            <div>
                <div className={dibsCss.mbMedium}>
                    <GetHelpZendeskArticlesSearch viewer={viewer} />
                </div>
                <GetHelpZendeskPromotedArticles viewer={viewer} />
            </div>
            <Suspense fallback={<GetHelpZendeskLatestOrderSkeleton />}>
                {!isCheckout && <GetHelpZendeskLatestOrder />}
            </Suspense>
        </div>
    );
};

export { GetHelpZendeskModalIntro };
