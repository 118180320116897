import { FC } from 'react';
import classnames from 'classnames';

import { GetHelpZendeskContentDivider } from './GetHelpZendeskContentDivider';

import styles from './GetHelpZendeskLatestOrderSkeleton.scss';
import orderTileStyles from './GetHelpZendeskOrderTile.scss';
import dibsCss from 'dibs-css';

const GetHelpZendeskLatestOrderSkeleton: FC = () => {
    return (
        <div className={dibsCss.mtMedium}>
            <GetHelpZendeskContentDivider />
            <div className={dibsCss.mtMedium}>
                <div
                    className={classnames(
                        styles.shimmer,
                        dibsCss.h14Px,
                        dibsCss.w50,
                        dibsCss.mbXsmall
                    )}
                />
                <div
                    className={classnames(
                        dibsCss.border,
                        dibsCss.borderSolid,
                        dibsCss.borderDolphin,
                        dibsCss.roundedXxsmall,
                        dibsCss.pSmall,
                        dibsCss.prMedium,
                        dibsCss.flex,
                        dibsCss.gapSmall
                    )}
                >
                    <div className={classnames(orderTileStyles.imageWrapper, styles.shimmer)} />
                    <div
                        className={classnames(
                            dibsCss.wFull,
                            dibsCss.flex,
                            dibsCss.flexCol,
                            dibsCss.justifyCenter,
                            dibsCss.gapXsmall
                        )}
                    >
                        <div className={classnames(styles.shimmer, dibsCss.h14Px, dibsCss.wFull)} />
                        <div className={classnames(styles.shimmer, dibsCss.h14Px, dibsCss.w60)} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export { GetHelpZendeskLatestOrderSkeleton };
