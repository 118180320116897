import { FC, useContext } from 'react';
import { useFragment, graphql } from 'react-relay';
import { FormattedMessage } from 'dibs-react-intl';

import ArrowRight from 'dibs-icons/exports/legacy/ArrowRight';
import ChairIcon from 'dibs-icons/exports/legacy/ChairOutlined';
import { Link } from 'dibs-elements/exports/Link';

import GetHelpContext from './GetHelpContext';
import { ACTION_SET_STEP, ACTION_SET_ITEM_ID, STEP_SELECTED_LISTING } from './constants';

import { ItemsListItem_item$key } from './__generated__/ItemsListItem_item.graphql';

import styles from './styles/SharedListItem.scss';

type Props = {
    item: ItemsListItem_item$key | null | undefined;
};

const ItemsListItem: FC<Props> = ({ item: transctionRef }) => {
    const {
        dispatch,
        state: { isEmbedded },
    } = useContext(GetHelpContext);

    const item = useFragment(
        graphql`
            fragment ItemsListItem_item on Item {
                serviceId
                title
                firstPhotoWebPath(size: small)
                dealerReference
                dibsReference
                dealerStatusDisplay
                timeSinceRecentPublication
                timeSinceOriginalPosting
            }
        `,
        transctionRef
    );
    const {
        serviceId,
        title,
        firstPhotoWebPath,
        dealerReference,
        dibsReference,
        dealerStatusDisplay,
        timeSinceRecentPublication,
        timeSinceOriginalPosting,
    } = item || {};
    const timeDisplay = timeSinceRecentPublication || timeSinceOriginalPosting;

    return (
        <Link
            dataTn="get-help-modal-orders-list-order"
            className={styles.container}
            onClick={() => {
                if (isEmbedded) {
                    window.scrollTo(0, 0);
                }
                dispatch({ type: ACTION_SET_ITEM_ID, itemId: serviceId || '' });
                dispatch({ type: ACTION_SET_STEP, step: STEP_SELECTED_LISTING });
            }}
            underline="none"
        >
            <div className={styles.left}>
                <div className={styles.imageWrapper}>
                    {firstPhotoWebPath ? (
                        <img src={firstPhotoWebPath} alt={title || ''} className={styles.image} />
                    ) : (
                        <ChairIcon className={styles.chairIcon} />
                    )}
                </div>
                <div className={styles.itemInfo}>
                    <div className={styles.title}>{title}</div>
                    <div className={styles.details}>
                        <div className={styles.orderNumber}>
                            {dealerReference ? (
                                <FormattedMessage
                                    id="getHelp.ItemsListItem.sellerRef"
                                    defaultMessage="Seller Ref {sellerRef}"
                                    values={{ sellerRef: dealerReference }}
                                />
                            ) : (
                                <FormattedMessage
                                    id="getHelp.ItemsListItem.itemRef"
                                    defaultMessage="Item Ref {itemRef}"
                                    values={{ itemRef: dibsReference }}
                                />
                            )}
                        </div>
                        <div className={styles.orderStatus}>
                            {dealerStatusDisplay}
                            {timeDisplay && <span className={styles.dotDivider}>.</span>}
                        </div>
                        {timeDisplay && (
                            <div className={styles.checkoutDate}>
                                <FormattedMessage
                                    id="getHelp.ItemsListItem.checkoutDate"
                                    defaultMessage="Last published {timeDisplay} ago"
                                    values={{
                                        timeDisplay,
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <ArrowRight className={styles.arrow} />
        </Link>
    );
};

export default ItemsListItem;
