import dibsCss from 'dibs-css';
import classnames from 'classnames';
import { FC } from 'react';

type WhatsAppProps = {
    className?: string;
    role?: 'img';
    ariaLabel?: string;
    ariaLabelledby?: string;
};

const WhatsApp: FC<WhatsAppProps> = props => {
    return (
        <svg
            className={classnames(dibsCss.dibsIcon, props.className)}
            data-tn={'whats-app'}
            role={props.role}
            aria-label={props.ariaLabel}
            aria-labelledby={props.ariaLabelledby}
            viewBox="0 0 250 250"
            fill="none"
        >
            <path
                d="M182.273 149.814c-3.11-1.549-18.402-9.036-21.253-10.071-2.85-1.033-4.924-1.548-6.997 1.55-2.074 3.098-8.035 10.071-9.849 12.137-1.814 2.065-3.629 2.324-6.739.774-3.11-1.549-13.132-4.817-25.011-15.363-9.246-8.208-15.489-18.344-17.303-21.443s-.194-4.774 1.364-6.317c1.4-1.388 3.11-3.616 4.665-5.424 1.555-1.807 2.074-3.099 3.111-5.163 1.036-2.067.518-3.874-.26-5.423-.777-1.55-6.998-16.786-9.59-22.984-2.524-6.035-5.088-5.218-6.997-5.314-1.813-.09-3.888-.108-5.961-.108-2.074 0-5.444.774-8.295 3.872-2.85 3.1-10.885 10.589-10.885 25.823 0 15.237 11.144 29.955 12.7 32.021 1.555 2.067 21.931 33.332 53.133 46.739 7.42 3.19 13.213 5.094 17.73 6.521 7.451 2.356 14.231 2.023 19.59 1.226 5.976-.888 18.402-7.487 20.994-14.717 2.592-7.232 2.592-13.43 1.814-14.72-.778-1.292-2.851-2.066-5.961-3.616m-56.75 77.116h-.042c-18.565-.007-36.774-4.971-52.659-14.352l-3.778-2.232-39.157 10.223 10.451-37.996-2.459-3.896c-10.357-16.393-15.827-35.34-15.819-54.796.023-56.77 46.436-102.958 103.504-102.958 27.634.01 53.611 10.734 73.145 30.197 19.534 19.462 30.285 45.331 30.274 72.844-.023 56.775-46.435 102.966-103.46 102.966zm88.052-190.6C190.074 12.916 158.821.014 125.522 0 56.912 0 1.071 55.57 1.043 123.873c-.009 21.834 5.723 43.145 16.616 61.933L0 250l65.987-17.227c18.182 9.87 38.652 15.072 59.485 15.078h.052c68.602 0 124.448-55.575 124.476-123.879.013-33.101-12.923-64.225-36.425-87.641"
                fill="#455A64"
            />
        </svg>
    );
};

export default WhatsApp;
