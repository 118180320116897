import { FC, ReactNode } from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'dibs-react-intl';

import { Link } from 'dibs-elements/exports/Link';

import { trackGetHelpZendeskEmbeddedEvent } from './getHelpZendeskTracking';

import dibsCss from 'dibs-css';
import styles from './GetHelpZendeskEmbeddedTopBlock.scss';

const GetHelpZendeskEmbeddedTopBlock: FC<{
    articles: { label: ReactNode; url: string; trackingLabel: string }[];
    viewAllLink: string;
    title: ReactNode;
    imageUrl: string;
    onViewAllClick: () => void;
}> = ({ articles, viewAllLink, title, imageUrl, onViewAllClick }) => {
    return (
        <div
            className={classnames(
                styles.block,
                dibsCss.flex,
                dibsCss.flexCol,
                dibsCss.pSmall,
                dibsCss.gapLarge,
                dibsCss.border,
                dibsCss.borderSolid,
                dibsCss.borderDolphin
            )}
        >
            {/* todo change to real url when images are provided  */}
            <div className={classnames(styles.imageWrapper, dibsCss.hidden)}>
                <img
                    src={imageUrl}
                    alt=""
                    className={classnames(dibsCss.wFull, dibsCss.hFull, dibsCss.objectContain)}
                />
            </div>
            <div>
                <h3
                    className={classnames(
                        dibsCss.sassyFontHeaderMedium,
                        dibsCss.mt0,
                        dibsCss.mbSmall
                    )}
                >
                    {title}
                </h3>
                <ul>
                    {articles.map(article => (
                        <li key={article?.url} className={dibsCss.mbXxsmall}>
                            <Link
                                href={article?.url}
                                underline="none"
                                target="_blank"
                                onClick={() => {
                                    trackGetHelpZendeskEmbeddedEvent({
                                        stepInteractionName: 'contact us page - article selected',
                                        trigger: article?.trackingLabel || '',
                                    });
                                }}
                            >
                                <span className={dibsCss.sassyFontBodySmallHeavy}>
                                    {article?.label}
                                </span>
                            </Link>
                        </li>
                    ))}
                </ul>
                <div className={dibsCss.mtSmall}>
                    <Link href={viewAllLink} target="_blank" onClick={onViewAllClick}>
                        <span className={dibsCss.sassyFontBodyMedium}>
                            <FormattedMessage
                                id="getHelp.GetHelpZendeskEmbeddedTopBlock.viewAll"
                                defaultMessage="View All"
                            />
                        </span>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export { GetHelpZendeskEmbeddedTopBlock };
