import { FC, MouseEventHandler } from 'react';
import { FormattedMessage } from 'dibs-react-intl';
import classnames from 'classnames';
import LanguageToggle from 'dibs-icons/exports/legacy/LanguageToggle';
import { Link } from 'dibs-elements/exports/Link';
import styles from './main.scss';

export type TranslationState = 'SHOW_ORIGINAL' | 'SHOW_TRANSLATION' | 'HIDDEN';

type Props = {
    onClick?: MouseEventHandler;
    currentState: TranslationState;
    className?: string;
};

const SharedViewOriginalLanguage: FC<Props> = props => {
    const { currentState, onClick, className } = props;

    if (currentState !== 'SHOW_ORIGINAL' && currentState !== 'SHOW_TRANSLATION') {
        return null;
    }

    return (
        <>
            <div className={classnames(styles.textContainer, className)}>
                <LanguageToggle className={styles.svgIcon} />
                <Link href="#" onClick={onClick}>
                    <FormattedMessage
                        id="dibs.elements.TranslationToggle.title"
                        defaultMessage="{currentState, select,
                            SHOW_ORIGINAL {View machine translation}
                            SHOW_TRANSLATION {View in original language}
                            other {}
                        }"
                        values={{ currentState }}
                    />
                </Link>
            </div>
        </>
    );
};

export default SharedViewOriginalLanguage;
