import { createContext } from 'react';

import { initialState } from './reducer';
import { DispatchType, State } from './types';

const GetHelpContext = createContext<{
    dispatch: DispatchType;
    state: State;
}>({ dispatch: () => {}, state: initialState });

export default GetHelpContext;
