/**
 * @generated SignedSource<<f64587c46549e49a1de9b0561f8bd041>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type shippingTrackingDetails_viewer$data = {
  readonly leadTimes: ReadonlyArray<{
    readonly endTimeUnit: number | null;
    readonly name: string | null;
    readonly startTimeUnit: number | null;
  } | null> | null;
  readonly " $fragmentType": "shippingTrackingDetails_viewer";
};
export type shippingTrackingDetails_viewer$key = {
  readonly " $data"?: shippingTrackingDetails_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"shippingTrackingDetails_viewer">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "shippingTrackingDetails_viewer"
};

(node as any).hash = "2dc67502b58614f92f88f3549fafbe1f";

export default node;
