import { getAbTestV2, trackAbTestV2Variant } from 'dibs-ab-tests/exports/clientAbTestV2';

const TEST_NAME = 'post-conf-delivery-date-bomt';

export const getPostConfDeliveryTestVariant = (): string | undefined =>
    getAbTestV2(TEST_NAME)?.variant;

export const isPostConfDeliveryTestVariant = (): boolean =>
    getPostConfDeliveryTestVariant() === 'variant';

let isTracked = false;
export const trackPostConfDeliveryAbTest = (): void => {
    const variant = getPostConfDeliveryTestVariant();
    if (!!variant && !isTracked) {
        isTracked = true;
        trackAbTestV2Variant(TEST_NAME);
    }
};
