import dibsCss from 'dibs-css';
import classnames from 'classnames';
import { FunctionComponent } from 'react';

type PlaceholderArtProps = {
    className?: string;
};

const PlaceholderArt: FunctionComponent<PlaceholderArtProps> = props => {
    return (
        <svg
            className={classnames(dibsCss.dibsIcon, props.className)}
            data-tn={'placeholder-art'}
            viewBox="0 0 250 250"
        >
            <path d="M174.8 104.5c17.3 0 31.3-14.1 31.3-31.3S192 41.9 174.8 41.9 143.5 56 143.5 73.2s14 31.3 31.3 31.3zm0-53.7c12.3 0 22.3 10 22.3 22.3s-10 22.3-22.3 22.3-22.3-10-22.3-22.3c0-12.2 10-22.3 22.3-22.3z" />
            <path d="M-.5-.8v250.7h250.4V-.8H-.5zm241.4 9v181.2c-11.8-9.8-24.2-15.3-24.9-15.6-20.2-8.4-36.6 1.3-38.9 2.8l-29.9 15.9c-18.1 9.3-30.2-12.1-30.7-13L98 150.9l-.1-.1c-6.3-9.2-14.5-14.7-24.3-16.1-20.5-3-40.4 12.7-41.3 13.4l-24 20.8V8.2h232.6zM8.5 240.9v-60.2L38.2 155c1.1-.9 18.1-13.9 34.3-11.5 7.3 1.1 13.2 5.1 18.1 12.2l18.2 28.1c4.5 8.1 15.2 19.9 29.4 19.9 4.2 0 8.6-1 13.3-3.4l30.2-16 .4-.3c.1-.1 13.7-9.1 30.5-2.1.2.1 16.4 7.3 28.4 19.5v39.3H8.5v.2z" />
        </svg>
    );
};
export default PlaceholderArt;
