import { trackEvent, eventNameConstants } from 'dibs-tracking';

const { EVENT_CONTACT_1STDIBS } = eventNameConstants;

export const trackGetHelpZendeskEvent = ({
    stepInteractionName,
    trigger,
}: {
    stepInteractionName: string;
    trigger?: string;
}): void => {
    trackEvent({
        eventName: EVENT_CONTACT_1STDIBS,
        interaction_type: 'buyer get help modal',
        step_interaction_name: stepInteractionName,
        trigger,
    });
};

export const trackGetHelpZendeskEmbeddedEvent = ({
    stepInteractionName,
    trigger,
}: {
    stepInteractionName: string;
    trigger?: string;
}): void => {
    trackEvent({
        eventName: EVENT_CONTACT_1STDIBS,
        interaction_type: 'contact us page',
        step_interaction_name: stepInteractionName,
        trigger,
    });
};
