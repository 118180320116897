import { FC, useEffect, ReactNode, useContext } from 'react';
import { useLazyLoadQuery, graphql, useRelayEnvironment } from 'react-relay';
import classnames from 'classnames';

import { GetHelpZendeskModalHeader } from './GetHelpZendeskModalHeader';
import { GetHelpZendeskFooter } from './GetHelpZendeskFooter';
import { GetHelpZendeskModalIntro } from './GetHelpZendeskModalIntro';
import { GetHelpZendeskOrdersList } from './GetHelpZendeskOrdersList';
import { GetHelpZendeskOrderInfo } from './GetHelpZendeskOrderInfo';
import { GetHelpZendeskArticle } from './GetHelpZendeskArticle';
import { GetHelpZendeskContactSupport } from './GetHelpZendeskContactSupport';
import { GetHelpZendeskArticlesSearchResults } from './GetHelpZendeskArticlesSearchResults';
import { GetHelpZendeskArticlesNoSearchResults } from './GetHelpZendeskArticlesNoSearchResults';
import { GetHelpZendeskError } from './GetHelpZendeskError';

import { initChat } from '../liveChat/initSalesforceChat';
import { adjustPdpSellerChatPosition } from '../liveChat/helpers/adjustPdpSellerChatPosition';
import { CHAT_TYPE_MAP } from '../liveChat/constants';
import { GetHelpZendeskContext } from './GetHelpZendeskContext';
import { STEPS } from './GetHelpZendeskConstants';

import { GetHelpZendeskModalRendererQuery } from './__generated__/GetHelpZendeskModalRendererQuery.graphql';

import dibsCss from 'dibs-css';
import styles from './GetHelpZendeskModalRenderer.scss';

const GetHelpZendeskModalRenderer: FC = () => {
    const environment = useRelayEnvironment();
    const { state } = useContext(GetHelpZendeskContext);
    const {
        userId,
        sellerId,
        step,
        orderId,
        articleId,
        searchQuery,
        isSeller,
        onChatEnd,
        isCheckout,
    } = state;

    const { viewer } = useLazyLoadQuery<GetHelpZendeskModalRendererQuery>(
        graphql`
            query GetHelpZendeskModalRendererQuery(
                $articleId: String!
                $hasArticleId: Boolean!
                $hasOrderId: Boolean!
                $hasSearchQuery: Boolean!
                $hasSellerId: Boolean!
                $hasUserId: Boolean!
                $isSeller: Boolean
                $searchQuery: String
                $sellerId: String!
                $transactionId: ID!
                $userId: String!
                $isCheckout: Boolean
            ) {
                viewer {
                    ...GetHelpZendeskModalIntro_viewer
                    ...GetHelpZendeskOrderInfo_viewer
                    ...GetHelpZendeskArticle_viewer
                    ...GetHelpZendeskModalHeader_viewer
                    ...GetHelpZendeskArticlesSearchResults_viewer
                    ...GetHelpZendeskArticlesNoSearchResults_viewer
                    ...GetHelpZendeskContactSupport_viewer
                    ...GetHelpZendeskFooter_viewer
                    contact1stdibsConfig(isSeller: $isSeller) {
                        isAgentAvailable
                        showChannelChat
                    }
                }
            }
        `,
        {
            articleId,
            hasArticleId: !!articleId,
            hasOrderId: !!orderId,
            hasSearchQuery: !!searchQuery,
            hasSellerId: !!sellerId,
            hasUserId: !!userId,
            isSeller,
            searchQuery,
            sellerId: sellerId || '',
            transactionId: orderId || '',
            userId: userId || '',
            isCheckout,
        }
    );

    const getModalContent = (): ReactNode => {
        switch (step) {
            case STEPS.INTRO:
                return <GetHelpZendeskModalIntro viewer={viewer} />;
            case STEPS.ORDERS_LIST:
                return <GetHelpZendeskOrdersList />;
            case STEPS.ORDER_INFO:
                return <GetHelpZendeskOrderInfo viewer={viewer} />;
            case STEPS.SEARCH_RESULTS:
                return <GetHelpZendeskArticlesSearchResults viewer={viewer} />;
            case STEPS.NO_SEARCH_RESULTS:
                return <GetHelpZendeskArticlesNoSearchResults viewer={viewer} />;
            case STEPS.ARTICLE:
                return <GetHelpZendeskArticle viewer={viewer} />;
            case STEPS.CONTACT_SUPPORT:
                return <GetHelpZendeskContactSupport viewer={viewer} />;
            case STEPS.ERROR:
                return <GetHelpZendeskError />;
            default:
                return <GetHelpZendeskModalIntro viewer={viewer} />;
        }
    };

    const { contact1stdibsConfig } = viewer;
    const { isAgentAvailable, showChannelChat } = contact1stdibsConfig || {};

    useEffect(() => {
        const chatShouldBeInitialized = showChannelChat && (isAgentAvailable || isSeller);

        // initiating chat in initial view to reduce wait time for chat button to be enabled in topic view
        if (chatShouldBeInitialized) {
            initChat({
                chatType: isSeller ? CHAT_TYPE_MAP.seller : CHAT_TYPE_MAP.buyer,
                environment,
                initiated: true,
                afterDestroy: () => {
                    if (typeof onChatEnd === 'function') {
                        onChatEnd();
                    }
                    if (!isSeller) {
                        adjustPdpSellerChatPosition({ reset: true });
                    }
                },
            });
        }
    }, [environment, isAgentAvailable, showChannelChat, isSeller, onChatEnd]);

    return (
        <>
            <GetHelpZendeskModalHeader viewer={viewer} />
            <div className={classnames(styles.content, dibsCss.pxSmall)}>{getModalContent()}</div>
            <GetHelpZendeskFooter viewer={viewer} />
        </>
    );
};

export { GetHelpZendeskModalRenderer };
