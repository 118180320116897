import { readInlineData, graphql } from 'react-relay';
import { defineMessages, IntlShape } from 'dibs-react-intl';

import { getBuyerEventHeader } from 'dibs-transaction-timeline/exports/getBuyerEventHeader';
import { isBomtTimelineOptimizationVariant } from './abTestHelpers';

import { getOrderStatusLabel_transaction$key } from './__generated__/getOrderStatusLabel_transaction.graphql';

const messages = defineMessages({
    canceled: {
        id: 'getHelp.OrderStatusLabel.canceled',
        defaultMessage: 'Canceled',
    },
});

export const getOrderStatusLabel = ({
    transaction: transactionRef,
    isSeller,
    intl,
}: {
    transaction: getOrderStatusLabel_transaction$key | null | undefined;
    isSeller: boolean;
    intl: IntlShape;
}): string | null => {
    const transaction = readInlineData(
        graphql`
            fragment getOrderStatusLabel_transaction on Transaction @inline {
                isCanceled
                currentBuyerTimelineStatus {
                    label
                }
                currentSellerTimelineStatus {
                    label
                }
                ...getBuyerEventHeader_transaction
            }
        `,
        transactionRef
    );

    if (isBomtTimelineOptimizationVariant()) {
        const statusLabel = getBuyerEventHeader({ transaction, intl });
        return statusLabel;
    }

    const { isCanceled, currentBuyerTimelineStatus, currentSellerTimelineStatus } =
        transaction || {};

    let statusLabel;

    if (isCanceled) {
        statusLabel = intl.formatMessage(messages.canceled);
    } else if (isSeller) {
        statusLabel = currentSellerTimelineStatus?.label;
    } else {
        statusLabel = currentBuyerTimelineStatus?.label;
    }

    return statusLabel || null;
};
