import { FC, useContext } from 'react';
import { useFragment, graphql } from 'react-relay';
import { FormattedMessage } from 'dibs-react-intl';

import { Link } from 'dibs-elements/exports/Link';

import GetHelpContext from './GetHelpContext';
import { ACTION_SET_TOPIC, ACTION_SET_STEP, STEP_TOPIC } from './constants';
import { trackTopicSelect } from './tracking';

import { PopularArticles_getHelpTopics$key } from './__generated__/PopularArticles_getHelpTopics.graphql';

import styles from './styles/PopularArticles.scss';

const PopularArticles: FC<{
    getHelpTopics: PopularArticles_getHelpTopics$key | null | undefined;
}> = ({ getHelpTopics: getHelpTopicsRef }) => {
    const { dispatch } = useContext(GetHelpContext);

    const helpTopics = useFragment(
        graphql`
            fragment PopularArticles_getHelpTopics on GetHelpTopics {
                topIssues {
                    label
                    topicKey
                }
            }
        `,
        getHelpTopicsRef
    );

    const topIssues = helpTopics?.topIssues || [];

    return (
        <div className={styles.container}>
            <div className={styles.title}>
                <FormattedMessage
                    id="getHelp.PopularArticles.title"
                    defaultMessage="Popular Articles"
                />
            </div>
            {topIssues.map(issue => (
                <Link
                    dataTn="get-help-popular-articles-link"
                    className={styles.issueLink}
                    key={issue?.topicKey}
                    onClick={() => {
                        dispatch({ type: ACTION_SET_TOPIC, topic: issue?.topicKey || '' });
                        dispatch({ type: ACTION_SET_STEP, step: STEP_TOPIC });
                        trackTopicSelect(issue?.topicKey || '');
                    }}
                    underline="none"
                >
                    {issue?.label}
                </Link>
            ))}
        </div>
    );
};

export default PopularArticles;
