import { FC, useContext, useState } from 'react';
import { useFragment, graphql } from 'react-relay';
import { FormattedMessage } from 'dibs-react-intl';

import { Button } from 'dibs-elements/exports/Button';
import { Link } from 'dibs-elements/exports/Link';

import ContactSellerForm from './ContactSellerForm';
import ContactBuyerForm from './ContactBuyerForm';

import GetHelpContext from './GetHelpContext';
import { trackContactSeller, trackContactOptionsExpanded, trackNavigateToMC } from './tracking';

import { OpenConversationCta_viewer$key } from './__generated__/OpenConversationCta_viewer.graphql';

import styles from './styles/OpenConversationCta.scss';

const OpenConversationCta: FC<{
    viewer: OpenConversationCta_viewer$key | null | undefined;
    setShowOtherHelp: (value: boolean) => void;
    showOtherHelp?: boolean;
}> = ({ viewer: viewerRef, setShowOtherHelp, showOtherHelp }) => {
    const {
        state: { topicValue, isSeller },
    } = useContext(GetHelpContext);
    const [isFormOpen, setIsFormOpen] = useState(false);
    const [isShowOtherHelpClicked, setIsShowOtherHelpClicked] = useState(showOtherHelp);

    const viewer = useFragment(
        graphql`
            fragment OpenConversationCta_viewer on Viewer {
                transaction(transactionId: $transactionId) @include(if: $hasOrderId) {
                    ...ContactSellerForm_transaction @include(if: $hasUserId)
                    ...ContactBuyerForm_transaction @include(if: $hasSellerId)
                    conversation {
                        serviceId
                    }
                    item {
                        seller {
                            sellerProfile {
                                company
                            }
                        }
                    }
                }
            }
        `,
        viewerRef
    );

    const { transaction } = viewer || {};
    const { conversation, item } = transaction || {};
    const { company: sellerCompany } = item?.seller?.sellerProfile || {};
    const conversationId = conversation?.serviceId;

    const contactForm = isSeller ? (
        <ContactBuyerForm transaction={transaction || null} />
    ) : (
        <ContactSellerForm transaction={transaction || null} />
    );

    const conversationLink = isSeller
        ? `/dealers/conversations/${conversationId}`
        : `/inbox/${conversationId}/`;

    return (
        <div className={styles.contactSellerWrapper} data-tn="get-help-modal-contact-seller">
            {!isSeller && (
                <div className={styles.soldBy} data-tn="get-help-modal-contact-seller-sold-by">
                    <FormattedMessage
                        id="getHelp.OpenConversationCta.soldBy"
                        defaultMessage="<b>Sold by</b> {sellerCompany}"
                        values={{ sellerCompany, b: content => <b>{content}</b> }}
                    />
                </div>
            )}
            {!isFormOpen && (
                <Button
                    dataTn="get-help-modal-contact-seller-cta"
                    {...(conversationId
                        ? {
                              href: conversationLink,
                              onClick: () => {
                                  if (!isSeller) {
                                      trackContactSeller(topicValue);
                                  }
                                  trackNavigateToMC();
                              },
                          }
                        : {
                              onClick: () => {
                                  setIsFormOpen(true);
                                  trackContactSeller(topicValue);
                              },
                          })}
                    className={styles.contactSellerBtn}
                >
                    {isSeller ? (
                        <FormattedMessage
                            id="getHelp.OpenConversationCta.contactBuyer"
                            defaultMessage="Send Client a Message"
                        />
                    ) : (
                        <FormattedMessage
                            id="getHelp.OpenConversationCta.contactSeller"
                            defaultMessage="Contact Seller"
                        />
                    )}
                </Button>
            )}
            {isFormOpen && contactForm}
            {!isSeller && (
                <div>
                    <span className={styles.contactSupportInfo}>
                        <FormattedMessage
                            id="getHelp.OpenConversationCta.contactSupportInfo"
                            defaultMessage="We recommend contacting the Seller for a quicker response solving your issue."
                        />
                    </span>
                    {!isShowOtherHelpClicked && (
                        <Link
                            className={styles.contactSupportLink}
                            onClick={() => {
                                setShowOtherHelp(true);
                                setIsShowOtherHelpClicked(true);
                                trackContactOptionsExpanded(topicValue);
                            }}
                            underline="underline"
                        >
                            <FormattedMessage
                                id="getHelp.OpenConversationCta.contactSupport"
                                defaultMessage="Still need help? Contact support"
                            />
                        </Link>
                    )}
                </div>
            )}
        </div>
    );
};

export default OpenConversationCta;
