import { createContext, FC, useContext, ReactNode } from 'react';
import { graphql } from 'react-relay';
import { useTranslationToggle, TranslationState } from 'dibs-elements/exports/useTranslationToggle';

import {
    ReturnPolicyTranslationProviderQuery,
    ReturnPolicyTranslationProviderQuery$data,
} from './__generated__/ReturnPolicyTranslationProviderQuery.graphql';

const TranslationQuery = graphql`
    query ReturnPolicyTranslationProviderQuery(
        $itemIds: [String]!
        $userId: String!
        $transactionId: ID!
        $hasItemIds: Boolean = false
        $hasTransactionId: Boolean = false
    ) {
        viewer {
            itemRead(itemIds: $itemIds) @include(if: $hasItemIds) {
                ... on Item {
                    returnPolicy(userId: $userId) {
                        additionalNotes
                    }
                }
            }
            transaction(transactionId: $transactionId) @include(if: $hasTransactionId) {
                returnPolicy {
                    additionalNotes
                }
            }
        }
    }
`;

type ContextStateValue = TranslationState<ReturnPolicyTranslationProviderQuery$data>;
const TranslationContext = createContext<ContextStateValue>([null, null]);

export const ReturnPolicyTranslationProvider: FC<{
    sourceLocale: string | null;
    variables: ReturnPolicyTranslationProviderQuery['variables'];
    children: ReactNode;
}> = ({ sourceLocale, children, variables }) => {
    const state = useTranslationToggle<ReturnPolicyTranslationProviderQuery>({
        sourceLocale,
        variables,
        query: TranslationQuery,
    });

    return <TranslationContext.Provider value={state}>{children}</TranslationContext.Provider>;
};

export function useReturnPolicyTranslations(): ContextStateValue {
    return useContext(TranslationContext);
}
