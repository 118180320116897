/**
 * @generated SignedSource<<0b62331bd7c01d21f04f700aa54912a7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EstimatedShippingDateDisplay_viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"shippingTrackingDetails_viewer">;
  readonly " $fragmentType": "EstimatedShippingDateDisplay_viewer";
};
export type EstimatedShippingDateDisplay_viewer$key = {
  readonly " $data"?: EstimatedShippingDateDisplay_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"EstimatedShippingDateDisplay_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EstimatedShippingDateDisplay_viewer",
  "selections": [
    {
      "kind": "InlineDataFragmentSpread",
      "name": "shippingTrackingDetails_viewer",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "LeadTime",
          "kind": "LinkedField",
          "name": "leadTimes",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endTimeUnit",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "startTimeUnit",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "8181617b1bb12d468f402c707bdedd08";

export default node;
