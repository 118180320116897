import dibsCss from 'dibs-css';
import classnames from 'classnames';
import { FunctionComponent } from 'react';

type CircleProps = {
    className?: string;
};

const Circle: FunctionComponent<CircleProps> = props => {
    return (
        <svg
            className={classnames(dibsCss.dibsIcon, props.className)}
            data-tn={'circle'}
            viewBox="0 0 100 100"
        >
            <circle cx="50" cy="50" r="50" />
        </svg>
    );
};
export default Circle;
