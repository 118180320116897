/**
 * @generated SignedSource<<79bd6099a0199448e2c10a4b2cfb9c99>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpdatedContactSellerForm_transaction$data = {
  readonly buyer: {
    readonly profile: {
      readonly displayName: string | null;
      readonly email: string | null;
    } | null;
  } | null;
  readonly item: {
    readonly seller: {
      readonly serviceId: string | null;
    } | null;
    readonly serviceId: string | null;
  } | null;
  readonly " $fragmentType": "UpdatedContactSellerForm_transaction";
};
export type UpdatedContactSellerForm_transaction$key = {
  readonly " $data"?: UpdatedContactSellerForm_transaction$data;
  readonly " $fragmentSpreads": FragmentRefs<"UpdatedContactSellerForm_transaction">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "serviceId",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UpdatedContactSellerForm_transaction",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "buyer",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Profile",
          "kind": "LinkedField",
          "name": "profile",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "displayName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "email",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Item",
      "kind": "LinkedField",
      "name": "item",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Seller",
          "kind": "LinkedField",
          "name": "seller",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Transaction",
  "abstractKey": null
};
})();

(node as any).hash = "2171c19bf2ad4fd5e95e1c6caa2b56a9";

export default node;
