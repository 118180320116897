import { RecaptchaForm } from '../src/RecaptchaForm';
import {
    validateVerificationCode,
    isValidErrorCode,
    mfaActionTypes,
    errorCodeKeys,
} from '../src/RecaptchaContainer';
import type { AllowedMFAErrorCodes, MFAErrorCodes, MFAActionType } from '../src/RecaptchaContainer';
export type { AllowedMFAErrorCodes, MFAErrorCodes, MFAActionType };
export { validateVerificationCode, isValidErrorCode, mfaActionTypes, errorCodeKeys };
export default RecaptchaForm;
