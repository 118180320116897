import { FC, useContext } from 'react';
import { useFragment, graphql } from 'react-relay';
import classnames from 'classnames';
import { FormattedMessage } from 'dibs-react-intl';

import { Link } from 'dibs-elements/exports/Link';
import Box from 'dibs-icons/exports/legacy/Box';
import List from 'dibs-icons/exports/legacy/List';
import InfoCircle from 'dibs-icons/exports/legacy/InfoCircle';

import GetHelpContext from './GetHelpContext';
import {
    ACTION_SET_STEP,
    ACTION_SET_HELP_TYPE,
    HELP_TYPE_ORDER_ISSUES,
    HELP_TYPE_OTHER_ASSISTANCE,
    HELP_TYPE_LISTINGS,
    STEP_ORDERS_LIST,
    STEP_OTHER_ASSISTANCE,
    STEP_ITEMS_LIST,
    STEP_SELECTED_ORDER,
    STEP_SELECTED_LISTING,
} from './constants';

import { IntroStepSeller_viewer$key } from './__generated__/IntroStepSeller_viewer.graphql';

import styles from './styles/IntroStepSeller.scss';

const IntroStepSeller: FC<{ viewer: IntroStepSeller_viewer$key }> = ({ viewer: viewerRef }) => {
    const {
        dispatch,
        state: { isEmbedded, orderId, itemPk },
    } = useContext(GetHelpContext);

    const viewer = useFragment(
        graphql`
            fragment IntroStepSeller_viewer on Viewer {
                seller(sellerId: $sellerId) @include(if: $hasSellerId) {
                    sellerProfile {
                        company
                    }
                }
            }
        `,
        viewerRef
    );

    const { company } = viewer.seller?.sellerProfile || {};

    return (
        <div className={classnames(styles.container, { [styles.isEmbedded]: isEmbedded })}>
            <div className={styles.containerInner}>
                <div className={styles.header} data-tn="get-help-modal-intro-step-greeting">
                    {company ? (
                        <FormattedMessage
                            id="getHelp.IntroStepSeller.introHeaderLoggedIn"
                            defaultMessage="Hi <italic>{company}</italic>, what can we help you with?"
                            values={{
                                company,
                                italic: content => (
                                    <span className={styles.company}>{content}</span>
                                ),
                            }}
                        />
                    ) : (
                        <FormattedMessage
                            id="getHelp.IntroStepSeller.introHeader"
                            defaultMessage="What can we help you with?"
                        />
                    )}
                </div>
                <div className={styles.ctasContainer}>
                    <Link
                        onClick={() => {
                            dispatch({
                                type: ACTION_SET_STEP,
                                step: orderId ? STEP_SELECTED_ORDER : STEP_ORDERS_LIST,
                            });
                            dispatch({
                                type: ACTION_SET_HELP_TYPE,
                                helpType: HELP_TYPE_ORDER_ISSUES,
                            });
                        }}
                        className={styles.cta}
                        underline="none"
                    >
                        <Box className={styles.ctaIcon} />
                        <FormattedMessage
                            id="getHelp.IntroStepSeller.ordersAndOffers"
                            defaultMessage="Orders & Offers"
                        />
                    </Link>
                    <Link
                        onClick={() => {
                            dispatch({
                                type: ACTION_SET_STEP,
                                step: itemPk ? STEP_SELECTED_LISTING : STEP_ITEMS_LIST,
                            });
                            dispatch({
                                type: ACTION_SET_HELP_TYPE,
                                helpType: HELP_TYPE_LISTINGS,
                            });
                        }}
                        className={styles.cta}
                        underline="none"
                    >
                        <List className={styles.ctaIcon} />
                        <FormattedMessage
                            id="getHelp.IntroStepSeller.listings"
                            defaultMessage="Listings"
                        />
                    </Link>
                    <Link
                        onClick={() => {
                            dispatch({ type: ACTION_SET_STEP, step: STEP_OTHER_ASSISTANCE });
                            dispatch({
                                type: ACTION_SET_HELP_TYPE,
                                helpType: HELP_TYPE_OTHER_ASSISTANCE,
                            });
                        }}
                        className={styles.cta}
                        underline="none"
                    >
                        <InfoCircle className={styles.ctaIcon} />
                        <FormattedMessage
                            id="getHelp.IntroStepSeller.otherAssistance"
                            defaultMessage="Other Assistance"
                        />
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default IntroStepSeller;
