/**
 * @generated SignedSource<<cacdeaa8490cf769763eb5eab281c2c7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SelectedOrder_viewer$data = {
  readonly getHelpTopics: {
    readonly " $fragmentSpreads": FragmentRefs<"OtherTopics_getHelpTopics" | "PopularArticles_getHelpTopics">;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"OrderInfo_viewer">;
  readonly " $fragmentType": "SelectedOrder_viewer";
};
export type SelectedOrder_viewer$key = {
  readonly " $data"?: SelectedOrder_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"SelectedOrder_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "helpType"
    },
    {
      "kind": "RootArgument",
      "name": "isSeller"
    },
    {
      "kind": "RootArgument",
      "name": "itemId"
    },
    {
      "kind": "RootArgument",
      "name": "orderId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "SelectedOrder_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OrderInfo_viewer"
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "helpType",
          "variableName": "helpType"
        },
        {
          "kind": "Variable",
          "name": "isSeller",
          "variableName": "isSeller"
        },
        {
          "kind": "Variable",
          "name": "itemId",
          "variableName": "itemId"
        },
        {
          "kind": "Variable",
          "name": "orderId",
          "variableName": "orderId"
        }
      ],
      "concreteType": "GetHelpTopics",
      "kind": "LinkedField",
      "name": "getHelpTopics",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PopularArticles_getHelpTopics"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "OtherTopics_getHelpTopics"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "2baf14ff1d20420e66633d5cc2a40d4a";

export default node;
