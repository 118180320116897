/**
 * @generated SignedSource<<36a72f4bcc9d0e00283d7cd718fafb7d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type TrackingUrlTypeEnum = "CARRIER" | "PROJECT44" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type TrackingDetails_viewer$data = {
  readonly transaction?: {
    readonly buyerTimelineStatuses: {
      readonly completed: boolean | null;
      readonly shipped: boolean | null;
    } | null;
    readonly isCanceled: boolean | null;
    readonly serviceId: string | null;
    readonly shipment: {
      readonly deliveryDateRangeDisplay: string | null;
      readonly estimatedShippingDate: string | null;
      readonly isBuyerShipping: boolean | null;
      readonly shipmentTracking: {
        readonly estimatedDeliveryDate: string | null;
        readonly lateByDate: string | null;
        readonly trackingUrl: string | null;
        readonly trackingUrlType: TrackingUrlTypeEnum | null;
      } | null;
      readonly " $fragmentSpreads": FragmentRefs<"CarrierTrackingDetails_shipment">;
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"EstimatedDeliveryDateDisplay_transaction" | "EstimatedShippingDateDisplay_transaction">;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"EstimatedDeliveryDateDisplay_viewer" | "EstimatedShippingDateDisplay_viewer">;
  readonly " $fragmentType": "TrackingDetails_viewer";
};
export type TrackingDetails_viewer$key = {
  readonly " $data"?: TrackingDetails_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"TrackingDetails_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "hasOrderId"
    },
    {
      "kind": "RootArgument",
      "name": "transactionId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "TrackingDetails_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EstimatedShippingDateDisplay_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EstimatedDeliveryDateDisplay_viewer"
    },
    {
      "condition": "hasOrderId",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "transactionId",
              "variableName": "transactionId"
            }
          ],
          "concreteType": "Transaction",
          "kind": "LinkedField",
          "name": "transaction",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "EstimatedDeliveryDateDisplay_transaction"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "EstimatedShippingDateDisplay_transaction"
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "serviceId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isCanceled",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Shipment",
              "kind": "LinkedField",
              "name": "shipment",
              "plural": false,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "CarrierTrackingDetails_shipment"
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "deliveryDateRangeDisplay",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "estimatedShippingDate",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isBuyerShipping",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ShipmentTracking",
                  "kind": "LinkedField",
                  "name": "shipmentTracking",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "lateByDate",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "trackingUrl",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "trackingUrlType",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "estimatedDeliveryDate",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "TransactionBuyerTimelineStatuses",
              "kind": "LinkedField",
              "name": "buyerTimelineStatuses",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "shipped",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "completed",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ]
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "a57625cd07617b4024ce2acd59fdde32";

export default node;
