import { FC, ReactNode } from 'react';
import { useFragment, graphql } from 'react-relay';
import { FormattedMessage, defineMessages, useIntl } from 'dibs-react-intl';

import { Link } from 'dibs-elements/exports/Link';
import { Button } from 'dibs-elements/exports/Button';
import CopyLink from 'dibs-copy-text/exports/CopyLink';

import { CarrierTrackingDetails_shipment$key } from './__generated__/CarrierTrackingDetails_shipment.graphql';

import styles from './CarrierTrackingDetails.scss';

const messages = defineMessages({
    copyLinkConfirmation: {
        id: 'ShipmentTrackingDetailsModalContent.copyLinkConfirmation',
        defaultMessage: 'Tracking Number Copied',
    },
});

const Label: FC<{ children: ReactNode }> = ({ children }) => (
    <span className={styles.label}>{children}</span>
);

const CarrierTrackingDetails: FC<{
    shipment: CarrierTrackingDetails_shipment$key | null | undefined;
    showTrackOnSiteButton?: boolean;
    onTrackingNumberLinkClick?: () => void;
    onTrackingCtaClick?: () => void;
}> = ({
    shipment: shipmentRef,
    showTrackOnSiteButton = true,
    onTrackingNumberLinkClick,
    onTrackingCtaClick,
}) => {
    const intl = useIntl();

    const shipment = useFragment(
        graphql`
            fragment CarrierTrackingDetails_shipment on Shipment {
                shipmentTracking {
                    trackingNumber
                    trackingUrl
                    carrier {
                        name
                        carrierMetaData {
                            websiteUrl
                            customerServiceEmail
                            customerServicePhoneNumber1
                            customerServicePhoneNumber2
                            internallyIntegrated
                        }
                    }
                }
            }
        `,
        shipmentRef
    );

    const shipmentTracking = shipment?.shipmentTracking;
    const { trackingNumber, carrier, trackingUrl } = shipmentTracking || {};
    const { name: carrierName, carrierMetaData } = carrier || {};
    const {
        websiteUrl: carrierWebsiteUrl,
        customerServiceEmail: carrierEmail,
        customerServicePhoneNumber1: carrierPhoneNumber1,
        customerServicePhoneNumber2: carrierPhoneNumber2,
        internallyIntegrated,
    } = carrierMetaData || {};

    return (
        <div>
            <div>
                <FormattedMessage
                    id="shipmentTracking.CarrierTrackingDetails.carrierLabel"
                    defaultMessage="<label>Carrier:</label> {carrierName}"
                    values={{ label: content => <Label>{content}</Label>, carrierName }}
                />
            </div>
            <div>
                <FormattedMessage
                    id="shipmentTracking.CarrierTrackingDetails.trackingNumberLabel"
                    defaultMessage="<label>Tracking Number:</label> {hasTrackingNumber, select, false {N/A} other {{trackingNumberLink}}}"
                    values={{
                        label: content => <Label>{content}</Label>,
                        trackingNumberLink: (
                            <div className={styles.copyLinkWrapper}>
                                <CopyLink
                                    copyNoir
                                    textToCopy={trackingNumber || ''}
                                    confirmationText={intl.formatMessage(
                                        messages.copyLinkConfirmation
                                    )}
                                    linkNode={
                                        trackingUrl ? (
                                            <Link
                                                href={trackingUrl || ''}
                                                target="_blank"
                                                onClick={onTrackingNumberLinkClick}
                                            >
                                                {trackingNumber || ''}
                                            </Link>
                                        ) : (
                                            trackingNumber || ''
                                        )
                                    }
                                />
                            </div>
                        ),
                        hasTrackingNumber: !!trackingNumber,
                    }}
                />
            </div>
            {carrierWebsiteUrl && (
                <div>
                    <FormattedMessage
                        id="shipmentTracking.CarrierTrackingDetails.websiteLabel"
                        defaultMessage="<label>Website</label>: {carrierWebsiteUrl}"
                        values={{
                            label: content => <Label>{content}</Label>,
                            carrierWebsiteUrl: (
                                <Link href={carrierWebsiteUrl || ''} target="_blank">
                                    {carrierWebsiteUrl}
                                </Link>
                            ),
                        }}
                    />
                </div>
            )}
            {carrierEmail && (
                <div>
                    <FormattedMessage
                        id="shipmentTracking.CarrierTrackingDetails.emailLabel"
                        defaultMessage="<label>Email:</label> {carrierEmail}"
                        values={{
                            label: content => <Label>{content}</Label>,
                            carrierEmail: (
                                <Link href={`mailto:${carrierEmail}`}>{carrierEmail}</Link>
                            ),
                        }}
                    />
                </div>
            )}
            {(carrierPhoneNumber1 || carrierPhoneNumber2) && (
                <div>
                    <FormattedMessage
                        id="shipmentTracking.CarrierTrackingDetails.phoneNumberLabel"
                        defaultMessage="<label>Phone number:</label> {carrierPhoneNumber1}{hasBothPhoneNumbers, select, true {,} other {}} {carrierPhoneNumber2}"
                        values={{
                            label: content => <Label>{content}</Label>,
                            carrierPhoneNumber1: (
                                <Link href={`tel:${carrierPhoneNumber1}`}>
                                    {carrierPhoneNumber1}
                                </Link>
                            ),
                            carrierPhoneNumber2: (
                                <Link href={`tel:${carrierPhoneNumber2}`}>
                                    {carrierPhoneNumber2}
                                </Link>
                            ),
                            hasBothPhoneNumbers: !!carrierPhoneNumber1 && !!carrierPhoneNumber2,
                        }}
                    />
                </div>
            )}
            {showTrackOnSiteButton && internallyIntegrated && (
                <Button
                    href={trackingUrl || ''}
                    className={styles.trackOnSiteCta}
                    opensInNewWindow
                    onClick={onTrackingCtaClick}
                >
                    <FormattedMessage
                        id="shipmentTracking.CarrierTrackingDetails.carrierTrackingCta"
                        defaultMessage="Carrier Tracking"
                    />
                </Button>
            )}
        </div>
    );
};

export default CarrierTrackingDetails;
