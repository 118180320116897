import { FC, useContext } from 'react';
import classnames from 'classnames';
import { graphql, useFragment } from 'react-relay';

import { FormattedMessage, useIntl } from 'dibs-react-intl';
import { Link } from 'dibs-elements/exports/Link';
import TrackingDetails from '../TrackingDetails';

import { getOrderStatusLabel } from '../getOrderStatusLabel';
import { GetHelpZendeskContext } from './GetHelpZendeskContext';

import { GetHelpZendeskOrderInfo_viewer$key } from './__generated__/GetHelpZendeskOrderInfo_viewer.graphql';

import dibsCss from 'dibs-css';
import styles from './GetHelpZendeskOrderInfo.scss';

const GetHelpZendeskOrderInfo: FC<{ viewer: GetHelpZendeskOrderInfo_viewer$key }> = ({
    viewer: viewerRef,
}) => {
    const intl = useIntl();
    const {
        state: { isSeller },
    } = useContext(GetHelpZendeskContext);

    const viewer = useFragment(
        graphql`
            fragment GetHelpZendeskOrderInfo_viewer on Viewer {
                ...TrackingDetails_viewer
                transaction(transactionId: $transactionId) @include(if: $hasOrderId) {
                    serviceId
                    item {
                        title
                        firstPhotoWebPath(size: small)
                    }
                    ...getOrderStatusLabel_transaction
                }
                trackOrderGetHelpTopic: getHelpTopic(topicKey: "trackOrder") {
                    label
                    instructionalText
                }
            }
        `,
        viewerRef
    );

    const { trackOrderGetHelpTopic, transaction } = viewer;
    const { label, instructionalText } = trackOrderGetHelpTopic || {};
    const { item, serviceId: orderId } = transaction || {};
    const { title, firstPhotoWebPath } = item || {};

    const statusLabel = getOrderStatusLabel({ transaction, isSeller: false, intl });

    return (
        <div className={dibsCss.mbSmall}>
            <div
                className={classnames(
                    dibsCss.flex,
                    dibsCss.itemsCenter,
                    dibsCss.gapSmall,
                    dibsCss.mbMedium
                )}
            >
                <div className={styles.imageWrapper}>
                    <img
                        src={firstPhotoWebPath || ''}
                        alt={title || ''}
                        className={classnames(styles.image, dibsCss.wFull, dibsCss.hFull)}
                    />
                </div>
                <div
                    className={classnames(
                        dibsCss.flex,
                        dibsCss.flexCol,
                        dibsCss.justifyCenter,
                        dibsCss.gapXsmall,
                        dibsCss.overflowHidden,
                        dibsCss.textLeft
                    )}
                >
                    <div className={dibsCss.sassyFontHeaderMedium}>{title}</div>
                    <div>
                        {statusLabel && (
                            <>
                                <span className={dibsCss.sassyFontBodySmallHeavy}>
                                    {statusLabel}
                                </span>
                                {' | '}
                            </>
                        )}

                        <Link href={`/orders/order/${orderId}/`}>
                            <FormattedMessage
                                id="getHelp.GetHelpZendeskOrderInfo.viewOrder"
                                defaultMessage="View order details"
                            />
                        </Link>
                    </div>
                </div>
            </div>
            <div className={classnames(dibsCss.sassyFontBodyLargeHeavy, dibsCss.mbSmall)}>
                {label}
            </div>
            <div
                className={dibsCss.sassyFontBodyMedium}
                dangerouslySetInnerHTML={{
                    __html: instructionalText || '',
                }}
            />
            <TrackingDetails viewer={viewer} isSeller={isSeller} />
        </div>
    );
};

export { GetHelpZendeskOrderInfo };
