import { FC } from 'react';
import { useFragment, graphql } from 'react-relay';
import { FormattedMessage } from 'dibs-react-intl';

import GetHelpHeader from './GetHelpHeader';
import PopularArticles from './PopularArticles';
import OtherHelp from './OtherHelp';

import { CheckoutAssistance_viewer$key } from './__generated__/CheckoutAssistance_viewer.graphql';

import styles from './styles/CheckoutAssistance.scss';

const CheckoutAssistance: FC<{ viewer: CheckoutAssistance_viewer$key }> = ({
    viewer: viewerRef,
}) => {
    const viewer = useFragment(
        graphql`
            fragment CheckoutAssistance_viewer on Viewer {
                checkoutAssistanceGetHelpTopics: getHelpTopics(helpType: checkoutAssistance) {
                    ...PopularArticles_getHelpTopics
                }
                ...OtherHelp_viewer
            }
        `,
        viewerRef
    );

    const getHelpTopics = viewer.checkoutAssistanceGetHelpTopics;

    return (
        <div className={styles.container}>
            <GetHelpHeader
                showBackButton={false}
                title={
                    <FormattedMessage
                        id="getHelp.CheckoutAssistance.header"
                        defaultMessage="Checkout assistance"
                    />
                }
            />
            <PopularArticles getHelpTopics={getHelpTopics} />
            <OtherHelp viewer={viewer} />
        </div>
    );
};

export default CheckoutAssistance;
