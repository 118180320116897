import dibsCss from 'dibs-css';
import classnames from 'classnames';
import { FC } from 'react';

type ShoppingBagCheckmarkCircleProps = {
    className?: string;
    role?: 'img';
    ariaLabel?: string;
    ariaLabelledby?: string;
};

const ShoppingBagCheckmarkCircle: FC<ShoppingBagCheckmarkCircleProps> = props => {
    return (
        <svg
            className={classnames(dibsCss.dibsIcon, props.className)}
            data-tn={'shopping-bag-checkmark-circle'}
            role={props.role}
            aria-label={props.ariaLabel}
            aria-labelledby={props.ariaLabelledby}
            viewBox="0 0 250 250"
        >
            <path d="M205 231H19L0 64h231l-26 167zm-179-9h172l24-150H9l17 150z" />
            <path d="M166 72H64l1-6c2.645-9.845 7.177-19.088 12-28 10.472-19.08 23.226-29 38-29 14.774 0 28.518 9.898 39 29 4.817 8.914 8.356 18.157 11 28l1 6zm-92-9h82c-4.271-13.15-17.448-45-41-45S78.281 49.85 74 63z" />
            <circle cx="190" cy="69" r="60" />
            <path
                d="M229 52.446L182.437 97 150 69.554l5.83-6.813 26.236 22.2L222.763 46 229 52.446z"
                fill="white"
            />
        </svg>
    );
};

export default ShoppingBagCheckmarkCircle;
