import { FC, useState, useCallback, useContext, useTransition } from 'react';
import classnames from 'classnames';
import { usePaginationFragment, graphql, useLazyLoadQuery } from 'react-relay';
import { FormattedMessage, defineMessages, useIntl } from 'dibs-react-intl';
import { useDebouncedCallback } from 'dibs-react-hooks/exports/useDebouncedCallback';

import { Input } from 'dibs-elements/exports/Input';
import { Link } from 'dibs-elements/exports/Link';
import { Spinner } from 'dibs-elements/exports/Spinner';
import Close from 'dibs-icons/exports/legacy/Close';
import MagnifyingGlass from 'dibs-icons/exports/legacy/MagnifyingGlass';
import { GetHelpZendeskOrderTile } from './GetHelpZendeskOrderTile';

import { GetHelpZendeskContext } from './GetHelpZendeskContext';

import { GetHelpZendeskOrdersList_viewer$key } from './__generated__/GetHelpZendeskOrdersList_viewer.graphql';
import { GetHelpZendeskOrdersListQuery } from './__generated__/GetHelpZendeskOrdersListQuery.graphql';
import { GetHelpZendeskOrdersListPaginationQuery } from './__generated__/GetHelpZendeskOrdersListPaginationQuery.graphql';

import dibsCss from 'dibs-css';

const messages = defineMessages({
    placeholder: {
        id: 'getHelp.OrdersList.placeholder',
        defaultMessage: 'Search by Order ID or Title',
    },
});

const MIN_SEARCH_LENGTH = 3;

const GetHelpZendeskOrdersList: FC = () => {
    const intl = useIntl();
    const {
        state: { userId },
    } = useContext(GetHelpZendeskContext);

    const [searchTerm, setSearchTerm] = useState('');
    const [hasSearched, setHasSearched] = useState(false);
    const [isRefetching, startTransition] = useTransition();

    const { viewer } = useLazyLoadQuery<GetHelpZendeskOrdersListQuery>(
        graphql`
            query GetHelpZendeskOrdersListQuery(
                $userId: String!
                $hasUserId: Boolean!
                $searchTerm: String!
                $viewName: String
            ) {
                viewer {
                    ...GetHelpZendeskOrdersList_viewer @include(if: $hasUserId)
                }
            }
        `,
        {
            userId: userId || '',
            hasUserId: !!userId,
            searchTerm: '',
            viewName: 'buyer.allOrders',
        }
    );

    const { data, hasNext, loadNext, isLoadingNext, refetch } = usePaginationFragment<
        GetHelpZendeskOrdersListPaginationQuery,
        GetHelpZendeskOrdersList_viewer$key
    >(
        graphql`
            fragment GetHelpZendeskOrdersList_viewer on Viewer
            @refetchable(queryName: "GetHelpZendeskOrdersListPaginationQuery")
            @argumentDefinitions(
                after: { type: String, defaultValue: "" }
                first: { type: Int, defaultValue: 10 }
            ) {
                transactionsPaginatedSearch(
                    userId: $userId
                    searchTerm: $searchTerm
                    viewName: $viewName
                    after: $after
                    first: $first
                ) @connection(key: "OrdersList_transactionsPaginatedSearch") {
                    edges {
                        node {
                            serviceId
                            ...GetHelpZendeskOrderTile_transaction
                        }
                    }
                }
            }
        `,
        viewer || null
    );

    const fetchData = useCallback(
        (val: string) => {
            startTransition(() => {
                setHasSearched(true);
                refetch({ searchTerm: val });
            });
        },
        [refetch, startTransition]
    );

    const [debouncedFetchData] = useDebouncedCallback(fetchData, 500);

    const onChange = (searchTermValue: string): void => {
        setSearchTerm(searchTermValue);
        if (searchTermValue.length >= MIN_SEARCH_LENGTH || !searchTermValue) {
            debouncedFetchData(searchTermValue);
        }
    };

    const transactions = data?.transactionsPaginatedSearch?.edges || [];
    const noTransactionsFound = !transactions.length && searchTerm;
    const hasOrders = !!transactions.length;

    return (
        <div className={dibsCss.mbLarge}>
            <div className={classnames(dibsCss.sassyFontHeaderMedium, dibsCss.mbSmall)}>
                <FormattedMessage
                    id="getHelp.GetHelpZendeskOrdersList.header"
                    defaultMessage="Select Order"
                />
            </div>
            {(hasOrders || hasSearched) && (
                <div className={dibsCss.mbMedium}>
                    <Input
                        dataTn="get-help-zendesk-orders-list-input"
                        size="medium"
                        value={searchTerm}
                        onChange={e => onChange(e.target.value)}
                        placeholder={intl.formatMessage(messages.placeholder)}
                        rightDecorator={
                            <div>
                                {searchTerm && (
                                    <Link onClick={() => onChange('')} className={dibsCss.flex}>
                                        <Close
                                            className={classnames(dibsCss.h16Px, dibsCss.w16Px)}
                                        />
                                    </Link>
                                )}
                            </div>
                        }
                        leftDecorator={
                            <MagnifyingGlass className={classnames(dibsCss.h20px, dibsCss.w20px)} />
                        }
                    />
                </div>
            )}
            {isRefetching ? (
                <div className={classnames(dibsCss.flex, dibsCss.itemsCenter)}>
                    <Spinner />
                </div>
            ) : (
                <>
                    <div className={classnames(dibsCss.flex, dibsCss.flexCol, dibsCss.gapSmall)}>
                        {transactions.map(transaction => (
                            <GetHelpZendeskOrderTile
                                key={transaction?.node?.serviceId || ''}
                                transaction={transaction?.node || null}
                            />
                        ))}
                        {isLoadingNext && hasNext && (
                            <div
                                className={classnames(
                                    dibsCss.wFull,
                                    dibsCss.flex,
                                    dibsCss.justifyCenter
                                )}
                            >
                                <Spinner />
                            </div>
                        )}
                        {hasNext && !isLoadingNext && (
                            <Link
                                dataTn="get-help-zendesk-modal-show-more-orders-cta"
                                onClick={() => loadNext(10)}
                            >
                                <div
                                    className={classnames(dibsCss.textLeft, dibsCss.sassyFontBody)}
                                >
                                    <FormattedMessage
                                        id="getHelp.GetHelpZendeskOrdersList.loadMore"
                                        defaultMessage="Show More Orders"
                                    />
                                </div>
                            </Link>
                        )}
                        {noTransactionsFound && (
                            <div>
                                <FormattedMessage
                                    id="getHelp.GetHelpZendeskOrdersList.noResultsFound"
                                    defaultMessage="We couldn’t find any results for “{searchTerm}”"
                                    values={{ searchTerm }}
                                />
                            </div>
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

export { GetHelpZendeskOrdersList };
