/**
 * @generated SignedSource<<2803a7b549c15869fbd52321a9d2b65b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GetHelpZendeskArticlesSearch_viewer$data = {
  readonly zendeskArticles: {
    readonly articlesSearch: ReadonlyArray<{
      readonly serviceId: string | null;
      readonly title: string | null;
    } | null> | null;
  } | null;
  readonly " $fragmentType": "GetHelpZendeskArticlesSearch_viewer";
};
export type GetHelpZendeskArticlesSearch_viewer$key = {
  readonly " $data"?: GetHelpZendeskArticlesSearch_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"GetHelpZendeskArticlesSearch_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": "",
      "kind": "LocalArgument",
      "name": "query"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "viewer"
      ],
      "operation": require('./GetHelpZendeskArticlesSearchRefetchQuery.graphql')
    }
  },
  "name": "GetHelpZendeskArticlesSearch_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Contact1stdibsZendeskArticles",
      "kind": "LinkedField",
      "name": "zendeskArticles",
      "plural": false,
      "selections": [
        {
          "alias": "articlesSearch",
          "args": [
            {
              "kind": "Variable",
              "name": "query",
              "variableName": "query"
            }
          ],
          "concreteType": "ZendeskArticle",
          "kind": "LinkedField",
          "name": "articles",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "serviceId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "title",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "65f3407def2643c13b21bc4abd570c2b";

export default node;
