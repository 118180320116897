import dibsCss from 'dibs-css';
import classnames from 'classnames';
import { FunctionComponent } from 'react';

type ListProps = {
    className?: string;
};

const List: FunctionComponent<ListProps> = props => {
    return (
        <svg
            className={classnames(dibsCss.dibsIcon, props.className)}
            data-tn={'list'}
            viewBox="0 0 250 250"
        >
            <rect x="62.8" y="63" width="184.2" height="9" />
            <rect x="62.6" y="121.5" width="184.2" height="9" />
            <rect x="62.6" y="180.5" width="184.2" height="9" />
            <circle cx="15.2" cy="67.2" r="12.9" />
            <circle cx="15" cy="124.9" r="12.9" />
            <circle cx="14.9" cy="184.8" r="12.9" />
        </svg>
    );
};
export default List;
