import dibsCss from 'dibs-css';
import classnames from 'classnames';
import { FunctionComponent } from 'react';

type MakeOfferProps = {
    className?: string;
};

const MakeOffer: FunctionComponent<MakeOfferProps> = props => {
    return (
        <svg
            className={classnames(dibsCss.dibsIcon, props.className)}
            data-tn={'make-offer'}
            viewBox="0 0 250 250"
        >
            <path d="M245.7 112.7L236 99.2c-3.3-4.6-6-13.3-6-18.9V63.5c0-8.5-6.4-17.1-14.4-19.7l-16.1-5.2c-5.4-1.7-12.8-7.1-16.1-11.6l-9.9-13.6C169.9 8.3 163 5 156.2 5c-2.1 0-4 .3-5.8.9l-16 5.1c-2.5.8-6.1 1.3-9.9 1.3-3.7 0-7.4-.5-9.9-1.3l-16-5.2c-1.8-.5-3.7-.8-5.7-.8-6.9 0-13.7 3.3-17.4 8.4L65.7 27c-3.3 4.5-10.6 9.9-16 11.6L34 43.8C26 46.4 20 55 20 63.5v16.8c0 5.6-3.1 14.2-6.4 18.9l-10 13.6c-5 6.8-5 17.5-.1 24.3l10.1 13.5c3.3 4.5 6.4 13.2 6.4 18.8v16.8c0 8.5 6 17.1 14 19.7l15.8 5.2c5.4 1.7 12.7 7.1 16 11.6l9.8 13.6c3.7 5.1 10.5 8.4 17.3 8.4 2.1 0 4-.3 5.8-.9l16-5.2c2.5-.8 6.1-1.3 9.9-1.3 3.8 0 7.4.5 9.9 1.3l16 5.2c1.9.6 3.8.9 5.8.9 6.8 0 13.6-3.3 17.4-8.4l9.8-13.6c3.3-4.5 10.6-9.9 16-11.6l16.1-5.2c8-2.6 14.4-11.3 14.4-19.7v-16.8c0-5.6 2.7-14.2 6-18.9l9.7-13.6c5-6.7 5-17.4 0-24.2zm-7 19.1l-9.4 13.6c-4.4 6-7.3 16.6-7.3 24v16.8c0 4.6-4.3 9.9-8.7 11.3l-16.2 5.2c-7 2.3-16.1 8.8-20.5 14.9l-9.9 13.6c-2 2.8-6.3 4.8-10.3 4.8-1.2 0-2.2-.2-3.1-.5l-16-5.2c-3.4-1.1-7.9-1.7-12.7-1.7-4.8 0-9.3.6-12.6 1.8l-16 5.2c-.9.3-1.9.5-3.1.5-3.9 0-8.2-2-10.2-4.8l-9.8-13.6c-4.4-6-13.3-12.5-20.4-14.9l-16.1-5.2c-4.4-1.4-8.3-6.7-8.3-11.3v-16.8c0-7.5-3.3-18-7.7-24l-9.8-13.6c-2.7-3.7-2.7-10.3 0-14l9.7-13.6c4.4-6 7.7-16.6 7.7-24V63.5c0-4.6 4-9.9 8.3-11.3l16-5.2c7-2.3 16-8.8 20.4-14.9l9.8-13.6c2-2.8 6.2-4.8 10.2-4.8 1.2 0 2.2.2 3.1.5l15.9 5.1c3.4 1.1 7.9 1.7 12.6 1.7 4.8 0 9.3-.6 12.6-1.7l16-5.2c.9-.3 1.9-.5 3.1-.5 3.9 0 8.2 2 10.2 4.8l9.9 13.6c4.4 6 13.3 12.5 20.4 14.9l16.5 5.2c4.4 1.4 8.7 6.7 8.7 11.3v16.8c0 7.5 3 18 7.3 24l9.6 13.6c3 3.7 2.8 10.4.1 14z" />
            <path d="M124.1 76.4l-58.5 58.5 52 52 58.5-58.5 7.5-30.1-29.4-29.4-30.1 7.5zm44.4 47.7l-51 51-40.2-40.2 51-51 23.3-5.8 22.7 22.7-5.8 23.3z" />
            <circle cx="146.7" cy="103.6" r="8.8" />
        </svg>
    );
};
export default MakeOffer;
