/**
 * @generated SignedSource<<92ba739aef196e3abb49c3f9c6171b9d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EstimatedDeliveryDateDisplay_viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"getPostConfDeliveryDateDisplay_viewer" | "shippingTrackingDetails_viewer">;
  readonly " $fragmentType": "EstimatedDeliveryDateDisplay_viewer";
};
export type EstimatedDeliveryDateDisplay_viewer$key = {
  readonly " $data"?: EstimatedDeliveryDateDisplay_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"EstimatedDeliveryDateDisplay_viewer">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "InlineDataFragmentSpread",
  "name": "shippingTrackingDetails_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "LeadTime",
      "kind": "LinkedField",
      "name": "leadTimes",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "endTimeUnit",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "startTimeUnit",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "args": null,
  "argumentDefinitions": ([]/*: any*/)
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EstimatedDeliveryDateDisplay_viewer",
  "selections": [
    (v0/*: any*/),
    {
      "kind": "InlineDataFragmentSpread",
      "name": "getPostConfDeliveryDateDisplay_viewer",
      "selections": [
        (v0/*: any*/)
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
})();

(node as any).hash = "3e60f3159803f1c49ae11b68ef16b724";

export default node;
