import { FC } from 'react';
import { FormattedMessage } from 'dibs-react-intl';

import MessageSentWrapper from './MessageSentWrapper';

const MessageSent: FC = () => {
    return (
        <MessageSentWrapper>
            <FormattedMessage
                id="getHelp.MessageSent.info"
                defaultMessage="A 1stDibs representative will review your message and reach out within <b>2 business days</b>."
                values={{
                    b: content => <b>{content}</b>,
                }}
            />
        </MessageSentWrapper>
    );
};

export default MessageSent;
