import { FC, useState, useCallback, ReactNode } from 'react';
import classnames from 'classnames';

import { Tooltip } from 'dibs-elements/exports/Tooltip';

import styles from './SharedTooltip.scss';

const SharedTooltip: FC<{
    label: ReactNode;
    children?: ReactNode;
    labelBold?: boolean;
    note?: ReactNode | null;
}> = ({ label, children, labelBold, note }) => {
    const [isTooltipOpen, setIsTooltipOpen] = useState(false);

    const showTooltip = useCallback(() => {
        setIsTooltipOpen(true);
    }, []);
    const hideTooltip = useCallback(() => setIsTooltipOpen(false), []);

    return (
        <>
            <span
                className={styles.container}
                onMouseEnter={showTooltip}
                onMouseLeave={hideTooltip}
            >
                <span className={classnames(styles.label, { [styles.bold]: labelBold })}>
                    {label}
                </span>

                <Tooltip
                    isVisible={isTooltipOpen}
                    onClose={() => setIsTooltipOpen(false)}
                    closeOnOutsideClick
                    direction="top"
                >
                    {children}
                </Tooltip>
            </span>
            {note}
        </>
    );
};

export default SharedTooltip;
