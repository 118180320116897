/**
 * @generated SignedSource<<305c9d9150bda598d573c7a0b63c2ebe>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ReturnPolicyEmbed_returnPolicy$data = {
  readonly additionalNotes: string | null;
  readonly buyerCopy: {
    readonly customizableDetailReturnCopy: {
      readonly htmlCopy: string | null;
    } | null;
    readonly detailsAdditionalNotesHeader: {
      readonly htmlCopy: string | null;
    } | null;
    readonly detailsAttribution: {
      readonly htmlCopy: string | null;
    } | null;
    readonly detailsDisclaimer: {
      readonly htmlCopy: string | null;
    } | null;
    readonly detailsRestockingFee: {
      readonly htmlCopy: string | null;
    } | null;
    readonly detailsReturnCopy: {
      readonly htmlCopy: string | null;
    } | null;
    readonly detailsReturnPolicyMainHeader: {
      readonly htmlCopy: string | null;
    } | null;
    readonly detailsShippingResponsibility: {
      readonly htmlCopy: string | null;
    } | null;
    readonly returnsFeesHeader: {
      readonly htmlCopy: string | null;
    } | null;
  } | null;
  readonly name: string | null;
  readonly " $fragmentType": "ReturnPolicyEmbed_returnPolicy";
};
export type ReturnPolicyEmbed_returnPolicy$key = {
  readonly " $data"?: ReturnPolicyEmbed_returnPolicy$data;
  readonly " $fragmentSpreads": FragmentRefs<"ReturnPolicyEmbed_returnPolicy">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "userCountryCode",
    "variableName": "userCountryCode"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "htmlCopy",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "userCountryCode"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReturnPolicyEmbed_returnPolicy",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ReturnPolicyCopy",
      "kind": "LinkedField",
      "name": "buyerCopy",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": (v0/*: any*/),
          "concreteType": "InlineCtaCopy",
          "kind": "LinkedField",
          "name": "detailsReturnCopy",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "InlineCtaCopy",
          "kind": "LinkedField",
          "name": "detailsDisclaimer",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": (v0/*: any*/),
          "concreteType": "InlineCtaCopy",
          "kind": "LinkedField",
          "name": "detailsShippingResponsibility",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "InlineCtaCopy",
          "kind": "LinkedField",
          "name": "detailsAdditionalNotesHeader",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "InlineCtaCopy",
          "kind": "LinkedField",
          "name": "returnsFeesHeader",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": (v0/*: any*/),
          "concreteType": "InlineCtaCopy",
          "kind": "LinkedField",
          "name": "detailsRestockingFee",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "InlineCtaCopy",
          "kind": "LinkedField",
          "name": "detailsReturnPolicyMainHeader",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "InlineCtaCopy",
          "kind": "LinkedField",
          "name": "detailsAttribution",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "InlineCtaCopy",
          "kind": "LinkedField",
          "name": "customizableDetailReturnCopy",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "additionalNotes",
      "storageKey": null
    }
  ],
  "type": "ReturnPolicy",
  "abstractKey": null
};
})();

(node as any).hash = "619f7750bf52d6bd90595e1fb88e35cd";

export default node;
