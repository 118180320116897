/**
 * @generated SignedSource<<2ce8e285d2a54d58734f55c13d11ee08>>
 * @relayHash b7adab0f8419211ffbb2d9f02bf474e3
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID ferrum/491.0.0-2024-12-04T08:42:30.904Z/GetHelpRendererQuery

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GetHelpTopicsHelpType = "checkoutAssistance" | "listings" | "orderIssues" | "otherAssistance" | "%future added value";
export type GetHelpRendererQuery$variables = {
  hasItemId: boolean;
  hasOrderId: boolean;
  hasSellerId: boolean;
  hasUserId: boolean;
  helpType?: GetHelpTopicsHelpType | null;
  isSeller: boolean;
  itemId: string;
  orderId?: string | null;
  sellerId: string;
  topicKey: string;
  transactionId: string;
  userCountryCode?: string | null;
  userId: string;
};
export type GetHelpRendererQuery$data = {
  readonly viewer: {
    readonly contact1stdibsConfig: {
      readonly isAgentAvailable: boolean | null;
      readonly showChannelChat: boolean | null;
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"CheckoutAssistance_viewer" | "IntroStepSeller_viewer" | "IntroStep_viewer" | "OtherAssistance_viewer" | "SelectedListing_viewer" | "SelectedOrder_viewer" | "Topic_viewer">;
  };
};
export type GetHelpRendererQuery = {
  response: GetHelpRendererQuery$data;
  variables: GetHelpRendererQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hasItemId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hasOrderId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hasSellerId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hasUserId"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "helpType"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isSeller"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "itemId"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orderId"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sellerId"
},
v9 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "topicKey"
},
v10 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "transactionId"
},
v11 = {
  "defaultValue": "",
  "kind": "LocalArgument",
  "name": "userCountryCode"
},
v12 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userId"
},
v13 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "isSeller",
      "value": false
    }
  ],
  "concreteType": "Contact1stdibsConfig",
  "kind": "LinkedField",
  "name": "contact1stdibsConfig",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isAgentAvailable",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showChannelChat",
      "storageKey": null
    }
  ],
  "storageKey": "contact1stdibsConfig(isSeller:false)"
},
v14 = {
  "kind": "Variable",
  "name": "helpType",
  "variableName": "helpType"
},
v15 = {
  "kind": "Variable",
  "name": "isSeller",
  "variableName": "isSeller"
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "topicKey",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "concreteType": "GetHelpTopic",
  "kind": "LinkedField",
  "name": "topIssues",
  "plural": true,
  "selections": [
    (v16/*: any*/),
    (v17/*: any*/)
  ],
  "storageKey": null
},
v19 = [
  (v18/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "GetHelpOtherIssues",
    "kind": "LinkedField",
    "name": "otherIssues",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "categoryKey",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "categoryLabel",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "GetHelpTopic",
        "kind": "LinkedField",
        "name": "topics",
        "plural": true,
        "selections": [
          (v17/*: any*/),
          (v16/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v20 = {
  "kind": "Variable",
  "name": "orderId",
  "variableName": "orderId"
},
v21 = [
  (v15/*: any*/),
  (v20/*: any*/),
  {
    "kind": "Variable",
    "name": "topicKey",
    "variableName": "topicKey"
  }
],
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "company",
  "storageKey": null
},
v28 = {
  "kind": "Variable",
  "name": "itemId",
  "variableName": "itemId"
},
v29 = {
  "alias": null,
  "args": [
    (v14/*: any*/),
    (v15/*: any*/),
    (v28/*: any*/),
    (v20/*: any*/)
  ],
  "concreteType": "GetHelpTopics",
  "kind": "LinkedField",
  "name": "getHelpTopics",
  "plural": false,
  "selections": (v19/*: any*/),
  "storageKey": null
},
v30 = [
  {
    "kind": "Variable",
    "name": "transactionId",
    "variableName": "transactionId"
  }
],
v31 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "displayValue",
    "storageKey": null
  }
],
v32 = {
  "alias": null,
  "args": null,
  "concreteType": "Period",
  "kind": "LinkedField",
  "name": "handlingTime",
  "plural": false,
  "selections": (v31/*: any*/),
  "storageKey": null
},
v33 = {
  "alias": null,
  "args": null,
  "concreteType": "Period",
  "kind": "LinkedField",
  "name": "collectionTime",
  "plural": false,
  "selections": (v31/*: any*/),
  "storageKey": null
},
v34 = {
  "alias": null,
  "args": null,
  "concreteType": "ShipmentServiceMethod",
  "kind": "LinkedField",
  "name": "serviceMethod",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "describeRange",
      "storageKey": null
    },
    (v23/*: any*/)
  ],
  "storageKey": null
},
v35 = [
  (v34/*: any*/),
  (v32/*: any*/),
  (v23/*: any*/)
],
v36 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "serviceId",
  "storageKey": null
},
v37 = [
  {
    "kind": "Variable",
    "name": "userCountryCode",
    "variableName": "userCountryCode"
  }
],
v38 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "htmlCopy",
    "storageKey": null
  }
],
v39 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v40 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "isCompleted",
    "storageKey": null
  },
  (v16/*: any*/)
],
v41 = [
  (v16/*: any*/)
],
v42 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v43 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v44 = [
  (v43/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      (v10/*: any*/),
      (v11/*: any*/),
      (v12/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "GetHelpRendererQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v13/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "IntroStep_viewer"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "IntroStepSeller_viewer"
          },
          {
            "condition": "hasOrderId",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "SelectedOrder_viewer"
              }
            ]
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "OtherAssistance_viewer"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Topic_viewer"
          },
          {
            "condition": "isSeller",
            "kind": "Condition",
            "passingValue": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "CheckoutAssistance_viewer"
              }
            ]
          },
          {
            "condition": "hasItemId",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "SelectedListing_viewer"
              }
            ]
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v7/*: any*/),
      (v4/*: any*/),
      (v10/*: any*/),
      (v1/*: any*/),
      (v9/*: any*/),
      (v12/*: any*/),
      (v3/*: any*/),
      (v8/*: any*/),
      (v2/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v0/*: any*/),
      (v11/*: any*/)
    ],
    "kind": "Operation",
    "name": "GetHelpRendererQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v13/*: any*/),
          {
            "alias": "otherAssistanceGetHelpTopics",
            "args": [
              (v14/*: any*/),
              (v15/*: any*/)
            ],
            "concreteType": "GetHelpTopics",
            "kind": "LinkedField",
            "name": "getHelpTopics",
            "plural": false,
            "selections": (v19/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v21/*: any*/),
            "concreteType": "GetHelpTopic",
            "kind": "LinkedField",
            "name": "getHelpTopic",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "showChannelLiveChat",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "showChannelWhatsApp",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "showChannelMessage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "showChannelEmail",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isOtherIssue",
                "storageKey": null
              },
              (v17/*: any*/),
              (v16/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "instructionalText",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "GetHelpTopicTypePrimaryCta",
                "kind": "LinkedField",
                "name": "primaryCta",
                "plural": false,
                "selections": [
                  (v16/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "link",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "supportCenterLink",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "showContactButton",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "showTrackingDetails",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "showReturnPolicy",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "supportRequestTypeId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "helpType",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "LeadTime",
            "kind": "LinkedField",
            "name": "leadTimes",
            "plural": true,
            "selections": [
              (v22/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endTimeUnit",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startTimeUnit",
                "storageKey": null
              },
              (v23/*: any*/)
            ],
            "storageKey": null
          },
          (v23/*: any*/),
          {
            "condition": "hasUserId",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Variable",
                    "name": "userId",
                    "variableName": "userId"
                  }
                ],
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Profile",
                    "kind": "LinkedField",
                    "name": "profile",
                    "plural": false,
                    "selections": [
                      (v24/*: any*/),
                      (v25/*: any*/),
                      (v26/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v23/*: any*/)
                ],
                "storageKey": null
              }
            ]
          },
          {
            "condition": "hasSellerId",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Variable",
                    "name": "sellerId",
                    "variableName": "sellerId"
                  }
                ],
                "concreteType": "Seller",
                "kind": "LinkedField",
                "name": "seller",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SellerProfile",
                    "kind": "LinkedField",
                    "name": "sellerProfile",
                    "plural": false,
                    "selections": [
                      (v27/*: any*/),
                      (v25/*: any*/),
                      (v24/*: any*/),
                      (v26/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v23/*: any*/)
                ],
                "storageKey": null
              }
            ]
          },
          {
            "condition": "hasOrderId",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": null,
                "args": (v21/*: any*/),
                "concreteType": "GetHelpTopic",
                "kind": "LinkedField",
                "name": "getHelpTopic",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "showOrderTimeline",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v29/*: any*/),
              {
                "alias": null,
                "args": (v30/*: any*/),
                "concreteType": "Transaction",
                "kind": "LinkedField",
                "name": "transaction",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ItemSkuType",
                    "kind": "LinkedField",
                    "name": "sku",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "leadTime",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isAvailableNow",
                        "storageKey": null
                      },
                      (v23/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TransactionLeadTime",
                    "kind": "LinkedField",
                    "name": "leadTime",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "value",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasLeadTime",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Shipment",
                    "kind": "LinkedField",
                    "name": "shipment",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "deliveryDateRangeDisplay",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "estimatedShippingDate",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "leadTimeMin",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "leadTimeMax",
                        "storageKey": null
                      },
                      (v32/*: any*/),
                      (v33/*: any*/),
                      (v34/*: any*/),
                      (v23/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ShipmentTracking",
                        "kind": "LinkedField",
                        "name": "shipmentTracking",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "trackingUrlType",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "estimatedDeliveryDate",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "trackingNumber",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "trackingUrl",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Carrier",
                            "kind": "LinkedField",
                            "name": "carrier",
                            "plural": false,
                            "selections": [
                              (v22/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "CarrierMetaData",
                                "kind": "LinkedField",
                                "name": "carrierMetaData",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "websiteUrl",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "customerServiceEmail",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "customerServicePhoneNumber1",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "customerServicePhoneNumber2",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "internallyIntegrated",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              (v23/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "lateByDate",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isBuyerShipping",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TransactionShipmentChangeReview",
                    "kind": "LinkedField",
                    "name": "pendingShipmentChangeReview",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Shipment",
                        "kind": "LinkedField",
                        "name": "shipment",
                        "plural": false,
                        "selections": [
                          (v34/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "shipmentType",
                            "storageKey": null
                          },
                          (v32/*: any*/),
                          (v33/*: any*/),
                          (v23/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ShipmentQuote",
                        "kind": "LinkedField",
                        "name": "shipmentQuote",
                        "plural": false,
                        "selections": (v35/*: any*/),
                        "storageKey": null
                      },
                      (v23/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ShipmentQuote",
                    "kind": "LinkedField",
                    "name": "currentShipmentQuote",
                    "plural": false,
                    "selections": (v35/*: any*/),
                    "storageKey": null
                  },
                  (v36/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isCanceled",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TransactionBuyerTimelineStatuses",
                    "kind": "LinkedField",
                    "name": "buyerTimelineStatuses",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "shipped",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "completed",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v23/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Conversation",
                    "kind": "LinkedField",
                    "name": "conversation",
                    "plural": false,
                    "selections": [
                      (v36/*: any*/),
                      (v23/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Item",
                    "kind": "LinkedField",
                    "name": "item",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Seller",
                        "kind": "LinkedField",
                        "name": "seller",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "SellerProfile",
                            "kind": "LinkedField",
                            "name": "sellerProfile",
                            "plural": false,
                            "selections": [
                              (v27/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v23/*: any*/),
                          (v36/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v23/*: any*/),
                      (v36/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ReturnPolicy",
                    "kind": "LinkedField",
                    "name": "returnPolicy",
                    "plural": false,
                    "selections": [
                      (v22/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ReturnPolicyCopy",
                        "kind": "LinkedField",
                        "name": "buyerCopy",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": (v37/*: any*/),
                            "concreteType": "InlineCtaCopy",
                            "kind": "LinkedField",
                            "name": "detailsReturnCopy",
                            "plural": false,
                            "selections": (v38/*: any*/),
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "InlineCtaCopy",
                            "kind": "LinkedField",
                            "name": "detailsDisclaimer",
                            "plural": false,
                            "selections": (v38/*: any*/),
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": (v37/*: any*/),
                            "concreteType": "InlineCtaCopy",
                            "kind": "LinkedField",
                            "name": "detailsShippingResponsibility",
                            "plural": false,
                            "selections": (v38/*: any*/),
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "InlineCtaCopy",
                            "kind": "LinkedField",
                            "name": "detailsAdditionalNotesHeader",
                            "plural": false,
                            "selections": (v38/*: any*/),
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "InlineCtaCopy",
                            "kind": "LinkedField",
                            "name": "returnsFeesHeader",
                            "plural": false,
                            "selections": (v38/*: any*/),
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": (v37/*: any*/),
                            "concreteType": "InlineCtaCopy",
                            "kind": "LinkedField",
                            "name": "detailsRestockingFee",
                            "plural": false,
                            "selections": (v38/*: any*/),
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "InlineCtaCopy",
                            "kind": "LinkedField",
                            "name": "detailsReturnPolicyMainHeader",
                            "plural": false,
                            "selections": (v38/*: any*/),
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "InlineCtaCopy",
                            "kind": "LinkedField",
                            "name": "detailsAttribution",
                            "plural": false,
                            "selections": (v38/*: any*/),
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "InlineCtaCopy",
                            "kind": "LinkedField",
                            "name": "customizableDetailReturnCopy",
                            "plural": false,
                            "selections": (v38/*: any*/),
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "additionalNotes",
                        "storageKey": null
                      },
                      (v23/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "buyer",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Profile",
                        "kind": "LinkedField",
                        "name": "profile",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "displayName",
                            "storageKey": null
                          },
                          (v25/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v23/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "condition": "hasSellerId",
                    "kind": "Condition",
                    "passingValue": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "buyer",
                        "plural": false,
                        "selections": [
                          (v36/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ]
                  }
                ],
                "storageKey": null
              },
              {
                "condition": "hasOrderId",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "alias": null,
                    "args": (v30/*: any*/),
                    "concreteType": "Transaction",
                    "kind": "LinkedField",
                    "name": "transaction",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Item",
                        "kind": "LinkedField",
                        "name": "item",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": [
                              {
                                "kind": "Literal",
                                "name": "size",
                                "value": "thumb"
                              }
                            ],
                            "kind": "ScalarField",
                            "name": "firstPhotoWebPath",
                            "storageKey": "firstPhotoWebPath(size:\"thumb\")"
                          },
                          (v39/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TransactionBuyerTimelineSteps",
                        "kind": "LinkedField",
                        "name": "buyerTimelineSteps",
                        "plural": true,
                        "selections": (v40/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TransactionSellerTimelineSteps",
                        "kind": "LinkedField",
                        "name": "sellerTimelineSteps",
                        "plural": true,
                        "selections": (v40/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TransactionCurrentBuyerTimelineStatus",
                        "kind": "LinkedField",
                        "name": "currentBuyerTimelineStatus",
                        "plural": false,
                        "selections": (v41/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TransactionCurrentSellerTimelineStatus",
                        "kind": "LinkedField",
                        "name": "currentSellerTimelineStatus",
                        "plural": false,
                        "selections": (v41/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TransactionHistory",
                        "kind": "LinkedField",
                        "name": "transactionHistory",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "HistoricalEvent",
                            "kind": "LinkedField",
                            "name": "historicalEvents",
                            "plural": true,
                            "selections": [
                              (v22/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "buyerCopy",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": [
                                  {
                                    "kind": "Literal",
                                    "name": "isBomtOptimizationVariant",
                                    "value": true
                                  }
                                ],
                                "concreteType": "buyerTransactionStatus",
                                "kind": "LinkedField",
                                "name": "buyerStatusCopy",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "header",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": "buyerStatusCopy(isBomtOptimizationVariant:true)"
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Return",
                        "kind": "LinkedField",
                        "name": "returns",
                        "plural": true,
                        "selections": [
                          (v36/*: any*/),
                          (v23/*: any*/),
                          (v42/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "showReturnDownloadShippingLabel",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ShipmentQuote",
                            "kind": "LinkedField",
                            "name": "shipmentQuote",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "isBuyerAccepted",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "isDealerAccepted",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "FinancialAmount",
                                "kind": "LinkedField",
                                "name": "subTotalAmount",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ConvertedAmountList",
                                    "kind": "LinkedField",
                                    "name": "convertedAmountList",
                                    "plural": true,
                                    "selections": [
                                      (v43/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "currency",
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              (v23/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Shipment",
                            "kind": "LinkedField",
                            "name": "shipment",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "hasUnknownShipmentTracking",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "isPreTransit",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ShipmentTracking",
                                "kind": "LinkedField",
                                "name": "shipmentTracking",
                                "plural": false,
                                "selections": [
                                  (v42/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "labelUrl",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Carrier",
                                    "kind": "LinkedField",
                                    "name": "carrier",
                                    "plural": false,
                                    "selections": [
                                      (v22/*: any*/),
                                      (v23/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              (v23/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isCheckoutProcessing",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "didCheckoutProcessingFail",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TransactionSummaryObject",
                        "kind": "LinkedField",
                        "name": "summary",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "FinancialAmount",
                            "kind": "LinkedField",
                            "name": "balanceDueAmount",
                            "plural": false,
                            "selections": (v44/*: any*/),
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "PaymentAuthorization",
                            "kind": "LinkedField",
                            "name": "checkoutPaymentAuthorization",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "paymentMethod",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "__typename",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "paymentMethodType",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              (v23/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "InvoiceSummary",
                            "kind": "LinkedField",
                            "name": "invoiceSummary",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "FinancialAmount",
                                "kind": "LinkedField",
                                "name": "amountToSettleBuyerCurrentInvoice",
                                "plural": false,
                                "selections": (v44/*: any*/),
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": "acceptQuote",
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "actionCode",
                            "value": "BUYER_APPROVE_RETURN_QUOTE"
                          }
                        ],
                        "kind": "ScalarField",
                        "name": "hasAction",
                        "storageKey": "hasAction(actionCode:\"BUYER_APPROVE_RETURN_QUOTE\")"
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "buyerCurrency",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PaymentAuthorization",
                        "kind": "LinkedField",
                        "name": "paymentAuthorizations",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "PaymentProviderTransaction",
                            "kind": "LinkedField",
                            "name": "providerTransaction",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "PaymentProviderTransactionResponse",
                                "kind": "LinkedField",
                                "name": "responses",
                                "plural": true,
                                "selections": [
                                  (v42/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v23/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ]
              }
            ]
          },
          {
            "condition": "hasItemId",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "condition": "hasItemId",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "alias": null,
                    "args": [
                      (v28/*: any*/)
                    ],
                    "concreteType": "Item",
                    "kind": "LinkedField",
                    "name": "item",
                    "plural": false,
                    "selections": [
                      (v39/*: any*/),
                      {
                        "alias": null,
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "size",
                            "value": "small"
                          }
                        ],
                        "kind": "ScalarField",
                        "name": "firstPhotoWebPath",
                        "storageKey": "firstPhotoWebPath(size:\"small\")"
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "dealerReference",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "dibsReference",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "dealerStatusDisplay",
                        "storageKey": null
                      },
                      (v23/*: any*/)
                    ],
                    "storageKey": null
                  }
                ]
              },
              (v29/*: any*/)
            ]
          },
          {
            "condition": "isSeller",
            "kind": "Condition",
            "passingValue": false,
            "selections": [
              {
                "alias": "checkoutAssistanceGetHelpTopics",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "helpType",
                    "value": "checkoutAssistance"
                  }
                ],
                "concreteType": "GetHelpTopics",
                "kind": "LinkedField",
                "name": "getHelpTopics",
                "plural": false,
                "selections": [
                  (v18/*: any*/)
                ],
                "storageKey": "getHelpTopics(helpType:\"checkoutAssistance\")"
              }
            ]
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "ferrum/491.0.0-2024-12-04T08:42:30.904Z/GetHelpRendererQuery",
    "metadata": {},
    "name": "GetHelpRendererQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "2b16688055fd31cc5201cb27aae6bf29";

export default node;
