import { FC, useContext } from 'react';
import classnames from 'classnames';
import { useFragment, graphql } from 'react-relay';
import { FormattedMessage } from 'dibs-react-intl';

import { Button } from 'dibs-elements/exports/Button';
import { Link } from 'dibs-elements/exports/Link';
import TrackingDetails from './TrackingDetails';
import OpenConversationCta from './OpenConversationCta';
import ItemReturnPolicy from './ItemReturnPolicy';
import UpdatedContactSellerForm from './UpdatedContactSellerForm';

import GetHelpContext from './GetHelpContext';
import { trackPrimaryCtaClick, trackSupportCenterClick } from './tracking';
import { HELP_TYPE_OTHER_ASSISTANCE } from './constants';
import { isSellerFacilitatedSupportTestVariant } from './abTestHelpers';

import { TopicInfo_viewer$key } from './__generated__/TopicInfo_viewer.graphql';

import styles from './styles/TopicInfo.scss';

const TopicInfo: FC<{
    viewer: TopicInfo_viewer$key | null | undefined;
    setShowOtherHelp: (value: boolean) => void;
    showOtherHelp?: boolean;
}> = ({ viewer: viewerRef, setShowOtherHelp, showOtherHelp }) => {
    const {
        state: { helpType, orderId, isSeller },
    } = useContext(GetHelpContext);

    const viewer = useFragment(
        graphql`
            fragment TopicInfo_viewer on Viewer {
                ...TrackingDetails_viewer
                ...OpenConversationCta_viewer
                ...ItemReturnPolicy_viewer
                getHelpTopic(topicKey: $topicKey, orderId: $orderId, isSeller: $isSeller) {
                    topicKey
                    label
                    instructionalText
                    primaryCta {
                        label
                        link
                    }
                    supportCenterLink
                    showContactButton
                    showTrackingDetails
                    showReturnPolicy
                    ...UpdatedContactSellerForm_getHelpTopic
                }
                transaction(transactionId: $transactionId) @include(if: $hasOrderId) {
                    ...UpdatedContactSellerForm_transaction
                }
            }
        `,
        viewerRef
    );

    const { getHelpTopic, transaction } = viewer || {};
    const {
        topicKey,
        label,
        instructionalText,
        primaryCta,
        supportCenterLink,
        showContactButton,
        showTrackingDetails,
        showReturnPolicy,
    } = getHelpTopic || {};
    const { label: primaryCtaLabel, link: primaryCtaLink } = primaryCta || {};

    const isSellerFacilitatedSupportVariant = isSellerFacilitatedSupportTestVariant();

    return (
        <>
            {helpType === HELP_TYPE_OTHER_ASSISTANCE && (
                <div
                    data-tn="get-help-modal-topic-info-label"
                    className={classnames(styles.title, styles.titleTop)}
                >
                    {label}
                </div>
            )}
            <div className={styles.container}>
                {showReturnPolicy && orderId && <ItemReturnPolicy viewer={viewer} />}
                {helpType !== HELP_TYPE_OTHER_ASSISTANCE && (
                    <div data-tn="get-help-modal-topic-info-label" className={styles.title}>
                        {label}
                    </div>
                )}
                <div
                    data-tn="get-help-modal-topic-info-intructional-text"
                    className={styles.instructionalText}
                    dangerouslySetInnerHTML={{
                        __html: instructionalText || '',
                    }}
                />

                {showTrackingDetails && helpType !== HELP_TYPE_OTHER_ASSISTANCE && (
                    <TrackingDetails viewer={viewer} isSeller={isSeller} />
                )}
                {primaryCta && (
                    <Button
                        dataTn="get-help-modal-primary-cta"
                        href={primaryCtaLink || ''}
                        onClick={() => trackPrimaryCtaClick(topicKey || '')}
                        className={styles.primaryCta}
                        opensInNewWindow
                    >
                        {primaryCtaLabel}
                    </Button>
                )}
                {showContactButton && (!isSellerFacilitatedSupportVariant || isSeller) && (
                    <OpenConversationCta
                        viewer={viewer}
                        showOtherHelp={showOtherHelp}
                        setShowOtherHelp={setShowOtherHelp}
                    />
                )}
                {supportCenterLink && (
                    <Link
                        dataTn="get-help-modal-support-center-link"
                        href={supportCenterLink || ''}
                        target="_blank"
                        className={styles.supportCenterLink}
                        onClick={() => trackSupportCenterClick(topicKey || '')}
                    >
                        <FormattedMessage
                            id="getHelp.TopicInfo.supportCenterLink"
                            defaultMessage="Learn more on Support Center"
                        />
                    </Link>
                )}
                {showContactButton && isSellerFacilitatedSupportVariant && (
                    <UpdatedContactSellerForm
                        transaction={transaction || null}
                        getHelpTopic={getHelpTopic || null}
                    />
                )}
            </div>
        </>
    );
};

export default TopicInfo;
