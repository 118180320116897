import { FC, ReactNode, useContext } from 'react';
import { FormattedMessage } from 'dibs-react-intl';

import { BarHeader } from 'dibs-elements/exports/BarHeader';
import { Button } from 'dibs-elements/exports/Button';
import CircleCheck from 'dibs-icons/exports/legacy/CircleCheck';

import GetHelpContext from './GetHelpContext';

import styles from './styles/MessageSentWrapper.scss';

const MessageSentWrapper: FC<{ children: ReactNode }> = ({ children }) => {
    const {
        state: { onClose, isSeller, isEmbedded },
    } = useContext(GetHelpContext);

    return (
        <div className={styles.container}>
            <div className={styles.contentWrapper}>
                <BarHeader
                    title={
                        <div className={styles.header}>
                            <CircleCheck className={styles.icon} />
                            <FormattedMessage
                                id="getHelp.MessageSentWrapper.header"
                                defaultMessage="Message Sent"
                            />
                        </div>
                    }
                />
                <div className={styles.info}>{children}</div>
            </div>
            {isSeller && !isEmbedded && (
                <Button
                    dataTn="get-help-modal-back-to-dashboard"
                    onClick={onClose}
                    className={styles.button}
                >
                    <FormattedMessage
                        id="getHelp.MessageSentWrapper.backToDashboard"
                        defaultMessage="Back to dashboard"
                    />
                </Button>
            )}
            {!isSeller && (
                <Button
                    dataTn="get-help-modal-continue-shopping-cta"
                    onClick={onClose}
                    className={styles.button}
                >
                    <FormattedMessage
                        id="getHelp.MessageSentWrapper.continueShoppingCta"
                        defaultMessage="Continue shopping"
                    />
                </Button>
            )}
        </div>
    );
};

export default MessageSentWrapper;
