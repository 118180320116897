/**
 * @generated SignedSource<<8d0d4d5aca32e51a38886408ff0f68a6>>
 * @relayHash 3630e19187e3c24e13d087e758565bfe
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID ferrum/491.0.0-2024-12-04T08:42:30.904Z/GetHelpZendeskModalRendererQuery

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GetHelpZendeskModalRendererQuery$variables = {
  articleId: string;
  hasArticleId: boolean;
  hasOrderId: boolean;
  hasSearchQuery: boolean;
  hasSellerId: boolean;
  hasUserId: boolean;
  isCheckout?: boolean | null;
  isSeller?: boolean | null;
  searchQuery?: string | null;
  sellerId: string;
  transactionId: string;
  userId: string;
};
export type GetHelpZendeskModalRendererQuery$data = {
  readonly viewer: {
    readonly contact1stdibsConfig: {
      readonly isAgentAvailable: boolean | null;
      readonly showChannelChat: boolean | null;
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"GetHelpZendeskArticle_viewer" | "GetHelpZendeskArticlesNoSearchResults_viewer" | "GetHelpZendeskArticlesSearchResults_viewer" | "GetHelpZendeskContactSupport_viewer" | "GetHelpZendeskFooter_viewer" | "GetHelpZendeskModalHeader_viewer" | "GetHelpZendeskModalIntro_viewer" | "GetHelpZendeskOrderInfo_viewer">;
  };
};
export type GetHelpZendeskModalRendererQuery = {
  response: GetHelpZendeskModalRendererQuery$data;
  variables: GetHelpZendeskModalRendererQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "articleId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hasArticleId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hasOrderId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hasSearchQuery"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hasSellerId"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hasUserId"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isCheckout"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isSeller"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "searchQuery"
},
v9 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sellerId"
},
v10 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "transactionId"
},
v11 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userId"
},
v12 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "isSeller",
      "variableName": "isSeller"
    }
  ],
  "concreteType": "Contact1stdibsConfig",
  "kind": "LinkedField",
  "name": "contact1stdibsConfig",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isAgentAvailable",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showChannelChat",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "serviceId",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v19 = [
  (v17/*: any*/),
  (v18/*: any*/),
  (v16/*: any*/)
],
v20 = [
  {
    "kind": "Variable",
    "name": "articleId",
    "variableName": "articleId"
  }
],
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v22 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "displayValue",
    "storageKey": null
  }
],
v23 = {
  "alias": null,
  "args": null,
  "concreteType": "Period",
  "kind": "LinkedField",
  "name": "handlingTime",
  "plural": false,
  "selections": (v22/*: any*/),
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "concreteType": "Period",
  "kind": "LinkedField",
  "name": "collectionTime",
  "plural": false,
  "selections": (v22/*: any*/),
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "concreteType": "ShipmentServiceMethod",
  "kind": "LinkedField",
  "name": "serviceMethod",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "describeRange",
      "storageKey": null
    },
    (v16/*: any*/)
  ],
  "storageKey": null
},
v26 = [
  (v25/*: any*/),
  (v23/*: any*/),
  (v16/*: any*/)
],
v27 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v28 = [
  (v27/*: any*/)
],
v29 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v30 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v31 = [
  (v30/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      (v10/*: any*/),
      (v11/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "GetHelpZendeskModalRendererQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "GetHelpZendeskModalIntro_viewer"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "GetHelpZendeskOrderInfo_viewer"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "GetHelpZendeskArticle_viewer"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "GetHelpZendeskModalHeader_viewer"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "GetHelpZendeskArticlesSearchResults_viewer"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "GetHelpZendeskArticlesNoSearchResults_viewer"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "GetHelpZendeskContactSupport_viewer"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "GetHelpZendeskFooter_viewer"
          },
          (v12/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      (v10/*: any*/),
      (v11/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Operation",
    "name": "GetHelpZendeskModalRendererQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "condition": "hasUserId",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Variable",
                    "name": "userId",
                    "variableName": "userId"
                  }
                ],
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Profile",
                    "kind": "LinkedField",
                    "name": "profile",
                    "plural": false,
                    "selections": [
                      (v13/*: any*/),
                      (v14/*: any*/),
                      (v15/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v16/*: any*/)
                ],
                "storageKey": null
              }
            ]
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Contact1stdibsZendeskArticles",
            "kind": "LinkedField",
            "name": "zendeskArticles",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Variable",
                    "name": "isCheckout",
                    "variableName": "isCheckout"
                  }
                ],
                "concreteType": "ZendeskArticle",
                "kind": "LinkedField",
                "name": "promotedArticles",
                "plural": true,
                "selections": (v19/*: any*/),
                "storageKey": null
              },
              {
                "alias": "articlesSearch",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "query",
                    "value": ""
                  }
                ],
                "concreteType": "ZendeskArticle",
                "kind": "LinkedField",
                "name": "articles",
                "plural": true,
                "selections": (v19/*: any*/),
                "storageKey": "articles(query:\"\")"
              },
              {
                "condition": "hasArticleId",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "alias": null,
                    "args": (v20/*: any*/),
                    "concreteType": "ZendeskArticle",
                    "kind": "LinkedField",
                    "name": "article",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "body",
                        "storageKey": null
                      },
                      (v18/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "url",
                        "storageKey": null
                      },
                      (v16/*: any*/)
                    ],
                    "storageKey": null
                  }
                ]
              },
              {
                "condition": "hasSearchQuery",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Variable",
                        "name": "query",
                        "variableName": "searchQuery"
                      }
                    ],
                    "concreteType": "ZendeskArticle",
                    "kind": "LinkedField",
                    "name": "articles",
                    "plural": true,
                    "selections": [
                      (v17/*: any*/),
                      (v18/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "snippet",
                        "storageKey": null
                      },
                      (v16/*: any*/)
                    ],
                    "storageKey": null
                  }
                ]
              },
              {
                "alias": null,
                "args": (v20/*: any*/),
                "kind": "ScalarField",
                "name": "topicKeyForArticle",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "LeadTime",
            "kind": "LinkedField",
            "name": "leadTimes",
            "plural": true,
            "selections": [
              (v21/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endTimeUnit",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startTimeUnit",
                "storageKey": null
              },
              (v16/*: any*/)
            ],
            "storageKey": null
          },
          {
            "condition": "hasOrderId",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Variable",
                    "name": "transactionId",
                    "variableName": "transactionId"
                  }
                ],
                "concreteType": "Transaction",
                "kind": "LinkedField",
                "name": "transaction",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ItemSkuType",
                    "kind": "LinkedField",
                    "name": "sku",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "leadTime",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isAvailableNow",
                        "storageKey": null
                      },
                      (v16/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TransactionLeadTime",
                    "kind": "LinkedField",
                    "name": "leadTime",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "value",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasLeadTime",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Shipment",
                    "kind": "LinkedField",
                    "name": "shipment",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "deliveryDateRangeDisplay",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "estimatedShippingDate",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "leadTimeMin",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "leadTimeMax",
                        "storageKey": null
                      },
                      (v23/*: any*/),
                      (v24/*: any*/),
                      (v25/*: any*/),
                      (v16/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ShipmentTracking",
                        "kind": "LinkedField",
                        "name": "shipmentTracking",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "trackingUrlType",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "estimatedDeliveryDate",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "trackingNumber",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "trackingUrl",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Carrier",
                            "kind": "LinkedField",
                            "name": "carrier",
                            "plural": false,
                            "selections": [
                              (v21/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "CarrierMetaData",
                                "kind": "LinkedField",
                                "name": "carrierMetaData",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "websiteUrl",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "customerServiceEmail",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "customerServicePhoneNumber1",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "customerServicePhoneNumber2",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "internallyIntegrated",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              (v16/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "lateByDate",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isBuyerShipping",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TransactionShipmentChangeReview",
                    "kind": "LinkedField",
                    "name": "pendingShipmentChangeReview",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Shipment",
                        "kind": "LinkedField",
                        "name": "shipment",
                        "plural": false,
                        "selections": [
                          (v25/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "shipmentType",
                            "storageKey": null
                          },
                          (v23/*: any*/),
                          (v24/*: any*/),
                          (v16/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ShipmentQuote",
                        "kind": "LinkedField",
                        "name": "shipmentQuote",
                        "plural": false,
                        "selections": (v26/*: any*/),
                        "storageKey": null
                      },
                      (v16/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ShipmentQuote",
                    "kind": "LinkedField",
                    "name": "currentShipmentQuote",
                    "plural": false,
                    "selections": (v26/*: any*/),
                    "storageKey": null
                  },
                  (v17/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isCanceled",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TransactionBuyerTimelineStatuses",
                    "kind": "LinkedField",
                    "name": "buyerTimelineStatuses",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "shipped",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "completed",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v16/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Item",
                    "kind": "LinkedField",
                    "name": "item",
                    "plural": false,
                    "selections": [
                      (v18/*: any*/),
                      {
                        "alias": null,
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "size",
                            "value": "small"
                          }
                        ],
                        "kind": "ScalarField",
                        "name": "firstPhotoWebPath",
                        "storageKey": "firstPhotoWebPath(size:\"small\")"
                      },
                      (v16/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TransactionCurrentBuyerTimelineStatus",
                    "kind": "LinkedField",
                    "name": "currentBuyerTimelineStatus",
                    "plural": false,
                    "selections": (v28/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TransactionCurrentSellerTimelineStatus",
                    "kind": "LinkedField",
                    "name": "currentSellerTimelineStatus",
                    "plural": false,
                    "selections": (v28/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TransactionHistory",
                    "kind": "LinkedField",
                    "name": "transactionHistory",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "HistoricalEvent",
                        "kind": "LinkedField",
                        "name": "historicalEvents",
                        "plural": true,
                        "selections": [
                          (v21/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "buyerCopy",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": [
                              {
                                "kind": "Literal",
                                "name": "isBomtOptimizationVariant",
                                "value": true
                              }
                            ],
                            "concreteType": "buyerTransactionStatus",
                            "kind": "LinkedField",
                            "name": "buyerStatusCopy",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "header",
                                "storageKey": null
                              }
                            ],
                            "storageKey": "buyerStatusCopy(isBomtOptimizationVariant:true)"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Return",
                    "kind": "LinkedField",
                    "name": "returns",
                    "plural": true,
                    "selections": [
                      (v17/*: any*/),
                      (v16/*: any*/),
                      (v29/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "showReturnDownloadShippingLabel",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ShipmentQuote",
                        "kind": "LinkedField",
                        "name": "shipmentQuote",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isBuyerAccepted",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isDealerAccepted",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "FinancialAmount",
                            "kind": "LinkedField",
                            "name": "subTotalAmount",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ConvertedAmountList",
                                "kind": "LinkedField",
                                "name": "convertedAmountList",
                                "plural": true,
                                "selections": [
                                  (v30/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "currency",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v16/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Shipment",
                        "kind": "LinkedField",
                        "name": "shipment",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hasUnknownShipmentTracking",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isPreTransit",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ShipmentTracking",
                            "kind": "LinkedField",
                            "name": "shipmentTracking",
                            "plural": false,
                            "selections": [
                              (v29/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "labelUrl",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Carrier",
                                "kind": "LinkedField",
                                "name": "carrier",
                                "plural": false,
                                "selections": [
                                  (v21/*: any*/),
                                  (v16/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v16/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isCheckoutProcessing",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "didCheckoutProcessingFail",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TransactionSummaryObject",
                    "kind": "LinkedField",
                    "name": "summary",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "FinancialAmount",
                        "kind": "LinkedField",
                        "name": "balanceDueAmount",
                        "plural": false,
                        "selections": (v31/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PaymentAuthorization",
                        "kind": "LinkedField",
                        "name": "checkoutPaymentAuthorization",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "paymentMethod",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "__typename",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "paymentMethodType",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v16/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "InvoiceSummary",
                        "kind": "LinkedField",
                        "name": "invoiceSummary",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "FinancialAmount",
                            "kind": "LinkedField",
                            "name": "amountToSettleBuyerCurrentInvoice",
                            "plural": false,
                            "selections": (v31/*: any*/),
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": "acceptQuote",
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "actionCode",
                        "value": "BUYER_APPROVE_RETURN_QUOTE"
                      }
                    ],
                    "kind": "ScalarField",
                    "name": "hasAction",
                    "storageKey": "hasAction(actionCode:\"BUYER_APPROVE_RETURN_QUOTE\")"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "buyerCurrency",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PaymentAuthorization",
                    "kind": "LinkedField",
                    "name": "paymentAuthorizations",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PaymentProviderTransaction",
                        "kind": "LinkedField",
                        "name": "providerTransaction",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "PaymentProviderTransactionResponse",
                            "kind": "LinkedField",
                            "name": "responses",
                            "plural": true,
                            "selections": [
                              (v29/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v16/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ]
          },
          {
            "alias": "trackOrderGetHelpTopic",
            "args": [
              {
                "kind": "Literal",
                "name": "topicKey",
                "value": "trackOrder"
              }
            ],
            "concreteType": "GetHelpTopic",
            "kind": "LinkedField",
            "name": "getHelpTopic",
            "plural": false,
            "selections": [
              (v27/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "instructionalText",
                "storageKey": null
              }
            ],
            "storageKey": "getHelpTopic(topicKey:\"trackOrder\")"
          },
          (v12/*: any*/),
          {
            "condition": "hasSellerId",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Variable",
                    "name": "sellerId",
                    "variableName": "sellerId"
                  }
                ],
                "concreteType": "Seller",
                "kind": "LinkedField",
                "name": "seller",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SellerProfile",
                    "kind": "LinkedField",
                    "name": "sellerProfile",
                    "plural": false,
                    "selections": [
                      (v14/*: any*/),
                      (v13/*: any*/),
                      (v15/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v16/*: any*/)
                ],
                "storageKey": null
              }
            ]
          },
          (v16/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "ferrum/491.0.0-2024-12-04T08:42:30.904Z/GetHelpZendeskModalRendererQuery",
    "metadata": {},
    "name": "GetHelpZendeskModalRendererQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "4c99adc7997cadc70612bfe23bf5ef38";

export default node;
