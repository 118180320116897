import dibsCss from 'dibs-css';
import classnames from 'classnames';
import { FunctionComponent } from 'react';

type ReturnArrowProps = {
    className?: string;
};

const ReturnArrow: FunctionComponent<ReturnArrowProps> = props => {
    return (
        <svg
            className={classnames(dibsCss.dibsIcon, props.className)}
            data-tn={'return-arrow'}
            viewBox="0 0 250 250"
        >
            <path d="M230.7 21C218.3 7.3 194.3 7.3 188.8 7H69v10h120.4c.2 0 21.6-.3 33.5 10.5 6.8 6.2 10.2 15.4 10.2 27v103.6c0 .1.1 7.6-5.1 15.3-7.2 10.7-21.2 16.8-40.5 17.5H20.1L53 149.5l-7.8-6.2L4.1 195l40.6 51.8 7.9-6.2-31-39.6h166.1c15.8-.6 37.1-4.9 48.5-22 6.7-10 6.8-19.9 6.8-21.1V54.5c-.7-16.8-5-25.3-12.3-33.5z" />
        </svg>
    );
};
export default ReturnArrow;
