import { FC, useContext } from 'react';
import classnames from 'classnames';
import { useFragment, graphql } from 'react-relay';
import SV from 'server-vars';

import { FormattedMessage } from 'dibs-react-intl';
import { Link } from 'dibs-elements/exports/Link';
import ArrowLeft from 'dibs-icons/exports/legacy/ArrowLeft';
import { ModalCloseButton } from 'dibs-elements/exports/ModalCloseButton';
import { GetHelpZendeskArticlesSearch } from './GetHelpZendeskArticlesSearch';

import { GetHelpZendeskContext } from './GetHelpZendeskContext';
import { ACTIONS, STEPS } from './GetHelpZendeskConstants';
import { Step } from './GetHelpZendeskTypes';

import { GetHelpZendeskModalHeader_viewer$key } from './__generated__/GetHelpZendeskModalHeader_viewer.graphql';

import dibsCss from 'dibs-css';
import styles from './GetHelpZendeskModalHeader.scss';

const isDesktop = SV.get('settings.isDesktop');

const GetHelpZendeskModalHeader: FC<{
    viewer: GetHelpZendeskModalHeader_viewer$key;
}> = ({ viewer: viewerRef }) => {
    const {
        state: { onClose, step },
        dispatch,
    } = useContext(GetHelpZendeskContext);

    const viewer = useFragment(
        graphql`
            fragment GetHelpZendeskModalHeader_viewer on Viewer {
                ...GetHelpZendeskArticlesSearch_viewer
            }
        `,
        viewerRef
    );

    const showSearch =
        step &&
        (
            [
                STEPS.ORDER_INFO,
                STEPS.ARTICLE,
                STEPS.SEARCH_RESULTS,
                STEPS.NO_SEARCH_RESULTS,
            ] as Step[]
        ).includes(step);

    return (
        <div
            className={classnames(
                styles.container,
                dibsCss.borderB,
                dibsCss.borderBSolid,
                dibsCss.borderDolphin,
                dibsCss.pxSmall,
                dibsCss.sticky,
                dibsCss.bgWhite,
                dibsCss.top0,
                dibsCss.zBump,
                {
                    [dibsCss.borderB0]: step === STEPS.INTRO,
                    [dibsCss.mbMedium]: step !== STEPS.INTRO,
                    [styles.isDraggable]: isDesktop,
                }
            )}
        >
            <div className={classnames(dibsCss.flex, dibsCss.itemsCenter, dibsCss.minH60px)}>
                {step !== STEPS.INTRO && (
                    <>
                        <Link
                            onClick={() => {
                                dispatch({ type: ACTIONS.GO_BACK });
                            }}
                        >
                            <ArrowLeft className={classnames(dibsCss.hSmall, dibsCss.wSmall)} />
                        </Link>
                        <span
                            className={classnames(dibsCss.sassyFontBodyLargeHeavy, dibsCss.mAuto)}
                        >
                            <FormattedMessage
                                id="getHelp.GetHelpZendeskModalHeader.title"
                                defaultMessage="Get Help"
                            />
                        </span>
                    </>
                )}
                <ModalCloseButton onClick={() => onClose()} className={styles.closeButton} />
            </div>
            {showSearch && (
                <div className={dibsCss.mbSmall}>
                    <GetHelpZendeskArticlesSearch viewer={viewer} />
                </div>
            )}
        </div>
    );
};
export { GetHelpZendeskModalHeader };
