import { FC } from 'react';
import { useFragment, graphql } from 'react-relay';

import GetHelpHeader from './GetHelpHeader';
import PopularArticles from './PopularArticles';
import OtherTopics from './OtherTopics';
import OrderInfo from './OrderInfo';

import { SelectedOrder_viewer$key } from './__generated__/SelectedOrder_viewer.graphql';

const SelectedOrder: FC<{ viewer: SelectedOrder_viewer$key }> = ({ viewer: viewerRef }) => {
    const viewer = useFragment(
        graphql`
            fragment SelectedOrder_viewer on Viewer {
                ...OrderInfo_viewer
                getHelpTopics(
                    orderId: $orderId
                    helpType: $helpType
                    isSeller: $isSeller
                    itemId: $itemId
                ) {
                    ...PopularArticles_getHelpTopics
                    ...OtherTopics_getHelpTopics
                }
            }
        `,
        viewerRef
    );

    const helpTopics = viewer.getHelpTopics;

    return (
        <div>
            <GetHelpHeader />
            <OrderInfo viewer={viewer} />
            <PopularArticles getHelpTopics={helpTopics} />
            <OtherTopics getHelpTopics={helpTopics} />
        </div>
    );
};

export default SelectedOrder;
