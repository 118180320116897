/**
 * @generated SignedSource<<b5bf6582363bc9a69ac4d652e8648232>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GetHelpZendeskModalHeader_viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"GetHelpZendeskArticlesSearch_viewer">;
  readonly " $fragmentType": "GetHelpZendeskModalHeader_viewer";
};
export type GetHelpZendeskModalHeader_viewer$key = {
  readonly " $data"?: GetHelpZendeskModalHeader_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"GetHelpZendeskModalHeader_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GetHelpZendeskModalHeader_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GetHelpZendeskArticlesSearch_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "f0aeddabad11245f7b988bd7214bedf6";

export default node;
