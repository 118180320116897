/**
 * @generated SignedSource<<72c0704684271d3a4f89745db181308d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EstimatedShippingDateDisplay_transaction$data = {
  readonly " $fragmentSpreads": FragmentRefs<"shippingTrackingDetails_transaction">;
  readonly " $fragmentType": "EstimatedShippingDateDisplay_transaction";
};
export type EstimatedShippingDateDisplay_transaction$key = {
  readonly " $data"?: EstimatedShippingDateDisplay_transaction$data;
  readonly " $fragmentSpreads": FragmentRefs<"EstimatedShippingDateDisplay_transaction">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "displayValue",
    "storageKey": null
  }
],
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "Period",
  "kind": "LinkedField",
  "name": "handlingTime",
  "plural": false,
  "selections": (v0/*: any*/),
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "Period",
  "kind": "LinkedField",
  "name": "collectionTime",
  "plural": false,
  "selections": (v0/*: any*/),
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "ShipmentServiceMethod",
  "kind": "LinkedField",
  "name": "serviceMethod",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "describeRange",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = [
  (v3/*: any*/),
  (v1/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EstimatedShippingDateDisplay_transaction",
  "selections": [
    {
      "kind": "InlineDataFragmentSpread",
      "name": "shippingTrackingDetails_transaction",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ItemSkuType",
          "kind": "LinkedField",
          "name": "sku",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "leadTime",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isAvailableNow",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "TransactionLeadTime",
          "kind": "LinkedField",
          "name": "leadTime",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "value",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasLeadTime",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Shipment",
          "kind": "LinkedField",
          "name": "shipment",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "deliveryDateRangeDisplay",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "estimatedShippingDate",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "leadTimeMin",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "leadTimeMax",
              "storageKey": null
            },
            (v1/*: any*/),
            (v2/*: any*/),
            (v3/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "TransactionShipmentChangeReview",
          "kind": "LinkedField",
          "name": "pendingShipmentChangeReview",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Shipment",
              "kind": "LinkedField",
              "name": "shipment",
              "plural": false,
              "selections": [
                (v3/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "shipmentType",
                  "storageKey": null
                },
                (v1/*: any*/),
                (v2/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ShipmentQuote",
              "kind": "LinkedField",
              "name": "shipmentQuote",
              "plural": false,
              "selections": (v4/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ShipmentQuote",
          "kind": "LinkedField",
          "name": "currentShipmentQuote",
          "plural": false,
          "selections": (v4/*: any*/),
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "Transaction",
  "abstractKey": null
};
})();

(node as any).hash = "0d037a592517624f8a714ead265419a3";

export default node;
