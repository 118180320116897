import { FC } from 'react';
import { useFragment, graphql } from 'react-relay';
import { FormattedMessage } from 'dibs-react-intl';

import { getShippingTrackingDetails } from '../helpers/shippingTrackingDetails';
import { getPostConfDeliveryDateDisplay } from '../helpers/getPostConfDeliveryDateDisplay';

import SharedTooltip from './SharedTooltip';

import { EstimatedDeliveryDateDisplay_transaction$key } from './__generated__/EstimatedDeliveryDateDisplay_transaction.graphql';
import { EstimatedDeliveryDateDisplay_viewer$key } from './__generated__/EstimatedDeliveryDateDisplay_viewer.graphql';

import dibsCss from 'dibs-css';
import styles from './SharedDateDislaySyles.scss';

const EstimatedDeliveryDateDisplay: FC<{
    transaction: EstimatedDeliveryDateDisplay_transaction$key | null | undefined;
    viewer: EstimatedDeliveryDateDisplay_viewer$key | null | undefined;
    labelBold?: boolean;
    isPostConfDeliveryVariant?: boolean;
}> = ({
    transaction: transactionRef,
    labelBold = true,
    isPostConfDeliveryVariant,
    viewer: viewerRef,
}) => {
    const viewer = useFragment(
        graphql`
            fragment EstimatedDeliveryDateDisplay_viewer on Viewer {
                ...shippingTrackingDetails_viewer
                ...getPostConfDeliveryDateDisplay_viewer
            }
        `,
        viewerRef
    );
    const transaction = useFragment(
        graphql`
            fragment EstimatedDeliveryDateDisplay_transaction on Transaction {
                ...shippingTrackingDetails_transaction
                ...getPostConfDeliveryDateDisplay_transaction
            }
        `,
        transactionRef
    );

    if (!transaction) {
        return null;
    }

    const {
        leadTime,
        estimatedHandlingTime,
        estimatedShippingTime,
        deliveryDateRangeDisplay,
        estimatedCollectionTime,
    } = getShippingTrackingDetails({ transaction, viewer });
    const deliveryDateDisplay = isPostConfDeliveryVariant
        ? getPostConfDeliveryDateDisplay({ viewer, transaction })
        : deliveryDateRangeDisplay;

    if (!deliveryDateDisplay) {
        return null;
    }

    return (
        <>
            <FormattedMessage
                id="shipmentTracking.BuyerOmtDeliveryDate.EstimatedDeliveryDateDisplay"
                defaultMessage="Estimated Delivery: {deliveryDateRangeDisplay}"
                values={{
                    deliveryDateRangeDisplay: isPostConfDeliveryVariant ? (
                        <span className={dibsCss.inlineBlock}>{deliveryDateDisplay}</span>
                    ) : (
                        <SharedTooltip label={deliveryDateDisplay} labelBold={labelBold}>
                            <div className={styles.infoText}>
                                <FormattedMessage
                                    id="shipmentTracking.EstimatedDeliveryDateDisplay.shippingDateInfo"
                                    defaultMessage="The estimated delivery date is based on your purchased date, shipping and seller’s locations, the carrier, and time a seller need to ship the item."
                                />
                            </div>
                            {leadTime && (
                                <div>
                                    <FormattedMessage
                                        id="shipmentTracking.EstimatedDeliveryDateDisplay.leadTime"
                                        defaultMessage="Production Time: {productionTime}"
                                        values={{
                                            productionTime: leadTime,
                                        }}
                                    />
                                </div>
                            )}
                            {estimatedHandlingTime && (
                                <div>
                                    <FormattedMessage
                                        id="shipmentTracking.EstimatedDeliveryDateDisplay.handlingTime"
                                        defaultMessage="Handling Time: {handlingTime}"
                                        values={{
                                            handlingTime: estimatedHandlingTime,
                                        }}
                                    />
                                </div>
                            )}
                            {estimatedCollectionTime && (
                                <div>
                                    <FormattedMessage
                                        id="shipmentTracking.EstimatedDeliveryDateDisplay.collectionTime"
                                        defaultMessage="Collections Time: {estimatedCollectionTime}"
                                        values={{
                                            estimatedCollectionTime,
                                        }}
                                    />
                                </div>
                            )}
                            {estimatedShippingTime && (
                                <div>
                                    <FormattedMessage
                                        id="shipmentTracking.EstimatedDeliveryDateDisplay.shippingTime"
                                        defaultMessage="Shipping Time: {shippingTime}"
                                        values={{
                                            shippingTime: estimatedShippingTime,
                                        }}
                                    />
                                </div>
                            )}
                        </SharedTooltip>
                    ),
                }}
            />
        </>
    );
};

export default EstimatedDeliveryDateDisplay;
