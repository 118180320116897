import { ReactNode } from 'react';
import { graphql, readInlineData } from 'react-relay';

import { FormattedDate } from 'dibs-react-intl';

import { getShippingTrackingDetails } from './shippingTrackingDetails';

import { getPostConfDeliveryDateDisplay_viewer$key } from './__generated__/getPostConfDeliveryDateDisplay_viewer.graphql';
import { getPostConfDeliveryDateDisplay_transaction$key } from './__generated__/getPostConfDeliveryDateDisplay_transaction.graphql';

export const getPostConfDeliveryDateDisplay = ({
    viewer: viewerRef,
    transaction: transactionRef,
}: {
    viewer: getPostConfDeliveryDateDisplay_viewer$key | null | undefined;
    transaction: getPostConfDeliveryDateDisplay_transaction$key;
}): ReactNode => {
    const viewer = readInlineData(
        graphql`
            fragment getPostConfDeliveryDateDisplay_viewer on Viewer @inline {
                ...shippingTrackingDetails_viewer
            }
        `,
        viewerRef
    );
    const transaction = readInlineData(
        graphql`
            fragment getPostConfDeliveryDateDisplay_transaction on Transaction @inline {
                shipment {
                    shipmentTracking {
                        trackingUrlType
                        estimatedDeliveryDate
                    }
                }
                ...shippingTrackingDetails_transaction
            }
        `,
        transactionRef
    );

    const { trackingUrlType, estimatedDeliveryDate } =
        transaction?.shipment?.shipmentTracking || {};
    const { deliveryDateRangeDisplay } = getShippingTrackingDetails({ transaction, viewer });

    return trackingUrlType === 'PROJECT44' && estimatedDeliveryDate ? (
        <FormattedDate value={estimatedDeliveryDate} month="long" day="numeric" />
    ) : (
        deliveryDateRangeDisplay
    );
};
