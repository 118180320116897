/**
 * @generated SignedSource<<fd979c2833286d4a4a9d30318f81a038>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GetHelpZendeskArticlesSearchResults_viewer$data = {
  readonly zendeskArticles: {
    readonly articles?: ReadonlyArray<{
      readonly serviceId: string | null;
      readonly snippet: string | null;
      readonly title: string | null;
    } | null> | null;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"GetHelpZendeskPromotedArticles_viewer">;
  readonly " $fragmentType": "GetHelpZendeskArticlesSearchResults_viewer";
};
export type GetHelpZendeskArticlesSearchResults_viewer$key = {
  readonly " $data"?: GetHelpZendeskArticlesSearchResults_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"GetHelpZendeskArticlesSearchResults_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "hasSearchQuery"
    },
    {
      "kind": "RootArgument",
      "name": "searchQuery"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "GetHelpZendeskArticlesSearchResults_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GetHelpZendeskPromotedArticles_viewer"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Contact1stdibsZendeskArticles",
      "kind": "LinkedField",
      "name": "zendeskArticles",
      "plural": false,
      "selections": [
        {
          "condition": "hasSearchQuery",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            {
              "alias": null,
              "args": [
                {
                  "kind": "Variable",
                  "name": "query",
                  "variableName": "searchQuery"
                }
              ],
              "concreteType": "ZendeskArticle",
              "kind": "LinkedField",
              "name": "articles",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "serviceId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "title",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "snippet",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ]
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "10c6c4396dd565c0ee67a23b93065dd4";

export default node;
