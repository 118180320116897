/**
 * @generated SignedSource<<7db7962d09ed5d5a42117c1e9be2c175>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OtherAssistance_viewer$data = {
  readonly otherAssistanceGetHelpTopics: {
    readonly " $fragmentSpreads": FragmentRefs<"OtherTopics_getHelpTopics" | "PopularArticles_getHelpTopics">;
  } | null;
  readonly " $fragmentType": "OtherAssistance_viewer";
};
export type OtherAssistance_viewer$key = {
  readonly " $data"?: OtherAssistance_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"OtherAssistance_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "helpType"
    },
    {
      "kind": "RootArgument",
      "name": "isSeller"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "OtherAssistance_viewer",
  "selections": [
    {
      "alias": "otherAssistanceGetHelpTopics",
      "args": [
        {
          "kind": "Variable",
          "name": "helpType",
          "variableName": "helpType"
        },
        {
          "kind": "Variable",
          "name": "isSeller",
          "variableName": "isSeller"
        }
      ],
      "concreteType": "GetHelpTopics",
      "kind": "LinkedField",
      "name": "getHelpTopics",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PopularArticles_getHelpTopics"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "OtherTopics_getHelpTopics"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "a4fbaa9b22605b44eb3d40fd50855025";

export default node;
