import { FC, useContext } from 'react';
import classnames from 'classnames';
import { graphql, useLazyLoadQuery } from 'react-relay';

import { FormattedMessage } from 'dibs-react-intl';
import { Link } from 'dibs-elements/exports/Link';
import { GetHelpZendeskOrderTile } from './GetHelpZendeskOrderTile';
import { GetHelpZendeskContentDivider } from './GetHelpZendeskContentDivider';

import { GetHelpZendeskContext } from './GetHelpZendeskContext';
import { ACTIONS, STEPS } from './GetHelpZendeskConstants';
import { trackGetHelpZendeskEvent } from './getHelpZendeskTracking';

import { GetHelpZendeskLatestOrderQuery } from './__generated__/GetHelpZendeskLatestOrderQuery.graphql';

import dibsCss from 'dibs-css';

const GetHelpZendeskLatestOrder: FC = () => {
    const {
        state: { userId },
        dispatch,
    } = useContext(GetHelpZendeskContext);

    const { viewer } = useLazyLoadQuery<GetHelpZendeskLatestOrderQuery>(
        graphql`
            query GetHelpZendeskLatestOrderQuery($userId: String, $hasUserId: Boolean!) {
                viewer {
                    transactionsPaginatedSearch(
                        userId: $userId
                        viewName: "buyer.allOrders"
                        first: 2
                        excludeCanceled: true
                        searchTerm: ""
                    ) @include(if: $hasUserId) {
                        edges {
                            node {
                                ...GetHelpZendeskOrderTile_transaction
                            }
                        }
                    }
                }
            }
        `,
        {
            hasUserId: !!userId,
            userId,
        }
    );

    const transactions = viewer?.transactionsPaginatedSearch?.edges;
    const latestTransaction = transactions?.[0]?.node;

    if (!latestTransaction) {
        return null;
    }

    const showViewMoreOrderLink = transactions?.length > 1;

    return (
        <div className={dibsCss.mtMedium}>
            <GetHelpZendeskContentDivider />
            <div className={dibsCss.mtMedium}>
                <h2
                    className={classnames(
                        dibsCss.mbXsmall,
                        dibsCss.sassyFontBodyMediumHeavy,
                        dibsCss.textIron
                    )}
                >
                    <FormattedMessage
                        id="getHelp.GetHelpZendeskLatestOrder.heading"
                        defaultMessage="Need help with your latest order?"
                    />
                </h2>
                <GetHelpZendeskOrderTile transaction={latestTransaction} />
                {showViewMoreOrderLink && (
                    <div className={dibsCss.mtSmall}>
                        <Link
                            onClick={() => {
                                dispatch({ type: ACTIONS.SET_STEP, step: STEPS.ORDERS_LIST });
                                trackGetHelpZendeskEvent({
                                    stepInteractionName: 'Full order list viewed',
                                });
                            }}
                        >
                            <span className={dibsCss.sassyFontBodyMedium}>
                                <FormattedMessage
                                    id="getHelp.GetHelpZendeskLatestOrder.viewMore"
                                    defaultMessage="View More Orders"
                                />
                            </span>
                        </Link>
                    </div>
                )}
            </div>
        </div>
    );
};

export { GetHelpZendeskLatestOrder };
