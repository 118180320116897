import { FC, useRef, useEffect, useReducer } from 'react';

import { GetHelpZendeskEmbedded } from './GetHelpZendeskEmbedded';
import { GetHelpZendeskModal } from './GetHelpZendeskModal';

import { GetHelpZendeskContext } from './GetHelpZendeskContext';
import { STEPS } from './GetHelpZendeskConstants';
import { Props } from './GetHelpZendeskTypes';
import { getHelpZendeskReducer, initialState } from './GetHelpZendeskReducer';
import {
    trackGetHelpZendeskEvent,
    trackGetHelpZendeskEmbeddedEvent,
} from './getHelpZendeskTracking';
import {
    getGetHelpZendeskLocalStorageState,
    resetGetHelpZendeskLocalStorageState,
} from './getHelpZendeskHelpers';
import { trackGetHelpZendeskVariant } from '../helpers';

const topicToArticleMap: { [key: string]: string } = {
    taxExempt: '14491937576091',
    auctions: '14956947715611',
    orderCancellation: '14544967384731',
    trade1stProgram: '14488817505947',
    accountSettings: '14520526026139',
};

const GetHelpZendesk: FC<Props> = ({ isEmbedded, placement, onClose, ...props }) => {
    const presetGetHelpZendeskOptions = getGetHelpZendeskLocalStorageState();

    const presetProps =
        props.isCheckout && presetGetHelpZendeskOptions.step === 'orderInfo'
            ? null
            : presetGetHelpZendeskOptions;

    const isOpenedByCtaRef = useRef(!presetGetHelpZendeskOptions?.isOpen);
    const isOpenedByCta = isOpenedByCtaRef?.current;

    const [state, dispatch] = useReducer(getHelpZendeskReducer, {
        ...initialState,
        ...props,
        isEmbedded,
        ...(props.topic &&
            topicToArticleMap?.[props.topic] && {
                articleId: topicToArticleMap[props.topic],
                stepsPath: [
                    { step: STEPS.INTRO },
                    { step: STEPS.ARTICLE, articleId: topicToArticleMap[props.topic] },
                ],
                step: STEPS.ARTICLE,
            }),
        ...(props.orderId &&
            props.showOrderInfo && {
                step: STEPS.ORDER_INFO,
                stepsPath: [{ step: STEPS.INTRO }, { step: STEPS.ORDER_INFO }],
            }),
        onClose: options => {
            if (!options?.isClosedAutomatically) {
                trackGetHelpZendeskEvent({ stepInteractionName: 'Get Help Modal Closed' });
            }

            resetGetHelpZendeskLocalStorageState();
            onClose();
        },
        ...(presetProps && presetProps?.isOpen && { ...presetProps }),
    });

    useEffect(() => {
        if (!isEmbedded && isOpenedByCta) {
            trackGetHelpZendeskVariant();
            trackGetHelpZendeskEvent({
                stepInteractionName: 'Get Help Modal Opened',
                trigger: placement || '',
            });
        }

        if (isEmbedded) {
            trackGetHelpZendeskVariant();
            trackGetHelpZendeskEmbeddedEvent({ stepInteractionName: 'contact us page opened' });
        }
    }, [isOpenedByCta, isEmbedded, placement]);

    return (
        <GetHelpZendeskContext.Provider value={{ dispatch, state }}>
            <div>{isEmbedded ? <GetHelpZendeskEmbedded /> : <GetHelpZendeskModal />}</div>
        </GetHelpZendeskContext.Provider>
    );
};
export { GetHelpZendesk };
