/**
 * @generated SignedSource<<db921d4a5b861520f6610d830a6ad4f8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type GetHelpTopicsHelpType = "checkoutAssistance" | "listings" | "orderIssues" | "otherAssistance" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type Topic_viewer$data = {
  readonly getHelpTopic: {
    readonly helpType: ReadonlyArray<GetHelpTopicsHelpType | null> | null;
    readonly label: string | null;
    readonly showContactButton: boolean | null;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"CurrentItemInfo_viewer" | "OrderInfo_viewer" | "OtherHelp_viewer" | "TopicInfo_viewer">;
  readonly " $fragmentType": "Topic_viewer";
};
export type Topic_viewer$key = {
  readonly " $data"?: Topic_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"Topic_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "hasItemId"
    },
    {
      "kind": "RootArgument",
      "name": "hasOrderId"
    },
    {
      "kind": "RootArgument",
      "name": "isSeller"
    },
    {
      "kind": "RootArgument",
      "name": "orderId"
    },
    {
      "kind": "RootArgument",
      "name": "topicKey"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "Topic_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OtherHelp_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TopicInfo_viewer"
    },
    {
      "condition": "hasOrderId",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "OrderInfo_viewer"
        }
      ]
    },
    {
      "condition": "hasItemId",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CurrentItemInfo_viewer"
        }
      ]
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "isSeller",
          "variableName": "isSeller"
        },
        {
          "kind": "Variable",
          "name": "orderId",
          "variableName": "orderId"
        },
        {
          "kind": "Variable",
          "name": "topicKey",
          "variableName": "topicKey"
        }
      ],
      "concreteType": "GetHelpTopic",
      "kind": "LinkedField",
      "name": "getHelpTopic",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "helpType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "label",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "showContactButton",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "a15b3a0270238a143d607e4bdbf28165";

export default node;
