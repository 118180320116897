/**
 * @generated SignedSource<<aac68a4e80f65ccfaabb5f3d38b4a6d4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type isMissingRefundEventInProgress_transaction$data = {
  readonly summary: {
    readonly invoiceSummary: {
      readonly amountToSettleBuyerCurrentInvoice: {
        readonly amount: number | null;
      } | null;
    } | null;
  } | null;
  readonly transactionHistory: {
    readonly historicalEvents: ReadonlyArray<{
      readonly buyerCopy: string | null;
      readonly name: string | null;
    } | null> | null;
  } | null;
  readonly " $fragmentType": "isMissingRefundEventInProgress_transaction";
};
export type isMissingRefundEventInProgress_transaction$key = {
  readonly " $data"?: isMissingRefundEventInProgress_transaction$data;
  readonly " $fragmentSpreads": FragmentRefs<"isMissingRefundEventInProgress_transaction">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "isMissingRefundEventInProgress_transaction"
};

(node as any).hash = "97b6a146edecf9d1ae624cd237ead42e";

export default node;
