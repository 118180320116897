import { FC, useState, useContext } from 'react';
import classnames from 'classnames';
import { graphql, useFragment } from 'react-relay';

import { FormattedMessage, defineMessages, useIntl, IntlShape } from 'dibs-react-intl';
import PersonBubble from 'dibs-icons/exports/legacy/PersonBubble';
import Message from 'dibs-icons/exports/legacy/Message';
import WhatsApp from 'dibs-icons/exports/legacy/WhatsApp';
import { GetHelpZendeskContactSupportLink } from './GetHelpZendeskContactSupportLink';
import { GetHelpZendeskSendMessageButton } from './GetHelpZendeskSendMessageButton';

import { adjustPdpSellerChatPosition } from '../liveChat/helpers/adjustPdpSellerChatPosition';
import { startChat } from '../liveChat/startLiveChat';
import { DEFAULT_CHAT_LOAD_DELAY_MS } from '../liveChat/constants';
import { getAvailability } from '../OtherHelp';
import { GetHelpZendeskContext } from './GetHelpZendeskContext';
import { RENDER_AS } from './GetHelpZendeskConstants';
import { trackChatInitiated, trackWhatsAppClick, trackSMSClick } from '../tracking';

import { GetHelpZendeskContactSupport_viewer$key } from './__generated__/GetHelpZendeskContactSupport_viewer.graphql';

import dibsCss from 'dibs-css';

const messages = defineMessages({
    defaultMessageText: {
        id: 'getHelp.GetHelpZendeskContactSupport.defaultMessageText',
        defaultMessage: 'Hello, I need help with Order {orderId}',
    },
});

const DIBS_SUPPORT_PHONE_NUMBER = '18777213427';

const getDefaultMessageText = ({
    orderId,
    intl,
}: {
    orderId?: string;
    intl: IntlShape;
}): string => {
    let message = '';

    if (orderId) {
        message = intl.formatMessage(messages.defaultMessageText, { orderId });
    }

    return encodeURI(message);
};

const GetHelpZendeskContactSupport: FC<{
    viewer: GetHelpZendeskContactSupport_viewer$key;
    showHeader?: boolean;
}> = ({ viewer: viewerRef, showHeader = true }) => {
    const intl = useIntl();
    const {
        state: {
            isEmbedded,
            isSeller,
            onChatStart,
            onClose,
            orderId,
            renderAs,
            sellerId,
            showAuthModal,
            topic,
            userId,
        },
    } = useContext(GetHelpZendeskContext);

    const viewer = useFragment(
        graphql`
            fragment GetHelpZendeskContactSupport_viewer on Viewer {
                contact1stdibsConfig(isSeller: $isSeller) {
                    isAgentAvailable
                    showChannelChat
                }
                user(userId: $userId) @include(if: $hasUserId) {
                    profile {
                        email
                        firstName
                        lastName
                    }
                }
                seller(sellerId: $sellerId) @include(if: $hasSellerId) {
                    sellerProfile {
                        email
                        firstName
                        lastName
                    }
                }
                zendeskArticles {
                    topicKeyForArticle(articleId: $articleId)
                }
            }
        `,
        viewerRef
    );

    const [isEmbeddedSvcLoading, setIsEmbeddedSvcLoading] = useState(false);

    const { contact1stdibsConfig, user, seller, zendeskArticles } = viewer;
    const profile = isSeller ? seller?.sellerProfile : user?.profile;
    const { firstName = '', lastName = '', email = '' } = profile || {};
    const { isAgentAvailable: configIsAgentAvailable, showChannelChat } =
        contact1stdibsConfig || {};
    const isAgentAvailable = configIsAgentAvailable || false;
    const showOnline = !!isAgentAvailable;
    const chatShouldBeInitialized =
        showChannelChat &&
        isAgentAvailable &&
        typeof window.embedded_svc?.liveAgentAPI?.startChat !== 'function' &&
        !isEmbeddedSvcLoading;

    const availabilityCopy = getAvailability({ isAgentAvailable, intl }) || '';

    if (chatShouldBeInitialized) {
        setIsEmbeddedSvcLoading(true);
        const interval = setInterval(() => {
            if (window.embedded_svc && window.embedded_svc.liveAgentAPI) {
                setIsEmbeddedSvcLoading(false);
                clearInterval(interval);
            }
        }, DEFAULT_CHAT_LOAD_DELAY_MS);
    }

    const topicValue = topic || zendeskArticles?.topicKeyForArticle;

    return (
        <div className={dibsCss.mbLarge}>
            {showHeader && (
                <>
                    <h1 className={dibsCss.sassyFontHeaderMedium}>
                        <FormattedMessage
                            id="getHelp.GetHelpZendeskContactSupport.title"
                            defaultMessage="Contact Support"
                        />
                    </h1>
                    <div className={classnames(dibsCss.sassyFontBodyMedium, dibsCss.mbSmall)}>
                        <FormattedMessage
                            id="getHelp.GetHelpZendeskContactSupport.info"
                            defaultMessage="Selected how you want to contact us"
                        />
                    </div>
                </>
            )}
            {availabilityCopy && (
                <>
                    <GetHelpZendeskContactSupportLink
                        title={
                            <FormattedMessage
                                id="getHelp.GetHelpZendeskContactSupport.liveChatLabel"
                                defaultMessage="Live Chat"
                            />
                        }
                        Icon={PersonBubble}
                        availability={
                            getAvailability({
                                isAgentAvailable,
                                isChat: true,
                                intl,
                            }) || ''
                        }
                        showOnline={showOnline}
                        disabled={(!isSeller && !isAgentAvailable) || isEmbeddedSvcLoading}
                        onClick={() => {
                            if (renderAs === RENDER_AS.LOGGED_OUT) {
                                if (showAuthModal) {
                                    showAuthModal({
                                        topicValue: topicValue || '',
                                        isChatFlow: true,
                                    });
                                }
                            } else {
                                if (typeof onChatStart === 'function') {
                                    onChatStart();
                                }
                                if (!isSeller) {
                                    adjustPdpSellerChatPosition({});
                                }
                                startChat({
                                    userId,
                                    orderId: orderId || '',
                                    firstName: firstName || '',
                                    lastName: lastName || '',
                                    email: email || '',
                                    selectedTopic: topicValue || '',
                                    itemPk: null,
                                    sellerId,
                                });
                                trackChatInitiated('');
                                if (!isEmbedded) {
                                    onClose({ isClosedAutomatically: true });
                                }
                            }
                        }}
                    />
                    <GetHelpZendeskContactSupportLink
                        title={
                            <FormattedMessage
                                id="getHelp.GetHelpZendeskContactSupport.whatsAppLabel"
                                defaultMessage="WhatsApp"
                            />
                        }
                        Icon={WhatsApp}
                        availability={availabilityCopy}
                        showOnline={showOnline}
                        href={`https://wa.me/${DIBS_SUPPORT_PHONE_NUMBER}?text=${getDefaultMessageText(
                            {
                                orderId,
                                intl,
                            }
                        )}`}
                        onClick={() => trackWhatsAppClick('')}
                    />
                    <GetHelpZendeskContactSupportLink
                        title={
                            <FormattedMessage
                                id="getHelp.GetHelpZendeskContactSupport.textMessageLabel"
                                defaultMessage="Text Message"
                            />
                        }
                        Icon={Message}
                        availability={availabilityCopy}
                        showOnline={showOnline}
                        href={`sms:+${DIBS_SUPPORT_PHONE_NUMBER}?&body=${getDefaultMessageText({
                            orderId,
                            intl,
                        })}`}
                        onClick={() => trackSMSClick('')}
                    />
                </>
            )}
            <GetHelpZendeskSendMessageButton topicValue={topicValue || ''} />
        </div>
    );
};

export { GetHelpZendeskContactSupport };
