import { FC, useState, useContext, useEffect } from 'react';
import { useFragment, graphql } from 'react-relay';
import classnames from 'classnames';

import TopicInfo from './TopicInfo';
import OtherHelp from './OtherHelp';
import OrderInfo from './OrderInfo';
import CurrentItemInfo from './CurrentItemInfo';
import GoBack from './GoBack';
import ContactSupportLink from './ContactSupportLink';

import GetHelpContext from './GetHelpContext';
import {
    HELP_TYPE_ORDER_ISSUES,
    ACTION_SET_STEP,
    STEP_INTRO,
    HELP_TYPE_LISTINGS,
} from './constants';
import {
    isSellerFacilitatedSupportTestVariant,
    trackSellerFacilitatedSupportTestVariant,
} from './abTestHelpers';

import { Topic_viewer$key } from './__generated__/Topic_viewer.graphql';

import dibsCss from 'dibs-css';
import styles from './styles/Topic.scss';

const Topic: FC<{ viewer: Topic_viewer$key }> = ({ viewer: viewerRef }) => {
    const {
        dispatch,
        state: { helpType, orderId, isSeller },
    } = useContext(GetHelpContext);

    const viewer = useFragment(
        graphql`
            fragment Topic_viewer on Viewer {
                ...OtherHelp_viewer
                ...TopicInfo_viewer
                ...OrderInfo_viewer @include(if: $hasOrderId)
                ...CurrentItemInfo_viewer @include(if: $hasItemId)
                getHelpTopic(topicKey: $topicKey, orderId: $orderId, isSeller: $isSeller) {
                    helpType
                    label
                    showContactButton
                }
            }
        `,
        viewerRef
    );

    const { getHelpTopic } = viewer;
    const { helpType: topicHelpType, label: topicLabel, showContactButton } = getHelpTopic || {};

    const isSellerFacilitatedSupportVariant = isSellerFacilitatedSupportTestVariant();

    useEffect(() => {
        if (showContactButton) {
            trackSellerFacilitatedSupportTestVariant();
        }
    }, [showContactButton]);

    const [scrollToOtherHelp, setScrollToOtherHelp] = useState(false);
    const [showOtherHelp, setShowOtherHelp] = useState(isSeller || !showContactButton);

    if (
        !topicLabel ||
        // if topic is only assigned to order issues redirect to intro
        (topicHelpType?.length === 1 && topicHelpType.includes(HELP_TYPE_ORDER_ISSUES) && !orderId)
    ) {
        dispatch({ type: ACTION_SET_STEP, step: STEP_INTRO });
        return null;
    }

    return (
        <div>
            <GoBack />
            {helpType === HELP_TYPE_ORDER_ISSUES && <OrderInfo viewer={viewer} />}
            {helpType === HELP_TYPE_LISTINGS && <CurrentItemInfo viewer={viewer} />}
            <TopicInfo
                viewer={viewer}
                setShowOtherHelp={setShowOtherHelp}
                showOtherHelp={showOtherHelp}
            />
            {isSellerFacilitatedSupportVariant && !showOtherHelp && (
                <div
                    className={classnames(
                        styles.contactSupportLinkWrapper,
                        dibsCss.pLarge,
                        dibsCss.pbSmall
                    )}
                >
                    <ContactSupportLink
                        onOtherHelpClick={() => {
                            setShowOtherHelp(true);
                            setScrollToOtherHelp(true);
                        }}
                    />
                </div>
            )}
            {showOtherHelp && (
                <OtherHelp
                    viewer={viewer}
                    scrollToOtherHelp={scrollToOtherHelp}
                    setScrollToOtherHelp={setScrollToOtherHelp}
                />
            )}
        </div>
    );
};

export default Topic;
