/**
 * @generated SignedSource<<7121e02bd7b1fc1f5d7e7e2971e41ae2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ContactBuyerForm_transaction$data = {
  readonly buyer: {
    readonly serviceId: string | null;
  } | null;
  readonly item: {
    readonly serviceId: string | null;
  } | null;
  readonly " $fragmentType": "ContactBuyerForm_transaction";
};
export type ContactBuyerForm_transaction$key = {
  readonly " $data"?: ContactBuyerForm_transaction$data;
  readonly " $fragmentSpreads": FragmentRefs<"ContactBuyerForm_transaction">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "serviceId",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ContactBuyerForm_transaction",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Item",
      "kind": "LinkedField",
      "name": "item",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "buyer",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    }
  ],
  "type": "Transaction",
  "abstractKey": null
};
})();

(node as any).hash = "7f1a5670e64c11e82f2d9e249fac72b1";

export default node;
