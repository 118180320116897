import { createContext } from 'react';

import { initialState } from './GetHelpZendeskReducer';
import { DispatchType, State } from './GetHelpZendeskTypes';

const GetHelpZendeskContext = createContext<{
    dispatch: DispatchType;
    state: State;
}>({ dispatch: () => {}, state: initialState });

export { GetHelpZendeskContext };
