import dibsCss from 'dibs-css';
import classnames from 'classnames';
import { FunctionComponent } from 'react';

type RecommendItemProps = {
    className?: string;
};

const RecommendItem: FunctionComponent<RecommendItemProps> = props => {
    return (
        <svg
            className={classnames(dibsCss.dibsIcon, props.className)}
            data-tn={'recommend-item'}
            viewBox="0 0 250 250"
        >
            <path d="M240.3 56.7c0-31.2-25.4-56.7-56.7-56.7-14.8 0-28.4 5.7-38.5 15.1H46.7c-6.7 0-13.2 2.8-17.8 7.6-4.6 4.8-7 11.2-6.6 18v.2l15.3 96.6c-10.4 3.9-16.3 10.8-18.4 17l-9.4 22-.1.3c-1.1 4.2-1.1 5.3-1.1 5.7-.1 5.2 1.6 9.7 4.9 13.1 6.5 6.6 16.7 6.9 18.7 6.9H52l-12.6 41.4c-.7 2.4.6 4.9 3 5.6.4.1.9.2 1.3.2 1.9 0 3.7-1.3 4.3-3.2l13.3-44h102.3l14.4 44.4c.6 1.9 2.4 3.1 4.3 3.1.5 0 .9-.1 1.4-.2 2.4-.8 3.7-3.3 2.9-5.7l-13.5-41.6H192c14.8 0 23.6-7.2 23.6-19.3 0-1.1-.4-3.1-1.1-6.2l-.1-.4-10.7-23.6-.2-.4c-5.6-9.5-13.2-14.1-20.5-16.3l3.9-23.1c-.8 0-1.7.1-2.5.1h-.3c31-.2 56.2-25.5 56.2-56.6zm-44.7 100.4l10.3 22.6c.4 1.5.7 3.1.8 3.7 0 6.8-5 10.2-14.6 10.2H32.2c-2.8 0-9-.9-12.3-4.2-1.6-1.6-2.4-3.8-2.4-6.4.1-.5.4-2 .8-3.3l9.2-21.5.1-.4c1.7-5.3 10-14.3 29.7-14.3h108.2c10.6-.2 22.5.9 30.1 13.6zm-30-22.8H57.4c-4 0-7.7.3-11.1.9L31.3 40c-.1-4.2 1.3-8.2 4.2-11.2 2.9-3 7-4.8 11.2-4.8h90.6c-6.5 9.2-10.3 20.4-10.3 32.5 0 29.2 22.3 53.4 50.7 56.3l-3.6 21.7c-3.3-.2-6.2-.2-8.5-.2zM39.5 137c.1 0 .2-.1.3-.1-.1 0-.2 0-.3.1zm2.2-.7s.1 0 0 0c.1 0 0 0 0 0zm142-32c-26.3 0-47.6-21.4-47.6-47.6S157.4 9 183.7 9s47.6 21.4 47.6 47.6-21.4 47.7-47.6 47.7z" />
            <polygon points="188.8,35.7 179.7,35.7 179.7,53.7 161.7,53.7 161.7,62.7 179.7,62.7 179.7,82.7 188.8,82.7 188.8,62.7 206.8,62.7 206.8,53.7 188.8,53.7" />
        </svg>
    );
};
export default RecommendItem;
