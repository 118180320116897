import dibsCss from 'dibs-css';
import classnames from 'classnames';
import { FC } from 'react';

type LanguageToggleProps = {
    className?: string;
    role?: 'img';
    ariaLabel?: string;
    ariaLabelledby?: string;
};

const LanguageToggle: FC<LanguageToggleProps> = props => {
    return (
        <svg
            className={classnames(dibsCss.dibsIcon, props.className)}
            data-tn={'language-toggle'}
            role={props.role}
            aria-label={props.ariaLabel}
            aria-labelledby={props.ariaLabelledby}
            x="0px"
            y="0px"
            viewBox="0 0 250 250"
            // style="enable-background:new 0 0 250 250"
        >
            <path d="M235.6 8.5H14.3C6.3 8.5-.2 15-.2 23v151.8c0 8 6.5 14.5 14.5 14.5h112l60.4 51.1c.8.7 1.9 1.1 2.9 1.1.6 0 1.3-.1 1.9-.4 1.6-.7 2.6-2.3 2.6-4.1v-47.7h41.6c8 0 14.5-6.5 14.5-14.5V23c-.1-8-6.6-14.5-14.6-14.5zm5.5 166.3c0 3-2.5 5.5-5.5 5.5H185v47l-55.6-47-.1.2-.1-.1H14.3c-3 0-5.5-2.5-5.5-5.5V23c0-3 2.5-5.5 5.5-5.5h221.4c3 0 5.5 2.5 5.5 5.5v151.8zm-115.9-16c-33.6 0-60.8-27.3-60.8-60.8s27.3-60.8 60.8-60.8c33.6 0 60.8 27.3 60.8 60.8s-27.3 60.8-60.8 60.8zm-51.7-56.4c1.9 22.5 18.3 41 39.8 46-12.5-15.1-19.2-30.5-20.1-46H73.5zm84.7 0c-.9 15.4-7.5 30.6-19.8 45.6 20.8-5.5 36.5-23.6 38.4-45.6h-18.6zm-28.5 0v42c12-13.9 18.4-27.9 19.4-42h-19.4zm-27.3 0c.9 13.7 7 27.2 18.3 40.7v-40.7h-18.3zm55.7-9h18.8c-2-22.2-17.9-40.4-39-45.7 12.2 15.3 18.9 30.6 20.2 45.7zm-28.4 0H149c-1.3-13.7-7.7-27.5-19.3-41.6v41.6zm-27.1 0h18.1V53.2c-10.8 13.7-16.9 27-18.1 40.2zm-29.1 0h19.9c1.3-15.2 8.1-30.6 20.4-46.1-21.7 4.9-38.3 23.5-40.3 46.1z" />
        </svg>
    );
};

export default LanguageToggle;
