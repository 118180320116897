import { FC } from 'react';
import { graphql, useFragment } from 'react-relay';
import { FormattedNumber } from 'dibs-react-intl';

import {
    ReturnsFormattedPrice_financialAmount$key,
    Currency,
} from './__generated__/ReturnsFormattedPrice_financialAmount.graphql';

const financialAmountFragment = graphql`
    fragment ReturnsFormattedPrice_financialAmount on FinancialAmount {
        convertedAmountList {
            amount
            currency
        }
    }
`;

type Props = {
    currency: Currency;
    financialAmount: ReturnsFormattedPrice_financialAmount$key | null | undefined;
    returnFeePercent?: number;
};

const ReturnsFormattedPrice: FC<Props> = ({
    currency,
    financialAmount: financialAmountRef,
    returnFeePercent,
}) => {
    const financialAmount = useFragment(financialAmountFragment, financialAmountRef);
    let convertedAmountList = financialAmount?.convertedAmountList || [];

    if (returnFeePercent !== undefined) {
        convertedAmountList = convertedAmountList.map(price => ({
            currency: price?.currency || null,
            amount: price?.amount ? (price.amount / 100) * returnFeePercent : 0,
        }));
    }

    const price = (convertedAmountList || []).find(o => o?.currency === currency);
    return <FormattedNumber value={price?.amount || 0} style="currency" currency={currency} />;
};

export { ReturnsFormattedPrice };
