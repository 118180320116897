import { FC, useReducer, useEffect } from 'react';
import { getBuyerId } from 'dibs-cookie-jar';

import { GetHelpZendesk } from './getHelpZendesk/GetHelpZendesk';
import GetHelpModal from './GetHelpModal';
import GetHelpEmbedded from './GetHelpEmbedded';

import { reducer, initialState } from './reducer';

import GetHelpContext from './GetHelpContext';
import {
    RENDER_AS_LOGGED_OUT,
    RENDER_AS_CONSUMER,
    STEP_INTRO,
    STEP_TOPIC,
    STEP_CHECKOUT_ASSISTANCE,
    STEP_SELECTED_ORDER,
    HELP_TYPE_ORDER_ISSUES,
    HELP_TYPE_OTHER_ASSISTANCE,
    HELP_TYPE_CHECKOUT_ASSISTANCE,
    TOPIC_VALUE_CHECKOUT_ASSISTANCE,
} from './constants';
import { trackBomtTimelineOptimizationTest } from 'dibs-transaction-timeline/exports/abTestHelpers';
import { trackGetHelpZendeskEvent } from './getHelpZendesk/getHelpZendeskTracking';
import { isGetHelpModalZendeskVariant, trackGetHelpZendeskVariant } from './helpers';

import { Props, RenderAsOptions } from './types';

// there are still few placements where Get Help is not lazy loaded
// this is to prevent from modal loading in the background if it's not openened
const GetHelpWrapper: FC<Props> = props => {
    if (!props.isEmbedded && !props.isOpen) {
        return null;
    }

    return <GetHelp {...props} />;
};

const GetHelp: FC<Props> = props => {
    const userId = getBuyerId(document.cookie);
    let renderAs: RenderAsOptions = RENDER_AS_LOGGED_OUT;
    const isGetHelpZendeskVariant = isGetHelpModalZendeskVariant();

    if (userId) {
        renderAs = RENDER_AS_CONSUMER;
    }

    const [state, dispatch] = useReducer(reducer, {
        ...initialState,
        ...props,
        renderAs,
        userId,
        ...(props.step === STEP_SELECTED_ORDER && {
            helpType: HELP_TYPE_ORDER_ISSUES,
            stepsPath: [STEP_SELECTED_ORDER],
        }),
        ...(props.topicValue && {
            step: STEP_TOPIC,
            helpType: props.orderId ? HELP_TYPE_ORDER_ISSUES : HELP_TYPE_OTHER_ASSISTANCE,
            stepsPath: [STEP_INTRO, STEP_TOPIC],
        }),
        ...(props.step === STEP_CHECKOUT_ASSISTANCE && {
            helpType: HELP_TYPE_CHECKOUT_ASSISTANCE,
            stepsPath: props.topicValue
                ? [STEP_CHECKOUT_ASSISTANCE, STEP_TOPIC]
                : [STEP_CHECKOUT_ASSISTANCE],
            orderId: '',
            topicValue: props.topicValue || TOPIC_VALUE_CHECKOUT_ASSISTANCE,
        }),
    });

    useEffect(() => {
        if (!isGetHelpZendeskVariant || (isGetHelpZendeskVariant && !props.isEmbedded)) {
            trackBomtTimelineOptimizationTest();
        }
    }, [isGetHelpZendeskVariant, props.isEmbedded]);

    useEffect(() => {
        if (!isGetHelpZendeskVariant) {
            trackGetHelpZendeskVariant();
            trackGetHelpZendeskEvent({
                stepInteractionName: 'Get Help Modal Opened',
                trigger: props.placement || '',
            });
        }
    }, [isGetHelpZendeskVariant, props.placement]);

    if (isGetHelpZendeskVariant) {
        const {
            isEmbedded,
            onClose,
            isOpen,
            placement,
            topicValue,
            onChatEnd,
            showAuthModal,
            orderId,
        } = props || {};
        const isCheckout = props.step === STEP_CHECKOUT_ASSISTANCE;
        const showOrderInfo = props.step === STEP_SELECTED_ORDER;

        return (
            <GetHelpZendesk
                userId={userId}
                isEmbedded={isEmbedded}
                onClose={onClose || (() => {})}
                isOpen={isOpen || false}
                placement={placement}
                topic={topicValue}
                onChatEnd={onChatEnd}
                renderAs={renderAs}
                isCheckout={isCheckout}
                showAuthModal={showAuthModal}
                orderId={orderId}
                showOrderInfo={showOrderInfo}
            />
        );
    }

    return (
        <GetHelpContext.Provider value={{ dispatch, state }}>
            {props.isEmbedded ? <GetHelpEmbedded /> : <GetHelpModal />}
        </GetHelpContext.Provider>
    );
};

export default GetHelpWrapper;
