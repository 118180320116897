import dibsCss from 'dibs-css';
import classnames from 'classnames';
import { FunctionComponent } from 'react';

type PaperclipProps = {
    className?: string;
};

const Paperclip: FunctionComponent<PaperclipProps> = props => {
    return (
        <svg
            className={classnames(dibsCss.dibsIcon, props.className)}
            data-tn={'paperclip'}
            viewBox="0 0 250 250"
        >
            <path d="M172.9 50.8c0-17.5-10.1-50.7-48.1-50.7S76.7 33.2 76.7 50.8v159c0 14 7.4 40.4 35.3 40.4s35.3-26.4 35.3-40.4V66.2c0-9-5.9-22.5-22.2-22.5-7.6 0-13.5 2.6-17.6 7.8-3.2 4-5.1 9.5-5.1 14.7v106.4l.3.2h8.5l.2-.2V66.3c.1-6.5 3.8-13.5 13.7-13.5 9.7 0 13.2 7.3 13.2 13.5v143.6c0 2.1-.3 9.4-3.5 16.5-4.4 9.8-12.2 14.9-22.8 14.9-20.6 0-26.2-19.7-26.3-31.4v-159c0-2 .3-12.4 5.3-22.2 4.5-8.9 14.1-19.5 33.8-19.5 20.1 0 29.7 11.1 34.1 20.5 4.8 10 5 20.1 5 21.2v121.7l.3.2h8.5l.2-.3V50.8z" />
        </svg>
    );
};
export default Paperclip;
