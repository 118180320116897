/**
 * @generated SignedSource<<f55c8d98a130aaae44c4ff42e57d7791>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type IntroStepSeller_viewer$data = {
  readonly seller?: {
    readonly sellerProfile: {
      readonly company: string | null;
    } | null;
  } | null;
  readonly " $fragmentType": "IntroStepSeller_viewer";
};
export type IntroStepSeller_viewer$key = {
  readonly " $data"?: IntroStepSeller_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"IntroStepSeller_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "hasSellerId"
    },
    {
      "kind": "RootArgument",
      "name": "sellerId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "IntroStepSeller_viewer",
  "selections": [
    {
      "condition": "hasSellerId",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "sellerId",
              "variableName": "sellerId"
            }
          ],
          "concreteType": "Seller",
          "kind": "LinkedField",
          "name": "seller",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "SellerProfile",
              "kind": "LinkedField",
              "name": "sellerProfile",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "company",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ]
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "f1079f44648dee7accb032e43da4db9a";

export default node;
