import dibsCss from 'dibs-css';
import classnames from 'classnames';
import { FunctionComponent } from 'react';

type InvoiceProps = {
    className?: string;
};

const Invoice: FunctionComponent<InvoiceProps> = props => {
    return (
        <svg
            className={classnames(dibsCss.dibsIcon, props.className)}
            data-tn={'invoice'}
            viewBox="0 0 250 250"
        >
            <path d="M30.4 0v250h188.7V0H30.4zm179.8 241H39.3V9h170.8v232z" />
            <path d="M94.8 121.8l-6.9 8.8c7 8 17.8 13.9 32.1 14.7v12.5h8.5v-12.5c21.6-1.7 29.7-14.6 29.7-27.3 0-18.2-15.3-23.7-29.7-27.7V59.9c7.8 1.1 15 4.6 20.6 10.6l7-8.5c-6.9-7.1-16-11.6-27.6-12.7v-13H120v12.9c-16.9 1-29.1 11.2-29.1 25.8 0 17.2 14.8 22 29.1 25.9V135c-11.2-1.3-19.7-7-25.2-13.2zm33.8-18.6c9.8 2.9 17.6 6.7 17.6 15.8 0 6.6-4.3 14.6-17.6 16v-31.8zm-25.6-29c0-8.3 7-14 17.1-14.7v28.6C110.5 85.3 103 82 103 74.2z" />
            <path d="M73.2 185.3H171c2.5 0 4.5-2 4.5-4.5s-2-4.5-4.5-4.5H73.2c-2.5 0-4.5 2-4.5 4.5.1 2.5 2.1 4.5 4.5 4.5z" />
            <path d="M73.2 212.6H171c2.5 0 4.5-2 4.5-4.5s-2-4.5-4.5-4.5H73.2c-2.5 0-4.5 2-4.5 4.5.1 2.5 2.1 4.5 4.5 4.5z" />
        </svg>
    );
};
export default Invoice;
