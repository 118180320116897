import { graphql, readInlineData } from 'react-relay';

import { shippingTrackingDetails_transaction$key } from './__generated__/shippingTrackingDetails_transaction.graphql';
import { shippingTrackingDetails_viewer$key } from './__generated__/shippingTrackingDetails_viewer.graphql';

const shippingTrackingDetailsFragment = graphql`
    fragment shippingTrackingDetails_transaction on Transaction @inline {
        sku {
            leadTime
            isAvailableNow
        }
        leadTime {
            value
            hasLeadTime
        }
        shipment {
            deliveryDateRangeDisplay
            estimatedShippingDate
            leadTimeMin
            leadTimeMax
            handlingTime {
                displayValue
            }
            collectionTime {
                displayValue
            }
            serviceMethod {
                describeRange
            }
        }
        pendingShipmentChangeReview {
            shipment {
                serviceMethod {
                    describeRange
                }
                shipmentType
                handlingTime {
                    displayValue
                }
                collectionTime {
                    displayValue
                }
            }
            shipmentQuote {
                serviceMethod {
                    describeRange
                }
                handlingTime {
                    displayValue
                }
            }
        }
        currentShipmentQuote {
            serviceMethod {
                describeRange
            }
            handlingTime {
                displayValue
            }
        }
    }
`;
const shippingTrackingDetailsViewerFragment = graphql`
    fragment shippingTrackingDetails_viewer on Viewer @inline {
        leadTimes {
            name
            endTimeUnit
            startTimeUnit
        }
    }
`;

export const getShippingTrackingDetails = ({
    transaction: transactionRef,
    viewer: viewerRef = null,
}: {
    transaction: shippingTrackingDetails_transaction$key;
    viewer?: shippingTrackingDetails_viewer$key | null | undefined;
}): {
    leadTime: string;
    hasLeadTime: boolean;
    estimatedHandlingTime: string;
    estimatedShippingTime: string;
    deliveryDateRangeDisplay: string;
    estimatedShippingDate: string;
    estimatedCollectionTime: string;
} => {
    const transaction = readInlineData(shippingTrackingDetailsFragment, transactionRef);
    const viewer = readInlineData(shippingTrackingDetailsViewerFragment, viewerRef);

    const {
        leadTime: leadTimeObject,
        shipment,
        pendingShipmentChangeReview,
        currentShipmentQuote,
        sku,
    } = transaction;
    const { deliveryDateRangeDisplay, estimatedShippingDate } = shipment || {};
    const { hasLeadTime: leadTimeHasLeadTime } = leadTimeObject || {};
    const { leadTime: skuLeadTime, isAvailableNow } = sku || {};
    const shipmentLeadTime = viewer?.leadTimes?.find(
        o =>
            typeof o?.startTimeUnit === 'number' &&
            o?.startTimeUnit === shipment?.leadTimeMin &&
            typeof o?.endTimeUnit === 'number' &&
            o?.endTimeUnit === shipment?.leadTimeMax
    )?.name;
    const leadTime = shipmentLeadTime || skuLeadTime || '';

    const hasLeadTime = leadTimeObject ? !!leadTimeHasLeadTime : !isAvailableNow;

    const { shipment: pendingShipment, shipmentQuote: pendingShipmentQuote } =
        pendingShipmentChangeReview || {};
    const hasPendingShipmentType = !!pendingShipment?.shipmentType;

    const displayedShipment = hasPendingShipmentType ? pendingShipment : shipment;

    const displayedQuote = hasPendingShipmentType ? pendingShipmentQuote : currentShipmentQuote;

    const estimatedHandlingTime =
        displayedShipment?.handlingTime?.displayValue ||
        displayedQuote?.handlingTime?.displayValue ||
        '';

    const estimatedShippingTime =
        displayedShipment?.serviceMethod?.describeRange ||
        displayedQuote?.serviceMethod?.describeRange ||
        '';

    const estimatedCollectionTime = displayedShipment?.collectionTime?.displayValue || '';

    return {
        leadTime,
        hasLeadTime,
        estimatedHandlingTime,
        estimatedShippingTime,
        deliveryDateRangeDisplay: deliveryDateRangeDisplay || '',
        estimatedShippingDate: estimatedShippingDate || '',
        estimatedCollectionTime,
    };
};
