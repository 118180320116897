/**
 * @generated SignedSource<<b9d8ce216e4560caace70cb8e0d73c23>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CarrierTrackingDetails_shipment$data = {
  readonly shipmentTracking: {
    readonly carrier: {
      readonly carrierMetaData: {
        readonly customerServiceEmail: string | null;
        readonly customerServicePhoneNumber1: string | null;
        readonly customerServicePhoneNumber2: string | null;
        readonly internallyIntegrated: boolean | null;
        readonly websiteUrl: string | null;
      } | null;
      readonly name: string | null;
    } | null;
    readonly trackingNumber: string | null;
    readonly trackingUrl: string | null;
  } | null;
  readonly " $fragmentType": "CarrierTrackingDetails_shipment";
};
export type CarrierTrackingDetails_shipment$key = {
  readonly " $data"?: CarrierTrackingDetails_shipment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CarrierTrackingDetails_shipment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CarrierTrackingDetails_shipment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ShipmentTracking",
      "kind": "LinkedField",
      "name": "shipmentTracking",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "trackingNumber",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "trackingUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Carrier",
          "kind": "LinkedField",
          "name": "carrier",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "CarrierMetaData",
              "kind": "LinkedField",
              "name": "carrierMetaData",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "websiteUrl",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "customerServiceEmail",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "customerServicePhoneNumber1",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "customerServicePhoneNumber2",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "internallyIntegrated",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Shipment",
  "abstractKey": null
};

(node as any).hash = "aef3c7cf6be4f81184790bc053dc389e";

export default node;
