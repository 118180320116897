import { graphql, readInlineData } from 'react-relay';

import { filterNulls } from 'dibs-ts-utils/exports/filterNulls';

import {
    getLastBuyerEvent_transaction$key,
    getLastBuyerEvent_transaction$data,
} from './__generated__/getLastBuyerEvent_transaction.graphql';

type Transaction = getLastBuyerEvent_transaction$data;

type HistoricalEvent = NonNullable<
    NonNullable<NonNullable<Transaction>['transactionHistory']>['historicalEvents']
>[0];

const PAYMENT_AUTHORIZATION_DENIED = 'PAYMENT_AUTHORIZATION_DENIED' as const;

function isInactionablePaymentDeniedEvent(
    transaction: Transaction,
    event: HistoricalEvent
): boolean {
    const { summary: transactionSummary } = transaction || {};
    const isPaymentDeniedEvent = event?.name === PAYMENT_AUTHORIZATION_DENIED;
    const hasNoBalanceDue = transactionSummary?.balanceDueAmount?.amount === 0;
    const isKlarna =
        transactionSummary?.checkoutPaymentAuthorization?.paymentMethod?.paymentMethodType ===
        'KLARNA';

    return isPaymentDeniedEvent && (hasNoBalanceDue || isKlarna);
}

function isEventEligibleForPrimaryStatus(event: HistoricalEvent): boolean {
    if (!event?.name) {
        return false;
    }
    return !['ORDER_MODIFICATION_ACCEPTED'].includes(event.name);
}

function filterIneligibleEvents(transaction: Transaction): (event: HistoricalEvent) => boolean {
    return function isEligibleEvent(event) {
        return (
            isEventEligibleForPrimaryStatus(event) &&
            !isInactionablePaymentDeniedEvent(transaction, event)
        );
    };
}

export const getLastBuyerEvent = ({
    transaction: transactionRef,
}: {
    transaction: getLastBuyerEvent_transaction$key | null | undefined;
}): HistoricalEvent | null => {
    const transaction = readInlineData(
        graphql`
            fragment getLastBuyerEvent_transaction on Transaction @inline {
                summary {
                    balanceDueAmount {
                        amount
                    }
                    checkoutPaymentAuthorization {
                        paymentMethod {
                            paymentMethodType
                        }
                    }
                }
                transactionHistory {
                    historicalEvents {
                        name
                        buyerCopy
                    }
                }
            }
        `,
        transactionRef
    );

    const { transactionHistory } = transaction || {};

    const historicalEvents = transactionHistory?.historicalEvents || [];

    if (!transaction) {
        return null;
    }

    const lastBuyerEvent =
        [...historicalEvents]
            .reverse()
            .filter(filterNulls)
            .filter(filterIneligibleEvents(transaction))
            .find(e => e?.buyerCopy) || null;

    return lastBuyerEvent;
};
