import { FC, useContext } from 'react';
import classnames from 'classnames';
import { useFragment, graphql } from 'react-relay';
import { FormattedMessage, useIntl } from 'dibs-react-intl';

import { Link } from 'dibs-elements/exports/Link';

import { getOrderStatusLabel } from '../getOrderStatusLabel';
import { ACTIONS, STEPS } from './GetHelpZendeskConstants';
import { GetHelpZendeskContext } from './GetHelpZendeskContext';
import { trackGetHelpZendeskEvent } from './getHelpZendeskTracking';

import { GetHelpZendeskOrderTile_transaction$key } from './__generated__/GetHelpZendeskOrderTile_transaction.graphql';

import dibsCss from 'dibs-css';
import styles from './GetHelpZendeskOrderTile.scss';

type Props = {
    transaction: GetHelpZendeskOrderTile_transaction$key | null | undefined;
};

const GetHelpZendeskOrderTile: FC<Props> = ({ transaction: transactionRef }) => {
    const intl = useIntl();
    const { dispatch } = useContext(GetHelpZendeskContext);

    const transaction = useFragment(
        graphql`
            fragment GetHelpZendeskOrderTile_transaction on Transaction {
                serviceId
                item {
                    title
                    firstPhotoWebPath(size: small)
                }
                ...getOrderStatusLabel_transaction
            }
        `,
        transactionRef
    );

    const { item, serviceId } = transaction || {};
    const { title, firstPhotoWebPath } = item || {};
    const statusLabel = getOrderStatusLabel({ transaction, isSeller: false, intl });

    return (
        <Link
            dataTn="get-help-zendesk-modal-orders-list-order"
            onClick={() => {
                dispatch({ type: ACTIONS.SET_STEP, step: STEPS.ORDER_INFO });
                dispatch({
                    type: ACTIONS.SET_ORDER_ID,
                    orderId: serviceId || '',
                });
                dispatch({
                    type: ACTIONS.SET_TOPIC,
                    topic: 'trackOrder',
                });
                trackGetHelpZendeskEvent({
                    stepInteractionName: 'Order Selected',
                });
            }}
            underline="none"
            className={dibsCss.wFull}
        >
            <div
                className={classnames(
                    styles.link,
                    dibsCss.flex,
                    dibsCss.border,
                    dibsCss.borderSolid,
                    dibsCss.borderDolphin,
                    dibsCss.roundedXxsmall,
                    dibsCss.pSmall,
                    dibsCss.prMedium,
                    dibsCss.gapSmall
                )}
            >
                <div className={styles.imageWrapper}>
                    <img
                        src={firstPhotoWebPath || ''}
                        alt={title || ''}
                        className={styles.image}
                        data-tn="get-help-zendesk-orders-list-order-item-image"
                    />
                </div>
                <div
                    className={classnames(
                        dibsCss.flex,
                        dibsCss.flexCol,
                        dibsCss.justifyCenter,
                        dibsCss.gapXsmall,
                        dibsCss.overflowHidden,
                        dibsCss.textLeft
                    )}
                >
                    <div
                        data-tn="get-help-orders-list-order-item-title"
                        className={classnames(dibsCss.sassyFontBodyMediumHeavy, dibsCss.truncate)}
                    >
                        {title}
                    </div>
                    <div>
                        <span data-tn="get-help-zendesk-orders-list-order-id">
                            <FormattedMessage
                                id="contact1stdibs.OrdersListOrder.orderNumber"
                                defaultMessage="Order {orderId}"
                                values={{ orderId: serviceId }}
                            />
                        </span>
                        {statusLabel && (
                            <>
                                {' | '}
                                <span
                                    className={dibsCss.sassyFontBodySmallHeavy}
                                    data-tn="get-help-zendesk-orders-list-order-status"
                                >
                                    <>{statusLabel}</>
                                </span>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </Link>
    );
};

export { GetHelpZendeskOrderTile };
