import { useState, useEffect, FunctionComponent, ChangeEventHandler, ReactNode } from 'react';
import { FormattedMessage } from 'dibs-react-intl';
import classnames from 'classnames';

import Paperclip from 'dibs-icons/exports/legacy/Paperclip';

import { RenderAsOptions } from './types';

import styles from './styles/FileUpload.scss';

type FileUploadProps = {
    onChange: (file?: File) => void;
    renderAs: RenderAsOptions;
    file?: File | null;
};

const FILE_SIZE_ERROR = (
    <FormattedMessage
        id="getHelp.FileUpload.fileSizeError"
        defaultMessage="The selected file was above 10 MB and was not attached. Please select a different file."
    />
);

const FileUpload: FunctionComponent<FileUploadProps> = ({ onChange, renderAs, file: formFile }) => {
    const [fileName, setFileName] = useState<string | undefined>();
    const [errorEl, setErrorEl] = useState<ReactNode>(null);

    const handleFileSelect: ChangeEventHandler<HTMLInputElement> = e => {
        const file = e.target.files?.[0];
        if (file) {
            const filesize = file.size / 1024 / 1024; // MB
            //as per requirements, https://1stdibs.atlassian.net/browse/TRADE-5247
            if (filesize > 10) {
                setErrorEl(FILE_SIZE_ERROR);
            } else {
                setErrorEl(null);
                onChange(e.target.files?.[0]);
                const selectedValue = e.target.value;
                if (selectedValue) {
                    const selectedFileName = selectedValue.split('\\').pop();
                    setFileName(selectedFileName);
                }
            }
        }
    };

    useEffect(() => {
        if (!formFile) {
            setFileName('');
        }
    }, [formFile]);

    return (
        <>
            <label className={classnames(styles.wrapper, { [styles.hasError]: !!errorEl })}>
                <input
                    className={styles.input}
                    data-tn="contact-1stdibs-upload"
                    type="file"
                    accept=".jpeg, .png, .gif, .heic, .mov, .mp4, .hevc, .pdf"
                    onChange={handleFileSelect}
                />
                <Paperclip className={renderAs === 'seller' ? styles.sellerIcon : styles.icon} />{' '}
                <div className={styles.label} data-tn="image-selector-label">
                    {fileName || (
                        <FormattedMessage
                            id="getHelp.FileUpload.label"
                            defaultMessage="Add an attachment (10 MB limit)"
                        />
                    )}
                </div>
            </label>
            {errorEl ? <div className={styles.errorWrapper}>{errorEl}</div> : null}
        </>
    );
};

export default FileUpload;
