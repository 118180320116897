import { FC, useRef, useContext } from 'react';
import { graphql, useFragment } from 'react-relay';

import { FormattedMessage } from 'dibs-react-intl';
import { GetHelpZendeskPromotedArticles } from './GetHelpZendeskPromotedArticles';

import { GetHelpZendeskContext } from './GetHelpZendeskContext';

import { GetHelpZendeskArticlesNoSearchResults_viewer$key } from './__generated__/GetHelpZendeskArticlesNoSearchResults_viewer.graphql';

import dibsCss from 'dibs-css';

const GetHelpZendeskArticlesNoSearchResults: FC<{
    viewer: GetHelpZendeskArticlesNoSearchResults_viewer$key;
}> = ({ viewer: viewerRef }) => {
    const {
        state: { searchQuery },
    } = useContext(GetHelpZendeskContext);

    const noResultsQuery = useRef(searchQuery);

    const viewer = useFragment(
        graphql`
            fragment GetHelpZendeskArticlesNoSearchResults_viewer on Viewer {
                ...GetHelpZendeskPromotedArticles_viewer
            }
        `,
        viewerRef
    );

    return (
        <div className={dibsCss.mbLarge}>
            <FormattedMessage
                id="getHelp.GetHelpZendeskArticlesSearchResults.noResultsMessage"
                defaultMessage="We couldn’t find any results for “{noResultsQuery}”"
                values={{ noResultsQuery: noResultsQuery.current }}
            />
            <div className={dibsCss.mtLarge}>
                <GetHelpZendeskPromotedArticles viewer={viewer} />
            </div>
        </div>
    );
};

export { GetHelpZendeskArticlesNoSearchResults };
