type SearchParams = Partial<Record<string, string>>;

export const commonKeysList: { [key: string]: string } = {
    itemPk: 'itemPk',
    orderId: 'orderId',
    topicValue: 'topic',
    categoryValue: 'category',
    caseNumber: 'caseNumber',
    helpType: 'helpType',
};

// currently all keys are the same
// but this config allows to add separate ones for buyer and seller in the future
export const SEARCH_PARAMS_KEYS_BUYER: SearchParams = {
    ...commonKeysList,
};

export const SEARCH_PARAMS_KEYS_SELLER: SearchParams = {
    ...commonKeysList,
};

const getSearchParamsProps = (paramsKeys: SearchParams): SearchParams => {
    const params = new URLSearchParams(window.location.search);
    const searchParamsProps: SearchParams = {};

    Object.keys(paramsKeys).forEach(key => {
        const value = params.get(paramsKeys[key] || '') || '';
        if (value) {
            searchParamsProps[key] = value;
        }
    });

    return searchParamsProps;
};

export const getBuyerSearchParamsProps = (): SearchParams => {
    return getSearchParamsProps(SEARCH_PARAMS_KEYS_BUYER);
};

export const getSellerSearchParamsProps = (): SearchParams => {
    return getSearchParamsProps(SEARCH_PARAMS_KEYS_SELLER);
};
