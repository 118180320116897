import { FC } from 'react';
import { useFragment, graphql } from 'react-relay';
import { FormattedMessage } from 'dibs-react-intl';

import { getShippingTrackingDetails } from '../helpers/shippingTrackingDetails';

import SharedTooltip from './SharedTooltip';

import { EstimatedShippingDateDisplay_transaction$key } from './__generated__/EstimatedShippingDateDisplay_transaction.graphql';
import { EstimatedShippingDateDisplay_viewer$key } from './__generated__/EstimatedShippingDateDisplay_viewer.graphql';

import styles from './SharedDateDislaySyles.scss';

const EstimatedShippingDateDisplay: FC<{
    transaction: EstimatedShippingDateDisplay_transaction$key | null | undefined;
    viewer: EstimatedShippingDateDisplay_viewer$key | null | undefined;
}> = ({ transaction: transactionRef, viewer: viewerRef }) => {
    const viewer = useFragment(
        graphql`
            fragment EstimatedShippingDateDisplay_viewer on Viewer {
                ...shippingTrackingDetails_viewer
            }
        `,
        viewerRef
    );
    const transaction = useFragment(
        graphql`
            fragment EstimatedShippingDateDisplay_transaction on Transaction {
                ...shippingTrackingDetails_transaction
            }
        `,
        transactionRef
    );

    if (!transaction) {
        return null;
    }

    const { leadTime, estimatedHandlingTime, estimatedShippingDate, estimatedCollectionTime } =
        getShippingTrackingDetails({
            viewer,
            transaction,
        });

    return (
        <>
            <FormattedMessage
                id="shipmentTracking.EstimatedShippingDateDisplay.EstimatedShippingDateDisplay"
                defaultMessage="Will be shipped by: {estimatedShippingDate}"
                values={{
                    estimatedShippingDate: (
                        <SharedTooltip label={estimatedShippingDate} labelBold>
                            <div className={styles.infoText}>
                                <FormattedMessage
                                    id="shipmentTracking.EstimatedShippingDateDisplay.shippingDateInfo"
                                    defaultMessage="The estimated shipping date is based on how much time a seller needs to prepare it for shipment after the order is confirmed and consists of:"
                                />
                            </div>
                            {leadTime && (
                                <div>
                                    <FormattedMessage
                                        id="shipmentTracking.EstimatedShippingDateDisplay.leadTime"
                                        defaultMessage="Production Time: {productionTime}"
                                        values={{
                                            productionTime: leadTime,
                                        }}
                                    />
                                </div>
                            )}
                            {estimatedHandlingTime && (
                                <div>
                                    <FormattedMessage
                                        id="shipmentTracking.EstimatedShippingDateDisplay.handlingTime"
                                        defaultMessage="Handling Time: {handlingTime}"
                                        values={{
                                            handlingTime: estimatedHandlingTime,
                                        }}
                                    />
                                </div>
                            )}
                            {estimatedCollectionTime && (
                                <div>
                                    <FormattedMessage
                                        id="shipmentTracking.EstimatedShippingDateDisplay.collectionTime"
                                        defaultMessage="Collections Time: {estimatedCollectionTime}"
                                        values={{
                                            estimatedCollectionTime,
                                        }}
                                    />
                                </div>
                            )}
                        </SharedTooltip>
                    ),
                }}
            />
        </>
    );
};

export default EstimatedShippingDateDisplay;
