import dibsCss from 'dibs-css';
import classnames from 'classnames';
import { FunctionComponent } from 'react';

type IconHelpButtonCloseProps = {
    className?: string;
};

const IconHelpButtonClose: FunctionComponent<IconHelpButtonCloseProps> = props => {
    return (
        <svg
            className={classnames(dibsCss.dibsIcon, props.className)}
            data-tn={'icon-help-button-close'}
            viewBox="0 0 250 250"
        >
            <path d="M125 89.286L35.714 0 0 35.714 89.286 125 0 214.286 35.714 250 125 160.714 214.286 250 250 214.286 160.714 125 250 35.714 214.286 0 125 89.286z" />
        </svg>
    );
};
export default IconHelpButtonClose;
