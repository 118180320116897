import { handleLocaleUrl, GLOBAL_CLIENT_ONLY_LOCALE } from 'dibs-intl/exports/urls';
import { commonKeysList } from './getSearchParamsProps';

const setSearchParams = (params: URLSearchParams): void => {
    const searchString = params.toString();
    let url = window.location.pathname;
    if (searchString) {
        url = `${url}?${searchString}`;
    }
    history.replaceState(null, '', handleLocaleUrl(url, GLOBAL_CLIENT_ONLY_LOCALE));
};

export const replaceSearchParam =
    (paramName: string) =>
    (value: string | null): void => {
        const searchParams = new URLSearchParams(window.location.search);
        if (value !== null) {
            searchParams.set(paramName, value);
        } else {
            searchParams.delete(paramName);
        }

        setSearchParams(searchParams);
    };

export const clearSearchParams = (): void => {
    const params = new URLSearchParams(window.location.search);

    Object.keys(commonKeysList).forEach(key => {
        params.delete(commonKeysList[key]);
    });
    setSearchParams(params);
};
