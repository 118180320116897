import { FC } from 'react';
import { FormattedMessage } from 'dibs-react-intl';
import { graphql, useFragment } from 'react-relay';

import ChairIcon from 'dibs-icons/exports/legacy/ChairOutlined';

import { CurrentItemInfo_viewer$key } from './__generated__/CurrentItemInfo_viewer.graphql';

import styles from './styles/CurrentItemInfo.scss';

const CurrentItemInfo: FC<{ viewer: CurrentItemInfo_viewer$key | null | undefined }> = ({
    viewer: viewerRef,
}) => {
    const viewer = useFragment(
        graphql`
            fragment CurrentItemInfo_viewer on Viewer {
                item(itemId: $itemId) @include(if: $hasItemId) {
                    title
                    firstPhotoWebPath(size: small)
                    dealerReference
                    dibsReference
                    dealerStatusDisplay
                }
            }
        `,
        viewerRef
    );

    const { item } = viewer || {};
    const { title, firstPhotoWebPath, dealerReference, dibsReference, dealerStatusDisplay } =
        item || {};

    return (
        <div className={styles.container}>
            <div className={styles.itemInfo}>
                <div className={styles.imageWrapper}>
                    {firstPhotoWebPath ? (
                        <img alt={title || ''} src={firstPhotoWebPath} className={styles.image} />
                    ) : (
                        <ChairIcon className={styles.chairIcon} />
                    )}
                </div>
                <div>
                    <div className={styles.title}>{title}</div>
                    <div className={styles.details}>
                        {dealerReference ? (
                            <FormattedMessage
                                id="getHelp.CurrentItemInfo.sellerRef"
                                defaultMessage="Seller Ref {sellerRef}"
                                values={{ sellerRef: dealerReference }}
                            />
                        ) : (
                            <FormattedMessage
                                id="getHelp.CurrentItemInfo.itemRef"
                                defaultMessage="Item Ref {itemRef}"
                                values={{ itemRef: dibsReference }}
                            />
                        )}
                        <span className={styles.dotDivider}>.</span>
                        <span className={styles.currentStatus}>{dealerStatusDisplay}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CurrentItemInfo;
