/**
 * @generated SignedSource<<09c2b88e2e1b3003b9eb061bc367b6ed>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GetHelpZendeskPromotedArticles_viewer$data = {
  readonly zendeskArticles: {
    readonly promotedArticles: ReadonlyArray<{
      readonly serviceId: string | null;
      readonly title: string | null;
    } | null> | null;
  } | null;
  readonly " $fragmentType": "GetHelpZendeskPromotedArticles_viewer";
};
export type GetHelpZendeskPromotedArticles_viewer$key = {
  readonly " $data"?: GetHelpZendeskPromotedArticles_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"GetHelpZendeskPromotedArticles_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "isCheckout"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "GetHelpZendeskPromotedArticles_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Contact1stdibsZendeskArticles",
      "kind": "LinkedField",
      "name": "zendeskArticles",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "isCheckout",
              "variableName": "isCheckout"
            }
          ],
          "concreteType": "ZendeskArticle",
          "kind": "LinkedField",
          "name": "promotedArticles",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "serviceId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "title",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "b53e6a80d34aad565029a5983137a3a7";

export default node;
