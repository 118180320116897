/**
 * @generated SignedSource<<809fa83837613e00c8bc4a8291d9de59>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ItemsList_viewer$data = {
  readonly items: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly serviceId: string | null;
        readonly " $fragmentSpreads": FragmentRefs<"ItemsListItem_item">;
      } | null;
    } | null> | null;
  } | null;
  readonly " $fragmentType": "ItemsList_viewer";
};
export type ItemsList_viewer$key = {
  readonly " $data"?: ItemsList_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"ItemsList_viewer">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "items"
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": "",
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "kind": "RootArgument",
      "name": "dealerPk"
    },
    {
      "defaultValue": 10,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "kind": "RootArgument",
      "name": "searchTerm"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [
        "viewer"
      ],
      "operation": require('./ItemsListPaginationQuery.graphql')
    }
  },
  "name": "ItemsList_viewer",
  "selections": [
    {
      "alias": "items",
      "args": [
        {
          "kind": "Variable",
          "name": "dealerPk",
          "variableName": "dealerPk"
        },
        {
          "kind": "Literal",
          "name": "includeContextAdmin",
          "value": true
        },
        {
          "kind": "Literal",
          "name": "queryTemplate",
          "value": "dealer.inventory.management"
        },
        {
          "kind": "Variable",
          "name": "searchTerm",
          "variableName": "searchTerm"
        },
        {
          "kind": "Literal",
          "name": "sort",
          "value": "-createDate"
        }
      ],
      "concreteType": "ItemsConnection",
      "kind": "LinkedField",
      "name": "__ItemsList_items_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ItemsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Item",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "serviceId",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "ItemsListItem_item"
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
})();

(node as any).hash = "9b4e54e0ebaa1ff411b3a5ced4bb1095";

export default node;
