import { FC, useContext } from 'react';
import classnames from 'classnames';
import { graphql, useFragment } from 'react-relay';

import { FormattedMessage } from 'dibs-react-intl';
import { Link } from 'dibs-elements/exports/Link';
import Article from 'dibs-icons/exports/Article';

import { separateSearchQueryFromString } from './getHelpZendeskHelpers';
import { GetHelpZendeskContext } from './GetHelpZendeskContext';
import { ACTIONS, STEPS } from './GetHelpZendeskConstants';
import { trackGetHelpZendeskEvent } from './getHelpZendeskTracking';

import { GetHelpZendeskArticlesSearchResults_viewer$key } from './__generated__/GetHelpZendeskArticlesSearchResults_viewer.graphql';

import dibsCss from 'dibs-css';
import styles from './GetHelpZendeskArticlesSearchResults.scss';

const GetHelpZendeskArticlesSearchResults: FC<{
    viewer: GetHelpZendeskArticlesSearchResults_viewer$key;
}> = ({ viewer: viewerRef }) => {
    const {
        state: { searchQuery },
        dispatch,
    } = useContext(GetHelpZendeskContext);

    const viewer = useFragment(
        graphql`
            fragment GetHelpZendeskArticlesSearchResults_viewer on Viewer {
                ...GetHelpZendeskPromotedArticles_viewer
                zendeskArticles {
                    articles(query: $searchQuery) @include(if: $hasSearchQuery) {
                        serviceId
                        title
                        snippet
                    }
                }
            }
        `,
        viewerRef
    );

    const articles = viewer?.zendeskArticles?.articles || [];

    if (!articles.length) {
        dispatch({ type: ACTIONS.SET_STEP, step: STEPS.NO_SEARCH_RESULTS });
    }

    return (
        <div className={dibsCss.mbXsmall}>
            <div className={classnames(dibsCss.sassyFontBodyMediumHeavy, dibsCss.textIron)}>
                <FormattedMessage
                    id="getHelp.GetHelpZendeskArticlesSearchResults.title"
                    defaultMessage="Search Results"
                />
            </div>
            <div className={dibsCss.mtSmall}>
                {articles.map(article => (
                    <Link
                        key={article?.serviceId || ''}
                        onClick={() => {
                            dispatch({
                                type: ACTIONS.OPEN_ARTICLE,
                                articleId: (article?.serviceId || '').split('-')[0],
                            });
                            trackGetHelpZendeskEvent({
                                stepInteractionName: 'search result clicked',
                                trigger: 'search results',
                            });
                        }}
                        underline="none"
                    >
                        <div
                            className={classnames(
                                styles.article,
                                dibsCss.textLeft,
                                dibsCss.mbMedium,
                                dibsCss.flex,
                                dibsCss.sassyFontBodyLarge
                            )}
                        >
                            <div className={classnames(dibsCss.fillShagreen400, dibsCss.mrSmall)}>
                                <Article variation="20CircleFilled" />
                            </div>
                            <div>
                                <div
                                    className={styles.title}
                                    dangerouslySetInnerHTML={{
                                        __html: separateSearchQueryFromString(
                                            article?.title || '',
                                            searchQuery
                                        ),
                                    }}
                                />
                                <div
                                    dangerouslySetInnerHTML={{ __html: article?.snippet || '' }}
                                    className={classnames(dibsCss.lineClamp2, dibsCss.mtXsmall)}
                                />
                            </div>
                        </div>
                    </Link>
                ))}
            </div>
        </div>
    );
};

export { GetHelpZendeskArticlesSearchResults };
