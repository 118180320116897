/**
 * @generated SignedSource<<35128fab7720f6f220abd5ce7f29076a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type TrackingUrlTypeEnum = "CARRIER" | "PROJECT44" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type getPostConfDeliveryDateDisplay_transaction$data = {
  readonly shipment: {
    readonly shipmentTracking: {
      readonly estimatedDeliveryDate: string | null;
      readonly trackingUrlType: TrackingUrlTypeEnum | null;
    } | null;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"shippingTrackingDetails_transaction">;
  readonly " $fragmentType": "getPostConfDeliveryDateDisplay_transaction";
};
export type getPostConfDeliveryDateDisplay_transaction$key = {
  readonly " $data"?: getPostConfDeliveryDateDisplay_transaction$data;
  readonly " $fragmentSpreads": FragmentRefs<"getPostConfDeliveryDateDisplay_transaction">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "getPostConfDeliveryDateDisplay_transaction"
};

(node as any).hash = "fdeb1ad81d86b5846d17a6d8bec517c5";

export default node;
