import { FC, useContext } from 'react';
import { FormattedMessage } from 'dibs-react-intl';

import { Link } from 'dibs-elements/exports/Link';
import MessageSentWrapper from './MessageSentWrapper';

import GetHelpContext from './GetHelpContext';

const ContactSellerMessageSent: FC = () => {
    const {
        state: { conversationId },
    } = useContext(GetHelpContext);

    return (
        <MessageSentWrapper>
            <FormattedMessage
                id="getHelp.ContactSellerMessageSent.info"
                defaultMessage="We'll send you an email when the seller responds. View all your messages at any time in <link>Inbox</link>."
                values={{
                    link: content => <Link href={`/inbox/${conversationId}/`}>{content}</Link>,
                }}
            />
        </MessageSentWrapper>
    );
};

export default ContactSellerMessageSent;
