/**
 * @generated SignedSource<<e89f485fb74da7d72025d5c4fda05eb1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OpenConversationCta_viewer$data = {
  readonly transaction?: {
    readonly conversation: {
      readonly serviceId: string | null;
    } | null;
    readonly item: {
      readonly seller: {
        readonly sellerProfile: {
          readonly company: string | null;
        } | null;
      } | null;
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"ContactBuyerForm_transaction" | "ContactSellerForm_transaction">;
  } | null;
  readonly " $fragmentType": "OpenConversationCta_viewer";
};
export type OpenConversationCta_viewer$key = {
  readonly " $data"?: OpenConversationCta_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"OpenConversationCta_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "hasOrderId"
    },
    {
      "kind": "RootArgument",
      "name": "hasSellerId"
    },
    {
      "kind": "RootArgument",
      "name": "hasUserId"
    },
    {
      "kind": "RootArgument",
      "name": "transactionId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "OpenConversationCta_viewer",
  "selections": [
    {
      "condition": "hasOrderId",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "transactionId",
              "variableName": "transactionId"
            }
          ],
          "concreteType": "Transaction",
          "kind": "LinkedField",
          "name": "transaction",
          "plural": false,
          "selections": [
            {
              "condition": "hasUserId",
              "kind": "Condition",
              "passingValue": true,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "ContactSellerForm_transaction"
                }
              ]
            },
            {
              "condition": "hasSellerId",
              "kind": "Condition",
              "passingValue": true,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "ContactBuyerForm_transaction"
                }
              ]
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Conversation",
              "kind": "LinkedField",
              "name": "conversation",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "serviceId",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Item",
              "kind": "LinkedField",
              "name": "item",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Seller",
                  "kind": "LinkedField",
                  "name": "seller",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "SellerProfile",
                      "kind": "LinkedField",
                      "name": "sellerProfile",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "company",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ]
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "746206022cb08d1a59e6e9574165ee98";

export default node;
