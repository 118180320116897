import { FC, useEffect, useState, ReactNode } from 'react';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import { defineMessages, useIntl, FormattedMessage } from 'dibs-react-intl';
import * as tracking from 'dibs-tracking';
import classnames from 'classnames';
import TranslationToggle from 'dibs-elements/exports/TranslationToggle';
import ArrowDown from 'dibs-icons/exports/legacy/ArrowDown';
import { ExpandingAreaWithReadMore } from 'dibs-elements/exports/ExpandingArea';
import { useReturnPolicyTranslations } from './ReturnPolicyTranslationProvider';

import styles from './styles/ReturnPolicy.scss';

import { ReturnPolicyEmbed_returnPolicy$data } from './__generated__/ReturnPolicyEmbed_returnPolicy.graphql';

type Props = {
    onLoaded?: () => void;
    returnPolicy: ReturnPolicyEmbed_returnPolicy$data | null | undefined;
    showReturnPolicyName: boolean;
    trackingData?: { category: string; label: string };
    hideDetails?: boolean;
    bgTransparent?: boolean;
};

const defaultCopy = defineMessages({
    policyName: {
        id: 'returnPolicy.InvoiceReturnPolicy.policyName',
        defaultMessage: 'Policy Name: {name}',
    },
    additionalNotes: {
        id: 'returnPolicy.InvoiceReturnPolicy.additionalNotes',
        defaultMessage: 'Additional Notes',
    },
});

const ReadMoreButton: FC<{ label: ReactNode; isExpanded: boolean }> = ({ label, isExpanded }) => {
    return (
        <>
            {label}
            <ArrowDown
                className={classnames(styles.expandingAreaArrow, {
                    [styles.expanded]: isExpanded,
                })}
            />
        </>
    );
};

const ReturnPolicyEmbed: FC<Props> = ({
    returnPolicy,
    showReturnPolicyName,
    onLoaded,
    trackingData,
    hideDetails,
    bgTransparent,
}) => {
    const intl = useIntl();
    const returnPolicyCopy = returnPolicy?.buyerCopy;
    const [state, dispatch] = useReturnPolicyTranslations();
    const [isExpanded, setIsExpanded] = useState(false);

    useEffect(() => {
        if (onLoaded) {
            onLoaded();
        }
    }, [onLoaded]);

    const returnCopy = returnPolicyCopy?.detailsReturnCopy?.htmlCopy;
    const customizableCopy = returnPolicyCopy?.customizableDetailReturnCopy?.htmlCopy || '';
    const showCustomizableCopy = !!customizableCopy;

    let returnPolicyName = null;
    if (showReturnPolicyName) {
        returnPolicyName = (
            <div className={styles.returnPolicySectionItem}>
                {intl.formatMessage(defaultCopy.policyName, { name: returnPolicy?.name })}
            </div>
        );
    }

    let returnPolicyDisplay = null;
    if (returnCopy) {
        returnPolicyDisplay = (
            <div
                data-tn="return-modal-return-policy"
                className={classnames(styles.returnPolicySectionItem, styles.returnPolicyCopy)}
            >
                <span dangerouslySetInnerHTML={{ __html: returnCopy }} />
                {showCustomizableCopy && (
                    <>
                        {' '}
                        <span dangerouslySetInnerHTML={{ __html: customizableCopy }} />
                    </>
                )}
            </div>
        );
    }

    // Restocking fee
    let restockingFee = null;
    const restockingFeeCopy = returnPolicyCopy?.detailsRestockingFee?.htmlCopy || '';
    if (restockingFeeCopy) {
        restockingFee = (
            <li
                data-tn="return-modal-restocking-fee"
                dangerouslySetInnerHTML={{ __html: restockingFeeCopy }}
            />
        );
    }

    // Return Shipping Responsibility
    let returnShippingResponsibility = null;
    const shippingResponsibility = returnPolicyCopy?.detailsShippingResponsibility?.htmlCopy;
    if (shippingResponsibility) {
        returnShippingResponsibility = (
            <li
                data-tn="return-modal-shipping-responsibility"
                dangerouslySetInnerHTML={{ __html: shippingResponsibility }}
            />
        );
    }

    let feesDetails = null;
    const returnPolicyFeesHeader = returnPolicyCopy?.returnsFeesHeader?.htmlCopy || '';
    if (restockingFee || returnShippingResponsibility) {
        feesDetails = (
            <>
                <div className={styles.paragraphHeader}>
                    <span
                        dangerouslySetInnerHTML={{
                            __html: returnPolicyFeesHeader,
                        }}
                    />
                </div>
                <ul className={styles.bulletList}>
                    {restockingFee}
                    {returnShippingResponsibility}
                </ul>
            </>
        );
    }

    // Additional Notes from seller
    let additionalNotes = null;
    const note = returnPolicy?.additionalNotes;
    const additionalNotesHeader =
        returnPolicyCopy?.detailsAdditionalNotesHeader?.htmlCopy ||
        intl.formatMessage(defaultCopy.additionalNotes);
    const englishReturnPolicy =
        state?.data?.viewer.itemRead?.[0]?.returnPolicy ||
        state?.data?.viewer.transaction?.returnPolicy;
    if (note) {
        additionalNotes = (
            <div className={styles.section} data-tn="return-modal-additional-notes">
                <div className={styles.paragraphHeader}>{additionalNotesHeader}</div>
                {state?.interfaceState === 'SHOW_ORIGINAL'
                    ? englishReturnPolicy?.additionalNotes
                    : note}
                {!!state && !!dispatch && (
                    <TranslationToggle
                        className={styles.translationToggle}
                        onClick={event => {
                            if (trackingData) {
                                tracking.trackEvent(
                                    {
                                        ...trackingData,
                                        action: 'return details - toggle language click',
                                    },
                                    event
                                );
                            }
                            dispatch({ type: 'TOGGLE_TRANSLATIONS' });
                        }}
                        currentState={state?.interfaceState}
                    />
                )}
            </div>
        );
    }

    let disclaimer = null;
    const disclaimerCopy = returnPolicyCopy?.detailsDisclaimer?.htmlCopy;
    if (disclaimerCopy) {
        disclaimer = (
            <div
                data-tn="return-modal-disclaimer"
                className={`${styles.disclaimer} ${styles.section}`}
            >
                <span dangerouslySetInnerHTML={{ __html: disclaimerCopy }} />
            </div>
        );
    }

    const detailsAttribution = returnPolicyCopy?.detailsAttribution;
    const attributionHtmlCopy = detailsAttribution ? (
        <div
            dangerouslySetInnerHTML={{ __html: detailsAttribution?.htmlCopy || '' }}
            className={styles.spaceBottom}
        />
    ) : null;

    const returnPolicySection = (
        <div
            className={classnames(styles.returnPolicySection, {
                [styles.bgTransparent]: bgTransparent,
            })}
        >
            {returnPolicyName}
            {returnPolicyDisplay}
            {feesDetails}
            {additionalNotes}
        </div>
    );

    return (
        <div>
            {attributionHtmlCopy}
            {returnPolicySection}
            {hideDetails ? (
                <ExpandingAreaWithReadMore
                    collapsedHeight={0}
                    showGradient={false}
                    onReadMoreClick={() => setIsExpanded(!isExpanded)}
                    buttonClass={styles.expandingAreaToggle}
                    readMore={
                        <ReadMoreButton
                            label={
                                <FormattedMessage
                                    id="returnPolicy.InvoiceReturnPolicy.returnPolicyViewMore"
                                    defaultMessage="View More"
                                />
                            }
                            isExpanded={isExpanded}
                        />
                    }
                    readLess={
                        <ReadMoreButton
                            label={
                                <FormattedMessage
                                    id="returnPolicy.InvoiceReturnPolicy.returnPolicyViewLess"
                                    defaultMessage="View Less"
                                />
                            }
                            isExpanded={isExpanded}
                        />
                    }
                >
                    {disclaimer}
                </ExpandingAreaWithReadMore>
            ) : (
                disclaimer
            )}
        </div>
    );
};

export default createFragmentContainer(ReturnPolicyEmbed, {
    returnPolicy: graphql`
        fragment ReturnPolicyEmbed_returnPolicy on ReturnPolicy {
            name
            buyerCopy {
                detailsReturnCopy(userCountryCode: $userCountryCode) {
                    htmlCopy
                }
                detailsDisclaimer {
                    htmlCopy
                }
                detailsShippingResponsibility(userCountryCode: $userCountryCode) {
                    htmlCopy
                }
                detailsAdditionalNotesHeader {
                    htmlCopy
                }
                returnsFeesHeader {
                    htmlCopy
                }
                detailsRestockingFee(userCountryCode: $userCountryCode) {
                    htmlCopy
                }
                detailsReturnPolicyMainHeader {
                    htmlCopy
                }
                detailsAttribution {
                    htmlCopy
                }
                customizableDetailReturnCopy {
                    htmlCopy
                }
            }
            additionalNotes
        }
    `,
});
