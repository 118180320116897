import { defineMessages, IntlShape } from 'dibs-react-intl';
import { hasKey } from 'dibs-ts-utils/exports/hasKey';

import { SEND_MESSAGE_ERRORS, SendMessageError } from './constants';

const messages = defineMessages<SendMessageError>({
    MESSAGE_RATE_LIMIT: {
        id: 'errorTools.errorMessages.sendMessage.MESSAGE_RATE_LIMIT',
        defaultMessage:
            'You have sent too many messages in a short period of time. Please try again later.',
    },
});

export const isSendMessageError = (code?: string): boolean =>
    !!code && hasKey(SEND_MESSAGE_ERRORS, code);

export const getSendMessageErrorMessage = ({
    code,
    intl,
}: {
    code?: string;
    intl: IntlShape;
}): string | null => {
    if (code && hasKey(messages, code)) {
        return intl.formatMessage(messages.MESSAGE_RATE_LIMIT);
    }
    return null;
};
