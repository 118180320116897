import { useReducer, useEffect, useState } from 'react';
import { fetchQuery } from 'react-relay';
import { Environment, GraphQLTaggedNode, OperationType } from 'relay-runtime';
import { LOCALE_US } from 'dibs-intl/exports/locales';
import { getLang } from 'dibs-intl/exports/languages';
import { useIntl } from 'dibs-react-intl';
import { getBuyerToken } from 'dibs-cookie-jar';

import { createFetcher } from 'dibs-relay-network-layer/client';
import { Network, RecordSource, Store } from 'relay-runtime';

function createNetworkLayer(locale: string): Environment {
    const source = new RecordSource();
    const store = new Store(source);
    const network = Network.create(
        createFetcher({
            userType: 'buyer',
            getQueries: () => {
                return { locale, ...getBuyerToken(document.cookie) };
            },
        })
    );

    return new Environment({
        network,
        store,
    });
}

export const INTERFACE_STATE = {
    HIDDEN: 'HIDDEN',
    SHOW_TRANSLATION: 'SHOW_TRANSLATION',
    SHOW_ORIGINAL: 'SHOW_ORIGINAL',
} as const;

export type InterfaceState = (typeof INTERFACE_STATE)[keyof typeof INTERFACE_STATE];
type ReducerState<ResponseShape> = {
    interfaceState: InterfaceState;
    loadingState: 'INITIAL' | 'START' | 'LOADING' | 'DONE';
    data: ResponseShape | null;
};
type ReducerActions<ResponseShape> =
    | {
          type: 'HANDLE_RESPONSE';
          data: ResponseShape;
      }
    | { type: 'TOGGLE_TRANSLATIONS' }
    | { type: 'HANDLE_ERROR'; err: unknown };

export type TranslationState<T> =
    | [ReducerState<T>, React.Dispatch<ReducerActions<T>>]
    | [null, null];

function hookReducer<ResponseShape>(
    state: ReducerState<ResponseShape>,
    action: ReducerActions<ResponseShape>
): ReducerState<ResponseShape> {
    switch (action.type) {
        case 'HANDLE_ERROR':
            return { ...state, loadingState: 'INITIAL' };
        case 'HANDLE_RESPONSE':
            return {
                ...state,
                data: action.data || null,
                interfaceState:
                    state.interfaceState === 'SHOW_TRANSLATION'
                        ? 'SHOW_ORIGINAL'
                        : state.interfaceState,
                loadingState: 'DONE',
            };
        case 'TOGGLE_TRANSLATIONS': {
            return {
                ...state,
                loadingState: state.loadingState === 'INITIAL' ? 'START' : state.loadingState,
                interfaceState:
                    state.interfaceState === 'SHOW_TRANSLATION' && state.data
                        ? 'SHOW_ORIGINAL'
                        : 'SHOW_TRANSLATION',
            };
        }
        default:
            return state;
    }
}

export const useTranslationToggle = <T extends OperationType>({
    sourceLocale,
    query,
    variables,
}: {
    sourceLocale: string | null;
    query: GraphQLTaggedNode;
    variables: T['variables'];
}): [ReducerState<T['response']>, React.Dispatch<ReducerActions<T['response']>>] => {
    const [relayEnv, setRelayEnv] = useState<Environment | null>(null);
    const { locale } = useIntl();
    const itemLocale = sourceLocale || LOCALE_US;

    useEffect(() => {
        setRelayEnv(createNetworkLayer(itemLocale));
    }, [itemLocale]);

    const [reducerState, dispatch] = useReducer(hookReducer, {
        data: null,
        interfaceState: getLang(locale) === getLang(itemLocale) ? 'HIDDEN' : 'SHOW_TRANSLATION',
        loadingState: 'INITIAL',
    });
    const { loadingState } = reducerState;

    useEffect(() => {
        if (loadingState === 'START') {
            if (relayEnv) {
                fetchQuery(relayEnv, query, variables).subscribe({
                    next: data => dispatch({ type: 'HANDLE_RESPONSE', data }),
                    error: (err: $TSFixMe) => {
                        dispatch({ type: 'HANDLE_ERROR', err });
                    },
                });
            }
        }
    }, [loadingState, relayEnv, query, variables]);

    return [reducerState, dispatch];
};
