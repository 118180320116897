/**
 * @generated SignedSource<<8165c015a38286ec42d6e3af7833110f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpdatedContactSellerForm_getHelpTopic$data = {
  readonly supportRequestTypeId: string | null;
  readonly " $fragmentType": "UpdatedContactSellerForm_getHelpTopic";
};
export type UpdatedContactSellerForm_getHelpTopic$key = {
  readonly " $data"?: UpdatedContactSellerForm_getHelpTopic$data;
  readonly " $fragmentSpreads": FragmentRefs<"UpdatedContactSellerForm_getHelpTopic">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UpdatedContactSellerForm_getHelpTopic",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "supportRequestTypeId",
      "storageKey": null
    }
  ],
  "type": "GetHelpTopic",
  "abstractKey": null
};

(node as any).hash = "c2d46cd5a7c2e84d4505ef4216ec2a1c";

export default node;
