import { FC, useState, useCallback, useContext, useTransition } from 'react';
import classnames from 'classnames';
import { usePaginationFragment, graphql, useLazyLoadQuery } from 'react-relay';
import { FormattedMessage, defineMessages, useIntl } from 'dibs-react-intl';
import { useDebouncedCallback } from 'dibs-react-hooks/exports/useDebouncedCallback';

import { Button } from 'dibs-elements/exports/Button';
import { Input } from 'dibs-elements/exports/Input';
import { Link } from 'dibs-elements/exports/Link';
import { Spinner } from 'dibs-elements/exports/Spinner';
import Close from 'dibs-icons/exports/legacy/Close';
import MagnifyingGlass from 'dibs-icons/exports/legacy/MagnifyingGlass';

import OrdersListOrder from './OrdersListOrder';
import GetHelpHeader from './GetHelpHeader';
import ListSkeletonLoader from './ListSkeletonLoader';

import GetHelpContext from './GetHelpContext';
import {
    ACTION_SET_STEP,
    ACTION_SET_TOPIC,
    ACTION_SET_HELP_TYPE,
    STEP_INTRO,
    STEP_OTHER_ASSISTANCE,
    STEP_TOPIC,
    HELP_TYPE_OTHER_ASSISTANCE,
} from './constants';

import { OrdersList_viewer$key } from './__generated__/OrdersList_viewer.graphql';
import { OrdersListQuery } from './__generated__/OrdersListQuery.graphql';
import { OrdersListPaginationQuery } from './__generated__/OrdersListPaginationQuery.graphql';

import styles from './styles/SharedListStyles.scss';

const messages = defineMessages({
    placeholder: {
        id: 'getHelp.OrdersList.placeholder',
        defaultMessage: 'Search by Order ID or Title',
    },
});

const MIN_SEARCH_LENGTH = 3;

const OrderNotListed: FC = () => {
    const {
        dispatch,
        state: { isSeller },
    } = useContext(GetHelpContext);

    return (
        <Link
            dataTn="get-help-modal-order-not-listed-link"
            onClick={() => {
                dispatch({
                    type: ACTION_SET_TOPIC,
                    topic: isSeller ? 'sellerOrderGeneral' : 'orderNotFound',
                });
                dispatch({ type: ACTION_SET_HELP_TYPE, helpType: HELP_TYPE_OTHER_ASSISTANCE });
                dispatch({ type: ACTION_SET_STEP, step: STEP_TOPIC });
            }}
            className={styles.resultNotFound}
        >
            <FormattedMessage
                id="getHelp.OrdersList.orderNotListed"
                defaultMessage="My order is not listed"
            />
        </Link>
    );
};

const OrdersList: FC = () => {
    const intl = useIntl();
    const {
        dispatch,
        state: { userId, sellerId, isEmbedded, isSeller },
    } = useContext(GetHelpContext);

    const [searchTerm, setSearchTerm] = useState('');
    const [hasSearched, setHasSearched] = useState(false);
    const [isRefetching, startTransition] = useTransition();

    const viewName = isSeller ? 'dealer.all' : 'buyer.allOrders';

    const { viewer } = useLazyLoadQuery<OrdersListQuery>(
        graphql`
            query OrdersListQuery(
                $userId: String!
                $hasUserId: Boolean!
                $searchTerm: String!
                $viewName: String
                $sellerId: String!
                $hasSellerId: Boolean!
                $excludeCanceled: Boolean!
            ) {
                viewer {
                    ...OrdersList_viewer @include(if: $hasUserId)
                    ...OrdersList_viewer @include(if: $hasSellerId)
                }
            }
        `,
        {
            userId: userId || '',
            sellerId: sellerId || '',
            hasUserId: !!userId,
            hasSellerId: !!sellerId,
            searchTerm: '',
            viewName,
            excludeCanceled: isSeller,
        }
    );

    const { data, hasNext, loadNext, isLoadingNext, refetch } = usePaginationFragment<
        OrdersListPaginationQuery,
        OrdersList_viewer$key
    >(
        graphql`
            fragment OrdersList_viewer on Viewer
            @refetchable(queryName: "OrdersListPaginationQuery")
            @argumentDefinitions(
                after: { type: String, defaultValue: "" }
                first: { type: Int, defaultValue: 10 }
            ) {
                transactionsPaginatedSearch(
                    userId: $userId
                    sellerId: $sellerId
                    searchTerm: $searchTerm
                    viewName: $viewName
                    excludeCanceled: $excludeCanceled
                    after: $after
                    first: $first
                ) @connection(key: "OrdersList_transactionsPaginatedSearch") {
                    numberOfPages
                    edges {
                        node {
                            serviceId
                            ...OrdersListOrder_transaction
                        }
                    }
                }
            }
        `,
        viewer || null
    );

    const fetchData = useCallback(
        (val: string) => {
            startTransition(() => {
                setHasSearched(true);
                refetch({ searchTerm: val });
            });
        },
        [refetch, startTransition]
    );

    const [debouncedFetchData] = useDebouncedCallback(fetchData, 500);

    const onChange = (searchTermValue: string): void => {
        setSearchTerm(searchTermValue);
        if (searchTermValue.length >= MIN_SEARCH_LENGTH || !searchTermValue) {
            debouncedFetchData(searchTermValue);
        }
    };

    const transactions = data?.transactionsPaginatedSearch?.edges || [];
    const noTransactionsFound = !transactions.length && searchTerm;
    const hasOrders = !!transactions.length;
    const noRecentOrders = !hasOrders && !hasSearched;

    return (
        <div
            className={classnames({
                [styles.noRecentResults]: noRecentOrders,
                [styles.isEmbedded]: isEmbedded,
            })}
        >
            <GetHelpHeader
                title={
                    isSeller ? (
                        <FormattedMessage
                            id="getHelp.OrdersList.greetingSeller"
                            defaultMessage="Select order or offer"
                        />
                    ) : (
                        <FormattedMessage
                            id="getHelp.OrdersList.greeting"
                            defaultMessage="Which order can we help you with?"
                        />
                    )
                }
            />
            <div className={styles.container}>
                {(hasOrders || hasSearched) && (
                    <div className={styles.searchInputWrapper}>
                        <Input
                            dataTn="getHelp-orders-list-input"
                            size="medium"
                            value={searchTerm}
                            onChange={e => onChange(e.target.value)}
                            placeholder={intl.formatMessage(messages.placeholder)}
                            rightDecorator={
                                <div className={styles.inputRightDecorator}>
                                    {searchTerm && (
                                        <Link
                                            className={styles.inputIconRemoveWrapper}
                                            onClick={() => onChange('')}
                                        >
                                            <Close className={styles.inputIconRemove} />
                                        </Link>
                                    )}
                                </div>
                            }
                            leftDecorator={<MagnifyingGlass className={styles.inputIconGlass} />}
                        />
                    </div>
                )}
                {isRefetching ? (
                    <ListSkeletonLoader />
                ) : (
                    <>
                        {noRecentOrders && (
                            <div className={styles.noRecentResultsView}>
                                <div className={styles.noRecentResultsViewInner}>
                                    <div className={styles.noRecentResultsLabel}>
                                        {isSeller ? (
                                            <FormattedMessage
                                                id="getHelp.OrdersList.noOrdersOrOffersLabel"
                                                defaultMessage="You don’t have any recent orders or offers."
                                            />
                                        ) : (
                                            <FormattedMessage
                                                id="getHelp.OrdersList.noOrdersLabel"
                                                defaultMessage="You don’t have any recent orders."
                                            />
                                        )}
                                    </div>
                                    <Button
                                        dataTn="get-help-modal-other-assistance-cta"
                                        type="transparent"
                                        onClick={() => {
                                            if (isSeller) {
                                                dispatch({
                                                    type: ACTION_SET_STEP,
                                                    step: STEP_INTRO,
                                                });
                                                return;
                                            }

                                            dispatch({
                                                type: ACTION_SET_STEP,
                                                step: STEP_OTHER_ASSISTANCE,
                                            });
                                            dispatch({
                                                type: ACTION_SET_HELP_TYPE,
                                                helpType: HELP_TYPE_OTHER_ASSISTANCE,
                                            });
                                        }}
                                        className={styles.otherAssistance}
                                    >
                                        {isSeller ? (
                                            <FormattedMessage
                                                id="getHelp.OrdersList.selectOtherTopic"
                                                defaultMessage="Select other topic"
                                            />
                                        ) : (
                                            <FormattedMessage
                                                id="getHelp.OrdersList.otherAssitance"
                                                defaultMessage="I need other assistance"
                                            />
                                        )}
                                    </Button>
                                </div>
                            </div>
                        )}
                        <div
                            className={classnames(styles.resultsContainer, {
                                [styles.isEmbedded]: isEmbedded,
                            })}
                        >
                            {transactions.map(transaction => (
                                <OrdersListOrder
                                    key={transaction?.node?.serviceId || ''}
                                    transaction={transaction?.node || null}
                                />
                            ))}

                            {isLoadingNext && hasNext && (
                                <div className={styles.spinnerContainer}>
                                    <Spinner />
                                </div>
                            )}
                            {hasNext && !isLoadingNext && (
                                <Button
                                    dataTn="get-help-modal-show-more-orders-cta"
                                    onClick={() => loadNext(10)}
                                    className={styles.loadMore}
                                    fullWidth
                                >
                                    <FormattedMessage
                                        id="getHelp.OrdersList.loadMore"
                                        defaultMessage="Show More"
                                    />
                                </Button>
                            )}
                            {noTransactionsFound && (
                                <div className={styles.noResultsFound}>
                                    <FormattedMessage
                                        id="getHelp.OrdersList.noResultsFound"
                                        defaultMessage="We couldn’t find any results for “{searchTerm}”"
                                        values={{ searchTerm }}
                                    />
                                </div>
                            )}
                            {((!hasNext && hasOrders) || (!hasOrders && hasSearched)) && (
                                <OrderNotListed />
                            )}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default OrdersList;
