/**
 * @generated SignedSource<<d7ef84902fe1a81f2fc4a5a5c316327b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GetHelpZendeskArticlesNoSearchResults_viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"GetHelpZendeskPromotedArticles_viewer">;
  readonly " $fragmentType": "GetHelpZendeskArticlesNoSearchResults_viewer";
};
export type GetHelpZendeskArticlesNoSearchResults_viewer$key = {
  readonly " $data"?: GetHelpZendeskArticlesNoSearchResults_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"GetHelpZendeskArticlesNoSearchResults_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GetHelpZendeskArticlesNoSearchResults_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GetHelpZendeskPromotedArticles_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "0626f925bb96a83d129bff7c8ad5ee8f";

export default node;
