import { FC, ReactNode, useEffect } from 'react';
import classnames from 'classnames';

import { Link } from 'dibs-elements/exports/Link';

import { chatCtaDisplayed } from './tracking';

import { CHANNEL_LIVE_CHAT } from './constants';

import styles from './styles/SupportChannelLink.scss';

type Props = {
    availability?: string;
    disabled?: boolean;
    Icon: FC<{ className?: string }>;
    channel: string;
    onClick?: () => void;
    showOnline?: boolean;
    title: ReactNode;
    isBorderHighlighted?: boolean;
    href?: string;
};

const SupportChannelLink: FC<Props> = ({
    availability,
    disabled,
    Icon,
    channel,
    onClick,
    showOnline = false,
    title,
    isBorderHighlighted,
    href,
}) => {
    useEffect(() => {
        if (channel === CHANNEL_LIVE_CHAT) {
            chatCtaDisplayed(showOnline);
        }
    }, [channel, showOnline]);

    return (
        <Link
            onClick={() => {
                if (disabled) {
                    return;
                } else if (onClick) {
                    onClick();
                }
            }}
            className={classnames(styles.channelCta, {
                [styles.disabled]: disabled,
                [styles.darkBorder]: isBorderHighlighted,
            })}
            underline="none"
            dataTn={`support-channel-link-${channel}`}
            href={href || ''}
            target="_blank"
        >
            <div className={styles.channelCtaLeft}>
                <Icon className={styles.channelIcon} />
                <div>
                    <div className={styles.title}>{title}</div>
                    <div className={styles.availability}>
                        {showOnline && <span className={styles.showOnline} />}
                        {availability}
                    </div>
                </div>
            </div>
        </Link>
    );
};

export default SupportChannelLink;
