import { FC, useContext } from 'react';
import classnames from 'classnames';
import { graphql, useFragment } from 'react-relay';

import { FormattedMessage } from 'dibs-react-intl';
import { Link } from 'dibs-elements/exports/Link';

import { GetHelpZendeskContext } from './GetHelpZendeskContext';
import { ACTIONS } from './GetHelpZendeskConstants';
import { trackGetHelpZendeskEvent } from './getHelpZendeskTracking';

import { GetHelpZendeskPromotedArticles_viewer$key } from './__generated__/GetHelpZendeskPromotedArticles_viewer.graphql';

import dibsCss from 'dibs-css';

const GetHelpZendeskPromotedArticles: FC<{ viewer: GetHelpZendeskPromotedArticles_viewer$key }> = ({
    viewer: viewerRef,
}) => {
    const { dispatch } = useContext(GetHelpZendeskContext);

    const viewer = useFragment(
        graphql`
            fragment GetHelpZendeskPromotedArticles_viewer on Viewer {
                zendeskArticles {
                    promotedArticles(isCheckout: $isCheckout) {
                        serviceId
                        title
                    }
                }
            }
        `,
        viewerRef
    );

    const promotedArticles = viewer?.zendeskArticles?.promotedArticles || [];

    if (!promotedArticles.length) {
        return null;
    }

    return (
        <>
            <div
                className={classnames(
                    dibsCss.sassyFontBodyMediumHeavy,
                    dibsCss.textIron,
                    dibsCss.mbSmall
                )}
            >
                <FormattedMessage
                    id="getHelp.GetHelpZendeskPromotedArticles.title"
                    defaultMessage="Quick Answers"
                />
            </div>
            <div className={classnames(dibsCss.flex, dibsCss.flexCol, dibsCss.gapXsmall)}>
                {promotedArticles.map(article => (
                    <Link
                        key={article?.serviceId || ''}
                        underline="none"
                        onClick={() => {
                            dispatch({
                                type: ACTIONS.OPEN_ARTICLE,
                                articleId: article?.serviceId || '',
                            });
                            trackGetHelpZendeskEvent({
                                stepInteractionName: 'Popular Article selected',
                                trigger: article?.title || '',
                            });
                        }}
                    >
                        <div
                            className={classnames(
                                dibsCss.sassyFontBodyMediumHeavy,
                                dibsCss.textLeft
                            )}
                        >
                            {article?.title}
                        </div>
                    </Link>
                ))}
            </div>
        </>
    );
};

export { GetHelpZendeskPromotedArticles };
