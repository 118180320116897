import { FC, ReactNode, CSSProperties } from 'react';
import classNames from 'classnames';
import Checkmark from 'dibs-icons/exports/legacy/Checkmark';
import Circle from 'dibs-icons/exports/legacy/Circle';
import IconHelpButtonClose from 'dibs-icons/exports/legacy/IconHelpButtonClose';

import styles from './main.scss';

type Step = {
    isCompleted?: boolean | null;
    label?: ReactNode;
    hasTargetLabelFormatting?: boolean;
    progress?: number;
    lineColor?: 'warning' | 'pending' | 'success';
    bubbleType?: 'hidden' | 'filled' | 'suspended';
};

type Props = {
    steps: Step[];
    size?: 'default' | 'small' | 'medium';
    showMobileStepLabel?: boolean;
};

const Timeline: FC<Props> = ({ steps, size, showMobileStepLabel = false }) => {
    const isSmall = size === 'small';
    const currentStatusIndex = (steps || []).map(step => step.isCompleted).lastIndexOf(true);

    return (
        <div data-tn="timeline" className={styles.container}>
            {steps.map(
                (
                    {
                        isCompleted,
                        label,
                        hasTargetLabelFormatting,
                        progress,
                        lineColor,
                        bubbleType,
                    },
                    index
                ) => {
                    const style = { width: `${progress}%` } as CSSProperties;

                    let icon: ReactNode;

                    switch (bubbleType) {
                        case 'filled':
                            icon = (
                                <Circle
                                    className={classNames(styles.checkmark, {
                                        [styles.checkmarkUncheckedSuccess]: lineColor === 'success',
                                    })}
                                />
                            );
                            break;

                        case 'suspended':
                            icon = (
                                <IconHelpButtonClose
                                    className={classNames(styles.checkmark, styles.suspended, {
                                        [styles.checkmarkUncheckedSuccess]: lineColor === 'success',
                                    })}
                                />
                            );
                            break;

                        default:
                            icon = (
                                <Checkmark
                                    className={classNames(styles.checkmark, {
                                        [styles.isSmall]: !isSmall,
                                    })}
                                />
                            );
                            break;
                    }

                    return (
                        <div
                            style={progress || progress === 0 ? style : {}}
                            data-tn={`timeline-step-completed-${isCompleted}`}
                            key={`timeline-step-completed-${index}`}
                            className={classNames(styles.timelineStep, {
                                [styles.stepCompleted]: isCompleted,
                                [styles.showMobileStepLabel]: showMobileStepLabel,
                            })}
                        >
                            {index !== 0 && (
                                <div
                                    className={classNames(styles.lineSegment, {
                                        [styles.lineSegmentSmall]: isSmall,
                                        [styles.lineSegmentMedium]: size === 'medium',
                                        [styles.lineWarningColor]: lineColor === 'warning',
                                        [styles.linePendingColor]: lineColor === 'pending',
                                        [styles.lineSuccessColor]: lineColor === 'success',
                                        [styles.roundStart]: index === 1,
                                        [styles.roundEnd]: !steps[index + 1],
                                    })}
                                />
                            )}
                            <div className={styles.checkmarkAndLabelContainer}>
                                {bubbleType !== 'hidden' && (
                                    <div
                                        className={classNames({
                                            [styles.checkmarkContainerSuccessColor]:
                                                lineColor === 'success',
                                            [styles.checkmarkContainer]: !isSmall,
                                            [styles.checkmarkContainerSmall]: isSmall,
                                        })}
                                    >
                                        {icon}
                                    </div>
                                )}
                                {hasTargetLabelFormatting && (
                                    <div
                                        data-tn="tier-indicator"
                                        className={classNames(styles.indicator, {
                                            [styles.indicatorCompleted]: isCompleted,
                                        })}
                                    >
                                        {!isCompleted && (
                                            <div className={styles.indicatorSeparator} />
                                        )}
                                        <div
                                            data-tn="tier-indicator-label"
                                            className={styles.indicatorLabel}
                                        >
                                            {label}
                                        </div>
                                    </div>
                                )}
                                {!hasTargetLabelFormatting && label && (
                                    <div
                                        className={classNames(styles.label, {
                                            [styles.currentStatus]: currentStatusIndex === index,
                                        })}
                                    >
                                        {label}
                                    </div>
                                )}
                            </div>
                        </div>
                    );
                }
            )}
        </div>
    );
};

export { Timeline, Step };
