import { FC } from 'react';
import classnames from 'classnames';

import styles from './styles/ListSkeletonLoader.scss';

const SingleLoaderItem: FC = () => {
    return (
        <div className={styles.itemContainer}>
            <div className={styles.right} />
            <div className={styles.left}>
                <div className={classnames(styles.line, styles.title)} />
                <div className={classnames(styles.line, styles.short)} />
                <div className={classnames(styles.line, styles.medium)} />
            </div>
        </div>
    );
};

const ListSkeletonLoader: FC = () => {
    return (
        <div>
            <SingleLoaderItem />
            <SingleLoaderItem />
            <SingleLoaderItem />
            <SingleLoaderItem />
        </div>
    );
};

export default ListSkeletonLoader;
