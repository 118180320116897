import { FC } from 'react';
import { useFragment, graphql } from 'react-relay';
import { FormattedMessage } from 'dibs-react-intl';

import OtherTopicsCategory from './OtherTopicsCategory';

import { OtherTopics_getHelpTopics$key } from './__generated__/OtherTopics_getHelpTopics.graphql';

import styles from './styles/OtherTopics.scss';

const OtherTopics: FC<{ getHelpTopics: OtherTopics_getHelpTopics$key | null | undefined }> = ({
    getHelpTopics: getHelpTopicsRef,
}) => {
    const getHelpTopics = useFragment(
        graphql`
            fragment OtherTopics_getHelpTopics on GetHelpTopics {
                otherIssues {
                    categoryKey
                    categoryLabel
                    topics {
                        topicKey
                        label
                    }
                }
            }
        `,
        getHelpTopicsRef
    );

    const otherIssues = getHelpTopics?.otherIssues || [];

    if (!otherIssues.length) {
        return null;
    }

    return (
        <div className={styles.container}>
            <div className={styles.title}>
                <FormattedMessage id="getHelp.OtherTopics.title" defaultMessage="Other Topics" />
            </div>
            {otherIssues.map(category => (
                <OtherTopicsCategory key={category?.categoryKey} category={category} />
            ))}
        </div>
    );
};

export default OtherTopics;
