import { FC, useContext } from 'react';
import { useMutation, graphql } from 'react-relay';
import { FormattedMessage, useIntl, defineMessages } from 'dibs-react-intl';
import { EMAIL_REGEX } from 'dibs-constants/exports/validationRegexes';

import { Button } from 'dibs-elements/exports/Button';
import { Input } from 'dibs-elements/exports/Input';
import { Spinner } from 'dibs-elements/exports/Spinner';
import { TextArea } from 'dibs-elements/exports/TextArea';
import Contact1stdibsFileUpload from './FileUpload';

import GetHelpContext from './GetHelpContext';
import {
    ACTION_SET_STEP,
    ACTION_UPDATE_FORM_DATA,
    RENDER_AS_LOGGED_OUT,
    STEP_ERROR,
    STEP_MESSAGE_SENT,
} from './constants';
import { trackSubmit } from './tracking';

import { FormData } from './types';

import { SendMessageFormMutation } from './__generated__/SendMessageFormMutation.graphql';

import styles from './styles/GetHelpSendMessageForm.scss';

const isValidEmail = (email: string | undefined): boolean => !!(email && email.match(EMAIL_REGEX));

const messages = defineMessages({
    messagePlaceholder: {
        id: 'getHelp.SendMessageForm.messagePlaceholder',
        defaultMessage: 'Let us know how we can help!',
    },
    nameLabel: {
        id: 'getHelp.SendMessageForm.nameLabel',
        defaultMessage: 'Your Name*',
    },
    emailLabel: {
        id: 'getHelp.SendMessageForm.emailLabel',
        defaultMessage: 'Your Email*',
    },
    emailError: {
        id: 'getHelp.SendMessageForm.emailError',
        defaultMessage: 'Please enter a valid email address',
    },
});

const SendMessageForm: FC = () => {
    const intl = useIntl();

    const {
        dispatch,
        state: { renderAs, formData, orderId, itemPk, topicValue, userId, sellerId },
    } = useContext(GetHelpContext);

    const { name, email, message, file, errors } = formData;

    const requiredFields: (keyof FormData)[] =
        renderAs === RENDER_AS_LOGGED_OUT ? ['name', 'email', 'message'] : ['message'];

    const setFieldValue = ({
        key,
        value,
    }: {
        key: keyof FormData;
        value?: string | File;
    }): void => {
        dispatch({ type: ACTION_UPDATE_FORM_DATA, formData: { ...formData, [key]: value } });
    };

    const setError = ({ key, value }: { key: keyof FormData; value: string }): void => {
        dispatch({
            type: ACTION_UPDATE_FORM_DATA,
            formData: { ...formData, errors: { ...formData.errors, [key]: value } },
        });
    };

    const [submitForm, isLoading] = useMutation<SendMessageFormMutation>(
        graphql`
            mutation SendMessageFormMutation($input: CreateContactRequestMutationInput!) {
                CreateContactRequestMutation(input: $input) {
                    recordId
                }
            }
        `
    );

    const validate = (): boolean => {
        let isValid = true;

        if (renderAs === RENDER_AS_LOGGED_OUT) {
            if (!isValidEmail(email)) {
                setError({
                    key: 'email',
                    value: intl.formatMessage(messages.emailError),
                });

                isValid = false;
            }
        }

        return isValid;
    };

    const onSubmit = (): void => {
        if (!validate()) {
            return;
        }

        submitForm({
            variables: {
                input: {
                    orderId,
                    itemPk,
                    name,
                    email,
                    message,
                    topic: topicValue,
                    buyerId: userId,
                    url: window.location.href,
                    attachment: file,
                    sellerId,
                },
            },
            onCompleted() {
                dispatch({ type: ACTION_SET_STEP, step: STEP_MESSAGE_SENT });
                trackSubmit(topicValue || '');
            },
            onError() {
                dispatch({ type: ACTION_SET_STEP, step: STEP_ERROR });
            },
        });
    };

    const isDisabled = !Object.values(
        requiredFields.reduce((acc, field) => ({ ...acc, [field]: formData[field] }), {})
    ).every(value => !!value);

    return (
        <div className={styles.container}>
            {renderAs === RENDER_AS_LOGGED_OUT && (
                <>
                    <div className={styles.inputWrapper}>
                        <Input
                            dataTn="get-help-send-message-form-name-input"
                            label={intl.formatMessage(messages.nameLabel)}
                            onChange={e =>
                                setFieldValue({ key: 'name', value: e.target.value || '' })
                            }
                            hasError={!!errors.name}
                            errorMessage={errors.name}
                        />
                    </div>
                    <div className={styles.inputWrapper}>
                        <Input
                            dataTn="get-help-send-message-form-email-input"
                            label={intl.formatMessage(messages.emailLabel)}
                            onChange={e =>
                                setFieldValue({ key: 'email', value: e.target.value || '' })
                            }
                            hasError={!!errors.email}
                            errorMessage={errors.email}
                        />
                    </div>
                </>
            )}
            <div className={styles.inputWrapper}>
                <TextArea
                    dataTn="get-help-send-message-textarea"
                    label={
                        <FormattedMessage
                            id="getHelp.SendMessageForm.messageLabel"
                            defaultMessage="Your Message*"
                        />
                    }
                    maxLength={30000}
                    placeholder={intl.formatMessage(messages.messagePlaceholder)}
                    onChange={e => setFieldValue({ key: 'message', value: e.target.value || '' })}
                    value={message}
                    errorMessage={errors.message}
                />
            </div>
            <Contact1stdibsFileUpload
                onChange={value => setFieldValue({ key: 'file', value })}
                renderAs={renderAs}
            />
            <Button
                dataTn="get-help-modal-send-message-cta"
                onClick={() => onSubmit()}
                fullWidth
                disabled={isDisabled}
            >
                {isLoading ? (
                    <Spinner size="tiny" containerClass={styles.spinner} />
                ) : (
                    <FormattedMessage
                        id="getHelp.SendMessageForm.sendMessageButton"
                        defaultMessage="send message"
                    />
                )}
            </Button>
        </div>
    );
};

export default SendMessageForm;
