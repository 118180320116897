/**
 * @generated SignedSource<<56b8807f03b3e799970dbdfeb6ced4b7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SelectedListing_viewer$data = {
  readonly getHelpTopics: {
    readonly " $fragmentSpreads": FragmentRefs<"OtherTopics_getHelpTopics" | "PopularArticles_getHelpTopics">;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"CurrentItemInfo_viewer">;
  readonly " $fragmentType": "SelectedListing_viewer";
};
export type SelectedListing_viewer$key = {
  readonly " $data"?: SelectedListing_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"SelectedListing_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "helpType"
    },
    {
      "kind": "RootArgument",
      "name": "isSeller"
    },
    {
      "kind": "RootArgument",
      "name": "itemId"
    },
    {
      "kind": "RootArgument",
      "name": "orderId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "SelectedListing_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CurrentItemInfo_viewer"
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "helpType",
          "variableName": "helpType"
        },
        {
          "kind": "Variable",
          "name": "isSeller",
          "variableName": "isSeller"
        },
        {
          "kind": "Variable",
          "name": "itemId",
          "variableName": "itemId"
        },
        {
          "kind": "Variable",
          "name": "orderId",
          "variableName": "orderId"
        }
      ],
      "concreteType": "GetHelpTopics",
      "kind": "LinkedField",
      "name": "getHelpTopics",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PopularArticles_getHelpTopics"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "OtherTopics_getHelpTopics"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "f4530dddab2be7064d8648d9345895f5";

export default node;
