import { FC, ReactNode } from 'react';
import classnames from 'classnames';

import { Link } from 'dibs-elements/exports/Link';

import dibsCss from 'dibs-css';
import styles from './GetHelpZendeskContactSupportLink.scss';

const GetHelpZendeskContactSupportLink: FC<{
    availability: string;
    disabled?: boolean;
    Icon: FC<{ className?: string }>;
    onClick?: () => void;
    showOnline: boolean;
    title: ReactNode;
    href?: string;
}> = ({ title, Icon, availability, showOnline, onClick, disabled, href = '' }) => {
    return (
        <div className={dibsCss.mbSmall}>
            <Link
                onClick={() => {
                    if (disabled) {
                        return;
                    } else if (onClick) {
                        onClick();
                    }
                }}
                className={classnames(dibsCss.wFull, dibsCss.block)}
                underline="none"
                href={href}
            >
                <div
                    className={classnames(
                        styles.link,
                        dibsCss.wFull,
                        dibsCss.flex,
                        dibsCss.itemsCenter,
                        dibsCss.border,
                        dibsCss.borderDolphin,
                        dibsCss.borderSolid,
                        dibsCss.roundedXxsmall,
                        dibsCss.pySmall,
                        dibsCss.pxMedium,
                        dibsCss.textLeft,
                        {
                            [styles.linkDisabled]: disabled,
                            [dibsCss.bgMoonstone]: disabled,
                            [dibsCss.textSatan]: disabled,
                            [dibsCss.borderMoonstone]: disabled,
                        }
                    )}
                >
                    <Icon
                        className={classnames(dibsCss.wMedium, dibsCss.hMedium, dibsCss.mrMedium)}
                    />
                    <div>
                        <span className={dibsCss.sassyFontBodyMediumHeavy}>{title}</span>
                        <div
                            className={classnames(
                                dibsCss.sassyFontBodySmall,
                                dibsCss.relative,
                                dibsCss.flex,
                                dibsCss.itemsCenter
                            )}
                        >
                            {showOnline && <span className={styles.showOnline} />}
                            {availability}
                        </div>
                    </div>
                </div>
            </Link>
        </div>
    );
};

export { GetHelpZendeskContactSupportLink };
