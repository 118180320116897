/**
 * @generated SignedSource<<08021afd2e1313feaf5f8a4e2eb9cef0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ItemsListItem_item$data = {
  readonly dealerReference: string | null;
  readonly dealerStatusDisplay: string | null;
  readonly dibsReference: string | null;
  readonly firstPhotoWebPath: string | null;
  readonly serviceId: string | null;
  readonly timeSinceOriginalPosting: string | null;
  readonly timeSinceRecentPublication: string | null;
  readonly title: string | null;
  readonly " $fragmentType": "ItemsListItem_item";
};
export type ItemsListItem_item$key = {
  readonly " $data"?: ItemsListItem_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"ItemsListItem_item">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ItemsListItem_item",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "serviceId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "size",
          "value": "small"
        }
      ],
      "kind": "ScalarField",
      "name": "firstPhotoWebPath",
      "storageKey": "firstPhotoWebPath(size:\"small\")"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dealerReference",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dibsReference",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dealerStatusDisplay",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timeSinceRecentPublication",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timeSinceOriginalPosting",
      "storageKey": null
    }
  ],
  "type": "Item",
  "abstractKey": null
};

(node as any).hash = "c77d2454c6e6dc09f68bb21941eb9cc2";

export default node;
