/**
 * @generated SignedSource<<e6644d58030e5d692aa04393fd78f880>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type Currency = "AUD" | "CAD" | "CHF" | "DKK" | "EUR" | "GBP" | "JPY" | "MXN" | "NOK" | "SEK" | "USD" | "%future added value";
export type ReturnStatusEnum = "APPROVED" | "CANCELED" | "COMPLETED" | "INITIATED" | "SELLER_RECEIVED" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type getReturnStatusCopy_transaction$data = {
  readonly acceptQuote: boolean | null;
  readonly buyerCurrency: Currency | null;
  readonly returns: ReadonlyArray<{
    readonly shipment: {
      readonly hasUnknownShipmentTracking: boolean | null;
      readonly isPreTransit: boolean | null;
      readonly shipmentTracking: {
        readonly carrier: {
          readonly name: string | null;
        } | null;
        readonly labelUrl: string | null;
        readonly status: string | null;
      } | null;
    } | null;
    readonly shipmentQuote: {
      readonly isBuyerAccepted: boolean | null;
      readonly isDealerAccepted: boolean | null;
      readonly subTotalAmount: {
        readonly " $fragmentSpreads": FragmentRefs<"ReturnsFormattedPrice_financialAmount">;
      } | null;
    } | null;
    readonly showReturnDownloadShippingLabel: boolean | null;
    readonly status: ReturnStatusEnum | null;
  } | null> | null;
  readonly " $fragmentType": "getReturnStatusCopy_transaction";
};
export type getReturnStatusCopy_transaction$key = {
  readonly " $data"?: getReturnStatusCopy_transaction$data;
  readonly " $fragmentSpreads": FragmentRefs<"getReturnStatusCopy_transaction">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "getReturnStatusCopy_transaction"
};

(node as any).hash = "7997407baa92d233eaf7ce14c851b7f4";

export default node;
