import dibsCss from 'dibs-css';
import classnames from 'classnames';
import { FunctionComponent } from 'react';

type StorefrontProps = {
    className?: string;
};

const Storefront: FunctionComponent<StorefrontProps> = props => {
    return (
        <svg
            className={classnames(dibsCss.dibsIcon, props.className)}
            data-tn={'storefront'}
            viewBox="0 0 250 250"
        >
            <rect x="72" y="147" width="9" height="27" />
            <rect
                x="127.2"
                y="157.5"
                transform="matrix(0.7071 -0.7071 0.7071 0.7071 -66.9206 159.4393)"
                width="63.6"
                height="6"
            />
            <rect
                x="159.2"
                y="170.8"
                transform="matrix(0.7071 -0.7071 0.7071 0.7071 -72.4102 172.6923)"
                width="26.2"
                height="6"
            />
            <rect
                x="132.7"
                y="144.2"
                transform="matrix(0.7071 -0.7071 0.7071 0.7071 -61.4311 146.1864)"
                width="26.2"
                height="6"
            />
            <path
                d="M250,118.5V85.9l-37.8-73.4H37.8L0,85.9v32.6h22v93H0v27h250v-27h-22v-93H250z M43.2,21.5h163.5l31.4,61H11.9L43.2,21.5z
		 M9,91.5h232v18H9V91.5z M31,118.5h59v93H31V118.5z M241,229.5H9v-9h232V229.5z M219,211.5H99v-93h120V211.5z"
            />
        </svg>
    );
};
export default Storefront;
