import { FC, ReactNode, useContext } from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'dibs-react-intl';

import { Button } from 'dibs-elements/exports/Button';
import { TextArea } from 'dibs-elements/exports/TextArea';
import { Spinner } from 'dibs-elements/exports/Spinner';
import { Link } from 'dibs-elements/exports/Link';

import GetHelpContext from './GetHelpContext';

import styles from './styles/ContactFormContent.scss';
import { trackUserMessage } from './tracking';

const messages = defineMessages({
    placeholder: {
        id: 'getHelp.ContactFormContent.textAreaPlaceholder',
        defaultMessage: 'Send a message to the seller to request more details.',
    },
});

type Props = {
    showSuccessMessage: boolean;
    sendMessage: () => void;
    conversationId?: string;
    setMessageContent: (message: string) => void;
    messageContent: string;
    isLoading: boolean;
    errorMessage: ReactNode;
};

const ContactFormContent: FC<Props> = ({
    showSuccessMessage,
    sendMessage,
    conversationId,
    setMessageContent,
    messageContent,
    isLoading,
    errorMessage,
}) => {
    const intl = useIntl();
    const {
        state: { isSeller },
    } = useContext(GetHelpContext);

    if (showSuccessMessage) {
        return (
            <div>
                <div
                    className={styles.successTitle}
                    data-tn="get-help-modal-contact-seller-form-success-message"
                >
                    <FormattedMessage
                        id="getHelp.ContactFormContent.successTitle"
                        defaultMessage="Your message was sent!"
                    />
                </div>
                <FormattedMessage
                    id="getHelp.ContactFormContent.successInfo"
                    defaultMessage="We'll send you an email when the {isSeller, select, true {client} other {seller}} responds. View all your messages at any time in <a>Inbox</a>."
                    values={{
                        isSeller,
                        a: content => (
                            <Link
                                dataTn="get-help-modal-inbox-link"
                                target="_blank"
                                className={styles.inboxLink}
                                href={
                                    isSeller
                                        ? `/dealers/conversations/${conversationId}`
                                        : `/inbox/${conversationId}/`
                                }
                            >
                                {content}
                            </Link>
                        ),
                    }}
                />
            </div>
        );
    }

    return (
        <div className={styles.container}>
            <TextArea
                dataTn="get-help-contact-form-textarea"
                onChange={e => setMessageContent(e.target.value)}
                placeholder={intl.formatMessage(messages.placeholder)}
                rows={3}
                value={messageContent}
                hideBorder
            />
            <div className={styles.buttonWrapper}>
                <Button
                    dataTn="get-help-modal-send-message-cta"
                    onClick={() => {
                        trackUserMessage();
                        sendMessage();
                    }}
                    size="medium"
                    className={styles.button}
                    disabled={!messageContent || isLoading}
                >
                    {isLoading ? (
                        <Spinner size="tiny" containerClass={styles.spinner} />
                    ) : (
                        <FormattedMessage
                            id="getHelp.ContactFormContent.send"
                            defaultMessage="Send"
                        />
                    )}
                </Button>
            </div>
            {!!errorMessage && (
                <div className={styles.error} data-tn="get-help-modal-send-message-error">
                    {errorMessage}
                </div>
            )}
        </div>
    );
};

export default ContactFormContent;
