import { FC, ReactNode, useContext, useState } from 'react';
import { useMutation, graphql, useFragment } from 'react-relay';
import classnames from 'classnames';

import { FormattedMessage, useIntl } from 'dibs-react-intl';

import ContactFormContent from './ContactFormContent';

import GetHelpContext from './GetHelpContext';
import {
    ACTION_SET_STEP,
    ACTION_SET_CONVERSATION_ID,
    STEP_CONTACT_SELLER_MESSAGE_SENT,
} from './constants';
import { trackContactSellerFormSubmitted } from './tracking';

import { UpdatedContactSellerFormMutation } from './__generated__/UpdatedContactSellerFormMutation.graphql';
import { UpdatedContactSellerForm_transaction$key } from './__generated__/UpdatedContactSellerForm_transaction.graphql';
import { UpdatedContactSellerForm_getHelpTopic$key } from './__generated__/UpdatedContactSellerForm_getHelpTopic.graphql';

import dibsCss from 'dibs-css';
import RecaptchaForm, {
    MFAErrorCodes,
    isValidErrorCode,
} from 'dibs-recaptcha/exports/RecaptchaForm';
import { recaptchaV3 } from 'dibs-recaptcha/exports/recaptcha';
import {
    getSendMessageErrorMessage,
    isSendMessageError,
} from 'dibs-error-tools/exports/ErrorMessages/getSendMessageErrorMessage';

const UpdatedContactSellerForm: FC<{
    transaction: UpdatedContactSellerForm_transaction$key | null | undefined;
    getHelpTopic: UpdatedContactSellerForm_getHelpTopic$key | null | undefined;
}> = ({ transaction: transactionRef, getHelpTopic: getHelpTopicKey }) => {
    const intl = useIntl();
    const {
        dispatch,
        state: { userId },
    } = useContext(GetHelpContext);
    const [messageContent, setMessageContent] = useState('');
    const [errorMessage, setErrorMessage] = useState<ReactNode>(null);
    const [mfaErrorCode, setMFAErrorCode] = useState<MFAErrorCodes | null>(null);

    const [contactDealer, isLoading] = useMutation<UpdatedContactSellerFormMutation>(
        graphql`
            mutation UpdatedContactSellerFormMutation($input: ContactDealerInput!) {
                contactDealer(input: $input) {
                    conversation {
                        serviceId
                    }
                }
            }
        `
    );

    const transaction = useFragment(
        graphql`
            fragment UpdatedContactSellerForm_transaction on Transaction {
                buyer {
                    profile {
                        displayName
                        email
                    }
                }
                item {
                    serviceId
                    seller {
                        serviceId
                    }
                }
            }
        `,
        transactionRef
    );

    const getHelpTopic = useFragment(
        graphql`
            fragment UpdatedContactSellerForm_getHelpTopic on GetHelpTopic {
                supportRequestTypeId
            }
        `,
        getHelpTopicKey
    );

    const { supportRequestTypeId } = getHelpTopic || {};
    const { item, buyer } = transaction || {};
    const { serviceId: itemId, seller } = item || {};
    const sellerId = seller?.serviceId || '';
    const displayName = buyer?.profile?.displayName;

    const sendMessage = async (mfaVerificationCode?: string): Promise<void> => {
        setErrorMessage(null);
        const captchaResponse = await recaptchaV3({
            action: 'contactDealer',
            userName: displayName,
            mfaVerificationCode,
            supportedMFATypes: ['CODE'],
        });
        await new Promise<void>(resolve =>
            contactDealer({
                variables: {
                    input: {
                        buyerId: userId,
                        messageContent,
                        sellerPk: sellerId,
                        displayName,
                        itemPk: itemId,
                        supportRequestTypeId,
                        mfaVerificationCode,
                        ...captchaResponse,
                    },
                },
                onCompleted(response) {
                    trackContactSellerFormSubmitted();
                    setMFAErrorCode(null);
                    resolve();
                    dispatch({
                        type: ACTION_SET_CONVERSATION_ID,
                        conversationId: response.contactDealer?.conversation?.serviceId || '',
                    });
                    dispatch({ type: ACTION_SET_STEP, step: STEP_CONTACT_SELLER_MESSAGE_SENT });
                },
                onError(error) {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    const sourceError = (error as any)?.source?.errors?.[0]?.message || '';
                    if (isValidErrorCode(sourceError)) {
                        setMFAErrorCode(sourceError);
                    } else {
                        const sendMessageErrorMessage = isSendMessageError(sourceError) ? (
                            getSendMessageErrorMessage({ code: sourceError, intl })
                        ) : (
                            <FormattedMessage
                                id="getHelp.UpdateContactSellerForm.error"
                                defaultMessage="Something went wrong."
                            />
                        );
                        setErrorMessage(sendMessageErrorMessage);
                    }
                    resolve();
                },
            })
        );
    };

    return mfaErrorCode ? (
        <RecaptchaForm
            onSubmit={mfaVerificationCode => sendMessage(mfaVerificationCode)}
            errorCode={mfaErrorCode}
            email={buyer?.profile?.email || ''}
            mfaActionType="displayed"
        />
    ) : (
        <div
            className={classnames(
                dibsCss.ptMedium,
                dibsCss.mtXsmall,
                dibsCss.borderT,
                dibsCss.borderTSolid,
                dibsCss.borderTDolphin
            )}
        >
            <div className={classnames(dibsCss.sassyFontHeaderMedium, dibsCss.mbXsmall)}>
                <FormattedMessage
                    id="getHelp.UpdatedContactSellerForm.contactSellerHeader"
                    defaultMessage="Contact Seller"
                />
            </div>
            <div className={dibsCss.mbMedium}>
                <FormattedMessage
                    id="getHelp.UpdatedContactSellerForm.contactSellerInfo"
                    defaultMessage="We recommend contacting seller for a quicker response solving your issue."
                />
            </div>
            <ContactFormContent
                showSuccessMessage={false}
                sendMessage={sendMessage}
                setMessageContent={setMessageContent}
                messageContent={messageContent}
                isLoading={isLoading}
                errorMessage={errorMessage}
            />
        </div>
    );
};

export default UpdatedContactSellerForm;
