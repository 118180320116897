/**
 * @generated SignedSource<<035c26a706cb06a74cafd6505b645c8c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OtherHelp_viewer$data = {
  readonly contact1stdibsConfig: {
    readonly isAgentAvailable: boolean | null;
    readonly showChannelChat: boolean | null;
  } | null;
  readonly getHelpTopic: {
    readonly isOtherIssue: boolean | null;
    readonly showChannelEmail: boolean | null;
    readonly showChannelLiveChat: boolean | null;
    readonly showChannelMessage: boolean | null;
    readonly showChannelWhatsApp: boolean | null;
  } | null;
  readonly seller?: {
    readonly sellerProfile: {
      readonly email: string | null;
      readonly firstName: string | null;
      readonly lastName: string | null;
    } | null;
  } | null;
  readonly user?: {
    readonly profile: {
      readonly email: string | null;
      readonly firstName: string | null;
      readonly lastName: string | null;
    } | null;
  } | null;
  readonly " $fragmentType": "OtherHelp_viewer";
};
export type OtherHelp_viewer$key = {
  readonly " $data"?: OtherHelp_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"OtherHelp_viewer">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "email",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "firstName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "lastName",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "hasSellerId"
    },
    {
      "kind": "RootArgument",
      "name": "hasUserId"
    },
    {
      "kind": "RootArgument",
      "name": "isSeller"
    },
    {
      "kind": "RootArgument",
      "name": "orderId"
    },
    {
      "kind": "RootArgument",
      "name": "sellerId"
    },
    {
      "kind": "RootArgument",
      "name": "topicKey"
    },
    {
      "kind": "RootArgument",
      "name": "userId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "OtherHelp_viewer",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "isSeller",
          "value": false
        }
      ],
      "concreteType": "Contact1stdibsConfig",
      "kind": "LinkedField",
      "name": "contact1stdibsConfig",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isAgentAvailable",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "showChannelChat",
          "storageKey": null
        }
      ],
      "storageKey": "contact1stdibsConfig(isSeller:false)"
    },
    {
      "condition": "hasUserId",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "userId",
              "variableName": "userId"
            }
          ],
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Profile",
              "kind": "LinkedField",
              "name": "profile",
              "plural": false,
              "selections": (v0/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ]
    },
    {
      "condition": "hasSellerId",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "sellerId",
              "variableName": "sellerId"
            }
          ],
          "concreteType": "Seller",
          "kind": "LinkedField",
          "name": "seller",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "SellerProfile",
              "kind": "LinkedField",
              "name": "sellerProfile",
              "plural": false,
              "selections": (v0/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ]
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "isSeller",
          "variableName": "isSeller"
        },
        {
          "kind": "Variable",
          "name": "orderId",
          "variableName": "orderId"
        },
        {
          "kind": "Variable",
          "name": "topicKey",
          "variableName": "topicKey"
        }
      ],
      "concreteType": "GetHelpTopic",
      "kind": "LinkedField",
      "name": "getHelpTopic",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "showChannelLiveChat",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "showChannelWhatsApp",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "showChannelMessage",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "showChannelEmail",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isOtherIssue",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
})();

(node as any).hash = "159aff0e18551ab3ab0fed66bbf6c807";

export default node;
