import {
    FC,
    useEffect,
    useRef,
    useState,
    useContext,
    useCallback,
    ChangeEvent,
    useTransition,
} from 'react';
import classnames from 'classnames';
import { useRefetchableFragment, graphql } from 'react-relay';
import { defineMessages, useIntl } from 'dibs-react-intl';

import { Spinner } from 'dibs-elements/exports/Spinner';
import { Input } from 'dibs-elements/exports/Input';
import { Link } from 'dibs-elements/exports/Link';
import MagnifyingGlass from 'dibs-icons/exports/legacy/MagnifyingGlass';
import Close from 'dibs-icons/exports/legacy/Close';

import { GetHelpZendeskContext } from './GetHelpZendeskContext';
import { ACTIONS } from './GetHelpZendeskConstants';
import { separateSearchQueryFromString } from './getHelpZendeskHelpers';
import { trackGetHelpZendeskEvent } from './getHelpZendeskTracking';

import { useDebouncedCallback } from 'dibs-react-hooks/exports/useDebouncedCallback';

import { GetHelpZendeskArticlesSearch_viewer$key } from './__generated__/GetHelpZendeskArticlesSearch_viewer.graphql';

import dibsCss from 'dibs-css';
import styles from './GetHelpZendeskArticlesSearch.scss';

const MIN_SEARCH_LENGTH = 3;

const messages = defineMessages({
    placeholder: {
        id: 'getHelp.GetHelpZendeskArticlesSearch.placeholder',
        defaultMessage: 'Search 1stDibs Help',
    },
});

const GetHelpZendeskArticlesSearch: FC<{ viewer: GetHelpZendeskArticlesSearch_viewer$key }> = ({
    viewer: viewerRef,
}) => {
    const intl = useIntl();
    const {
        state: { searchQuery },
        dispatch,
    } = useContext(GetHelpZendeskContext);
    const [query, setQuery] = useState(searchQuery);
    const [showResults, setShowResults] = useState(false);
    const [fetching, startTransition] = useTransition();
    const searchRef = useRef<HTMLDivElement | null>(null);

    const [viewer, refetch] = useRefetchableFragment(
        graphql`
            fragment GetHelpZendeskArticlesSearch_viewer on Viewer
            @argumentDefinitions(query: { type: "String", defaultValue: "" })
            @refetchable(queryName: "GetHelpZendeskArticlesSearchRefetchQuery") {
                zendeskArticles {
                    articlesSearch: articles(query: $query) {
                        serviceId
                        title
                    }
                }
            }
        `,
        viewerRef
    );

    useEffect(() => {
        setQuery(searchQuery);
    }, [searchQuery]);

    const fetchData = useCallback(
        (value: string) => {
            startTransition(() => {
                refetch({ query: value });
            });
        },
        [refetch, startTransition]
    );

    const [debouncedFetchData] = useDebouncedCallback(fetchData, 200);

    const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
        const value = e.target.value;

        setQuery(value);
        if (value.length >= MIN_SEARCH_LENGTH || !value) {
            debouncedFetchData(value);
        }
    };

    const onSearch = (): void => {
        dispatch({ type: ACTIONS.SET_SEARCH_RESULTS_STEP, searchQuery: query });
        trackGetHelpZendeskEvent({
            stepInteractionName: 'search query entered',
        });
    };

    const suggestions = (viewer?.zendeskArticles?.articlesSearch || []).slice(0, 5);

    const searchIcon = (
        <div
            className={classnames(
                styles.searchButton,
                dibsCss.flex,
                dibsCss.itemsCenter,
                dibsCss.justifyCenter,
                { [dibsCss.bgNoir]: !!query }
            )}
        >
            <MagnifyingGlass
                className={classnames(dibsCss.h20Px, dibsCss.w20Px, {
                    [dibsCss.fillWhite]: !!query,
                })}
            />
        </div>
    );

    return (
        <div ref={searchRef}>
            <Input
                size="medium"
                dataTn="get-help-zendesk-articles-search-input"
                placeholder={intl.formatMessage(messages.placeholder)}
                value={query}
                onChange={onChange}
                rightDecoratorClass={styles.rightDecorator}
                onBlur={e => {
                    if (searchRef?.current && !searchRef.current.contains(e.relatedTarget)) {
                        setShowResults(false);
                    }
                }}
                onFocus={() => {
                    setShowResults(true);
                }}
                rightDecorator={
                    <div className={classnames(dibsCss.flex, dibsCss.itemsCenter)}>
                        {query && (
                            <>
                                <Link
                                    onClick={e => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        setQuery('');
                                        setShowResults(false);
                                    }}
                                >
                                    <div className={dibsCss.flex}>
                                        <Close
                                            className={classnames(
                                                dibsCss.w14px,
                                                dibsCss.h14px,
                                                dibsCss.mrXsmall
                                            )}
                                        />
                                    </div>
                                </Link>
                                <Link onClick={onSearch}>{searchIcon}</Link>
                            </>
                        )}
                        {!query && searchIcon}
                    </div>
                }
                onKeyDown={e => {
                    if (e.key === 'Enter') {
                        onSearch();
                    }
                }}
            />
            {showResults && (!!suggestions.length || fetching) && (
                <div className={dibsCss.relative}>
                    <div
                        className={classnames(
                            dibsCss.absolute,
                            dibsCss.top0,
                            dibsCss.pyXsmall,
                            dibsCss.border,
                            dibsCss.borderSolid,
                            dibsCss.borderNoir,
                            dibsCss.borderT0,
                            dibsCss.wFull,
                            dibsCss.bgWhite
                        )}
                    >
                        {fetching && (
                            <div
                                className={classnames(
                                    dibsCss.flex,
                                    dibsCss.justifyCenter,
                                    dibsCss.wFull
                                )}
                            >
                                <Spinner size="tiny" />
                            </div>
                        )}
                        {!fetching &&
                            suggestions.map(suggestion => (
                                <Link
                                    key={suggestion?.serviceId || ''}
                                    onClick={() => {
                                        setQuery('');
                                        dispatch({
                                            type: ACTIONS.OPEN_ARTICLE,
                                            articleId: (suggestion?.serviceId || '').split('-')[0],
                                        });
                                        trackGetHelpZendeskEvent({
                                            stepInteractionName: 'search result clicked',
                                            trigger: 'search dropdown',
                                        });
                                    }}
                                    className={dibsCss.block}
                                    underline="none"
                                >
                                    <div
                                        className={classnames(
                                            styles.label,
                                            dibsCss.pyXxsmall,
                                            dibsCss.pxSmall,
                                            dibsCss.sassyFontBodyMedium,
                                            dibsCss.textLeft
                                        )}
                                        dangerouslySetInnerHTML={{
                                            __html: separateSearchQueryFromString(
                                                suggestion?.title || '',
                                                query
                                            ),
                                        }}
                                    />
                                </Link>
                            ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export { GetHelpZendeskArticlesSearch };
