/**
 * @generated SignedSource<<ac1bd68a7ae1b31e336dfb2294396a75>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CurrentItemInfo_viewer$data = {
  readonly item?: {
    readonly dealerReference: string | null;
    readonly dealerStatusDisplay: string | null;
    readonly dibsReference: string | null;
    readonly firstPhotoWebPath: string | null;
    readonly title: string | null;
  } | null;
  readonly " $fragmentType": "CurrentItemInfo_viewer";
};
export type CurrentItemInfo_viewer$key = {
  readonly " $data"?: CurrentItemInfo_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"CurrentItemInfo_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "hasItemId"
    },
    {
      "kind": "RootArgument",
      "name": "itemId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "CurrentItemInfo_viewer",
  "selections": [
    {
      "condition": "hasItemId",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "itemId",
              "variableName": "itemId"
            }
          ],
          "concreteType": "Item",
          "kind": "LinkedField",
          "name": "item",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "title",
              "storageKey": null
            },
            {
              "alias": null,
              "args": [
                {
                  "kind": "Literal",
                  "name": "size",
                  "value": "small"
                }
              ],
              "kind": "ScalarField",
              "name": "firstPhotoWebPath",
              "storageKey": "firstPhotoWebPath(size:\"small\")"
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "dealerReference",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "dibsReference",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "dealerStatusDisplay",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ]
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "758061b17bc6f2bb92c00dbaee6562b6";

export default node;
