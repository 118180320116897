import { FC, ReactNode, MouseEventHandler } from 'react';
import classnames from 'classnames';

import { defineMessages, useIntl } from 'dibs-react-intl';
import InfoCircle from 'dibs-icons/exports/legacy/InfoCircle';
import Close from 'dibs-icons/exports/legacy/Close';

import dibsCss from 'dibs-css';

export const VARIANTS = [
    'default',
    'informational',
    'warning',
    'alert',
    'success',
    'transparent',
] as const;

export const SIZES = ['default', 'compact'] as const;

type Variant = (typeof VARIANTS)[number];
type Sizes = (typeof SIZES)[number];

const messages = defineMessages({
    label: {
        id: 'InlineAlert.CloseButton.label',
        defaultMessage: 'Close',
    },
});

const iconClasses = classnames(
    dibsCss.dibsIcon,
    dibsCss.wSmall,
    dibsCss.hSmall,
    dibsCss.alignMiddle
);

export const InlineAlert: FC<{
    children?: ReactNode;
    variant?: Variant;
    size?: Sizes;
    /** defaults to `InfoCircle`. use `null` to omit icon. */
    icon?: FC | null;
    /** `undefined`/`null`/absent `onClose` removes close button */
    onClose?: MouseEventHandler<HTMLButtonElement>;
}> = ({ onClose, children, size = 'default', variant = 'default', icon: Icon = InfoCircle }) => {
    const { formatMessage } = useIntl();
    return (
        <div
            className={classnames(
                dibsCss.sassyFontBodySmall,
                dibsCss.flex,
                dibsCss.roundedXxsmall,
                {
                    // variant classes
                    [dibsCss.bgMoonstone]: variant === 'default',
                    [dibsCss.bgBlue50]: variant === 'informational',
                    [dibsCss.bgYellow50]: variant === 'warning',
                    [dibsCss.bgRed50]: variant === 'alert',
                    [dibsCss.bgGreen50]: variant === 'success',
                    [dibsCss.borderDolphin]: variant === 'transparent',
                    [dibsCss.borderSolid]: variant === 'transparent',
                    [dibsCss.border]: variant === 'transparent',
                },
                {
                    // size classes
                    [dibsCss.pSmall]: size === 'default',
                    [dibsCss.pyXsmall]: size === 'compact',
                    [dibsCss.pxSmall]: size === 'compact',
                }
            )}
        >
            {Icon && (
                <div
                    style={{ height: '1.5em' }}
                    className={classnames(
                        dibsCss.mrXsmall,
                        dibsCss.shrink0,
                        dibsCss.grow0,
                        dibsCss.bgTransparent,
                        dibsCss.borderNone
                    )}
                >
                    <Icon
                        className={classnames(iconClasses, {
                            // variant classes
                            [dibsCss.textBlue700]: variant === 'informational',
                            [dibsCss.textYellow700]: variant === 'warning',
                            [dibsCss.textRed700]: variant === 'alert',
                            [dibsCss.textGreen700]: variant === 'success',
                        })}
                    />
                </div>
            )}
            <p className={classnames(dibsCss.wFull, dibsCss.m0)}>{children}</p>
            {onClose && (
                // eslint-disable-next-line react/forbid-elements
                <button
                    type="button"
                    style={{ height: '1.5em' }}
                    className={classnames(
                        dibsCss.selfStart,
                        dibsCss.cursorPointer,
                        dibsCss.mlXsmall,
                        dibsCss.bgTransparent,
                        dibsCss.borderNone,
                        dibsCss.p0,
                        dibsCss.shrink0,
                        dibsCss.grow0
                    )}
                    aria-label={formatMessage(messages.label)}
                    data-tn="inline-alert-close-button"
                    onClick={onClose}
                >
                    <Close className={iconClasses} />
                </button>
            )}
        </div>
    );
};
