/**
 * @generated SignedSource<<c0664c25a34e1dfe2beb392afb4d6d83>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ItemReturnPolicy_viewer$data = {
  readonly transaction?: {
    readonly returnPolicy: {
      readonly " $fragmentSpreads": FragmentRefs<"ReturnPolicyEmbed_returnPolicy">;
    } | null;
  } | null;
  readonly " $fragmentType": "ItemReturnPolicy_viewer";
};
export type ItemReturnPolicy_viewer$key = {
  readonly " $data"?: ItemReturnPolicy_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"ItemReturnPolicy_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "hasOrderId"
    },
    {
      "kind": "RootArgument",
      "name": "transactionId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ItemReturnPolicy_viewer",
  "selections": [
    {
      "condition": "hasOrderId",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "transactionId",
              "variableName": "transactionId"
            }
          ],
          "concreteType": "Transaction",
          "kind": "LinkedField",
          "name": "transaction",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ReturnPolicy",
              "kind": "LinkedField",
              "name": "returnPolicy",
              "plural": false,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "ReturnPolicyEmbed_returnPolicy"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ]
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "acecfd0abbb4020c4d0ea90053d6ee50";

export default node;
