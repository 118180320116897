/**
 * @generated SignedSource<<93065d9c75b4638d817173481e38746f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GetHelpZendeskOrderTile_transaction$data = {
  readonly item: {
    readonly firstPhotoWebPath: string | null;
    readonly title: string | null;
  } | null;
  readonly serviceId: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"getOrderStatusLabel_transaction">;
  readonly " $fragmentType": "GetHelpZendeskOrderTile_transaction";
};
export type GetHelpZendeskOrderTile_transaction$key = {
  readonly " $data"?: GetHelpZendeskOrderTile_transaction$data;
  readonly " $fragmentSpreads": FragmentRefs<"GetHelpZendeskOrderTile_transaction">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "serviceId",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "label",
    "storageKey": null
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "buyerCopy",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "amount",
    "storageKey": null
  }
],
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "TransactionHistory",
  "kind": "LinkedField",
  "name": "transactionHistory",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "HistoricalEvent",
      "kind": "LinkedField",
      "name": "historicalEvents",
      "plural": true,
      "selections": [
        (v2/*: any*/),
        (v3/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GetHelpZendeskOrderTile_transaction",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Item",
      "kind": "LinkedField",
      "name": "item",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "size",
              "value": "small"
            }
          ],
          "kind": "ScalarField",
          "name": "firstPhotoWebPath",
          "storageKey": "firstPhotoWebPath(size:\"small\")"
        }
      ],
      "storageKey": null
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "getOrderStatusLabel_transaction",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isCanceled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "TransactionCurrentBuyerTimelineStatus",
          "kind": "LinkedField",
          "name": "currentBuyerTimelineStatus",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "TransactionCurrentSellerTimelineStatus",
          "kind": "LinkedField",
          "name": "currentSellerTimelineStatus",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "kind": "InlineDataFragmentSpread",
          "name": "getBuyerEventHeader_transaction",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "TransactionHistory",
              "kind": "LinkedField",
              "name": "transactionHistory",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "HistoricalEvent",
                  "kind": "LinkedField",
                  "name": "historicalEvents",
                  "plural": true,
                  "selections": [
                    (v2/*: any*/),
                    (v3/*: any*/),
                    {
                      "alias": null,
                      "args": [
                        {
                          "kind": "Literal",
                          "name": "isBomtOptimizationVariant",
                          "value": true
                        }
                      ],
                      "concreteType": "buyerTransactionStatus",
                      "kind": "LinkedField",
                      "name": "buyerStatusCopy",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "header",
                          "storageKey": null
                        }
                      ],
                      "storageKey": "buyerStatusCopy(isBomtOptimizationVariant:true)"
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Return",
              "kind": "LinkedField",
              "name": "returns",
              "plural": true,
              "selections": [
                (v0/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isCheckoutProcessing",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "didCheckoutProcessingFail",
              "storageKey": null
            },
            {
              "kind": "InlineDataFragmentSpread",
              "name": "getLastBuyerEvent_transaction",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "TransactionSummaryObject",
                  "kind": "LinkedField",
                  "name": "summary",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "FinancialAmount",
                      "kind": "LinkedField",
                      "name": "balanceDueAmount",
                      "plural": false,
                      "selections": (v4/*: any*/),
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "PaymentAuthorization",
                      "kind": "LinkedField",
                      "name": "checkoutPaymentAuthorization",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": null,
                          "kind": "LinkedField",
                          "name": "paymentMethod",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "paymentMethodType",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                (v5/*: any*/)
              ],
              "args": null,
              "argumentDefinitions": []
            },
            {
              "kind": "InlineDataFragmentSpread",
              "name": "getReturnStatusCopy_transaction",
              "selections": [
                {
                  "alias": "acceptQuote",
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "actionCode",
                      "value": "BUYER_APPROVE_RETURN_QUOTE"
                    }
                  ],
                  "kind": "ScalarField",
                  "name": "hasAction",
                  "storageKey": "hasAction(actionCode:\"BUYER_APPROVE_RETURN_QUOTE\")"
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "buyerCurrency",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Return",
                  "kind": "LinkedField",
                  "name": "returns",
                  "plural": true,
                  "selections": [
                    (v6/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "showReturnDownloadShippingLabel",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ShipmentQuote",
                      "kind": "LinkedField",
                      "name": "shipmentQuote",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "isBuyerAccepted",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "isDealerAccepted",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "FinancialAmount",
                          "kind": "LinkedField",
                          "name": "subTotalAmount",
                          "plural": false,
                          "selections": [
                            {
                              "args": null,
                              "kind": "FragmentSpread",
                              "name": "ReturnsFormattedPrice_financialAmount"
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Shipment",
                      "kind": "LinkedField",
                      "name": "shipment",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "hasUnknownShipmentTracking",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "isPreTransit",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ShipmentTracking",
                          "kind": "LinkedField",
                          "name": "shipmentTracking",
                          "plural": false,
                          "selections": [
                            (v6/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "labelUrl",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "Carrier",
                              "kind": "LinkedField",
                              "name": "carrier",
                              "plural": false,
                              "selections": [
                                (v2/*: any*/)
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "args": null,
              "argumentDefinitions": []
            },
            {
              "kind": "InlineDataFragmentSpread",
              "name": "getDeniedPaymentAuthorizationStatus_transaction",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PaymentAuthorization",
                  "kind": "LinkedField",
                  "name": "paymentAuthorizations",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "PaymentProviderTransaction",
                      "kind": "LinkedField",
                      "name": "providerTransaction",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "PaymentProviderTransactionResponse",
                          "kind": "LinkedField",
                          "name": "responses",
                          "plural": true,
                          "selections": [
                            (v6/*: any*/)
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "args": null,
              "argumentDefinitions": []
            },
            {
              "kind": "InlineDataFragmentSpread",
              "name": "isMissingRefundEventInProgress_transaction",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "TransactionSummaryObject",
                  "kind": "LinkedField",
                  "name": "summary",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "InvoiceSummary",
                      "kind": "LinkedField",
                      "name": "invoiceSummary",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "FinancialAmount",
                          "kind": "LinkedField",
                          "name": "amountToSettleBuyerCurrentInvoice",
                          "plural": false,
                          "selections": (v4/*: any*/),
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                (v5/*: any*/)
              ],
              "args": null,
              "argumentDefinitions": []
            }
          ],
          "args": null,
          "argumentDefinitions": []
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "Transaction",
  "abstractKey": null
};
})();

(node as any).hash = "14486ddc6b51109b186e0cb78dfc13c3";

export default node;
