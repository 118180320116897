import { FC, ReactNode, useState, useRef, useEffect, MouseEventHandler } from 'react';
import classnames from 'classnames';
import Copy from 'dibs-icons/exports/legacy/Copy';
import { Tooltip, Alignment, TooltipDirection } from 'dibs-elements/exports/Tooltip';

import styles from './CopyLink.scss';

type CopyLinkProps = {
    copyNoir?: boolean;
    copyDealer?: boolean;
    dataTn?: string;
    linkNode?: ReactNode;
    textToCopy: string;
    infoText?: string;
    confirmationText?: string;
    hideLink?: boolean;
    iconStart?: boolean;
    icon?: ReactNode;
    tooltipOptions?: {
        align?: Alignment;
        direction?: TooltipDirection;
    };
    displayInline?: boolean;
    onClick?: MouseEventHandler<HTMLDivElement>;
};

const CopyLink: FC<CopyLinkProps> = props => {
    const {
        copyNoir,
        copyDealer,
        textToCopy,
        linkNode,
        confirmationText = 'Copied',
        infoText,
        dataTn,
        hideLink = false,
        iconStart = false,
        icon = null,
        tooltipOptions,
        displayInline = false,
        onClick = () => {},
    } = props;
    const [isTooltipShowing, setIsTooltipShowing] = useState(false);

    const timeoutRef = useRef<NodeJS.Timeout | null>(null);

    function clearTimeoutRef(): void {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
    }

    function flashTooltip(): void {
        clearTimeoutRef();
        setIsTooltipShowing(true);
        timeoutRef.current = setTimeout(() => {
            setIsTooltipShowing(false);
        }, 1500);
    }

    useEffect(() => {
        return () => clearTimeoutRef();
    }, []);

    async function onCopy(): Promise<void> {
        try {
            await navigator.clipboard.writeText(textToCopy);
            flashTooltip();
        } catch (err) {
            // eslint-disable-next-line no-console
            console.error('Failed to copy: ', err);
        }
    }

    const copyClasses = classnames({
        [styles.copyNoir]: copyNoir,
        [styles.copyDealer]: copyDealer,
    });

    const copyIcon = icon || (
        <Copy className={classnames(styles.copyIcon, { [styles.iconStart]: iconStart })} />
    );

    return (
        <div
            className={styles.copyInfo}
            data-tn={dataTn}
            style={displayInline ? { display: 'inline-flex' } : {}}
            onClick={e => {
                onCopy();
                onClick(e);
            }}
        >
            <div title={infoText || 'Click to copy'} className={copyClasses}>
                {!hideLink && (linkNode || textToCopy)}
            </div>
            {!displayInline && copyIcon}
            <Tooltip
                isVisible={isTooltipShowing}
                type="small"
                align={tooltipOptions?.align || 'right'}
                direction={tooltipOptions?.direction || 'top'}
            >
                {confirmationText}
            </Tooltip>
        </div>
    );
};

export default CopyLink;
