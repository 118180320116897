// actions
export const ACTION_SET_STEP = 'SET_STEP' as const;
export const ACTION_SET_HELP_TYPE = 'SET_HELP_TYPE' as const;
export const ACTION_SET_ORDER_ID = 'SET_ORDER_ID' as const;
export const ACTION_SET_TOPIC = 'SET_TOPIC' as const;
export const ACTION_UPDATE_FORM_DATA = 'UPDATE_FORM_DATA' as const;
export const ACTION_GO_BACK = 'GO_BACK' as const;
export const ACTION_SET_CONVERSATION_ID = 'ACTION_SET_CONVERSATION_ID' as const;
// seller specific
export const ACTION_SET_ITEM_ID = 'ACTION_SET_ITEM_ID' as const;

// steps
export const STEP_INTRO = 'intro' as const;
export const STEP_OTHER_ASSISTANCE = 'otherAssistance' as const;
export const STEP_ORDERS_LIST = 'ordersList' as const;
export const STEP_SELECTED_ORDER = 'selectedOrder' as const;
export const STEP_TOPIC = 'topic' as const;
export const STEP_MESSAGE_SENT = 'messageSent' as const;
export const STEP_CONTACT_SELLER_MESSAGE_SENT = 'contactSellerMessageSent' as const;
export const STEP_ERROR = 'error' as const;
export const STEP_CHECKOUT_ASSISTANCE = 'checkoutAssistance' as const;
// seller specific
export const STEP_ITEMS_LIST = 'itemsList' as const;
export const STEP_SELECTED_LISTING = 'selectedListing' as const;

// other
export const HELP_TYPE_ORDER_ISSUES = 'orderIssues' as const;
export const HELP_TYPE_OTHER_ASSISTANCE = 'otherAssistance' as const;
export const HELP_TYPE_CHECKOUT_ASSISTANCE = 'checkoutAssistance' as const;
// seller specific
export const HELP_TYPE_LISTINGS = 'listings' as const;

export const RENDER_AS_CONSUMER = 'consumer' as const;
export const RENDER_AS_LOGGED_OUT = 'loggedOut' as const;
export const RENDER_AS_SELLER = 'seller' as const;

export const CHANNEL_LIVE_CHAT = 'liveChat' as const;
export const CHANNEL_MESSAGE = 'message' as const;
export const CHANNEL_WHATSAPP = 'whatsApp' as const;
export const CHANNEL_EMAIL = 'email' as const;

export const TOPIC_VALUE_CHECKOUT_ASSISTANCE = 'checkoutAssistance' as const;
