/**
 * @generated SignedSource<<ed0e8f516e5f7f912f8ed7ee55fb9145>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TransactionTimeline_transaction$data = {
  readonly buyerTimelineSteps: ReadonlyArray<{
    readonly isCompleted: boolean | null;
    readonly label: string | null;
  } | null> | null;
  readonly isCanceled: boolean | null;
  readonly sellerTimelineSteps: ReadonlyArray<{
    readonly isCompleted: boolean | null;
    readonly label: string | null;
  } | null> | null;
  readonly " $fragmentType": "TransactionTimeline_transaction";
};
export type TransactionTimeline_transaction$key = {
  readonly " $data"?: TransactionTimeline_transaction$data;
  readonly " $fragmentSpreads": FragmentRefs<"TransactionTimeline_transaction">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "isCompleted",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "label",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TransactionTimeline_transaction",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isCanceled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TransactionBuyerTimelineSteps",
      "kind": "LinkedField",
      "name": "buyerTimelineSteps",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TransactionSellerTimelineSteps",
      "kind": "LinkedField",
      "name": "sellerTimelineSteps",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": null
    }
  ],
  "type": "Transaction",
  "abstractKey": null
};
})();

(node as any).hash = "1cb5b3deddcc35487b80304319586475";

export default node;
