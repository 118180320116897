import { graphql, readInlineData } from 'react-relay';

import { isMissingRefundEventInProgress_transaction$key } from './__generated__/isMissingRefundEventInProgress_transaction.graphql';

const transactionFragment = graphql`
    fragment isMissingRefundEventInProgress_transaction on Transaction @inline {
        summary {
            invoiceSummary {
                amountToSettleBuyerCurrentInvoice {
                    amount
                }
            }
        }
        transactionHistory {
            historicalEvents {
                name
                buyerCopy
            }
        }
    }
`;

export const isMissingRefundEventInProgress = (
    transactionRef: isMissingRefundEventInProgress_transaction$key | null | undefined
): boolean => {
    const transaction = readInlineData(transactionFragment, transactionRef);
    const historicalEvents = transaction?.transactionHistory?.historicalEvents || [];
    const amountToSettleBuyerCurrentInvoice =
        transaction?.summary?.invoiceSummary?.amountToSettleBuyerCurrentInvoice?.amount ?? 0;
    const lastBuyerEvent = [...historicalEvents].reverse().find(e => e?.buyerCopy);
    return lastBuyerEvent?.name === 'REFUND_SUBMITTED' && amountToSettleBuyerCurrentInvoice < 0;
};
