import { clearSearchParams } from './replaceSearchParam';
import { State, ActionType } from './types';
import {
    ACTION_SET_STEP,
    ACTION_SET_HELP_TYPE,
    ACTION_SET_ORDER_ID,
    ACTION_SET_TOPIC,
    ACTION_UPDATE_FORM_DATA,
    ACTION_GO_BACK,
    ACTION_SET_ITEM_ID,
    ACTION_SET_CONVERSATION_ID,
    RENDER_AS_CONSUMER,
    STEP_INTRO,
    STEP_ORDERS_LIST,
    STEP_SELECTED_ORDER,
    STEP_CHECKOUT_ASSISTANCE,
    STEP_SELECTED_LISTING,
    TOPIC_VALUE_CHECKOUT_ASSISTANCE,
} from './constants';

export const initialState: State = {
    step: STEP_INTRO,
    stepsPath: [STEP_INTRO],
    helpType: null,
    renderAs: RENDER_AS_CONSUMER,
    itemPk: '',
    orderId: '',
    onClose: () => {},
    isOpen: false,
    topicValue: '',
    showAuthModal: () => {},
    isEmbedded: false,
    userId: '',
    placement: 'globalNav',
    sellerId: '',
    isSeller: false,
    conversationId: '',
    formData: {
        name: '',
        email: '',
        message: '',
        file: null,
        errors: {
            name: '',
            email: '',
            message: '',
        },
    },
};

const setSearchParams = <T>(func: T, params?: string | null): void => {
    if (typeof func === 'function') {
        func(params || null);
    }
};

export function reducer(state: State, action: ActionType): State {
    switch (action.type) {
        case ACTION_SET_STEP: {
            window.scrollTo(0, 0);

            return {
                ...state,
                step: action.step,
                stepsPath: [...state.stepsPath, action.step],
            };
        }
        case ACTION_SET_HELP_TYPE: {
            clearSearchParams();
            setSearchParams<State['onHelpTypeChange']>(state.onHelpTypeChange, action.helpType);

            return { ...state, helpType: action.helpType };
        }
        case ACTION_SET_ORDER_ID: {
            setSearchParams<State['onOrderIdChange']>(state.onOrderIdChange, action.orderId);

            return { ...state, orderId: action.orderId };
        }
        case ACTION_SET_ITEM_ID: {
            return { ...state, itemPk: action.itemId };
        }
        case ACTION_SET_CONVERSATION_ID: {
            return { ...state, conversationId: action.conversationId };
        }
        case ACTION_SET_TOPIC: {
            setSearchParams<State['onTopicChange']>(state.onTopicChange, action.topic);

            return { ...state, topicValue: action.topic };
        }
        case ACTION_UPDATE_FORM_DATA: {
            return { ...state, formData: action.formData };
        }
        case ACTION_GO_BACK: {
            state.stepsPath.pop();
            const lastStep = state.stepsPath.slice(-1)[0];

            if (lastStep === STEP_INTRO) {
                clearSearchParams();
            }

            if (lastStep === STEP_ORDERS_LIST) {
                setSearchParams<State['onOrderIdChange']>(state.onOrderIdChange, null);
            }

            if (lastStep === STEP_SELECTED_ORDER || lastStep === STEP_SELECTED_LISTING) {
                setSearchParams<State['onTopicChange']>(state.onTopicChange, null);
            }

            return {
                ...state,
                stepsPath: state.stepsPath,
                step: lastStep,
                ...(lastStep === STEP_INTRO && { orderId: '', itemPk: '' }),
                ...(lastStep === STEP_CHECKOUT_ASSISTANCE && {
                    topicValue: TOPIC_VALUE_CHECKOUT_ASSISTANCE,
                }),
            };
        }
        default: {
            return state;
        }
    }
}
