/**
 * @generated SignedSource<<081d9c962a5f8513a5213853128d39c1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type getPostConfDeliveryDateDisplay_viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"shippingTrackingDetails_viewer">;
  readonly " $fragmentType": "getPostConfDeliveryDateDisplay_viewer";
};
export type getPostConfDeliveryDateDisplay_viewer$key = {
  readonly " $data"?: getPostConfDeliveryDateDisplay_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"getPostConfDeliveryDateDisplay_viewer">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "getPostConfDeliveryDateDisplay_viewer"
};

(node as any).hash = "21e8ead42581cf095e21346bc35cff1b";

export default node;
