import { graphql, readInlineData } from 'react-relay';

import { defineMessages, IntlShape } from 'dibs-react-intl';

import { getLastBuyerEvent } from './getLastBuyerEvent';
import { getReturnStatusCopy } from './getReturnStatusCopy';
import { isMissingRefundEventInProgress } from './isMissingRefundEventInProgress';

import {
    getBuyerEventHeader_transaction$key,
    getBuyerEventHeader_transaction$data as Transaction,
} from './__generated__/getBuyerEventHeader_transaction.graphql';

const PAYMENT_AUTHORIZATION_DENIED = 'PAYMENT_AUTHORIZATION_DENIED' as const;

type HistoricalEvent = NonNullable<
    NonNullable<NonNullable<Transaction>['transactionHistory']>['historicalEvents']
>[0];

const messages = defineMessages({
    defaultHeader: {
        id: 'getBuyerEventHeader.defaultHeader',
        defaultMessage: 'Processing',
    },
    orderUpdated: {
        id: 'getBuyerEventHeader.orderUpdated',
        defaultMessage: 'Order Updated',
    },
    checkoutProcessingHeader: {
        id: 'getBuyerEventHeader.checkoutProcessingHeader',
        defaultMessage: 'Processing',
    },
    deniedPaymentHeader: {
        id: 'getBuyerEventHeader.deniedPaymentHeader',
        defaultMessage: 'Action Required: Complete Payment',
    },
    checkoutProcessingFailedHeader: {
        id: 'getBuyerEventHeader.checkoutProcessingFailedHeader',
        defaultMessage: 'Processing Failed',
    },
    refundPending: {
        id: 'getBuyerEventHeader.refundPending',
        defaultMessage: 'Refund Pending',
    },
});

function getDefaultValue(lastBuyerEvent: HistoricalEvent, intl: IntlShape): string {
    let header = '';

    if (!lastBuyerEvent || !lastBuyerEvent?.buyerCopy) {
        header = intl.formatMessage(messages.defaultHeader);
        return header;
    }

    if (!lastBuyerEvent?.buyerStatusCopy?.header) {
        header = intl.formatMessage(messages.orderUpdated);
    }

    return header;
}

export const getBuyerEventHeader = ({
    transaction: transactionRef,
    intl,
}: {
    transaction: getBuyerEventHeader_transaction$key | null | undefined;
    intl: IntlShape;
}): string => {
    const transaction = readInlineData(
        graphql`
            fragment getBuyerEventHeader_transaction on Transaction @inline {
                transactionHistory {
                    historicalEvents {
                        name
                        buyerCopy
                        buyerStatusCopy(isBomtOptimizationVariant: true) {
                            header
                        }
                    }
                }
                returns {
                    serviceId
                }
                isCheckoutProcessing
                didCheckoutProcessingFail
                ...getLastBuyerEvent_transaction
                ...getReturnStatusCopy_transaction
                ...getDeniedPaymentAuthorizationStatus_transaction
                ...isMissingRefundEventInProgress_transaction
            }
        `,
        transactionRef
    );

    if (isMissingRefundEventInProgress(transaction)) {
        return intl.formatMessage(messages.refundPending);
    }

    const { transactionHistory, returns, isCheckoutProcessing, didCheckoutProcessingFail } =
        transaction || {};
    const { historicalEvents } = transactionHistory || {};

    const lastBuyerEventName = getLastBuyerEvent({ transaction })?.name;
    const lastBuyerEvent = (historicalEvents || []).find(e => e?.name === lastBuyerEventName);

    const returnObj = returns?.[0];
    const hasActiveReturn = returnObj?.serviceId;

    const defaultValue = getDefaultValue(lastBuyerEvent || null, intl);
    let buyerStatusHeader = lastBuyerEvent?.buyerStatusCopy?.header || defaultValue;

    if (hasActiveReturn) {
        const { header } = getReturnStatusCopy({ transaction, intl });

        buyerStatusHeader = header || '';
    }

    if (isCheckoutProcessing) {
        buyerStatusHeader = intl.formatMessage(messages.checkoutProcessingHeader);
    }

    if (lastBuyerEventName === PAYMENT_AUTHORIZATION_DENIED) {
        buyerStatusHeader = intl.formatMessage(messages.deniedPaymentHeader);
    }

    if (didCheckoutProcessingFail) {
        buyerStatusHeader = intl.formatMessage(messages.checkoutProcessingFailedHeader);
    }

    return buyerStatusHeader;
};
