import { FC, useContext } from 'react';
import classNames from 'classnames';
import { graphql, useFragment } from 'react-relay';

import { Link } from 'dibs-elements/exports/Link';
import { FormattedMessage } from 'dibs-react-intl';

import { GetHelpZendeskContext } from './GetHelpZendeskContext';
import { STEPS, ACTIONS } from './GetHelpZendeskConstants';
import { Step } from './GetHelpZendeskTypes';
import { trackGetHelpZendeskEvent } from './getHelpZendeskTracking';

import { GetHelpZendeskFooter_viewer$key } from './__generated__/GetHelpZendeskFooter_viewer.graphql';

import dibsCss from 'dibs-css';

const TRIGGER_TRACKING_MAP: Partial<Record<Step, string>> = {
    [STEPS.ORDER_INFO]: 'order info',
    [STEPS.NO_SEARCH_RESULTS]: 'no search results',
};

const GetHelpZendeskFooter: FC<{ viewer: GetHelpZendeskFooter_viewer$key }> = ({
    viewer: viewerRef,
}) => {
    const {
        state: { step, isCheckout },
        dispatch,
    } = useContext(GetHelpZendeskContext);

    const viewer = useFragment(
        graphql`
            fragment GetHelpZendeskFooter_viewer on Viewer {
                zendeskArticles {
                    article(articleId: $articleId) @include(if: $hasArticleId) {
                        title
                    }
                }
            }
        `,
        viewerRef
    );

    const showFooter =
        (step &&
            ([STEPS.ORDER_INFO, STEPS.ARTICLE, STEPS.NO_SEARCH_RESULTS] as Step[]).includes(
                step
            )) ||
        (step === STEPS.INTRO && isCheckout);

    if (!showFooter) {
        return null;
    }

    let trackingTrigger = '';

    if (step === STEPS.ARTICLE) {
        trackingTrigger = viewer?.zendeskArticles?.article?.title || '';
    } else {
        trackingTrigger = TRIGGER_TRACKING_MAP?.[step] || '';
    }

    return (
        <div
            className={classNames(
                dibsCss.pySmall,
                dibsCss.pxMedium,
                dibsCss.bgMoonstone,
                dibsCss.sassyFontBodyMedium
            )}
        >
            <FormattedMessage
                id="getHelp.GetHelpZendeskFooter.title"
                defaultMessage="Need further assistance? <contact>Contact Support</contact>"
                values={{
                    contact: content => (
                        <Link
                            onClick={() => {
                                dispatch({ type: ACTIONS.SET_STEP, step: STEPS.CONTACT_SUPPORT });
                                trackGetHelpZendeskEvent({
                                    stepInteractionName: 'contact support channels',
                                    trigger: trackingTrigger,
                                });
                            }}
                        >
                            <span className={dibsCss.sassyFontBodyMediumHeavy}>{content}</span>
                        </Link>
                    ),
                }}
            />
        </div>
    );
};

export { GetHelpZendeskFooter };
