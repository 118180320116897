import { FC, useState, useCallback, useContext, useTransition } from 'react';
import classnames from 'classnames';
import { usePaginationFragment, graphql, useLazyLoadQuery } from 'react-relay';
import { FormattedMessage, defineMessages, useIntl } from 'dibs-react-intl';
import { useDebouncedCallback } from 'dibs-react-hooks/exports/useDebouncedCallback';

import { Button } from 'dibs-elements/exports/Button';
import { Input } from 'dibs-elements/exports/Input';
import { Link } from 'dibs-elements/exports/Link';
import { Spinner } from 'dibs-elements/exports/Spinner';
import Close from 'dibs-icons/exports/legacy/Close';
import MagnifyingGlass from 'dibs-icons/exports/legacy/MagnifyingGlass';

import GetHelpHeader from './GetHelpHeader';
import ListSkeletonLoader from './ListSkeletonLoader';
import ItemsListItem from './ItemsListItem';

import GetHelpContext from './GetHelpContext';
import {
    ACTION_SET_STEP,
    ACTION_SET_TOPIC,
    ACTION_SET_HELP_TYPE,
    STEP_INTRO,
    STEP_TOPIC,
    HELP_TYPE_OTHER_ASSISTANCE,
} from './constants';

import { ItemsList_viewer$key } from './__generated__/ItemsList_viewer.graphql';
import { ItemsListQuery } from './__generated__/ItemsListQuery.graphql';
import { ItemsListPaginationQuery } from './__generated__/ItemsListPaginationQuery.graphql';

import styles from './styles/SharedListStyles.scss';

const messages = defineMessages({
    placeholder: {
        id: 'getHelp.ItemsList.placeholder',
        defaultMessage: 'Search by Seller, Item Ref or Title',
    },
});

const MIN_SEARCH_LENGTH = 3;

const ItemNotListed: FC = () => {
    const { dispatch } = useContext(GetHelpContext);

    return (
        <Link
            dataTn="get-help-modal-item-not-listed-link"
            onClick={() => {
                dispatch({ type: ACTION_SET_TOPIC, topic: 'itemNotFound' });
                dispatch({ type: ACTION_SET_HELP_TYPE, helpType: HELP_TYPE_OTHER_ASSISTANCE });
                dispatch({ type: ACTION_SET_STEP, step: STEP_TOPIC });
            }}
            className={styles.resultNotFound}
        >
            <FormattedMessage
                id="getHelp.ItemsList.itemNotListed"
                defaultMessage="I can't find my listing"
            />
        </Link>
    );
};

const ItemsList: FC = () => {
    const intl = useIntl();
    const {
        dispatch,
        state: { sellerId, isEmbedded },
    } = useContext(GetHelpContext);

    const [searchTerm, setSearchTerm] = useState('');
    const [hasSearched, setHasSearched] = useState(false);
    const [isRefetching, startTransition] = useTransition();

    const { viewer } = useLazyLoadQuery<ItemsListQuery>(
        graphql`
            query ItemsListQuery(
                $searchTerm: String!
                $hasSellerId: Boolean!
                $dealerPk: [String]
            ) {
                viewer {
                    ...ItemsList_viewer @include(if: $hasSellerId)
                }
            }
        `,
        {
            hasSellerId: !!sellerId,
            searchTerm: '',
            dealerPk: [sellerId],
        }
    );

    const { data, hasNext, loadNext, isLoadingNext, refetch } = usePaginationFragment<
        ItemsListPaginationQuery,
        ItemsList_viewer$key
    >(
        graphql`
            fragment ItemsList_viewer on Viewer
            @refetchable(queryName: "ItemsListPaginationQuery")
            @argumentDefinitions(
                after: { type: String, defaultValue: "" }
                first: { type: Int, defaultValue: 10 }
            ) {
                items(
                    dealerPk: $dealerPk
                    first: $first
                    after: $after
                    searchTerm: $searchTerm
                    queryTemplate: "dealer.inventory.management"
                    sort: "-createDate"
                    includeContextAdmin: true
                ) @connection(key: "ItemsList_items") {
                    edges {
                        node {
                            serviceId
                            ...ItemsListItem_item
                        }
                    }
                }
            }
        `,
        viewer || null
    );

    const fetchData = useCallback(
        (val: string) => {
            startTransition(() => {
                setHasSearched(true);
                refetch({ searchTerm: val });
            });
        },
        [refetch, startTransition]
    );

    const [debouncedFetchData] = useDebouncedCallback(fetchData, 500);

    const onChange = (searchTermValue: string): void => {
        setSearchTerm(searchTermValue);
        if (searchTermValue.length >= MIN_SEARCH_LENGTH || !searchTermValue) {
            debouncedFetchData(searchTermValue);
        }
    };

    const items = data?.items?.edges || [];
    const noItemsFound = !items.length && searchTerm;
    const hasItems = !!items.length;
    const noRecentItems = !hasItems && !hasSearched;

    return (
        <div
            className={classnames({
                [styles.noRecentResults]: noRecentItems,
                [styles.isEmbedded]: isEmbedded,
            })}
        >
            <GetHelpHeader
                title={
                    <FormattedMessage
                        id="getHelp.ItemsList.greeting"
                        defaultMessage="Select listing"
                    />
                }
            />
            <div className={styles.container}>
                {(hasItems || hasSearched) && (
                    <div className={styles.searchInputWrapper}>
                        <Input
                            dataTn="getHelp-items-list-input"
                            size="medium"
                            value={searchTerm}
                            onChange={e => onChange(e.target.value)}
                            placeholder={intl.formatMessage(messages.placeholder)}
                            rightDecorator={
                                <div className={styles.inputRightDecorator}>
                                    {searchTerm && (
                                        <Link
                                            className={styles.inputIconRemoveWrapper}
                                            onClick={() => onChange('')}
                                        >
                                            <Close className={styles.inputIconRemove} />
                                        </Link>
                                    )}
                                </div>
                            }
                            leftDecorator={<MagnifyingGlass className={styles.inputIconGlass} />}
                        />
                    </div>
                )}
                {isRefetching ? (
                    <ListSkeletonLoader />
                ) : (
                    <>
                        {noRecentItems && (
                            <div className={styles.noRecentResultsView}>
                                <div className={styles.noRecentResultsViewInner}>
                                    <div className={styles.noRecentResultsLabel}>
                                        <FormattedMessage
                                            id="getHelp.ItemsList.noItemsLabel"
                                            defaultMessage="You don’t have any recent listings."
                                        />
                                    </div>
                                    <Button
                                        dataTn="get-help-modal-select-other-topic-cta"
                                        type="transparent"
                                        onClick={() =>
                                            dispatch({
                                                type: ACTION_SET_STEP,
                                                step: STEP_INTRO,
                                            })
                                        }
                                        className={styles.otherAssistance}
                                    >
                                        <FormattedMessage
                                            id="getHelp.ItemsList.seeOtherTopic"
                                            defaultMessage="Select other topic"
                                        />
                                    </Button>
                                </div>
                            </div>
                        )}
                        <div
                            className={classnames(styles.resultsContainer, {
                                [styles.isEmbedded]: isEmbedded,
                            })}
                        >
                            {items.map(item => (
                                <ItemsListItem
                                    key={item?.node?.serviceId || ''}
                                    item={item?.node || null}
                                />
                            ))}

                            {isLoadingNext && hasNext && (
                                <div className={styles.spinnerContainer}>
                                    <Spinner />
                                </div>
                            )}
                            {hasNext && !isLoadingNext && (
                                <Button
                                    dataTn="get-help-modal-show-more-items-cta"
                                    onClick={() => loadNext(10)}
                                    className={styles.loadMore}
                                    fullWidth
                                >
                                    <FormattedMessage
                                        id="getHelp.ItemsList.loadMore"
                                        defaultMessage="Show More"
                                    />
                                </Button>
                            )}
                            {noItemsFound && (
                                <div className={styles.noResultsFound}>
                                    <FormattedMessage
                                        id="getHelp.ItemsList.noResultsFound"
                                        defaultMessage="We couldn’t find any results for “{searchTerm}”"
                                        values={{ searchTerm }}
                                    />
                                </div>
                            )}
                            {((!hasNext && hasItems) || (!hasItems && hasSearched)) && (
                                <ItemNotListed />
                            )}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default ItemsList;
