import { FC, useContext } from 'react';

import { FormattedMessage } from 'dibs-react-intl';
import { Link } from 'dibs-elements/exports/Link';

import GetHelpContext from './GetHelpContext';
import { trackContactOptionsExpanded } from './tracking';

import dibsCss from 'dibs-css';

const ContactSupportLink: FC<{ onOtherHelpClick: () => void }> = ({ onOtherHelpClick }) => {
    const {
        state: { topicValue },
    } = useContext(GetHelpContext);

    return (
        <Link
            onClick={() => {
                onOtherHelpClick();
                trackContactOptionsExpanded(topicValue);
            }}
            underline="underline"
        >
            <span className={dibsCss.sassyFontBody}>
                <FormattedMessage
                    id="getHelp.ContactSupportLink.contactSupport"
                    defaultMessage="<bold>Still need help?</bold> Contact support"
                    values={{
                        bold: content => (
                            <span className={dibsCss.sassyFontBodyTypeHeavy}>{content}</span>
                        ),
                    }}
                />
            </span>
        </Link>
    );
};

export default ContactSupportLink;
