import dibsCss from 'dibs-css';
import classnames from 'classnames';
import { FC } from 'react';

type PaddleOutlinedProps = {
    className?: string;
    role?: 'img';
    ariaLabel?: string;
    ariaLabelledby?: string;
};

const PaddleOutlined: FC<PaddleOutlinedProps> = props => {
    return (
        <svg
            className={classnames(dibsCss.dibsIcon, props.className)}
            data-tn={'paddle-outlined'}
            role={props.role}
            aria-label={props.ariaLabel}
            aria-labelledby={props.ariaLabelledby}
            viewBox="0 0 250 250"
            fill="none"
        >
            <path d="M145.6 0h-41.2C56.5 0 17.6 39 17.6 86.9c0 47.9 39 86.9 86.9 86.9h41.2c47.9 0 86.9-39 86.9-86.9 0-47.9-39.1-86.9-87-86.9zm0 164.6h-41.2c-42.9 0-77.8-34.9-77.8-77.8S61.5 9 104.4 9h41.2c42.9 0 77.8 34.9 77.8 77.8 0 43-34.9 77.8-77.8 77.8z" />
            <path d="M99.9 228.8c0 11.7 10.4 21.2 23.1 21.2h4.1c12.7 0 23-9.5 23-21.1v-46H99.9v45.9zm9-36.8H141v37c0 6.6-6.2 12-13.9 12H123c-7.8 0-14.1-5.4-14.1-12.1V192z" />
        </svg>
    );
};

export default PaddleOutlined;
