import dibsCss from 'dibs-css';
import classnames from 'classnames';
import { FunctionComponent } from 'react';

type CreditCardProps = {
    className?: string;
};

const CreditCard: FunctionComponent<CreditCardProps> = props => {
    return (
        <svg
            className={classnames(dibsCss.dibsIcon, props.className)}
            data-tn={'credit-card'}
            viewBox="0 0 250 250"
        >
            <path d="M230.4 71.4h-14.9L211 38.9c-1.5-10.7-11.4-18.2-22.1-16.7l-172 24.1c-5.2.7-9.8 3.4-12.9 7.6S-.5 63.2.2 68.4l16.4 116.9c.7 5.2 3.4 9.7 7.6 12.9 3.4 2.6 7.5 4 11.8 4h1.2v6.9c0 10.8 8.8 19.6 19.6 19.6h173.7c10.8 0 19.6-8.8 19.6-19.6V91c-.1-10.8-8.9-19.6-19.7-19.6zM241 91v26.8H46.2V91c0-5.8 4.7-10.5 10.5-10.5h173.7c5.8-.1 10.6 4.7 10.6 10.5zM9.1 67.1c-.4-2.8.3-5.5 2-7.8s4.2-3.7 6.9-4.1l172-24.1c.5-.1 1-.1 1.5-.1 5.2 0 9.7 3.8 10.4 9.1l3.7 26.6-33.6 4.7H56.7c-10.6 0-19.2 8.4-19.5 18.9l-24.3 3.4-3.8-26.6zM29.6 191c-2.3-1.7-3.7-4.2-4.1-6.9l-11.4-81.4 23-3.2V193c-2.6.3-5.3-.4-7.5-2zm200.8 28.6H56.7c-5.8 0-10.5-4.7-10.5-10.5v-82.2H241V209c0 5.8-4.8 10.6-10.6 10.6z" />
            <path d="M73 162.5h45.7c2.5 0 4.5-2 4.5-4.5s-2-4.5-4.5-4.5H73c-2.5 0-4.5 2-4.5 4.5s2 4.5 4.5 4.5z" />
            <path d="M158.9 183.1H73c-2.5 0-4.5 2-4.5 4.5s2 4.5 4.5 4.5h85.8c2.5 0 4.5-2 4.5-4.5.1-2.5-1.9-4.5-4.4-4.5z" />
        </svg>
    );
};
export default CreditCard;
