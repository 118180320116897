import dibsCss from 'dibs-css';
import classnames from 'classnames';
import { FunctionComponent } from 'react';

type CopyProps = {
    className?: string;
};

const Copy: FunctionComponent<CopyProps> = props => {
    return (
        <svg
            className={classnames(dibsCss.dibsIcon, props.className)}
            data-tn={'copy'}
            viewBox="0 0 250 250"
        >
            <path d="M185.5 49.1V0H17v200.9h47.5V250H233V49.1h-47.5zm-121 142.8H26V9h150.5v40.1h-112v142.8zM224 241H73.5V58.1H224V241z" />
            <path d="M199 103.5H98.5c-2.5 0-4.5 2-4.5 4.5s2 4.5 4.5 4.5H199c2.5 0 4.5-2 4.5-4.5s-2-4.5-4.5-4.5z" />
            <path d="M199 132H98.5c-2.5 0-4.5 2-4.5 4.5s2 4.5 4.5 4.5H199c2.5 0 4.5-2 4.5-4.5s-2-4.5-4.5-4.5z" />
            <path d="M199 160.4H98.5c-2.5 0-4.5 2-4.5 4.5s2 4.5 4.5 4.5H199c2.5 0 4.5-2 4.5-4.5s-2-4.5-4.5-4.5z" />
        </svg>
    );
};
export default Copy;
