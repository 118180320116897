/**
 * @generated SignedSource<<42aad1dd9af7789caa609517625f46c2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type ShipmentType = "EXTERNALLY_ARRANGED" | "FIRSTDIBS_FACILITATED" | "LOCAL_PICKUP" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type shippingTrackingDetails_transaction$data = {
  readonly currentShipmentQuote: {
    readonly handlingTime: {
      readonly displayValue: string | null;
    } | null;
    readonly serviceMethod: {
      readonly describeRange: string | null;
    } | null;
  } | null;
  readonly leadTime: {
    readonly hasLeadTime: boolean | null;
    readonly value: string | null;
  } | null;
  readonly pendingShipmentChangeReview: {
    readonly shipment: {
      readonly collectionTime: {
        readonly displayValue: string | null;
      } | null;
      readonly handlingTime: {
        readonly displayValue: string | null;
      } | null;
      readonly serviceMethod: {
        readonly describeRange: string | null;
      } | null;
      readonly shipmentType: ShipmentType | null;
    } | null;
    readonly shipmentQuote: {
      readonly handlingTime: {
        readonly displayValue: string | null;
      } | null;
      readonly serviceMethod: {
        readonly describeRange: string | null;
      } | null;
    } | null;
  } | null;
  readonly shipment: {
    readonly collectionTime: {
      readonly displayValue: string | null;
    } | null;
    readonly deliveryDateRangeDisplay: string | null;
    readonly estimatedShippingDate: string | null;
    readonly handlingTime: {
      readonly displayValue: string | null;
    } | null;
    readonly leadTimeMax: number | null;
    readonly leadTimeMin: number | null;
    readonly serviceMethod: {
      readonly describeRange: string | null;
    } | null;
  } | null;
  readonly sku: {
    readonly isAvailableNow: boolean | null;
    readonly leadTime: string | null;
  } | null;
  readonly " $fragmentType": "shippingTrackingDetails_transaction";
};
export type shippingTrackingDetails_transaction$key = {
  readonly " $data"?: shippingTrackingDetails_transaction$data;
  readonly " $fragmentSpreads": FragmentRefs<"shippingTrackingDetails_transaction">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "shippingTrackingDetails_transaction"
};

(node as any).hash = "4ad8b6995153fbc0fcb88530a3c34716";

export default node;
