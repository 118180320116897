/**
 * @generated SignedSource<<c9304673964edfa69cd36786c667b168>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TopicInfo_viewer$data = {
  readonly getHelpTopic: {
    readonly instructionalText: string | null;
    readonly label: string | null;
    readonly primaryCta: {
      readonly label: string | null;
      readonly link: string | null;
    } | null;
    readonly showContactButton: boolean | null;
    readonly showReturnPolicy: boolean | null;
    readonly showTrackingDetails: boolean | null;
    readonly supportCenterLink: string | null;
    readonly topicKey: string | null;
    readonly " $fragmentSpreads": FragmentRefs<"UpdatedContactSellerForm_getHelpTopic">;
  } | null;
  readonly transaction?: {
    readonly " $fragmentSpreads": FragmentRefs<"UpdatedContactSellerForm_transaction">;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"ItemReturnPolicy_viewer" | "OpenConversationCta_viewer" | "TrackingDetails_viewer">;
  readonly " $fragmentType": "TopicInfo_viewer";
};
export type TopicInfo_viewer$key = {
  readonly " $data"?: TopicInfo_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"TopicInfo_viewer">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "hasOrderId"
    },
    {
      "kind": "RootArgument",
      "name": "isSeller"
    },
    {
      "kind": "RootArgument",
      "name": "orderId"
    },
    {
      "kind": "RootArgument",
      "name": "topicKey"
    },
    {
      "kind": "RootArgument",
      "name": "transactionId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "TopicInfo_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TrackingDetails_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OpenConversationCta_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ItemReturnPolicy_viewer"
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "isSeller",
          "variableName": "isSeller"
        },
        {
          "kind": "Variable",
          "name": "orderId",
          "variableName": "orderId"
        },
        {
          "kind": "Variable",
          "name": "topicKey",
          "variableName": "topicKey"
        }
      ],
      "concreteType": "GetHelpTopic",
      "kind": "LinkedField",
      "name": "getHelpTopic",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "topicKey",
          "storageKey": null
        },
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "instructionalText",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "GetHelpTopicTypePrimaryCta",
          "kind": "LinkedField",
          "name": "primaryCta",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "link",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "supportCenterLink",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "showContactButton",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "showTrackingDetails",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "showReturnPolicy",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "UpdatedContactSellerForm_getHelpTopic"
        }
      ],
      "storageKey": null
    },
    {
      "condition": "hasOrderId",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "transactionId",
              "variableName": "transactionId"
            }
          ],
          "concreteType": "Transaction",
          "kind": "LinkedField",
          "name": "transaction",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "UpdatedContactSellerForm_transaction"
            }
          ],
          "storageKey": null
        }
      ]
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
})();

(node as any).hash = "9d58ed440baff29c270a686256dbf1ba";

export default node;
