import { FC, useState } from 'react';
import { FormattedMessage } from 'dibs-react-intl';
import classnames from 'classnames';

import Envelope from 'dibs-icons/exports/legacy/Envelope';
import { ExpandingArea } from 'dibs-elements/exports/ExpandingArea';
import { GetHelpZendeskSendMessageForm } from './GetHelpZendeskSendMessageForm';

import dibsCss from 'dibs-css';

const GetHelpZendeskSendMessageButton: FC<{ topicValue: string }> = ({ topicValue }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    return (
        <div
            className={classnames(
                dibsCss.border,
                dibsCss.borderDolphin,
                dibsCss.borderSolid,
                dibsCss.roundedXxsmall,
                dibsCss.pySmall,
                dibsCss.pxMedium,
                dibsCss.textLeft
            )}
        >
            <ExpandingArea
                dataTn="contact-send-email"
                label={
                    <div className={classnames(dibsCss.flex, dibsCss.itemsCenter)}>
                        <Envelope
                            className={classnames(
                                dibsCss.wMedium,
                                dibsCss.hMedium,
                                dibsCss.mrMedium
                            )}
                        />
                        <div>
                            <div className={dibsCss.sassyFontBodyMediumHeavy}>
                                <FormattedMessage
                                    id="getHelp.SendMessageButton.label"
                                    defaultMessage="Send Email"
                                />
                            </div>
                            <div>
                                <FormattedMessage
                                    id="getHelp.SendMessageButton.info"
                                    defaultMessage="Typically respond within 24 hours"
                                />
                            </div>
                        </div>
                    </div>
                }
                expanded={isExpanded}
                onClick={() => setIsExpanded(!isExpanded)}
                showArrow
            >
                <div>
                    <GetHelpZendeskSendMessageForm topicValue={topicValue} />
                </div>
            </ExpandingArea>
        </div>
    );
};

export { GetHelpZendeskSendMessageButton };
