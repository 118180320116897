import { FC, useContext, Suspense, useRef } from 'react';
import classnames from 'classnames';
import SV from 'server-vars';

import { ErrorBoundary } from 'dibs-error-tools/ErrorBoundary';
import { ModalContainer } from 'dibs-elements/src/ModalContainer';
import { ModalSpinner } from 'dibs-elements/exports/ModalSpinner';
import { GetHelpZendeskModalRenderer } from './GetHelpZendeskModalRenderer';
import { GetHelpZendeskError } from './GetHelpZendeskError';

import { localStorage } from 'dibs-browser-storage';
import { GetHelpZendeskContext } from './GetHelpZendeskContext';
import { GET_HELP_MODAL_ZENDESK_OPTIONS } from './GetHelpZendeskConstants';
import { trackGetHelpZendeskEvent } from './getHelpZendeskTracking';
import { getGetHelpZendeskLocalStorageState } from './getHelpZendeskHelpers';

import dibsCss from 'dibs-css';
import styles from './GetHelpZendeskModal.scss';

// 2 hours
const MODAL_EXPIRATION_INTERVAL = 7200000;

const isDesktop = SV.get('settings.isDesktop');

const GetHelpZendeskModal: FC = () => {
    const { state } = useContext(GetHelpZendeskContext);
    const dragHandle = useRef<HTMLDivElement>(null);
    const { isOpen, onClose, orderId, step, articleId, stepsPath } = state;

    const getHelpModalZendesk = getGetHelpZendeskLocalStorageState();

    const presetPositionX = getHelpModalZendesk?.positionX;
    const presetPositionY = getHelpModalZendesk?.positionY;

    const onModalOpen = (): void => {
        if (!getHelpModalZendesk?.isOpen) {
            localStorage.setItem(GET_HELP_MODAL_ZENDESK_OPTIONS, {
                isOpen: true,
                timestamp: Date.now(),
                orderId,
                step,
                articleId,
                stepsPath,
            });
        } else {
            trackGetHelpZendeskEvent({
                stepInteractionName: 'Navigation With Get Help Modal Opened',
                trigger: 'Log the clicked navigation link',
            });

            const modalTimestamp = getHelpModalZendesk?.timestamp;

            if (!modalTimestamp) {
                return;
            }

            if (Date.now() - modalTimestamp > MODAL_EXPIRATION_INTERVAL) {
                onClose({ isClosedAutomatically: true });
            }
        }
    };

    const setPosition = (x: number, y: number): void => {
        localStorage.setItem(GET_HELP_MODAL_ZENDESK_OPTIONS, {
            isOpen: true,
            positionX: x,
            positionY: y,
        });

        trackGetHelpZendeskEvent({
            stepInteractionName: 'Get Help Modal Dragged',
        });
    };

    return (
        <ModalContainer
            dataTn="get-help-zendesk-modal"
            isOpen={isOpen}
            onClose={() => onClose()}
            modalPosition="rightTop"
            closeOnOutsideClick={false}
            {...(isDesktop && {
                modalClass: classnames(styles.modal, dibsCss.roundedXxsmall),
                preventBackScroll: false,
                removeOverlay: true,
                onOpen: onModalOpen,
                draggableOptions: {
                    isDraggable: true,
                    dragHandleRef: dragHandle,
                    presetPositionX,
                    presetPositionY,
                    setPosition,
                },
            })}
        >
            {isDesktop && (
                <div
                    ref={dragHandle}
                    className={classnames(
                        styles.dragArea,
                        dibsCss.wFull,
                        dibsCss.hMedium,
                        dibsCss.sticky,
                        dibsCss.top0,
                        dibsCss.zLow
                    )}
                />
            )}
            <ErrorBoundary fallback={() => <GetHelpZendeskError renderOutsideContent />}>
                <Suspense fallback={<ModalSpinner isOpen />}>
                    <GetHelpZendeskModalRenderer />
                </Suspense>
            </ErrorBoundary>
        </ModalContainer>
    );
};

export { GetHelpZendeskModal };
